import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPageAccess } from "../actions/dataActions"
import { getInstalledAddOnData } from "../actions/organizationActions/addOnsActions";
import { RESET_LIVECAM_VIDEO_REF } from "../constants/livecamConstant";
import { invoiceRoles } from "../actions/userActions";

function Sidebar(props) {
    const [isPipModeStart, setIsPipModeStart] = useState(false);
    const [invoiceRole, setInvoiceRole] = useState([
        "annotator user",
        "po user",
        "security guard",
        "ocr checker",
        "grn user",
        "finance user"
    ]);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const dispatch = useDispatch();

    const installedAddOnDataGet = useSelector((state) => state.InstalledAddOnData);
    const { success: getInstalledAddOnDataSuccess } = installedAddOnDataGet;

    const videoRefGet = useSelector((state) => state.videoRefGet);
    const { videoRefObj } = videoRefGet;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    useLayoutEffect(() => {
        dispatch(getPageAccess())
        dispatch(getInstalledAddOnData())
    }, [])

    useEffect(() => {
        videoRefObj?.on('enterpictureinpicture', function () {
            setIsPipModeStart(true)
        });
        videoRefObj?.on('leavepictureinpicture', function () {
            setIsPipModeStart(false)
            videoRefObj?.dispose();
            dispatch({ type: RESET_LIVECAM_VIDEO_REF })
        });
    }, [videoRefObj])

    let navPages = useMemo(() => {
        let addon = {}
        getInstalledAddOnDataSuccess?.forEach((item) => {
            if (item.installed_status && item.is_pinned) {
                addon[item.name] = 'ri-tools-fill'
            }
        })

        return ({
            home: 'ri-home-2-line',
            livecam: 'ri-camera-line',
            services: 'ri-tools-fill',
            events: 'ri-layout-masonry-line',
            analytics: 'ri-line-chart-line',
            organization: 'ri-stack-line',
            ...addon,
            settings: 'ri-settings-4-line'
        })

    }, [getInstalledAddOnDataSuccess])

    const disposeVideoObject = () => {
        if (videoRefObj) {
            if (!isPipModeStart && !videoRefObj.isDisposed()) {
                videoRefObj.dispose();
                dispatch({ type: RESET_LIVECAM_VIDEO_REF })
            }
        }
    }
    return (
        <div className="sidebar_left">
            <button
                className="bg-transparent pe-4 d-flex align-items-center ms-auto d-md-none"
                onClick={() => props.setDrawerShow(e => !e)}
            >
                <i className={`ri-menu-${props.drawerShow ? 'fold' : 'unfold'}-line fs-4`}></i>
            </button>
            <div className="sidbar_logo d-flex justify-content-center mt-2 mt-md-3">
                <div className="nwarch-logo">NWarch <span className="text-white">AI</span></div>
            </div>
            <div>
                <ul>
                    {Object.keys(navPages).map((e, i) => {
                        if (invoiceRole.includes(userInfo?.user_role)) {
                            if (accessPage?.[e]?.read?.includes(userInfo?.user_role)) {
                                return (
                                    <li className={`menu_links`} key={`${i + 1}`}>
                                        <NavLink
                                            to={`/${e}`}
                                            className={`d-flex align-items-center link_options text-capitalize`}
                                            activeclassname="active"
                                            onClick={disposeVideoObject}
                                        >
                                            <i className={`${navPages[e]} me-2`}></i>
                                            {e.replaceAll('_', " ")}
                                        </NavLink>
                                    </li>
                                );
                            }
                        } else {
                            return (
                                <li className={`menu_links`} key={`${i + 1}`}>
                                    <NavLink
                                        to={`/${e}`}
                                        className={`d-flex align-items-center link_options text-capitalize`}
                                        activeclassname="active"
                                        onClick={disposeVideoObject}
                                    >
                                        <i className={`${navPages[e]} me-2`}></i>
                                        {e.replaceAll('_', " ")}
                                    </NavLink>
                                </li>
                            );
                        }
                    })}
                </ul>
                <div className="position-absolute bottom-0 left-0 text-center bottom_powered mb-3">
                    <h6 style={{ fontSize: "10px", color: "var(--powered_by)" }} className="m-0">Powered by D&W</h6>
                    <div style={{ fontSize: "10px", marginBottom: "10px", color: "var(--powered_by)" }} className="m-0 opacity">Version {accessPage?.software_version}</div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
