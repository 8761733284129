import { Button } from 'antd';
import React, { useRef } from 'react'
function UploadCSV({ onChange, loading, tooltipContent }) {
    const tooltip = useRef(null);
    return (
        <div
            className="position-relative"
            onMouseEnter={() => tooltip.current.className = "position-absolute d-block"}
            onMouseLeave={() => tooltip.current.className = "position-absolute d-none"}
        >
            <input
                type="file"
                id="upload-csv-file"
                accept='.csv, .xlsx'
                className="d-none"
                onChange={onChange}
            />
            <div ref={tooltip} className='position-absolute d-none' id="upload-file-tooltip" >
                <div style={{
                    background: "rgba(51, 51, 51, 0.06)",
                    padding: "8px 9px",
                    borderRadius: "5.38117px"
                }
                }>
                    {tooltipContent}
                </div>
            </div>
            {loading ? (
                <Button className='bg-dark m-0 cursor-pointer d-flex align-items-center rounded-1 text-nowrap border is-disable upload-btn text-white h-100' size="large" loading>
                    <i className="ri-upload-2-line"></i>
                </Button>
            ) : (
                <label htmlFor="upload-csv-file" className="upload-btn bg-dark border m-0 cursor-pointer text-nowrap rounded-1 d-flex align-items-center text-white h-100">
                    <i className="ri-upload-2-line"></i>
                </label>
            )}
        </div>
    )
}
export default UploadCSV
