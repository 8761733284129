import {
    SERVICES_DATA_LIST_REQUEST,
    SERVICES_DATA_LIST_SUCCESS,
    SERVICES_DATA_LIST_FAIL,

    SERVICES_DATA_REQUEST,
    SERVICES_DATA_SUCCESS,
    SERVICES_DATA_FAIL,

    GET_SINGLE_SERVICES_DATA_REQUEST,
    GET_SINGLE_SERVICES_DATA_SUCCESS,
    GET_SINGLE_SERVICES_DATA_FAIL,

    ALL_SERVICES_DELETE_REQUEST,
    ALL_SERVICES_DELETE_SUCCESS,
    ALL_SERVICES_DELETE_FAIL,
    ALL_SERVICES_DELETE_RESET,

    CAMERA_DATA_REQUEST,
    CAMERA_DATA_SUCCESS,
    CAMERA_DATA_FAIL,
    CAMERA_DATA_RESET,

    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    ADD_SERVICE_FAIL,
    ADD_SERVICE_RESET,

    SERVICE_CONFIG_DATA_REQUEST,
    SERVICE_CONFIG_DATA_SUCCESS,
    SERVICE_CONFIG_DATA_FAIL,

    CREATE_SERVICE_CONFIG_USER_REQUEST,
    CREATE_SERVICE_CONFIG_USER_SUCCESS,
    CREATE_SERVICE_CONFIG_USER_FAIL,

    SERVICE_CONFIG_DELETE_REQUEST,
    SERVICE_CONFIG_DELETE_SUCCESS,
    SERVICE_CONFIG_DELETE_FAIL,
    SERVICE_CONFIG_DELETE_RESET,

    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_REQUEST,
    SERVICE_DELETE_FAIL,
    SERVICE_DELETE_RESET,

    SERVICES_CREATE_REQUEST,
    SERVICES_CREATE_SUCCESS,
    SERVICES_CREATE_FAIL,
    SERVICES_CREATE_RESET,

    SERVICES_UPDATE_REQUEST,
    SERVICES_UPDATE_SUCCESS,
    SERVICES_UPDATE_FAIL,
    SERVICES_UPDATE_RESET,

    GET_SERVICE_USER_REQUEST,
    GET_SERVICE_USER_SUCCESS,
    GET_SERVICE_USER_FAIL,
    GET_SERVICE_USER_RESET,

    GET_ALERT_REQUEST,
    GET_ALERT_SUCCESS,
    GET_ALERT_FAIL,


    ADD_FACE_REQUEST,
    ADD_FACE_SUCCESS,
    ADD_FACE_FAIL,
    ADD_FACE_RESET,

    BUY_SERVICE_REQUEST,
    BUY_SERVICE_SUCCESS,
    BUY_SERVICE_FAIL,
    BUY_SERVICE_RESET,

    SERVICE_ON_OFF_REQUEST,
    SERVICE_ON_OFF_SUCCESS,
    SERVICE_ON_OFF_FAIL,
    SERVICE_ON_OFF_RESET,

    GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST,
    GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
    GET_TIME_WISE_CAMERA_SCHEDULE_FAIL,


    UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST,
    UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
    UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL,
    UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET,

    UPDATE_SERVICE_VERSION_REQUEST,
    UPDATE_SERVICE_VERSION_SUCCESS,
    UPDATE_SERVICE_VERSION_FAIL,
    UPDATE_SERVICE_VERSION_RESET,

    RETRAIN_PROCESS_REQUEST,
    RETRAIN_PROCESS_SUCCESS,
    RETRAIN_PROCESS_FAIL,
    RETRAIN_PROCESS_RESET,

    CHECK_RETRAIN_STATUS_REQUEST,
    CHECK_RETRAIN_STATUS_SUCCESS,
    CHECK_RETRAIN_STATUS_FAIL,
    CHECK_RETRAIN_STATUS_RESET


} from "../constants/servicesConstant.js";

export const getTimeWiseCameraScheduleReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST:
            return { loading: true };

        case GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS:
            return { loading: false, success: action.payload };

        case GET_TIME_WISE_CAMERA_SCHEDULE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const updateTimeWiseCameraScheduleReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST:
            return { loading: true };

        case UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS:
            return { loading: false, success: true };

        case UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL:
            return { loading: false, error: action.payload };

        case UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET:
            return {};

        default:
            return state;
    }
};


export const servicesListReducer = (state = { serviceData: {} }, action) => {
    switch (action.type) {
        case SERVICES_DATA_LIST_REQUEST:
            const getServices = { ...state.serviceData }

            return { loading: Object.values(getServices).length === 0, serviceData: getServices };

        case SERVICES_DATA_LIST_SUCCESS:
            return { loading: false, serviceData: action.payload };

        case SERVICES_DATA_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const getServicesDataReducer = (state = { serviceData: {} }, action) => {
    switch (action.type) {
        case SERVICES_DATA_REQUEST:
            const getServices = { ...state.serviceData }

            return { serviceData: { ...getServices }, loading: Object.values(getServices).length === 0 };

        case SERVICES_DATA_SUCCESS:
            return { serviceData: action.payload, loading: false };

        case SERVICES_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const getSingleServicesDataReducer = (state = { success: {} }, action) => {
    switch (action.type) {
        case GET_SINGLE_SERVICES_DATA_REQUEST:
            return { success: { ...state.success }, loading: true };

        case GET_SINGLE_SERVICES_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_SINGLE_SERVICES_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};


export const serviceConfigReducer = (state = { serviceConfigData: {} }, action) => {
    switch (action.type) {
        case SERVICE_CONFIG_DATA_REQUEST:
            return { loading: true };

        case SERVICE_CONFIG_DATA_SUCCESS:
            return { loading: false, serviceConfigData: action.payload };

        case SERVICE_CONFIG_DATA_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const serviceConfigDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_CONFIG_DELETE_REQUEST:
            return { loading: true };

        case SERVICE_CONFIG_DELETE_SUCCESS:
            return { loading: false, deleteSuccess: true };

        case SERVICE_CONFIG_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case SERVICE_CONFIG_DELETE_RESET:
            return {};

        default:
            return state;
    }
};


export const servicesCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICES_CREATE_REQUEST:
            return { loading: true };

        case SERVICES_CREATE_SUCCESS:
            return { loading: false, serviceData: true };

        case SERVICES_CREATE_FAIL:
            return { loading: false, error: action.payload };

        case SERVICES_CREATE_RESET:
            return {};

        default:
            return state;
    }
};

export const servicesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_DELETE_REQUEST:
            return { loading: true };

        case SERVICE_DELETE_SUCCESS:
            return { loading: false, deleteSuccess: true };

        case SERVICE_DELETE_FAIL:
            return { loading: false, error: action.payload };


        case SERVICE_DELETE_RESET:
            return {};

        default:
            return state;
    }
};


export const servicesConfigCreateReducer = (state = { userCreate: {} }, action) => {
    switch (action.type) {
        case CREATE_SERVICE_CONFIG_USER_REQUEST:
            return { loading: true };

        case CREATE_SERVICE_CONFIG_USER_SUCCESS:
            return { loading: false, userCreate: action.payload };

        case CREATE_SERVICE_CONFIG_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const servicesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICES_UPDATE_REQUEST:
            return { loading: true };

        case SERVICES_UPDATE_SUCCESS:
            return { loading: false, serviceData: true };

        case SERVICES_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case SERVICES_UPDATE_RESET:
            return {};

        default:
            return state;
    }
};

export const getServicesUserReducer = (state = { getConfigUser: [] }, action) => {
    switch (action.type) {
        case GET_SERVICE_USER_REQUEST:
            return { loading: true };

        case GET_SERVICE_USER_SUCCESS:
            return { loading: false, getConfigUser: action.payload };

        case GET_SERVICE_USER_FAIL:
            return { loading: false, error: action.payload };

        case GET_SERVICE_USER_RESET:
            return {};

        default:
            return state;
    }
};


export const getAlertDataReducer = (state = { getAlert: {} }, action) => {
    switch (action.type) {
        case GET_ALERT_REQUEST:
            return { loading: true, getAlert: { ...state.getAlert } };

        case GET_ALERT_SUCCESS:
            return { loading: false, getAlert: action.payload };

        case GET_ALERT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const addServicesReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_SERVICE_REQUEST:
            return { loading: true };

        case ADD_SERVICE_SUCCESS:
            return { loading: false, success: true };

        case ADD_SERVICE_FAIL:
            return { loading: false, error: action.payload };

        case ADD_SERVICE_RESET:
            return {};

        default:
            return state;
    }
};

export const getCameraReducer = (state = { cameraData: {} }, action) => {
    switch (action.type) {
        case CAMERA_DATA_REQUEST:
            return { loading: true };

        case CAMERA_DATA_SUCCESS:
            return { loading: false, cameraData: action.payload };

        case CAMERA_DATA_FAIL:
            return { loading: false, error: action.error };

        case CAMERA_DATA_RESET:
            return { cameraData: {} };

        default:
            return state;
    }
};

export const allServiceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ALL_SERVICES_DELETE_REQUEST:
            return { loading: true };

        case ALL_SERVICES_DELETE_SUCCESS:
            return { loading: false, serviceDelete: true };

        case ALL_SERVICES_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case ALL_SERVICES_DELETE_RESET:
            return {};

        default:
            return state;
    }
};



export const addFaceReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_FACE_REQUEST:
            return { loading: true };

        case ADD_FACE_SUCCESS:
            return { loading: false, addFaceSuccess: true };

        case ADD_FACE_FAIL:
            return { loading: false, error: action.payload };

        case ADD_FACE_RESET:
            return {};

        default:
            return state;
    }
};

export const buyServicesReducer = (state = {}, action) => {
    switch (action.type) {
        case BUY_SERVICE_REQUEST:
            return { loading: true }

        case BUY_SERVICE_SUCCESS:
            return { loading: false, buyServiceSuccess: action.payload }

        case BUY_SERVICE_FAIL:
            return { loading: false, error: action.payload }

        case BUY_SERVICE_RESET:
            return {};

        default:
            return state;
    }
}

export const ServicesOn_Off_Reducer = (state = {}, action) => {
    switch (action.type) {
        case SERVICE_ON_OFF_REQUEST:
            return { loading: true }

        case SERVICE_ON_OFF_SUCCESS:
            return { loading: false, success: action.payload }

        case SERVICE_ON_OFF_FAIL:
            return { loading: false, error: action.payload }

        case SERVICE_ON_OFF_RESET:
            return {};

        default:
            return state;
    }
}

export const Services_version_update_Reducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SERVICE_VERSION_REQUEST:
            return { loading: true }

        case UPDATE_SERVICE_VERSION_SUCCESS:
            return { loading: false, success: action.payload }

        case UPDATE_SERVICE_VERSION_FAIL:
            return { loading: false, error: action.payload }

        case UPDATE_SERVICE_VERSION_RESET:
            return {};

        default:
            return state;
    }
}


export const Retrain_process_Reducer = (state = {}, action) => {
    switch (action.type) {
        case RETRAIN_PROCESS_REQUEST:
            return { loading: true }

        case RETRAIN_PROCESS_SUCCESS:
            return { loading: false, success: action.payload }

        case RETRAIN_PROCESS_FAIL:
            return { loading: false, error: action.payload }

        case RETRAIN_PROCESS_RESET:
            return {};

        default:
            return state;
    }
}

export const Check_retrain_status_Reducer = (state = {}, action) => {
    switch (action.type) {
        case CHECK_RETRAIN_STATUS_REQUEST:
            return { loading: true }

        case CHECK_RETRAIN_STATUS_SUCCESS:
            return { loading: false, success: action.payload }

        case CHECK_RETRAIN_STATUS_FAIL:
            return { loading: false, error: action.payload }

        case CHECK_RETRAIN_STATUS_RESET:
            return {};

        default:
            return state;
    }
}