import React, { useState, useEffect, useMemo } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Radio } from 'antd';
import CheckBoxGroup from './CheckBoxGroup'
import { getConfigCameras, getConfigCamerasGroup } from '../../../actions/analyticsActions';
import { useSelector } from "react-redux";

function AnalyticsFIlter(props) {
    const {
        CameraTypeFilter,
        setCameraTypeFilter,
        CheckBoxCameraValue,
        setCheckBoxCameraValue,
        CheckBoxGroupValue,
        setCheckBoxGroupValue,
        setCamerasOption,
        CamerasOption,
        setCamerasGroupOption,
        CamerasGroupOption,
        DateFilter,
        disable
    } = props

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        async function startFetching() {
            setCamerasOption([]);
            let formData = {
                date_filter: DateFilter
            }
            const { data } = await getConfigCameras(formData, userInfo?.token);
            if (!ignore) {
                setCamerasOption(data);
            }
        }

        let ignore = false;
        startFetching()
        return () => {
            ignore = true;
        };
    }, [DateFilter])

    useEffect(() => {
        async function startFetching() {
            setCamerasGroupOption([]);
            const { data } = await getConfigCamerasGroup(userInfo?.token);
            if (!ignore) {
                setCamerasGroupOption(data);
            }
        }
        let ignore = false;
        startFetching()
        return () => {
            ignore = true;
        };
    }, [])

    const allCameraSelected = useMemo(() => JSON.stringify(CamerasOption?.map(e => e.camera_id).sort()) === JSON.stringify(CheckBoxCameraValue.sort()), [CheckBoxCameraValue, CamerasOption])

    const allGroupSelected = useMemo(() => JSON.stringify(CamerasGroupOption?.map(e => e.id).sort()) === JSON.stringify(CheckBoxGroupValue.sort()), [CheckBoxGroupValue, CamerasGroupOption])

    return (
        <OverlayTrigger
            trigger="click"
            placement={'bottom'}
            rootClose
            overlay={
                <Popover className="report-camera-filter analytics-container">
                    <Popover.Body>
                        <Radio.Group value={CameraTypeFilter} onChange={(e) => {
                            setCheckBoxGroupValue([])
                            setCheckBoxCameraValue([])
                            setCameraTypeFilter(e.target.value)
                        }} className="mb-3">
                            <Radio.Button value="camera_group">Camera Groups</Radio.Button>
                            <Radio.Button value="camera">Cameras</Radio.Button>
                            {/* <Radio.Button value="custom"><i className="ri-calendar-line" style={{ fontWeight: "400" }}></i></Radio.Button> */}
                        </Radio.Group>
                        {CameraTypeFilter === 'camera' ? (
                            <CheckBoxGroup
                                parent="All Camera"
                                option={CamerasOption}
                                setCheckBoxValue={(e) => setCheckBoxCameraValue(e)}
                                CheckBoxValue={CheckBoxCameraValue}
                                allCheckBoxSelected={allCameraSelected}
                                displayValue={"camera_name"}
                                selectedIdName={"camera_id"}
                                disabled={disable}
                            />
                        ) : (
                            <CheckBoxGroup
                                parent="All Camera Groups"
                                option={CamerasGroupOption}
                                setCheckBoxValue={(e) => setCheckBoxGroupValue(e)}
                                CheckBoxValue={CheckBoxGroupValue}
                                allCheckBoxSelected={allGroupSelected}
                                displayValue={"group_name"}
                                selectedIdName={"id"}
                                disabled={disable}
                            />
                        )}
                    </Popover.Body>
                </Popover>
            }
        >
            <button type="button" className="bg-white camera-wise-filter text-nowrap">Filter By Camera <i className="fas fa-caret-down ms-2 p-0"></i></button>
        </OverlayTrigger>
    )
}

export default AnalyticsFIlter