import React, { useContext, useState, useRef, useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { Accordion, Card, AccordionContext } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import SearchInput from '../../components/SearchInput';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => {
            if (callback) {
                callback(eventKey)
            }
        },
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            className='more-filter-btn'
            onClick={decoratedOnClick}
        >
            {isCurrentEventKey ? (
                <>
                    Less Filters
                    <i className="fas fa-angle-up iconDrop ms-2"></i>
                </>
            ) : (
                <>
                    More Filters
                    <i className="fas fa-angle-down iconDrop ms-2"></i>
                </>
            )}
        </button>
    );
}


function LivecamFilter(
    {
        groupData,
        serviceData,
        statusFilterHandler,
        serviceNameFilterHandler,
        groupNameFilterHandler,
        searchValueHandler,
        advanceFilter,
        className,
        groupNameFilterValue,
        serviceNameFilterValue,
        statusFilterValue }
) {
    const [searchActivate, setSearchActivate] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [cameraGroupList, setCameraGroupList] = useState([])
    const [cameraServicesList, setCameraServicesList] = useState([])

    const groupNameFilter = useRef(null)
    const serviceNameFilter = useRef(null)
    const statusFilter = useRef(null)

    const camera_group_list = groupData?.map((group, index) => (
        { camera_group: group.group_name, id: group.id, count: group.count }
    ))

    const camera_service_list = serviceData?.map((service) => ({
        camera_group: service.service_name, id: service.id, count: service.count
    }))


    useEffect(() => {
        let groupTotalCount = 0
        let servicesTotalCount = 0

        if (camera_group_list) {
            camera_group_list?.map(e => (
                groupTotalCount += e.count
            ))
            const newCameraGroupList = [{ camera_group: "All Group", id: 1000, count: groupTotalCount }].concat(...camera_group_list)
            setCameraGroupList(newCameraGroupList)
        }

        if (camera_service_list) {
            camera_service_list?.map(e => (
                servicesTotalCount += e.count
            ))
            const newCameraGroupList = [{ camera_group: "All Services", id: 999, count: servicesTotalCount }].concat(...camera_service_list)
            setCameraServicesList(newCameraGroupList)
        }
    }, [groupData, serviceData])

    const camera_status_list = [
        {
            camera_group: "Both", id: 1
        },
        {
            camera_group: "Active", id: 2
        },
        {
            camera_group: "Inactive", id: 3
        },
    ]

    const customOptionList = (e) => {
        let mergeCameraService
        if (cameraGroupList.length > 0 && cameraServicesList.length > 0) {
            mergeCameraService = [...cameraGroupList, ...cameraServicesList]
        }

        return (
            <div className="d-flex justify-content-between">
                {e}
                {mergeCameraService?.map((event, index) => {
                    if (event.camera_group === e) {
                        return (
                            <span key={`${index + 1}`}>{event?.count}</span>
                        )
                    }
                })}
            </div>
        );
    }

    const selectedOptionValue = (e) => {
        return (
            <div>
                Status - {e}
            </div>
        )
    };

    return (
        <div>
            <div className={`d-flex align-items-end gap-1 ${searchActivate && 'mb-3'} ${className}`}>
                <div className="w-100">
                    {searchActivate ? (
                        <SearchInput
                            width="100%"
                            placeholder="Search by camera and group name"
                            value={searchValue}
                            onChange={(e) => {
                                searchValueHandler(e)
                                setSearchValue(e);
                            }}
                        />
                    ) : (
                        <Multiselect
                            onSelect={groupNameFilterHandler}
                            onRemove={groupNameFilterHandler}
                            selectedValues={groupNameFilterValue}
                            ref={groupNameFilter}
                            className={`${groupNameFilterValue.length !== 0 && 'remove-placeholder'}`}
                            selectionLimit={1}
                            // singleSelect={true}
                            options={cameraGroupList}
                            displayValue="camera_group"
                            placeholder='All Group'
                            emptyRecordMsg="No Group available."
                            avoidHighlightFirstOption={true}
                            showArrow={true}
                            optionValueDecorator={customOptionList}
                            customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                            customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                        />
                    )}
                </div>
                <button className='livecam-search-btn' onClick={() => {
                    setSearchActivate(!searchActivate);
                }}>
                    {!searchActivate ? (
                        <i className="ri-search-line"></i>
                    ) : (
                        <i className="fas fa-filter"></i>
                    )}
                </button>
            </div>
            {(!searchActivate && advanceFilter) && (
                <Accordion alwaysOpen={false} flush>
                    <Accordion.Collapse eventKey="0">
                        <div className='d-flex my-2 gap-3'>
                            <div className='w-100'>
                                <Multiselect
                                    onSelect={serviceNameFilterHandler}
                                    onRemove={serviceNameFilterHandler}
                                    selectedValues={serviceNameFilterValue}
                                    className={`${serviceNameFilterValue.length !== 0 && 'remove-placeholder'}`}
                                    selectionLimit={1}
                                    showArrow={true}
                                    ref={serviceNameFilter}
                                    // singleSelect={true}
                                    options={cameraServicesList}
                                    displayValue="camera_group"
                                    placeholder='All Services'
                                    emptyRecordMsg="No Services available."
                                    avoidHighlightFirstOption={true}
                                    optionValueDecorator={customOptionList}
                                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                                />
                            </div>
                            <div className='w-100'>
                                <Multiselect
                                    onSelect={statusFilterHandler}
                                    onRemove={statusFilterHandler}
                                    selectedValues={statusFilterValue}
                                    className={`${statusFilterValue.length !== 0 && 'remove-placeholder'}`}
                                    selectionLimit={1}
                                    ref={statusFilter}
                                    showArrow={true}
                                    // singleSelect={true}
                                    options={camera_status_list}
                                    displayValue="camera_group"
                                    placeholder='Status - Both'
                                    emptyRecordMsg="No Status available."
                                    avoidHighlightFirstOption={true}
                                    selectedValueDecorator={selectedOptionValue}
                                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                                />
                            </div>
                        </div>
                    </Accordion.Collapse>
                    <Card.Header>
                        <ContextAwareToggle eventKey="0" />
                    </Card.Header>
                </Accordion>
            )}
        </div>
    )
}

export default LivecamFilter