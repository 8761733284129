import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCamera,
  updateCamera,
} from "../../actions/dataActions";
import { getAllCameraTypes, getLivecamData } from '../../actions/livecamAction'
import { toast } from "react-toastify";
import { AddCmeraAlert, UpdateCmeraAlert, VideoFormatAlert } from "../../components/ToastAlert";
import "react-toastify/dist/ReactToastify.css";
import camera_load from "../../components/Loading_animation_for_camera_load.gif";
import {
  ADD_CAMERA_RESET,
  UPDATE_CAMERA_RESET,
} from "../../constants/dataConstants";
import { capitalizeFirstLater, videoValidation } from '../../global/Helper'
import { Form } from 'react-bootstrap';
import CameraGroupSelect from './CameraGroupSelect'



const AddCamera = (props) => {
  const [validated, setValidated] = useState(false);
  const [allCameraType, setAllCameraType] = useState("");
  const [cameraTypeName, setCameraTypeName] = useState("");
  const [regexPatternValidation, setRegexPatternValidation] = useState("");
  const [cameraName, setCameraName] = useState("");
  const [rtspUrl, setRtspUrl] = useState("");
  const [manuName, setManuName] = useState("");
  const [groupOptionValue, setGroupOptionValue] = useState(null);
  const [cameraGroupValidation, setCameraGroupValidation] = useState(false);
  const dispatch = useDispatch();

  const AddCameraData = useSelector((state) => state.AddCameraData);
  const { loading: Load, cameraAddSuccess } = AddCameraData;

  const CameraUpdate = useSelector((state) => state.CameraUpdate);
  const { loading: updateLoad, cameraUpdateSuccess } = CameraUpdate;

  const camera_id = props.camera_data?.camera_id;
  const camera_detail = props.camera_data;
  const camera_group = props.cameraGroupData;
  const token = props.token

  const CCTVBrands = {
    "Axis Communications": "rtsp://username:password@camera_IP/axis-media/media.amp",
    "Hikvision": "rtsp://username:password@camera_IP/Streaming/Channels/1",
    "Dahua": "rtsp://username:password@camera_IP/cam/realmonitor",
    "Samsung Techwin (Hanwha Techwin)": "rtsp://username:password@camera_IP/profile2",
    "Sony": "rtsp://username:password@camera_IP/media/video1",
    "Vivotek": "rtsp://username:password@camera_IP/live.sdp",
    "Amcrest": "rtsp://username:password@camera_IP/cam/realmonitor",
    "Lorex": "rtsp://username:password@camera_IP/media/video1",
    "Uniview (UNV)": "rtsp://username:password@camera_IP/cam/realmonitor",
    "Reolink": "rtsp://username:password@camera_IP/h264Preview_01_main",
    "Videocon": "rtsp://username:password@camera_IP/...rest_of_the_URL",
    "Vacron": "rtsp://username:password@camera_IP/Streaming/channels/{channel_number}/preview",
    "D-Link": "rtsp://username:password@camera_IP/videoMain",
    "Other": undefined,
  }

  useEffect(() => {
    if (cameraAddSuccess) {
      dispatch(getLivecamData())
      toast(<AddCmeraAlert camera_name={cameraName} />);
      dispatch({ type: ADD_CAMERA_RESET });
      props.handleClose(true);
    }
    if (cameraUpdateSuccess) {
      dispatch(getLivecamData())
      toast(<UpdateCmeraAlert camera_name={cameraName} />);
      dispatch({ type: UPDATE_CAMERA_RESET });
      props.handleClose(true);
    }
  }, [cameraAddSuccess, cameraUpdateSuccess]);

  useEffect(() => {
    if (camera_detail) {
      setRtspUrl(camera_detail?.rtsp_url);
      setCameraName(camera_detail?.camera_name);
      setManuName(camera_detail?.manufacturer_name);
      setCameraTypeName(camera_detail?.camera_type.type_label)
      setRegexPatternValidation(camera_detail?.camera_type.regex_pattern_validation)

      camera_group.filter((e) => {
        if (e.group_name === camera_detail?.group_name) {
          setGroupOptionValue(e.id)
        }
      })
    }
  }, [camera_detail]);

  useEffect(() => {
    async function startFetching() {
      setAllCameraType("")
      const allCameraTypeData = await getAllCameraTypes(token)
      if (!ignore) {
        setAllCameraType(allCameraTypeData)
      }
    }
    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    }
  }, [])

  const showhandle = () => {
    return (
      <>
        <label>{cameraTypeName ? (capitalizeFirstLater(cameraTypeName) + " URL") : "Livecam URL"}</label>
        <br />
        {cameraTypeName == 'video' ? (
          <div className="position-relative">
            <label
              htmlFor="video-upload"
              className="input_box text-truncate d-block position-absolute top-0 video-upload-label cursor-pointer h-100"
            >
              {rtspUrl?.name || camera_detail?.rtsp_url || 'No file Choose'}
            </label>
            <Form.Control
              type="file"
              pattern={videoValidation}
              className={`form-control text-truncate input_box ${!cameraTypeName && 'is-disable'}`}
              name="rtsp_url"
              accept='.MP4, .mp4, .AVI, .avi, .MKV, .mkv'
              title={!cameraTypeName ? "Please select livecam format then enter the URL." : "Please Enter Livecam URL."}
              disabled={!cameraTypeName}
              id="video-upload"
              onChange={(e) => {
                setRtspUrl(e.target.files[0]);
              }}
              required={!camera_detail?.rtsp_url}
            />
            <Form.Control.Feedback type="invalid">
              Please select the video.
            </Form.Control.Feedback>
          </div>
        ) :
          <>
            <Form.Control
              type="text"
              pattern={regexPatternValidation}
              className={!cameraTypeName ? "form-control input_box is-disable" : "form-control input_box"}
              name="rtsp_url"
              placeholder={CCTVBrands[manuName] ? 'eg: ' + CCTVBrands[manuName] : "Please Enter Livecam URL."}
              title={!cameraTypeName ? "Please select livecam format then enter the URL." : "Please Enter Livecam URL."}
              defaultValue={rtspUrl}
              disabled={!cameraTypeName}
              onChange={(e) => {
                setRtspUrl(e.target.value);
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter the URL.
            </Form.Control.Feedback>
          </>
        }
      </>
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || groupOptionValue === null) {
      e.preventDefault();
      e.stopPropagation();
      if (groupOptionValue === null) {
        setCameraGroupValidation(true)
      } else {
        setCameraGroupValidation(false)
      }
    } else {
      if (cameraTypeName === 'video' && videoValidation?.test(rtspUrl.name || camera_detail?.rtsp_url) === false) {
        const videoFormat = rtspUrl.name?.split('.').pop();
        toast(<VideoFormatAlert videoFormat={videoFormat} />);
        return false;
      }
      const camera_details = {
        camera_id: camera_id,
        group_id: groupOptionValue,
        camera_name: e.target.camera_name.value,
        manufacture_name: e.target.manufacture_name.value,
        type_label: e.target.livecam_type_name.value,
        rtsp_url: rtspUrl,
      };

      if (cameraTypeName === 'video' && typeof (rtspUrl) !== 'object') {
        delete camera_details.rtsp_url
      }

      if (!camera_id) {
        dispatch(addCamera(camera_details));
      } else {
        dispatch(updateCamera(camera_details));
      }
    }
    setValidated(true);
  };
  const modalClose = (e) => {
    props.handleClose(true);
  };
  return (
    <div className="my-3 mx-4">
      {
        <div className="p-relative">
          {Load || updateLoad ? (
            <img src={camera_load} className="p-absolute w100" alt="" />
          ) : null}
          <div className={Load || updateLoad ? "filer-blur position-relative" : "position-relative"}>
            <i
              className="far fa-times close_btn_modal position-absolute"
              onClick={modalClose}
            ></i>
            <div className="closable_header d-flex justify-content-center">
              <h5 className=" h2">
                {camera_id ? "Update Livecam" : "Add Livecam"}
              </h5>
            </div>
            <div className="mt-2">
              <Form action="" onSubmit={submitHandler} noValidate validated={validated}>
                <div className="row ">
                  <div className='text-start col-sm-6'>
                    <CameraGroupSelect
                      optionId={(e) => setGroupOptionValue(e)}
                      selectOptionId={groupOptionValue}
                      cameraGroupValidation={cameraGroupValidation}
                      setCameraGroupValidation={setCameraGroupValidation}
                      defaultValue={camera_detail?.group_name}
                    />
                  </div>
                  <Form.Group className="col-sm-6 text-start">
                    <label>Livecam Name</label>
                    <br />
                    <Form.Control
                      type="text"
                      className="form-control input_box"
                      name="camera_name"
                      placeholder="Enter Livecam Name"
                      title="Enter Livecam Name"
                      defaultValue={cameraName}
                      onChange={(e) => setCameraName(e.target.value)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the Livecam name.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-sm-6 text-start mt-3">
                    <label htmlFor="manufacture_name_id">
                      Livecam Manufacturer Name
                    </label>
                    <br />
                    <Form.Select
                      name="manufacture_name"
                      className="form-select select_box"
                      id="manufacture_name_id"
                      title="Select Livecam Manufacturer Name"
                      value={manuName}
                      onChange={(e) => {
                        setManuName(e.target.value);
                      }}
                      required
                    >
                      <option value="">Select Manufacturer</option>
                      {Object.keys(CCTVBrands)?.map((e, i) => (
                        <option key={`${i + 1}`} value={e}>{e}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select the Manufacturer Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-sm-6 mt-3">
                    <label htmlFor="livecam_name_id">
                      Livecam Format
                    </label>
                    <br />
                    <Form.Select
                      name="livecam_type_name"
                      className="form-select select_box"
                      id="livecam_name_id"
                      title="Select Livecam Format"
                      value={cameraTypeName}
                      onChange={(e) => {
                        setCameraTypeName(e.target.value);
                        setRegexPatternValidation(e.target.selectedOptions.item(0).attributes.pattern_validation?.value)
                      }}
                      required
                    >
                      <option value="" pattern_validation="">Select Livecam Format</option>
                      {allCameraType?.data?.map((data) => (
                        <option key={data.id} pattern_validation={data.regex_pattern_validation} value={data.type_label}>{capitalizeFirstLater(data.type_label)}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select the Livecam format.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="col-sm-12 has-validation mt-3">
                    {showhandle()}
                  </Form.Group>
                </div>

                <div className="text-end mt-4">
                  <button
                    className="btn me-4 px-3 py-2 border close"
                    onClick={modalClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className={(Load || updateLoad) ? "disabled btn btn_primary px-4 py-2 border" : "btn btn_primary px-4 py-2 border"}
                    type="submit"
                  >
                    {camera_id ? "Update" : "Add"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default AddCamera;
