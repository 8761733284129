import axios from 'axios'
import {
    POST_SETUP_OCCUPANCY_DATA_REQUEST,
    POST_SETUP_OCCUPANCY_DATA_SUCCESS,
    POST_SETUP_OCCUPANCY_DATA_FAIL,
} from '../constants/occupancyMonitoringConstants'
import { API_ENDPOINT } from '../constants/apiEndpoint'
import { postAPI } from '../global/ApiManager';

export const postOccupancySetupData = (configId, field, postData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_SETUP_OCCUPANCY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/parent_service_config_api/${configId}/${field}/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: POST_SETUP_OCCUPANCY_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_SETUP_OCCUPANCY_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};

