import {
    GET_FACE_RECOGNITION_CAMERAS_REQUEST,
    GET_FACE_RECOGNITION_CAMERAS_SUCCESS,
    GET_FACE_RECOGNITION_CAMERAS_FAIL,

    GET_FACE_RECOGNITION_USERS_REQUEST,
    GET_FACE_RECOGNITION_USERS_SUCCESS,
    GET_FACE_RECOGNITION_USERS_FAIL,

    DELETE_FACE_RECOGNITION_CAMERA_REQUEST,
    DELETE_FACE_RECOGNITION_CAMERA_SUCCESS,
    DELETE_FACE_RECOGNITION_CAMERA_FAIL,
    DELETE_FACE_RECOGNITION_CAMERA_RESET,

    DELETE_FACE_RECOGNITION_USER_REQUEST,
    DELETE_FACE_RECOGNITION_USER_SUCCESS,
    DELETE_FACE_RECOGNITION_USER_FAIL,
    DELETE_FACE_RECOGNITION_USER_RESET,

    ADD_FACE_RECOGNITION_USER_REQUEST,
    ADD_FACE_RECOGNITION_USER_SUCCESS,
    ADD_FACE_RECOGNITION_USER_FAIL,
    ADD_FACE_RECOGNITION_USER_RESET,

    GET_USER_CONFIGURATION_REQUEST,
    GET_USER_CONFIGURATION_SUCCESS,
    GET_USER_CONFIGURATION_FAIL,

    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST,
    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,
    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET,

    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST,
    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,
    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET,

    GET_CAMERAS_CONFIGURATION_REQUEST,
    GET_CAMERAS_CONFIGURATION_SUCCESS,
    GET_CAMERAS_CONFIGURATION_FAIL,

    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_REQUEST,
    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_SUCCESS,
    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_FAIL,
    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_RESET,

    DELETE_FACE_PERMANENT_REQUEST,
    DELETE_FACE_PERMANENT_SUCCESS,
    DELETE_FACE_PERMANENT_FAIL,
    DELETE_FACE_PERMANENT_RESET,

    RESTORE_FACE_REQUEST,
    RESTORE_FACE_SUCCESS,
    RESTORE_FACE_FAIL,
    RESTORE_FACE_RESET,

    RECYCLED_FACE_REQUEST,
    RECYCLED_FACE_SUCCESS,
    RECYCLED_FACE_FAIL,
    RECYCLED_FACE_RESET,

    MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST,
    MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS,
    MULTIPLE_ANGLE_FACE_VALIDATION_FAIL,
    MULTIPLE_ANGLE_FACE_VALIDATION_RESET,
} from '../constants/faceRecognitionConstant.js'


export const getFaceRecognitionCameraReducer = (state = { faceCameraData: [] }, action) => {
    switch (action.type) {
        case GET_FACE_RECOGNITION_CAMERAS_REQUEST:
            return { loading: true };

        case GET_FACE_RECOGNITION_CAMERAS_SUCCESS:
            return { loading: false, faceCameraData: action.payload };

        case GET_FACE_RECOGNITION_CAMERAS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const getFaceRecognitionUsersReducer = (state = { faceUsersData: [] }, action) => {
    switch (action.type) {
        case GET_FACE_RECOGNITION_USERS_REQUEST:
            return { loading: true };

        case GET_FACE_RECOGNITION_USERS_SUCCESS:
            return { loading: false, faceUsersData: action.payload };

        case GET_FACE_RECOGNITION_USERS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const deleteFaceRecognitionCameraReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_FACE_RECOGNITION_CAMERA_REQUEST:
            return { loading: true };

        case DELETE_FACE_RECOGNITION_CAMERA_SUCCESS:
            return { loading: false, cameraDeleteSuccess: true };

        case DELETE_FACE_RECOGNITION_CAMERA_FAIL:
            return { loading: false, error: action.payload };

        case DELETE_FACE_RECOGNITION_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};


export const deleteFaceRecognitionUserReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_FACE_RECOGNITION_USER_REQUEST:
            return { loading: true };

        case DELETE_FACE_RECOGNITION_USER_SUCCESS:
            return { loading: false, userDeleteSuccess: true };

        case DELETE_FACE_RECOGNITION_USER_FAIL:
            return { loading: false, error: action.payload };

        case DELETE_FACE_RECOGNITION_USER_RESET:
            return {};

        default:
            return state;
    }
};


export const addUserInFaceRecognitionReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_FACE_RECOGNITION_USER_REQUEST:
            return { loading: true };

        case ADD_FACE_RECOGNITION_USER_SUCCESS:
            return { loading: false, addFaceSuccess: true };

        case ADD_FACE_RECOGNITION_USER_FAIL:
            return { loading: false, error: action.payload };

        case ADD_FACE_RECOGNITION_USER_RESET:
            return {};

        default:
            return state;
    }
};

export const getUsersConfigurationReducer = (state = { usersData: [] }, action) => {
    switch (action.type) {
        case GET_USER_CONFIGURATION_REQUEST:
            return { loading: true };

        case GET_USER_CONFIGURATION_SUCCESS:
            return { loading: false, usersData: action.payload };

        case GET_USER_CONFIGURATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const applyUsersInFaceRecognitionCameraReducer = (state = {}, action) => {
    switch (action.type) {
        case APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST:
            return { loading: true };

        case APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS:
            return { loading: false, applyUsersSuccess: true };

        case APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL:
            return { loading: false, error: action.payload };

        case APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};


export const removeUsersInFaceRecognitionCameraReducer = (state = {}, action) => {
    switch (action.type) {
        case REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST:
            return { loading: true };

        case REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS:
            return { loading: false, removeUsersSuccess: true };

        case REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL:
            return { loading: false, error: action.payload };

        case REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};

export const getCamerasConfigurationReducer = (state = { camerasData: [] }, action) => {
    switch (action.type) {
        case GET_CAMERAS_CONFIGURATION_REQUEST:
            return { loading: true };

        case GET_CAMERAS_CONFIGURATION_SUCCESS:
            return { loading: false, camerasData: action.payload };

        case GET_CAMERAS_CONFIGURATION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const applyCamerasInFaceRecognitionUserReducer = (state = {}, action) => {
    switch (action.type) {
        case APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_REQUEST:
            return { loading: true };

        case APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_SUCCESS:
            return { loading: false, applyCamerasSuccess: true };

        case APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_FAIL:
            return { loading: false, error: action.payload };

        case APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_RESET:
            return {};

        default:
            return state;
    }
};

export const deleteFacePermanentReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_FACE_PERMANENT_REQUEST:
            return { loading: true };

        case DELETE_FACE_PERMANENT_SUCCESS:
            return { loading: false, deleteUserFaceSuccess: true };

        case DELETE_FACE_PERMANENT_FAIL:
            return { loading: false, error: action.payload };

        case DELETE_FACE_PERMANENT_RESET:
            return {}

        default:
            return state;
    }
};

export const restoreFaceReducer = (state = {}, action) => {
    switch (action.type) {
        case RESTORE_FACE_REQUEST:
            return { loading: true };

        case RESTORE_FACE_SUCCESS:
            return { loading: false, restoreUserFaceSuccess: true };

        case RESTORE_FACE_FAIL:
            return { loading: false, error: action.payload };

        case RESTORE_FACE_RESET:
            return {}

        default:
            return state;
    }
};

export const recycledFacesReducer = (state = { recycledData: {} }, action) => {
    switch (action?.type) {
        case RECYCLED_FACE_REQUEST:
            return { loading: true };

        case RECYCLED_FACE_SUCCESS:
            return { loading: false, recycledData: action.payload };

        case RECYCLED_FACE_FAIL:
            return { loading: false, error: action.payload };

        case RECYCLED_FACE_RESET:
            return {}

        default:
            return state;
    }
};

export const multipleAngleFaceValidationReducer = (state = {}, action) => {
    switch (action?.type) {
        case MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST:
            return { loading: true }

        case MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS:
            return { loading: false, faceVerified: action.payload }

        case MULTIPLE_ANGLE_FACE_VALIDATION_FAIL:
            return { loading: false, error: action.payload }

        case MULTIPLE_ANGLE_FACE_VALIDATION_RESET:
            return {};

        default:
            return state;
    }
};