import React, { useEffect } from 'react';
import pageNotFound from './images/404-page-not-foung.svg'
import { useNavigate } from "react-router-dom";

const PageNotFound = (props) => {

    let navigate = useNavigate();

    return (
        <div className='error-page'>
            <div className='d-flex flex-column align-items-center justify-content-center error-page-child-container'>
                <img src={pageNotFound} style={{ maxWidth: "415px" }} />
                {props.resetErrorBoundary ? (
                    <h3 className='fw-bolder mb-1 text-center'>Oops! Something Went Wrong</h3>
                ) : (
                    <h3 className='fw-bolder mb-1 text-center'>Oops! Page Not Found</h3>
                )}
                <p className='mb-1 text-center'>This page doesn’t exist or removed. We suggest you back to home or try again.</p>
                <div className="d-flex gap-3">
                    {props.resetErrorBoundary ? (
                        <div>
                            <button className='try-error-btn' type='primary' onClick={props.resetErrorBoundary}>
                                Try Again
                            </button>
                        </div>
                    ) : (
                        <button className='try-error-btn' type='primary' onClick={() => window.location.reload()}>Try Again</button>
                    )}
                    <button className='error-btn' type='primary' onClick={() => {
                        navigate(-1);
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);
                    }}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound