
import React, { useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import renewCalender from '../../images/renew-calender.png'
import successIcon from '../../images/success-icon.png'
import { sendSubscribeMail, getServicesDetail } from '../../actions/subscriptionActions'
import { SEND_SUBSCRIPTION_RESET } from '../../constants/subscriptionConstant'
import camera_load from "../../components/Loading_animation_for_camera_load.gif";

function RenewConfirmModal(props) {

    const dispatch = useDispatch();

    const sendSubscribeEmail = useSelector((state) => state.sendSubscribeEmail);
    const { loading, success } = sendSubscribeEmail;

    const serviceGetDetail = useSelector((state) => state.serviceGetDetail);
    const { serviceData: serviceSuccess } = serviceGetDetail;

    const sendEmail = () => {
        const serviceDetails = {
            service_name: props.item_name,
        }
        dispatch(sendSubscribeMail(serviceDetails))
    }

    useEffect(() => {
        if (success) {
            dispatch(getServicesDetail(props.item_name))
            dispatch({ type: SEND_SUBSCRIPTION_RESET })
        }
    }, [success])

    return (
        <div>
            <div className="text-end">
                <button
                    className="fas fa-times close_btn_modal"
                    style={{ display: "contents" }}
                    onClick={props.onHide}
                >
                </button>
            </div>
            {loading ?
                (<img src={camera_load} className="p-absolute w100" alt="" />) :
                null}
            <div className={loading ? "filer-blur" : ""}>
                <div className="d-flex flex-column align-itmes-center justify-content-center mt-2 text-center">
                    <div className="mb-3">
                        {serviceSuccess?.is_email_send ?
                            (<img src={successIcon} alt="calender icon" width='165' />)
                            : (<img src={renewCalender} alt="calender icon" width='165' />)}
                    </div>
                    {serviceSuccess?.is_email_send ?
                        (<h4 className="mb-0"><b>Your Request has been Received our team will Back to You!</b></h4>)
                        :
                        <>
                            <h4 className="mb-0"><b>{props.item_name} Subscription is expired.</b></h4>
                            <h5 className="mb-0">Please renew to perform the requested action</h5>
                        </>
                    }
                </div>
                <div className="d-flex align-itmes-center justify-content-center mt-4">
                    {serviceSuccess?.is_email_send ?
                        (<button
                            className="btn px-3 py-2 bg-warning text-dark"
                            onClick={props.onHide}
                        >
                            OK
                        </button>) :
                        (<button
                            className={loading ? "btn px-3 py-2 bg-warning text-dark disabled" : "btn px-3 py-2 bg-warning text-dark"}
                            onClick={sendEmail}
                        >
                            Renew
                        </button>)
                    }
                </div>
            </div>
        </div>
    );
}

export default RenewConfirmModal;
