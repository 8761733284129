import React from 'react'
import noInternet from '../images/no-internet.gif'

function NoInternetConnection({ onTryAgain, className, children }) {
    return (
        <div className={`${className} rounded-2 h-100 d-flex align-items-center`}>
            <div className="text-center w-100">
                <div className="mb-1">
                    <img src={noInternet} alt="calender icon" width='180' />
                </div>
                <h3 className="mb-0"><b>No Internet Connection</b></h3>
                <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Could not connect to the internet. <br></br>Please check your network.</p>
                <div className="text-center mt-4">
                    <button
                        className="btn px-3 py-2 bg-warning text-dark"
                        onClick={onTryAgain}
                    >
                        TRY AGAIN
                    </button>
                </div>
                {children}
            </div>
        </div>
    )
}

export default NoInternetConnection