import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CreateServiceAlert, CommonErrorAlert } from "../../components/ToastAlert";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
    SERVICES_UPDATE_RESET,
} from "../../constants/servicesConstant";
import {
    serviceUpdateWithCamera,
    getServicesData
} from "../../actions/servicesActions";
import { getFaceRecognitionCamera } from '../../actions/faceRecognitionActions';
import { Button } from 'antd';
import Multiselect from "multiselect-react-dropdown";



function CreateService(props) {
    const [cameras, setCameras] = useState([]);

    const dispatch = useDispatch();

    const servicesUpdate = useSelector((state) => state.servicesUpdate);
    const { loading: serviceUpdateLoad, error: serviceUpdateError, serviceData: succussUpdate } = servicesUpdate;

    const cameraGet = useSelector((state) => state.cameraGet);
    const { cameraData: allCamera, loading } = cameraGet;

    const options = allCamera?.filter(camera => camera.active_status)?.map(camera => ({
        label: camera.camera_name,
        value: camera.camera_id,
    }));

    useEffect(() => {
        if (serviceUpdateError) {
            toast(<CommonErrorAlert message={serviceUpdateError} />);
            dispatch({ type: SERVICES_UPDATE_RESET });
        }
    }, [serviceUpdateError])

    useEffect(() => {
        if (succussUpdate) {
            toast(<CreateServiceAlert service_name={props.service_name} />);
            dispatch({ type: SERVICES_UPDATE_RESET });
            props.onHide()
            if (!props.service_name.includes("Face Recognition")) {
                dispatch(getServicesData());
            } else {
                dispatch(getFaceRecognitionCamera(props.service_id))
            }
        }

    }, [succussUpdate, dispatch]);

    const submitHandler = async (e) => {
        const service_id = props.service_id
        dispatch(
            serviceUpdateWithCamera(
                service_id,
                cameras?.map(e => e.value),
            )
        );
    };

    return (
        <div>
            <div className="service_box">
                <div className="p-4 pt-0 pb-0">
                    <div className="row mb-3">
                        <div className="col-12 text-start mt-3">
                            <div>Select the Livecam</div>
                            <div className="row m-0">
                                <div className="col p-0">
                                    <Multiselect
                                        onSelect={(e) => setCameras(e)}
                                        onRemove={(e) => setCameras(e)}
                                        selectedValues={cameras}
                                        options={options}
                                        displayValue="label"
                                        className={`filterBox bg-light-grey border_radius_sm ${cameras.length !== 0 && 'remove-placeholder'}`}
                                        showCheckbox
                                        placeholder='Select Livecam'
                                        emptyRecordMsg="No Livecam Found."
                                        avoidHighlightFirstOption={true}
                                        showArrow={true}
                                        loading={loading}
                                        customArrow={<><i className="fas fa-caret-down"></i></>}
                                        customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-end mt-4">
                            <button type="button" onClick={props.onHide} className="btn me-4  px-3 py-2 border close">Cancel</button>

                            {serviceUpdateLoad ? (
                                <Button type="primary" size="large" loading>
                                    Save
                                </Button>
                            ) :
                                (
                                    <button
                                        className="btn btn_primary px-4 py-2"
                                        onClick={submitHandler}
                                    >
                                        Save
                                    </button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateService;
