import React, { useState } from 'react'
import Header from "../../components/Header";
import SearchInput from "../../components/SearchInput";
import { Button, Table, Image, Modal } from 'react-bootstrap';
import profile_image from '../../images/profile_image.png'
import { useNavigate } from "react-router-dom";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";

function CameraRequestScreen() {

    let navigate = useNavigate();

    const [deleteModalShow, setDeleteModalShow] = useState(false);

    const crumbHandler = () => {
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        Settings
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Users & Permissions
                    </li>
                    <li className="breadcrumb-item active text-dark p-0" aria-current="page">
                        <b>User Requests</b>
                    </li>
                </ol>
            </nav>
        );
    };

    return (
        <>
            <div>{crumbHandler()}</div>
            <Header props={{ page_name: "User Requests" }} />
            <div className="d-flex justify-content-between align-items-center">
                <SearchInput onChange={(e) => { console.log(e) }} />
                <Button
                    variant="dark"
                    onClick={() => navigate(-1)}
                    className='d-flex align-items-center go-back-btn'>
                    <span className='go-back-arrow-icon me-1'></span>Go Back
                </Button>
            </div>
            <Table size="sm" className="mt-2">
                <thead
                    className="eventstable border_radius_xsm"
                >
                    <tr>
                        <th className="">User Name</th>
                        <th className="">Employee Code</th>
                        <th className="">LiveCam Request</th>
                        <th className="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Image
                                height={30}
                                width={40}
                                className="me-1 border_radius_sm"
                                src={profile_image}
                            />{" "}
                            Rachel
                        </td>
                        <td>EC11456</td>
                        <td>Camera Ground Floor Grp1</td>
                        <td>
                            <div className='d-flex justify-content-center'>
                                <button
                                    className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                                    onClick={(e) => {
                                    }}
                                >
                                    <i className="fas fa-check"></i>
                                </button>
                                <button
                                    className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                                    onClick={(e) => {
                                        setDeleteModalShow(true)
                                    }}
                                >
                                    <i className="ri-delete-bin-5-line "></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>

            </Table>
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "55%" }}>
                <div className="text-center background-none">
                    <img className='background-none opacity' src={noRequestFound} alt="" height={"220px"} width={"220px"} />
                    <p className='opacity'>No New Requests were found on this page.</p>
                </div>
            </div> */}
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        // item_name={userName}
                        // dispatch_item={DeleteUser(userProfileId)}
                        onHide={() => { setDeleteModalShow(false) }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CameraRequestScreen