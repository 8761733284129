function PasswordHideShow(passwordInput, eyeOpenClose) {
    if (passwordInput.type === 'password') {
        eyeOpenClose.current.className = 'ri-eye-line text-dark'
        passwordInput.type = 'text'
    }
    else {
        eyeOpenClose.current.className = 'ri-eye-close-line text-dark'
        passwordInput.type = 'password'
    }
}

export default PasswordHideShow
