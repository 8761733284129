import React, { useEffect, useState } from 'react'
import SearchInput from '../../../components/SearchInput'
import { Table } from "antd";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import noRequestFound from '../../../images/no-camera-request-found.gif'
import AddUser from './AddUserModal'
import sampleFile from '../../../components/sample.csv'
import { useDispatch, useSelector } from "react-redux";
import { getMasterListUserData, deleteMasterListUserData, uploadMasterListUsersCSVFile } from '../../../actions/organizationActions/masterListActions';
import Loader from '../../../components/Loader';
import { Alert, Modal } from 'react-bootstrap';
import { DeleteServiceAlert, CommonErrorAlert, CommonSuccessAlert } from '../../../components/ToastAlert'
import { toast } from "react-toastify";
import { DELETE_MASTER_LIST_USER_DATA_RESET } from '../../../constants/organizationConstant/masterListConstant'
import TextToCopy from '../../../components/TextToCopy';
import UploadCSV from '../../../components/UploadCSV';



function MasterList() {

    const [UserDeleteModalShow, setUserDeleteModalShow] = useState(false);
    const [addUserModalShow, setAddUserModalShow] = useState(false);
    const [UserDetails, setUserDetails] = useState("");
    const [UserName, setUserName] = useState("");
    const [UserId, setUserId] = useState("");
    const [search, setSearch] = useState("");
    const [CSVUploadLoading, setCSVUploadLoading] = useState(false);

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const masterListUserDataGet = useSelector((state) => state.masterListUserDataGet);
    const { error: getMasterListUserError, loading: getMasterListUserLoad, success: getMasterListUserSuccess } = masterListUserDataGet;

    const masterListUserDataDelete = useSelector((state) => state.masterListUserDataDelete);
    const { error: deleteMasterListUserError, loading: deleteMasterListUserLoad, success: deleteMasterListUserSuccess } = masterListUserDataDelete;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    useEffect(() => {
        dispatch(getMasterListUserData())
    }, [])


    useEffect(() => {
        if (deleteMasterListUserSuccess) {
            setUserDeleteModalShow(false)
            dispatch(getMasterListUserData())
            toast(<DeleteServiceAlert service_name={UserName} />);
            dispatch({ type: DELETE_MASTER_LIST_USER_DATA_RESET })
        }
        if (deleteMasterListUserError) {
            toast(<CommonErrorAlert message={deleteMasterListUserError} />);
            dispatch({ type: DELETE_MASTER_LIST_USER_DATA_RESET })
        }
    }, [deleteMasterListUserSuccess, deleteMasterListUserError])

    const uploadCSVOnChange = async (e) => {
        setCSVUploadLoading(true)
        const data = await uploadMasterListUsersCSVFile(userInfo?.token, e.target.files[0])
        if (data.status === 200) {
            toast(<CommonSuccessAlert message={<><b>CSV</b> file users created Successfully!</>} />);
            dispatch(getMasterListUserData())
            e.target.value = null;
        } else {
            toast(<CommonErrorAlert message={<>{data.error.response.data.error}</>} />);
            e.target.value = null;
        }
        setCSVUploadLoading(false)
    }

    const userTableColumns = [
        {
            title: 'User Id',
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: true,
            width: "10%"
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
            ellipsis: true,
            width: "12%"
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
        },
        {
            title: 'Phone',
            dataIndex: 'contact',
            key: 'contact',
            ellipsis: true,
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            ellipsis: true,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            ellipsis: true,
        },
    ];

    const userTableData = getMasterListUserSuccess?.filter((e) => (e.phone?.toLowerCase().includes(search?.toLowerCase())) || e.id?.toLowerCase().includes(search?.toLowerCase()) || e.location?.toLowerCase().includes(search?.toLowerCase()) || e.department?.toLowerCase().includes(search?.toLowerCase()) || e.username?.toLowerCase().includes(search?.toLowerCase()) || (e.email).toLowerCase()?.includes((search?.toLowerCase())))?.map(e => {

        return {
            key: e.id,
            user_id: e.id,
            user_name: e.username,
            email: (
                <div className='d-flex align-items-center clipboard-container' title={e.email}>
                    <TextToCopy text={e.email} className='clipboard-text' style={{ fontSize: "14px" }} />
                </div>
            ),
            contact: e.phone,
            department: e.department,
            location: e.location,
            action: <>
                <div className="d-flex action-btn">
                    {pageAccess?.['update'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                            onClick={() => {
                                setAddUserModalShow(true);
                                setUserDetails(e)
                            }}
                        >
                            <i className="ri-edit-line" style={{ fontSize: "14px" }}></i>
                        </button>
                    )}
                    {pageAccess?.['delete'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            onClick={() => {
                                setUserDeleteModalShow(true);
                                setUserName(e.first_name)
                                setUserId(e.id)
                            }}
                        >
                            <i className="ri-delete-bin-5-line" style={{ fontSize: "14px" }}></i>
                        </button>
                    )}
                </div>
            </>,
        }
    })

    return (
        <div className="master-list-container h-100">
            <div className='organization-headings mb-2'>Users</div>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <SearchInput
                    onChange={(e) => setSearch(e)}
                />
                <div className='d-flex gap-2'>

                    {pageAccess?.['create'].includes(userInfo?.user_role) && (
                        <UploadCSV
                            onChange={uploadCSVOnChange}
                            loading={CSVUploadLoading}
                            tooltipContent={<>Upload User list in CSV file.< br /> <a className='text-primary cursor-pointer' href={sampleFile} download="sample.csv">Download</a> the sample file.</>}
                        />
                    )}
                    {pageAccess?.['create'].includes(userInfo?.user_role) && (
                        <button
                            className="add-user-btn text-white"
                            onClick={() => {
                                setAddUserModalShow(true);
                                setUserDetails(undefined)
                            }}
                        >
                            <i className="fas fa-plus me-2"></i>
                            Add User
                        </button>
                    )}
                </div>
            </div>
            {getMasterListUserError ? (
                <Alert variant="danger">{getMasterListUserError}</Alert>) : (
                <>
                    <Table
                        columns={userTableColumns}
                        dataSource={userTableData} pagination={false}
                        scroll={{ x: 800, y: "calc(100vh - 292px)" }}
                    />
                    {getMasterListUserLoad && (
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "56vh" }}>
                            <Loader margin="0" />
                        </div>
                    )}
                    {userTableData?.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 190px)" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                                <p className='opacity'>No Users Found!</p>
                            </div>
                        </div>)}
                </>
            )}
            <Modal
                scrollable={false}
                show={addUserModalShow}
                size="lg"
                onHide={() => setAddUserModalShow(false)}
                backdrop="static"
                centered
            >
                <AddUser
                    closeAddUserModal={() => setAddUserModalShow(false)}
                    UserDetails={UserDetails}
                />
            </Modal>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={UserDeleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        onHide={() => setUserDeleteModalShow(false)}
                        item_name={UserName}
                        dispatch_item={deleteMasterListUserData(UserId)}
                        loading={deleteMasterListUserLoad}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MasterList