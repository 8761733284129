import React from "react";

const Header = (props) => {
    return (
        <div>
            <div className="event_header d-flex justify-content-between align-items-center">
                <label className="fs-4" htmlFor="event_select">
                    {props.props.page_name}
                </label>
            </div>
        </div>
    );
};

export default Header;
