import React, { useRef, useState } from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form } from "react-bootstrap";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: "var(--primary_bg)",
        color: "var(--black)",
        '&:hover, &:focus': {
            backgroundColor: "#febe00c7",
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

function Day(props) {
    const { day, selectedDay, ...other } = props;

    if (selectedDay == null) {
        return <PickersDay day={day} {...other} />;
    }

    const start = selectedDay.startOf('week');
    const end = selectedDay.endOf('week');

    const dayIsBetween = day.isBetween(start, end, null, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
        />
    );
}


function WeekComparison(props) {
    const {
        StartDate,
        setStartDate,
        EndDate,
        setEndDate
    } = props

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const [CalendarType, setCalendarType] = useState('start');

    const startData = StartDate ? `${StartDate.startOf('week').$D}/${StartDate.startOf('week').$M + 1}/${StartDate.startOf('week').$y} - ${StartDate.endOf('week').$D}/${StartDate.endOf('week').$M + 1}/${StartDate.endOf('week').$y}` : ''


    const endData = EndDate ? `${EndDate.startOf('week').$D}/${EndDate.startOf('week').$M + 1}/${EndDate.startOf('week').$y} - ${EndDate.endOf('week').$D}/${EndDate.endOf('week').$M + 1}/${EndDate.endOf('week').$y}` : ''

    const DisableStartDate = (date) => {
        return date.startOf('week').$d.toLocaleDateString() === EndDate.startOf('week').$d.toLocaleDateString();
    }

    const DisableEndDate = (date) => {
        return date.startOf('week').$d.toLocaleDateString() === StartDate.startOf('week').$d.toLocaleDateString();
    }

    return (
        <div>
            <h6>Select Weeks for Comparison</h6>
            <div className="d-flex align-items-center flex-column justify-content-center gap-2" style={{ width: "320px" }}>
                <Form.Control
                    ref={startDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={startData || ""}
                    placeholder='dd/mm/yyyy'
                    onFocus={() => setCalendarType('start')}
                />
                <Form.Control
                    ref={endDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={endData || ""}
                    placeholder='dd/mm/yyyy'
                    onFocus={() => setCalendarType('end')}
                    autoFocus
                />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {CalendarType === 'start' ? (
                    <DateCalendar
                        value={StartDate}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                            setStartDate(newValue)
                            startDateRef.current.focus()
                        }}
                        shouldDisableDate={DisableStartDate}
                        slots={{ day: Day }}
                        slotProps={{
                            day: {
                                selectedDay: StartDate,
                            },
                        }}
                    />

                ) : (
                    <DateCalendar
                        value={EndDate}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                            setEndDate(newValue)
                            endDateRef.current.focus()
                        }}
                        shouldDisableDate={DisableEndDate}
                        slots={{ day: Day }}
                        slotProps={{
                            day: {
                                selectedDay: EndDate,
                            },
                        }}
                    />
                )}
            </LocalizationProvider>
        </div>
    )
}

export default WeekComparison