import React from 'react';
import "antd/dist/antd.css";
import { Steps } from "antd";

const FaceValidationStepper = (props) => {

    const { Step } = Steps;

    return (
        <Steps className='py-3' size='small' status={props.status} current={props.currentStep} labelPlacement="vertical">
            <Step title="Front Face" />
            <Step title="Left Side" />
            <Step title="Right Side" />
        </Steps>
    )
}

export default FaceValidationStepper;