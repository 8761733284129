import React from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap'

export default function GoBackButton() {
    let navigate = useNavigate();
    return (
        <Button
            variant="dark"
            onClick={() => navigate(-1)}
            className='d-flex align-items-center go-back-btn'>
            <i className="fas fa-chevron-left me-2"></i>Back
        </Button>
    )
}
