import React, { useState } from 'react'
import { Checkbox } from 'antd';
import { Form } from 'react-bootstrap';

function CheckBoxWithInput({ checkBoxLabel, checkBoxName, checked, inputValue, day, onDayChange, onCheckboxChange, onInputChange }) {

    const [Show, setShow] = useState(checked);


    const FontStyling = {
        fontSize: "14px",
        whiteSpace: "nowrap",
        color: "#000000",
    }

    const InputStyle = {
        fontSize: "0.70rem",
        padding: "5px 10px",
        width: "90%",
        height: "36px",
    }

    const InputComponent = {
        email: (
            <Form.Control
                style={InputStyle}
                className="alert-input cursor-pointer border_radius_sm"
                type="email"
                placeholder='Enter Email ID'
                onChange={e => {
                    onInputChange(e.target.value)
                }}
                value={inputValue || ""}
            >

            </Form.Control>
        ),
        whatsapp: (
            <Form.Control
                style={InputStyle}
                className="alert-input cursor-pointer border_radius_sm"
                placeholder='Enter WhatsApp Number'
                onChange={e => {
                    if (String(e.target.value).length <= 12) {
                        onInputChange(e.target.value)
                    }
                }}
                type="number"
                value={inputValue || ""}
                min={0}
            >

            </Form.Control>
        ),
        api: (
            <Form.Control
                style={InputStyle}
                className="alert-input cursor-pointer border_radius_sm"
                placeholder='Enter API Endpoint'
                onChange={e => {
                    onInputChange(e.target.value)
                }}
                type="text"
                value={inputValue || ""}
                maxLength={100}
            >

            </Form.Control>
        ),
        daily_report: (
            <Form.Control
                style={InputStyle}
                className="alert-input cursor-pointer border_radius_sm"
                onChange={e => {
                    onInputChange(e.target.value)
                }}
                type="time"
                value={inputValue || ""}
            >

            </Form.Control>
        ),
        weekly_report: (
            <div>
                <select
                    onChange={e => {
                        onDayChange(e.target.value)
                    }}
                    value={day || ""}
                    className="alert-input select-notification-days cursor-pointer"
                >
                    <option value="">Select Day</option>
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>w
                    <option value="0">Sunday</option>
                </select>
                <input
                    disabled={!day}
                    style={{ fontSize: "0.70rem", padding: "5px", width: "51%", height: "36px", borderRadius: "0px 4px 4px 0" }}
                    className={`alert-input ${!day ? "is-disable" : "cursor-pointer"}`}
                    onChange={e => {
                        onInputChange(e.target.value)
                    }}
                    type="time"
                    value={inputValue || ""}
                />
            </div>
        ),
        monthly_report: (
            <Form.Control
                style={InputStyle}
                className="alert-input cursor-pointer border_radius_sm"
                onChange={e => {
                    onInputChange(e.target.value)
                }}
                type="datetime-local"
                value={inputValue || ""}
            >
            </Form.Control>
        )
    }

    return (
        <div className=''>
            <Checkbox
                style={FontStyling}
                onChange={(e) => {
                    onCheckboxChange(e.target.checked)
                    setShow(e.target.checked)
                }}
                name={checkBoxName}
                checked={Show}
            >
                {checkBoxLabel}
            </Checkbox>
            {Show && (
                InputComponent[checkBoxName]
            )}
        </div>
    )
}

export default CheckBoxWithInput