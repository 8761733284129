import React from 'react'
import "./Loader.css"
import formLoader from "./formLoader.gif"

function Loading({ className }) {
    return (
        <div className={`loading-message ${className}`}>
            <img src={formLoader} alt="" height="36px" />
        </div>
    )
}

export default Loading
