import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react'
import { Image } from "react-bootstrap";
import captureTutorialGif from '../../../images/capture_tutorial.gif'
import userSlash from '../../../images/add-face-icon.svg'
import Webcam from "react-webcam";
import { useDispatch, useSelector } from 'react-redux';
import { faceAngleValidation, multipleAngleFaceValidation } from '../../../actions/faceRecognitionActions';
import { MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS, MULTIPLE_ANGLE_FACE_VALIDATION_RESET } from '../../../constants/faceRecognitionConstant';
import { Button } from 'antd';
import { imageToBase64, imgValidation, removeBase64String } from '../../../global/Helper'
import { ImgFormatAlert } from '../../../components/ToastAlert';
import { toast } from 'react-toastify';

const UpdateCaptureFace = (props) => {
    const {
        userDetails,
        leftFace,
        rightFace,
        frontFace,
        serviceId,
        videoConstraints,
        onSwitchCamera
    } = props

    const dispatch = useDispatch();

    const [faceDirection, setFaceDirection] = useState('front');
    const [captureImage, setCaptureImage] = useState(undefined);
    const [errors, setErrors] = useState(undefined);
    const [faceValidate, setFaceValidate] = useState(undefined);

    const faceValidation = useSelector((state) => state.faceValidation);
    const { error, faceVerified, loading } = faceValidation

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (faceVerified) {
            if (faceDirection === 'front') {
                props.setFrontFace(captureImage);
            }
            if (faceDirection === 'right') {

                props.setRightFace(captureImage);
            }
            if (faceDirection === 'left') {
                props.setLeftFace(captureImage);
            }
            setFaceValidate({ success: true });
        }
    }, [faceVerified])

    useEffect(() => {
        if (error) {
            setErrors(JSON.parse(error)?.data)
            props.startStreaming();
        } else if (faceVerified) {
            props.stopLiveCamera();
        }
    }, [error, faceVerified])

    useEffect(() => {
        if (faceVerified || error) {
            setTimeout(() => {
                dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_RESET })
                // setFaceValidate(undefined)
            }, 5000);
        }
    }, [faceVerified, error]);


    const webcamRef = useRef(null);

    useEffect(() => {
        if (props.videoStream) {
            const faceCaptureInterval = setInterval(async () => {

                const imageSrc = webcamRef?.current?.getScreenshot();
                if (imageSrc) {
                    let image_base64 = imageSrc.replace(removeBase64String, "")
                    let face_data = {
                        faceAngle: faceDirection,
                        capturedFace: image_base64,
                        service_id: serviceId
                    }

                    const { data, status } = await faceAngleValidation(userInfo?.token, face_data)
                    status === 200 && setFaceValidate({ success: true });
                    status === 400 && setFaceValidate({ error: JSON.parse(data)?.data });
                }

            }, 1000);

            return () => {
                clearInterval(faceCaptureInterval)
            }
        }
    }, [props.videoStream, webcamRef, faceDirection])


    const captureFace = useCallback((uploadImage) => {
        const imageSrc = uploadImage || webcamRef.current.getScreenshot();

        setCaptureImage(imageSrc)

        let image_base64 = imageSrc.replace(removeBase64String, "")

        if (faceDirection) {
            if (uploadImage) {
                dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS, payload: faceDirection })
            } else {
                dispatch(multipleAngleFaceValidation({ faceAngle: faceDirection, capturedFace: image_base64, "service_id": serviceId }));
            }
        }

    }, [webcamRef, faceDirection]);

    const getDefaultFace = (faceDirection, captureImage) => {
        switch (faceDirection) {
            case "front":
                return (frontFace || userDetails.image || userSlash);

            case "right":
                return (rightFace || userDetails.image_right || userSlash);

            case "left":
                return (leftFace || userDetails.image_left || userSlash)

            default:
                return captureImage
        }
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-center employee-detail-container py-3">
                <button
                    className={`w-100 ${faceDirection === 'front' ? 'user-visited' : 'user-unvisited'}`}
                    style={{
                        padding: "2px 22px",
                        borderRadius: "2px 0 0 2px"
                    }}
                    onClick={() => {
                        if (faceDirection !== 'front') {
                            props.stopLiveCamera();
                            setFaceDirection('front')
                            setErrors(undefined)
                            setCaptureImage(undefined)
                        }
                    }}
                    type='button'
                >
                    Front
                </button>
                <button
                    className={`w-100 ${faceDirection === 'left' ? 'user-visited' : 'user-unvisited'}`}
                    style={{
                        padding: "2px 22px",
                        borderRight: "0.8px solid rgba(0, 0, 0, 0.12)",
                        borderLeft: "0.8px solid rgba(0, 0, 0, 0.12)",
                    }}
                    type='button'
                    onClick={() => {
                        if (faceDirection !== 'left') {
                            props.stopLiveCamera();
                            setFaceDirection('left')
                            setErrors(undefined)
                            setCaptureImage(undefined)
                        }
                    }}
                >
                    Left
                </button>
                <button
                    className={`w-100 ${faceDirection === 'right' ? 'user-visited' : 'user-unvisited'}`}
                    style={{
                        padding: "2px 22px",
                        borderRadius: "0 2px 2px 0"
                    }}
                    type='button'
                    onClick={() => {
                        if (faceDirection !== 'right') {
                            props.stopLiveCamera();
                            setFaceDirection('right')
                            setErrors(undefined)
                            setCaptureImage(undefined)
                        }
                    }}
                >
                    Right
                </button>
            </div>
            <div className='mt-2 position-relative d-flex align-items-center flex-column'>
                {props.videoStream ?
                    <Fragment>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={videoConstraints}
                            className={`object-fill ${(errors || faceValidate?.error) && 'border-danger border border-3'} ${(faceVerified || faceValidate?.success) && 'border-success border border-3'}`}
                            screenshotQuality={1}
                            forceScreenshotSourceSize={true}
                            width={370}
                            height={320}
                        />
                        <div style={{ width: "370px" }}>
                            {(errors || faceValidate?.error) && <div className={`text-truncate text-start text-danger fw-semibold ${(faceVerified || faceValidate?.success) && 'd-none'}`} title={errors || faceValidate?.error}>{errors || faceValidate?.error}</div>}
                            {(faceVerified || faceValidate?.success) && <div className='text-truncate text-start text-success fw-semibold'>Perfect Positioned!</div>}
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        {captureImage ?
                            <Image
                                width={370}
                                height={320}
                                className='object-fill'
                                src={getDefaultFace(faceDirection, captureImage)}
                            /> :
                            <Image
                                width={370}
                                height={320}
                                className='object-fill'
                                src={getDefaultFace(faceDirection, captureTutorialGif)}
                            />}
                    </Fragment>
                }
            </div>
            <div className='d-flex justify-content-center my-3'>
                {!props.videoStream ? (
                    <button
                        className='d-flex align-items-center capture-btn border_radius_sm btn justify-content-center'
                        style={{ width: "146px " }}
                        onClick={() => {
                            props.startStreaming();
                            setErrors(undefined)
                        }}
                    >
                        <label htmlFor="">Change Image</label>
                    </button>
                ) : (
                    <Fragment>
                        {loading ?
                            <Button
                                type="text"
                                style={{ width: "146px " }}
                                className='d-flex align-items-center capture-btn border_radius_sm btn justify-content-center'
                                size="large"
                                loading
                            >
                                Capture
                            </Button> :
                            <div className='d-flex justify-content-center gap-3 w-100'>
                                <input
                                    type="file"
                                    accept='.jpg,.jpeg,.png'
                                    className=" d-none"
                                    id='upload-face-obj'
                                    onChange={async (e) => {
                                        const [file] = e.target.files;

                                        if (imgValidation.test(file.name)) {
                                            const imgObj = await imageToBase64(file)
                                            captureFace(imgObj);
                                            setErrors(undefined)
                                        } else {
                                            const photoFormat = file.name?.split('.').pop();
                                            toast(<ImgFormatAlert photoFormat={photoFormat} />)
                                        }
                                        e.target.value = '';
                                    }}
                                />
                                <label
                                    htmlFor='upload-face-obj'
                                    className='capture-btn btn d-flex align-items-center' style={{ border: "none" }}>
                                    <i className="ri-upload-2-line fs-5 me-2"></i>
                                    Upload
                                </label>
                                <button
                                    className='d-flex align-items-center capture-btn border_radius_sm btn justify-content-center'
                                    style={{ width: "146px " }}
                                    onClick={() => {
                                        captureFace();
                                        setErrors(undefined)
                                    }}
                                >
                                    Capture
                                </button>
                                <button
                                    onClick={onSwitchCamera}
                                    className='capture-btn btn d-flex align-items-center' style={{ border: "none" }}>
                                    <i className="ri-refresh-line fs-5 me-2"></i>
                                    Switch
                                </button>
                            </div>}
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default UpdateCaptureFace