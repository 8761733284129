import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { capitalizeFirstLater, getProgressBarColor } from '../../global/Helper'
const SystemUtilization = () => {
    const [data, setData] = useState({});

    const fetchData = () => {
        axios.get(`${API_ENDPOINT}/service_app/get_system_utilization/`)
            .then(response => {
                const cpu_objects = {
                    cpu_utilization: response.data.cpu_utilization,
                    ram_utilization: response.data.ram_utilization,
                    memory_utilization: response.data.memory_utilization,
                };
                if (response.data.gpu_utilization >= 0 && response.data.gpu_memory >= 0) {
                    const gpu_cpu_objects = {
                        gpu_utilization: response.data.gpu_utilization,
                        gpu_memory: response.data.gpu_memory,
                        ...cpu_objects
                    }
                    setData(gpu_cpu_objects);
                } else {
                    setData(cpu_objects);
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {

        fetchData();

        const interval = setInterval(() => {
            fetchData();
        }, 7000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="d-flex flex-lg-nowrap flex-wrap my-3 gap-2">
            {Object.keys(data)?.map(key => (
                <div className='utilization-box' key={key}>
                    <div className='d-flex justify-content-between flex-nowrap mb-1'>
                        <div className='text-nowrap utilization-text'>{capitalizeFirstLater(key.split("_").join(" "))}</div>
                        <div>{data[key]}%</div>
                    </div>
                    <div className="progress" style={{ height: "5px" }}>
                        <div className={`progress-bar ${getProgressBarColor(data[key])}`} role="progressbar" style={{ width: `${data[key]}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SystemUtilization