export const GET_SOFTWARE_UPDATE_DATA_REQUEST = 'GET_SOFTWARE_UPDATE_DATA_REQUEST';
export const GET_SOFTWARE_UPDATE_DATA_SUCCESS = 'GET_SOFTWARE_UPDATE_DATA_SUCCESS';
export const GET_SOFTWARE_UPDATE_DATA_FAIL = 'GET_SOFTWARE_UPDATE_DATA_FAIL';


export const PUT_SOFTWARE_UPDATE_DATA_REQUEST = 'PUT_SOFTWARE_UPDATE_DATA_REQUEST';
export const PUT_SOFTWARE_UPDATE_DATA_SUCCESS = 'PUT_SOFTWARE_UPDATE_DATA_SUCCESS';
export const PUT_SOFTWARE_UPDATE_DATA_FAIL = 'PUT_SOFTWARE_UPDATE_DATA_FAIL';
export const PUT_SOFTWARE_UPDATE_DATA_RESET = 'PUT_SOFTWARE_UPDATE_DATA_RESET';


export const INSTANT_SOFTWARE_UPDATE_REQUEST = 'INSTANT_SOFTWARE_UPDATE_REQUEST';
export const INSTANT_SOFTWARE_UPDATE_SUCCESS = 'INSTANT_SOFTWARE_UPDATE_SUCCESS';
export const INSTANT_SOFTWARE_UPDATE_FAIL = 'INSTANT_SOFTWARE_UPDATE_FAIL';
export const INSTANT_SOFTWARE_UPDATE_RESET = 'INSTANT_SOFTWARE_UPDATE_RESET';
