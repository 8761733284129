import React, { Fragment } from 'react'

function AccessTooltip({ access, text }) {
  return (
    <Fragment>
      {!access ? 'Access Denied!' : text}
    </Fragment>
  )
}

export default AccessTooltip