import React from 'react'

function SearchInput({ onChange, value, width = "50%", placeholder = 'Search', className }) {
    return (
        <div className={`user-search position-relative w-100 ${className}`}>
            <i className="ri-search-line text-black"></i>
            <input
                placeholder={placeholder}
                style={{ width: `${width}` }}
                className="m-0 text-truncate text-black"
                title={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

export default SearchInput