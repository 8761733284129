import {
    ALERT_MECHANISM_DATA_GET_REQUEST,
    ALERT_MECHANISM_DATA_GET_SUCCESS,
    ALERT_MECHANISM_DATA_GET_FAIL,

    ALERT_MECHANISM_DATA_POST_REQUEST,
    ALERT_MECHANISM_DATA_POST_SUCCESS,
    ALERT_MECHANISM_DATA_POST_FAIL,
    ALERT_MECHANISM_DATA_POST_RESET,

    ALERT_MECHANISM_DATA_GET_LIST_REQUEST,
    ALERT_MECHANISM_DATA_GET_LIST_SUCCESS,
    ALERT_MECHANISM_DATA_GET_LIST_FAIL,

    ALERT_MECHANISM_DATA_DELETE_REQUEST,
    ALERT_MECHANISM_DATA_DELETE_SUCCESS,
    ALERT_MECHANISM_DATA_DELETE_FAIL,
    ALERT_MECHANISM_DATA_DELETE_RESET,

    ALERT_MECHANISM_DATA_UPDATE_REQUEST,
    ALERT_MECHANISM_DATA_UPDATE_SUCCESS,
    ALERT_MECHANISM_DATA_UPDATE_FAIL,
    ALERT_MECHANISM_DATA_UPDATE_RESET,
} from '../constants/alertMechanismConstant'


export const getAlertMechanismDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case ALERT_MECHANISM_DATA_GET_REQUEST:
            return { loading: true };

        case ALERT_MECHANISM_DATA_GET_SUCCESS:
            return { loading: false, success: action.payload }

        case ALERT_MECHANISM_DATA_GET_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};



export const postAlertMechanismDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ALERT_MECHANISM_DATA_POST_REQUEST:
            return { loading: true };

        case ALERT_MECHANISM_DATA_POST_SUCCESS:
            return { loading: false, success: true }

        case ALERT_MECHANISM_DATA_POST_FAIL:
            return { loading: false, error: action.payload };

        case ALERT_MECHANISM_DATA_POST_RESET:
            return {};

        default:
            return state;
    }
};


export const getListAlertMechanismDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case ALERT_MECHANISM_DATA_GET_LIST_REQUEST:
            return { loading: true };

        case ALERT_MECHANISM_DATA_GET_LIST_SUCCESS:
            return { loading: false, success: action.payload }

        case ALERT_MECHANISM_DATA_GET_LIST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const alertMechanismDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ALERT_MECHANISM_DATA_DELETE_REQUEST:
            return { loading: true };

        case ALERT_MECHANISM_DATA_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ALERT_MECHANISM_DATA_DELETE_FAIL:
            return { loading: false, error: action.payload };

        case ALERT_MECHANISM_DATA_DELETE_RESET:
            return {};

        default:
            return state;
    }
};


export const updateAlertMechanismDataReducer = (state = {}, action) => {
    switch (action.type) {
        case ALERT_MECHANISM_DATA_UPDATE_REQUEST:
            return { loading: true };

        case ALERT_MECHANISM_DATA_UPDATE_SUCCESS:
            return { loading: false, success: true }

        case ALERT_MECHANISM_DATA_UPDATE_FAIL:
            return { loading: false, error: action.payload };

        case ALERT_MECHANISM_DATA_UPDATE_RESET:
            return {};

        default:
            return state;
    }
};
