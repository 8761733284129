import axios from 'axios'
import {
    GET_ANALYTICS_SERVICE_REQUEST,
    GET_ANALYTICS_SERVICE_SUCCESS,
    GET_ANALYTICS_SERVICE_FAIL,

    POST_ANALYTICS_FILTER_DATA_REQUEST,
    POST_ANALYTICS_FILTER_DATA_SUCCESS,
    POST_ANALYTICS_FILTER_DATA_FAIL,

    GET_SINGLE_ANALYTICS_DATA_REQUEST,
    GET_SINGLE_ANALYTICS_DATA_SUCCESS,
    GET_SINGLE_ANALYTICS_DATA_FAIL,

} from '../constants/analyticsConstant'

import { API_ENDPOINT } from '../constants/apiEndpoint'
import { getAPI, postAPI } from '../global/ApiManager';

export const getAnalyticsServices = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ANALYTICS_SERVICE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/analytics_app/get_analytics_page_data/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: GET_ANALYTICS_SERVICE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_ANALYTICS_SERVICE_FAIL,
            error: error
        })
    }
};


export const postAnalyticsFilterData = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ANALYTICS_FILTER_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/analytics_app/compare_analytics_page_data/`,
            filterData,
            config,
            dispatch
        )

        dispatch({
            type: POST_ANALYTICS_FILTER_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: POST_ANALYTICS_FILTER_DATA_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
};



export const getAnalyticsCameraServiceData = async (id, token) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        const formData = {
            "service_id": id
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/service_app/get_analytics_service_cameras/`,
            formData,
            config
        );

        return { "data": data }
    } catch (error) {
        return { "data": [] }
    }
}


export const analyticsOnOff = async (id, token) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        await axios.patch(
            `${API_ENDPOINT}/analytics_app/on_off_alert/${id}/`,
            config
        );

        return { "status": 200 }
    } catch (error) {
        return { "status": 404 }
    }
}


export const getSingleAnalyticsData = (configId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SINGLE_ANALYTICS_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/analytics_app/notification_config_api/${configId}/`, dispatch)


        dispatch({
            type: GET_SINGLE_ANALYTICS_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_SINGLE_ANALYTICS_DATA_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
};




export const analyticsNotificationDelete = async (config_id, notification_id, token) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        await axios.delete(
            `${API_ENDPOINT}/analytics_app/delete_alert_recipients/${config_id}/${notification_id}/`,
            config
        );

        return { "status": 200 }
    } catch (error) {
        return { "status": 404 }
    }
}



export const getConfigCameras = async (formData, token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/analytics_app/cameras_data/`,
            formData,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}


export const getConfigCamerasGroup = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/analytics_app/camera_groups_data/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}


export const getAnalyticsCustomBarChartData = async (formData, token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/analytics_app/get_analytics_page_data/`,
            formData,
            config
        )

        return { 'data': data }

    } catch (error) {
        return { 'data': {} }
    }
};

export const getFilteredServices = async (formData, token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/analytics_app/service_filter_data/`,
            formData,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}

function downloadFile(url, fileName) {
    window.open(url, '_blank')
    // fetch(url)
    //     .then(response => response.blob())
    //     .then(blob => {
    //         const a = document.createElement('a');
    //         const objectURL = URL.createObjectURL(blob);

    //         a.href = objectURL;
    //         a.download = fileName;

    //         document.body.appendChild(a);
    //         a.click();

    //         document.body.removeChild(a);
    //         URL.revokeObjectURL(objectURL);
    //     })
    //     .catch(error => console.error('Error downloading file:', error));
}

export const exportAnalyticsReport = async (formData, token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/analytics_app/export_analytics_report/`,
            formData,
            config
        )

        let nullFound = false;

        Object.keys(data).forEach((e) => {
            if (!data[e]){
                nullFound = true;
                return;
            }
            downloadFile(data[e], `analytics ${e} report for ${formData.service_name} start ${new Date(formData.start_date).toLocaleTimeString([], { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })} to ${new Date(formData.end_date).toLocaleTimeString([], { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}.${e}`)
        })

        if (nullFound) {
            return { 'status': 400 };
        }

        return { 'status': 200 }
    } catch (error) {
        return { 'status': 400 }
    }
}