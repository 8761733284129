import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { postOccupancySetupData } from '../../../actions/occupancyMonitoringActions';
import { sortObjectByKey } from '../../../global/Helper';
import { POST_SETUP_OCCUPANCY_DATA_RESET } from '../../../constants/occupancyMonitoringConstants';
import { CommonErrorAlert, CommonSuccessAlert } from '../../../components/ToastAlert';
import { toast } from 'react-toastify';
import { getServicesData } from '../../../actions/servicesActions';
import { Button } from 'antd';

function OccupancyMonitoringModal(props) {

    const {
        data,
        occupancyMonitoringModalHide,
    } = props

    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const [TotalBreakHours, setTotalBreakHours] = useState(0);
    const [HoursCovered, setHoursCovered] = useState(0);
    const [Weekend, setWeekend] = useState([]);
    const [FromTime, setFromTime] = useState('');
    const [ToTime, setToTime] = useState('');
    const [DutyTimeDuration, setDutyTimeDuration] = useState('');
    const [BreakTime, setBreakTime] = useState([{
        "break_type": "",
        "duration": "",
    }])

    const OccupancySetupDataPost = useSelector((state) => state.OccupancySetupDataPost);
    const { loading: OccupancySetupLoad, success: OccupancySetupSuccess, error: OccupancySetupError } = OccupancySetupDataPost;

    const dispatch = useDispatch();

    const weekOption = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]

    useEffect(() => {
        if (OccupancySetupSuccess) {
            occupancyMonitoringModalHide()
            dispatch(getServicesData())
            toast(<CommonSuccessAlert message={'Occupancy monitoring setup successfully'} />);
            dispatch({ type: POST_SETUP_OCCUPANCY_DATA_RESET })
        }
        if (OccupancySetupError) {
            toast(<CommonErrorAlert message={OccupancySetupError} />);
            dispatch({ type: POST_SETUP_OCCUPANCY_DATA_RESET })
        }
    }, [OccupancySetupSuccess, OccupancySetupError])

    useEffect(() => {
        const TotalBreakTime = BreakTime?.map(e => e.duration).reduce((a, b) => Number(a) + Number(b))

        setTotalBreakHours(Number(TotalBreakTime))

        setHoursCovered(Number(DutyTimeDuration) - TotalBreakTime)

    }, [DutyTimeDuration, BreakTime])

    useEffect(() => {
        if (FromTime && ToTime) {
            const startTime = new Date(`1970-01-01T${FromTime}:00`);
            const endTime = new Date(`1970-01-01T${ToTime}:00`);

            const timeDifference = endTime - startTime;

            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            setDutyTimeDuration(`${hours}.${minutes}`)
        }
    }, [FromTime, ToTime])

    useEffect(() => {
        if (data?.extra_fields?.occupancy_monitor_config) {
            setBreakTime(data.extra_fields.occupancy_monitor_config.break_time)
            setWeekend(data.extra_fields.occupancy_monitor_config.weekend)
            setFromTime(data.extra_fields.occupancy_monitor_config.duty_time.from)
            setToTime(data.extra_fields.occupancy_monitor_config.duty_time.to)
        }
    }, [data])

    const formData = useMemo(() => ({
        'occupancy_monitor_config': {
            'duty_time': { 'from': FromTime, 'to': ToTime },
            'break_time': BreakTime,
            'weekend': Weekend
        }
    }), [FromTime, ToTime, BreakTime, Weekend])

    useEffect(() => {
        setDisableSaveBtn(true)
        for (const element of BreakTime) {
            if (element.break_type.length > 0 && String(element.duration).length > 0 && ToTime.length > 0 && FromTime.length > 0 && JSON.stringify(sortObjectByKey(data.extra_fields.occupancy_monitor_config)) !== JSON.stringify(sortObjectByKey(formData.occupancy_monitor_config))) {
                setDisableSaveBtn(false)
            } else {
                setDisableSaveBtn(true)
                break;
            }
        }
    }, [formData, data])

    const submitSetupOccupancyData = () => {
        dispatch(postOccupancySetupData(data.id, 'occupancy_monitor_config', formData))
    }

    const onBreakTimeInputChange = (key, value, index) => {
        const updateBreakTIme = [...BreakTime]

        updateBreakTIme[index] = {
            ...updateBreakTIme[index],
        };
        updateBreakTIme[index][key] = value

        setBreakTime(updateBreakTIme)
    }
    return (
        <>
            <Modal.Header className='border-none' closeButton>
                <Modal.Title >
                    Setup for Occupancy Monitoring
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4'>
                <div className='occupancy-monitoring-container px-3 mx-2'>
                    <div className='d-flex border-bottom pb-4'>
                        <div className='fs-6 bold bullet-heading bold position-relative text-nowrap' style={{ width: "200px" }}>
                            Set Duty Time :
                        </div>
                        <div className='d-flex gap-2'>
                            <div>
                                <label className='d-block m-0'>From</label>
                                <input
                                    type="time"
                                    className='bg-grey border border_radius_sm p-2'
                                    value={FromTime || ''}
                                    onChange={(e) => setFromTime(e.target.value)}
                                />
                            </div>
                            <div>
                                <label className='d-block m-0'>To</label>
                                <input
                                    type="time"
                                    className='bg-grey border border_radius_sm p-2'
                                    value={ToTime || ''}
                                    onChange={(e) => setToTime(e.target.value)}
                                />
                            </div>
                            <div>
                                <label className='d-block m-0'>Duration</label>
                                <div className='position-relative'>
                                    <input
                                        type="text"
                                        style={{ width: "92px" }}
                                        className='bg-grey border border_radius_sm p-2'
                                        placeholder='00.00'
                                        disabled
                                        value={DutyTimeDuration || ''}
                                    />
                                    <span
                                        className='position-absolute opacity top-0 bottom-0 m-auto end-0 me-2'
                                        style={{ height: "fit-content" }}
                                    >
                                        hr
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex border-bottom py-4'>
                        <div className='fs-6 bold bullet-heading bold position-relative text-nowrap' style={{ width: "200px" }}>
                            Set Break time :
                        </div>
                        <div>
                            <div className='d-flex align-items-start gap-1 mb-2'>
                                <div className='opacity bold'>Total Break Hours : </div><div className='text-dark bold'>{TotalBreakHours !== 0 ? TotalBreakHours.toFixed(2) : "00"}</div>
                                <div className='ms-auto'>
                                    <button
                                        className='d-flex align-items-start border_radius_xsm bg-dark add-break-btn fs-5 text-white'
                                        style={{
                                            padding: "10px 12px"
                                        }}
                                        onClick={() => setBreakTime([...BreakTime, {
                                            "break_type": "",
                                            "duration": "",
                                        }])}
                                    >
                                        <i className="fas fa-plus" style={{ fontSize: "15px" }}></i>
                                    </button>
                                </div>
                            </div>
                            {BreakTime?.map((e, index) => (
                                <div key={`${index + 1}`} className='d-flex align-items-end gap-2 mb-2'>
                                    <div>
                                        <label className={`d-block m-0 ${index > 0 && 'd-none'}`}>Break Type</label>
                                        <input
                                            type="text"
                                            style={{ width: "220px" }}
                                            className='bg-grey border border_radius_sm p-2'
                                            placeholder='e.g Lunch'
                                            value={e.break_type || ''}
                                            onChange={(e) => onBreakTimeInputChange('break_type', e.target.value, index)}
                                        />
                                    </div>
                                    <div>
                                        <label className={`d-block m-0 ${index > 0 && 'd-none'}`}>Duration</label>
                                        <div className='position-relative'>
                                            <input
                                                type="number"
                                                style={{ width: "92px" }}
                                                className='bg-grey border border_radius_sm p-2'
                                                placeholder='00.00'
                                                value={e.duration || ''}
                                                min={0}
                                                onChange={(e) => onBreakTimeInputChange('duration', e.target.value, index)}
                                            />
                                            <span
                                                className='position-absolute opacity top-0 bottom-0 m-auto end-0 me-2'
                                                style={{ height: "fit-content" }}
                                            >
                                                hr
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className={`bg-danger text-white fs-5 border_radius_xsm px-2 ${BreakTime.length === 1 && 'is-disable'}`}
                                            onClick={() => {
                                                if (BreakTime.length !== 1) {
                                                    setBreakTime(BreakTime.filter((e, i) => index !== i))
                                                }
                                            }}
                                        >
                                            <i className="ri-delete-bin-5-line"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='d-flex border-bottom py-4'>
                        <div className='fs-6 bold bullet-heading bold position-relative text-nowrap' style={{ width: "300px" }}>
                            Set Weekend :
                        </div>
                        <div>
                            <div className='d-flex flex-wrap gap-2'>
                                {weekOption?.map(e => (
                                    <div key={e}>
                                        <button
                                            className='bg-grey bold weekend-btn btn'
                                            style={{ border: Weekend.includes(e) ? "2px solid var(--primary_bg)" : "none" }}
                                            onClick={() => {
                                                if (Weekend.includes(e)) {
                                                    setWeekend(Weekend.filter(w => w !== e))
                                                } else {
                                                    setWeekend([...Weekend, e])
                                                }
                                            }}
                                        >
                                            {e}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='d-flex pt-4'>
                        <div className='fs-6 bold bullet-heading bold position-relative'>
                            {String(HoursCovered).length === 1 && '0'}{!isNaN(HoursCovered) ? HoursCovered.toFixed(2) : "00"} hrs has to be covered daily by person
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-none'>
                {OccupancySetupLoad ? (
                    <Button
                        type="primary"
                        className="btn btn_primary ms-auto"
                        size="large"
                        loading
                    >
                        Save
                    </Button>
                ) : (
                    <button
                        onClick={submitSetupOccupancyData}
                        className='btn bg-warning px-4'
                        disabled={disableSaveBtn}
                    >
                        Save
                    </button>
                )}
            </Modal.Footer>
        </>
    )
}

export default OccupancyMonitoringModal