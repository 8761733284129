import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { Spinner, Form, Modal, Dropdown, ButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'antd';
import { cameraFrameRefresh } from "../../actions/dataActions";
import Crop from "../../components/jCrop/Crop";
import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { getServicesData } from "../../actions/servicesActions";
import { DefaultUpdateAlerts } from "../../components/ToastAlert";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ClassesDropdown from "./ClassesDropdown";
import selectSquareImg from '../../images/select-square.svg'
import selectLinesImg from '../../images/select-lines.svg'
import selectPointsImg from '../../images/select-points.svg'
import CropKonva from "../../components/jCrop/CropKonva";
import PolygonRect from "../../components/jCrop/PolygonRect";
import { areaImageHeight } from "../../global/Helper";
import addArea from '../../components/jCrop/icons/addArea.png'
import { postAPI } from "../../global/ApiManager";

function ServiceConfigurationScreen(props) {

    const {
        setShow,
    } = props

    const [undoAreaSelection, setUndoAreaSelection] = useState(0);
    const [redoAreaSelection, setRedoAreaSelection] = useState(0);
    const [updateServiceLoading, setUpdateServiceLoading] = useState(false);
    const [imageSelectionParams, setImageSelectionParams] = useState([]);
    const [redoPoints, setRedoPoints] = useState([]);
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);
    const [imageWidthHeight, setImageWidthHeight] = useState({});
    const [classesName, setClassesName] = React.useState([]);
    const [accuracyLevel, setAccuracyLevel] = useState(0.75);
    const [areaSelectionStyle, setAreaSelectionStyle] = useState('rectangle');

    const [rectangles, setRectangles] = useState([]);
    const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
    const [selectedEditableIndex, setSelectedEditableIndex] = useState(null);

    const ServicesDataGetSingle = useSelector((state) => state.ServicesDataGetSingle);
    const { success: configureServiceData, loading } = ServicesDataGetSingle;

    const defaultInputsValue = {
        people_count: configureServiceData?.extra_fields?.people_count,
        speed_limit: configureServiceData?.extra_fields?.speed_limit,
        area_distance: configureServiceData?.extra_fields?.area_distance
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ defaultValues: defaultInputsValue });

    const [clear, setClear] = useState(false);

    const dispatch = useDispatch();

    const RefreshCameraFrame = useSelector((state) => state.RefreshCameraFrame);
    const { loading: frameLoad } = RefreshCameraFrame;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const handleClear = (e) => {
        setImageSelectionParams([])
        setRectangles([])
        setRedoPoints([])
        setClear(true);
    };

    useEffect(() => {
        if (areaSelectionStyle === 'points' || areaSelectionStyle === 'polygon') {
            for (const element of imageSelectionParams) {
                setDisableSaveBtn(element.length < 4)
            }
        }
        if (areaSelectionStyle === 'rectangle') {
            setDisableSaveBtn(rectangles.length === 0)
        }
        if (areaSelectionStyle === 'lines') {
            setDisableSaveBtn(imageSelectionParams.length < 4)
        }
    }, [imageSelectionParams, areaSelectionStyle, undoAreaSelection, redoAreaSelection, rectangles.length])

    const camera_area_selection_handler = async (e) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };

        let post_data = {
            service_id: configureServiceData.service.id,
            camera_id: configureServiceData.camera.camera_id,
            image_selection_params: imageSelectionParams,
            selected_classes: classesName,
            confidence_score: Number(accuracyLevel.toFixed(2)),
            squares_selection: rectangles,
            area_selection_type: areaSelectionStyle,
            ...imageWidthHeight
        };

        if (configureServiceData.service.service_name.toLowerCase().includes("people count")) {
            post_data.people_count = Number(e.people_count)
        }

        if (configureServiceData.service.service_name.toLowerCase().includes("vehicle overspeed")) {
            post_data.speed_limit = Number(e.speed_limit)
            post_data.area_distance = Number(e.area_distance)
        }

        setUpdateServiceLoading(true)
        await postAPI(`/service_app/update_service_on_camera/`, post_data, config, dispatch);
        setShow(false);
        dispatch(getServicesData())
        toast(<DefaultUpdateAlerts name={configureServiceData.camera.camera_name} />);
        setUpdateServiceLoading(false)
    };

    const numberInputValidator = (e) => {
        if (Number.isInteger(Number(e))) {
            return (e).trim().length > 0;
        }
    }

    useEffect(() => {
        configureServiceData.extra_fields?.selected_classes && setClassesName(configureServiceData.extra_fields.selected_classes)
        configureServiceData.extra_fields?.confidence_score && setAccuracyLevel(configureServiceData.extra_fields.confidence_score)
        configureServiceData.extra_fields?.squares_selection && setRectangles(configureServiceData.extra_fields.squares_selection)
        if (configureServiceData.extra_fields.area_selection_type) {
            setAreaSelectionStyle(configureServiceData.extra_fields.area_selection_type)
        } else {
            setAreaSelectionStyle(configureServiceData?.service?.extra_details?.area_selection_type?.[0] || 'points')
        }
    }, [configureServiceData.extra_fields])

    const options = useMemo(() => configureServiceData?.service?.all_classes?.map(e => e.class_name), [configureServiceData])

    const onRectangleChange = (index) => {
        const newRectangle = rectangles?.map(rect => ({
            ...rect,
            stroke: "#4AC237",
            textBackground: "#4AC237"
        }))

        const updatedRectangles = [...newRectangle];
        if (index >= 0) {
            updatedRectangles[index] = {
                ...updatedRectangles[index],
                stroke: "#333",
                textBackground: "#333",
            };
        }
        setRectangles(updatedRectangles);
    }

    const permanentDelete = (index) => {
        setRectangles(rectangles.filter((e, i) => i !== index))
    }

    const onSeatEdit = (index) => {
        setSelectedShapeIndex(index)
        selectedEditableIndex === index ? setSelectedEditableIndex(null) : setSelectedEditableIndex(index)
        onRectangleChange(index)
    }

    useEffect(() => {
        if (areaSelectionStyle === 'rectangle') {
            const getRectangleArea = rectangles?.map((rect) => {
                const x = Math.round(rect.x)
                const y = Math.round(rect.y)
                const width = Math.round(rect.width)
                const height = Math.round(rect.height)

                return (
                    [
                        {
                            x: x,
                            y: y,
                        },
                        {
                            x: x + width,
                            y: y,
                        },
                        {
                            x: x + width,
                            y: y + height,
                        },
                        {
                            x: x,
                            y: y + height,
                        },
                    ]
                );
            })
            setImageSelectionParams(getRectangleArea)
        }
    }, [rectangles, areaSelectionStyle])

    const addRectangle = () => {
        const newRectangle = {
            x: 1090 / 2 - 50,
            y: areaImageHeight / 2 - 50,
            width: 110,
            height: 130,
            fill: '#4AC2374D',
            stroke: '#4AC237',
            textBackground: '#4AC237',
            strokeWidth: 1,
            draggable: true,
            is_delete: false,
            text: `Box No. ${rectangles.length + 1}`,
        };
        setRectangles([...rectangles, newRectangle]);
    };

    const getAreaSelectionType = {
        'points': <Crop
            area_selection_style={'multipleRectangles'}
            serviceName={configureServiceData.service.service_name}
            linePointSize={4}
            area_values={configureServiceData?.extra_fields?.image_selection_params ? [configureServiceData?.extra_fields?.image_selection_params] : null}
            send_image={configureServiceData?.camera?.image}
            clearFalse={(e) => setClear(e)}
            get_areas={(e) => setImageSelectionParams(e)}
            redoPoints={(e) => setRedoPoints(e)}
            clear_data={clear}
            undo_area_selection={undoAreaSelection}
            redo_area_selection={redoAreaSelection}
            setImageWidthHeight={setImageWidthHeight}
            width={1090}
            height={areaImageHeight}
        />,
        'lines': <Crop
            area_selection_style={'lines'}
            serviceName={configureServiceData.service.service_name}
            linePointSize={4}
            area_values={configureServiceData?.extra_fields?.image_selection_params ? [configureServiceData?.extra_fields?.image_selection_params] : null}
            send_image={configureServiceData?.camera?.image}
            clearFalse={(e) => setClear(e)}
            get_areas={(e) => setImageSelectionParams(e)}
            redoPoints={(e) => setRedoPoints(e)}
            clear_data={clear}
            undo_area_selection={undoAreaSelection}
            redo_area_selection={redoAreaSelection}
            setImageWidthHeight={setImageWidthHeight}
            width={1090}
            height={areaImageHeight}
        />,
        'rectangle': <CropKonva
            width={1090}
            height={areaImageHeight}
            send_image={configureServiceData?.camera?.image}
            rectangles={rectangles}
            setRectangles={setRectangles}
            selectedShapeIndex={selectedShapeIndex}
            setSelectedShapeIndex={setSelectedShapeIndex}
            deleteRectangle={permanentDelete}
            editRectangle={onSeatEdit}
            setSelectedEditableIndex={setSelectedEditableIndex}
            onRectangleChange={onRectangleChange}
            squareName='Box No.'
        />,
        'polygon': <PolygonRect
            area_values={configureServiceData?.extra_fields?.image_selection_params}
            background_image={configureServiceData?.camera?.image}
            get_areas={(e) => setImageSelectionParams(e)}
            width={1090}
            height={areaImageHeight}
            clear={clear}
            setClear={(e) => setClear(e)}
            undo_area_selection={undoAreaSelection}
            redo_area_selection={redoAreaSelection}
            setRedoPoints={setRedoPoints}
            redoPoints={redoPoints}
        />
    }

    const getSelectionImage = {
        'polygon': selectPointsImg,
        'rectangle': selectSquareImg,
        'points': selectPointsImg,
        'lines': selectLinesImg,
    }

    return (
        <Modal.Body className="p-0 service-config-modal">
            <div className="row m-0">
                <div className="col-md-12 py-3">
                    <div>
                        {loading === false && (
                            <div>
                                <Modal.Header className="position-relative justify-content-between pt-0 pb-2" closeButton>
                                    <Modal.Title className="fs-5 text-capitalize">
                                        {configureServiceData.service.service_name} - {configureServiceData?.camera.camera_name}
                                    </Modal.Title>
                                </Modal.Header>
                                <div className="position-relative">
                                    {areaSelectionStyle === 'rectangle' && (
                                        <button
                                            className="btn bg-grey border_radius_sm bg-white position-absolute end-0 top-0 fs-12 text-nowrap m-2 me-4"
                                            style={{ zIndex: "1" }}
                                            onClick={addRectangle}
                                        >
                                            <img
                                                src={addArea}
                                                width={20}
                                                height={20}
                                                className="me-2"
                                                alt="..."
                                            />
                                            Add Square
                                        </button>
                                    )}
                                    <div className={`jcrop_modal d-flex justify-content-center overflow-auto ${frameLoad && 'filter-class'}`}>
                                        {getAreaSelectionType[areaSelectionStyle]}
                                    </div>
                                    {frameLoad &&
                                        <div className="position-absolute spinner-position">
                                            <Spinner animation="border" variant="secondary" />
                                        </div>
                                    }
                                </div>
                                <div className="d-flex align-items-end justify-content-between py-2 px-2">
                                    <div className="d-flex align-items-end gap-3">
                                        <button
                                            className={`refresh-frame-btn border_radius_sm d-flex align-items-center justify-content-center ${frameLoad && 'is-disable'}`}
                                            title="Refresh Frame"
                                            onClick={() => dispatch(cameraFrameRefresh({ camera_id: configureServiceData?.camera?.camera_id }, configureServiceData.id))}
                                        >
                                            <i className={`ri-refresh-line ${frameLoad && 'rotate'}`}></i>
                                        </button>
                                        {areaSelectionStyle !== 'rectangle' && (
                                            <>
                                                <button
                                                    className={`${undoAreaSelection} undo-btn border_radius_sm ${imageSelectionParams?.length === 0 && 'is-disable'}`}
                                                    title="Undo"
                                                    disabled={imageSelectionParams?.length === 0}
                                                    onClick={() => setUndoAreaSelection((undoAreaSelection) => undoAreaSelection + 1)}
                                                >
                                                    <i className="ri-arrow-go-back-line"></i>
                                                </button>
                                                <button
                                                    className={`undo-btn border_radius_sm ${redoPoints.length === 0 && 'is-disable'}`}
                                                    title="Redo"
                                                    disabled={redoPoints.length === 0}
                                                    onClick={() => setRedoAreaSelection((redoAreaSelection) => redoAreaSelection + 1)}
                                                >
                                                    <i className="ri-arrow-go-forward-line"></i>
                                                </button>
                                            </>
                                        )}
                                        <Dropdown as={ButtonGroup} drop='end' className="area-selection-dropdown">
                                            <button
                                                className={`select_again_txt rounded-start border-0 text-nowrap pe-0`}
                                                disabled={!imageSelectionParams?.length > 0}
                                                onClick={handleClear}
                                            >
                                                Select Again
                                            </button>
                                            <Dropdown.Toggle className="select_again_txt rounded-end border border-0" id="dropdown-split-basic" />

                                            <Dropdown.Menu className="p-0 px-2">
                                                {Object.keys(getSelectionImage)?.map((e, i) => (
                                                    <Dropdown.Item
                                                        key={`${i + 1}`}
                                                        className={`border-bottom p-2 d-flex align-items-center gap-2 ${e === areaSelectionStyle && 'active'} ${!configureServiceData.service?.extra_details?.area_selection_type?.includes(e) && 'is-disable'}`}
                                                        onClick={() => {
                                                            setAreaSelectionStyle(e)
                                                            setUndoAreaSelection(0)
                                                            setRedoAreaSelection(0)
                                                            setRedoPoints([])
                                                            setClear(true)
                                                        }}
                                                    >
                                                        <img src={getSelectionImage[e]} alt="..." width={16} height={16} />
                                                        <div className="text-black-50 fw-semibold fs-15 text-capitalize">Select {e}</div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>


                                        <ClassesDropdown
                                            options={options}
                                            ClassesName={classesName}
                                            setClassesName={setClassesName}
                                            setAccuracyLevel={setAccuracyLevel}
                                            AccuracyLevel={accuracyLevel}
                                            showAccuracySlider={true}
                                            multiple={true}
                                        />
                                    </div>
                                    <Form noValidate onSubmit={handleSubmit(camera_area_selection_handler)} className="ms-3 d-flex align-items-end w-100 gap-3 justify-content-end">
                                        {configureServiceData?.service.service_name?.toLowerCase().includes("people count") && (
                                            <Form.Group className="w-100">
                                                <Form.Label className='m-0' htmlFor=""><b>People Count<span className="text-danger">*</span></b></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className='service-config-input border_radius_sm'
                                                    id="username"
                                                    placeholder="Enter the value for People Count"
                                                    isInvalid={!!errors.people_count}
                                                    {...register("people_count", {
                                                        required: true,
                                                        onChange: (e) => {
                                                            if (numberInputValidator(e.target.value)) {
                                                                setValue("people_count", e.target.value)
                                                            } else {
                                                                setValue("people_count", '')
                                                            }
                                                        }

                                                    })}
                                                    maxLength="10"
                                                />
                                            </Form.Group>
                                        )}
                                        {configureServiceData?.service.service_name?.toLowerCase().includes("vehicle overspeed") && (
                                            <>
                                                <Form.Group className="w-100 text-nowrap">
                                                    <Form.Label className='m-0' htmlFor=""><b>Speed Limits ( km/hr )<span className="text-danger">*</span></b></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="username"
                                                        className='service-config-input border_radius_sm'
                                                        id="username"
                                                        placeholder="Enter speed limits in km/hr"
                                                        isInvalid={!!errors.speed_limit}
                                                        {...register("speed_limit", {
                                                            required: true,
                                                        })}
                                                        min="0"
                                                    />
                                                </Form.Group>
                                                <Form.Group className="w-100 text-nowrap">
                                                    <Form.Label className='m-0' htmlFor=""><b>Distance between two lines<span className="text-danger">*</span></b></Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="username"
                                                        className='service-config-input border_radius_sm'
                                                        id="username"
                                                        placeholder="Enter distance between two lines"
                                                        isInvalid={!!errors.area_distance}
                                                        {...register("area_distance", {
                                                            required: true,
                                                        })}
                                                        min="0"
                                                    />
                                                </Form.Group>
                                            </>
                                        )}
                                        {updateServiceLoading ? (<Button type="primary" className="btn btn_primary" size="large" loading>
                                            Save
                                        </Button>) : (
                                            <button
                                                className="btn btn_primary px-4"
                                                disabled={disableSaveBtn}
                                            >
                                                Save
                                            </button>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal.Body>
    )
}

export default ServiceConfigurationScreen