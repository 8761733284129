import React from "react";
import TopNavbar from "./TopNavbar";

function RightContainer(props) {
    return (
        <div className="sidebar_right_container">
            <div className="sidebar_right_inner_container" style={{ position: "relative" }}>
                <div className="right_screen">
                    <TopNavbar
                        props={props.page}
                    />
                    <div className="right_inner_screen px-4 pt-1 pb-2">
                        {props.props.screen}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RightContainer;
