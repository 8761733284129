export const Colors = ['#FFB84C', "#F266AB", "#A459D1", "#2CD3E1", "#481D6C", '#46337E', '#365C8D', '#277F8E'
    , '#1FA187', '#4AC16D',
    '#73B865', '#E1AC09',
    '#B4720F', '#003046', '#007D89', '#606872',
    '#006EB9', '#68514C', '#D6F88C', '#D151D3',
    '#549263', '#EE27F2', '#5D0F5C', '#839646',
    '#D70C79', '#63DC42']

export const reportSummaryColors = ["#333333", "#E73F49", "#F7AA2D", "#ED8E00", "#FFC700", "#3B1206", "#723111", "#992E01", "#AF4104", "#BC640A", "#DA7E02", "#F1A70E", "#F9CC14", "#FCDB78"]

export const reportSummaryColorsOpacity = ["#666666", "#F18C92", "#FACC81", "#ed8e009c", "#ffc70094", "#3b12069e", "#723111a6", "#992e01ab", "#af4104ad", "#bc640aa1", "#da7e0280", "#f1a70e87", "#f9cc148c", "#fcdb7887"]


export const reportSummaryDoughnutColors = ["#333333", "#D38300", "#F7AA2D", "#FFCC79", "#FFD38B", "#FFE9C6", "#FFF2DD"]

export const lineChartColors = ['#6F6F6F', "#FEBE00"]