import axios from 'axios'
import {
    ALERT_MECHANISM_DATA_POST_REQUEST,
    ALERT_MECHANISM_DATA_POST_SUCCESS,
    ALERT_MECHANISM_DATA_POST_FAIL,

    ALERT_MECHANISM_DATA_GET_LIST_REQUEST,
    ALERT_MECHANISM_DATA_GET_LIST_SUCCESS,
    ALERT_MECHANISM_DATA_GET_LIST_FAIL,

    ALERT_MECHANISM_DATA_DELETE_REQUEST,
    ALERT_MECHANISM_DATA_DELETE_SUCCESS,
    ALERT_MECHANISM_DATA_DELETE_FAIL,

    ALERT_MECHANISM_DATA_UPDATE_REQUEST,
    ALERT_MECHANISM_DATA_UPDATE_SUCCESS,
    ALERT_MECHANISM_DATA_UPDATE_FAIL,
} from '../constants/alertMechanismConstant'

import { API_ENDPOINT } from '../constants/apiEndpoint'
import { deleteAPI, getAPI, postAPI, putAPI } from '../global/ApiManager'

export const getAlertMechanismData = async (serviceName, token) => {
    try {
        const params = {
            service_name: serviceName
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/analytics_app/alert_value_cameras_service/`,
            config
        )

        return { data: data, status: 200 }

    } catch (error) {
        return { data: error, status: 400 }
    }
}

export const postAlertMechanismData = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ALERT_MECHANISM_DATA_POST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        formData.user_id = userInfo.id


        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/analytics_app/notification_config_api/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: ALERT_MECHANISM_DATA_POST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ALERT_MECHANISM_DATA_POST_FAIL,
            payload: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
}

export const getListAlertMechanismData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: ALERT_MECHANISM_DATA_GET_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()


        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/analytics_app/notification_config_api/`, dispatch)

        dispatch({
            type: ALERT_MECHANISM_DATA_GET_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ALERT_MECHANISM_DATA_GET_LIST_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const alertMechanismDelete = (alert_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ALERT_MECHANISM_DATA_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()


        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/analytics_app/notification_config_api/${alert_id}/`,
            config,
            dispatch
        )

        dispatch({
            type: ALERT_MECHANISM_DATA_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ALERT_MECHANISM_DATA_DELETE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const updateAlertMechanismData = (formData, alert_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ALERT_MECHANISM_DATA_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        formData.user_id = userInfo.id


        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await putAPI(
            `/analytics_app/notification_config_api/${alert_id}/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: ALERT_MECHANISM_DATA_UPDATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ALERT_MECHANISM_DATA_UPDATE_FAIL,
            payload: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
}
