import { Button, Switch } from 'antd'
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import BackupTimePeriod from './BackupTimePeriod'
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSoftwareUpdateData, instantSoftwareUpdate, putSoftwareUpdateData } from '../../../actions/organizationActions/softwareUpdateActions';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => {
            if (callback) {
                callback(eventKey)
            }
        }
    );

    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <div
            className='d-flex align-items-center p-2 pb-0 cursor-pointer fs-6 w-100'
            onClick={decoratedOnClick}
        >
            <i className={`fs-4  me-2 ${isCurrentEventKey ? "ri-arrow-down-s-fill" : "ri-arrow-right-s-fill"}`}></i>
            {children}
        </div>
    );
}

function SoftwareUpdate() {
    const [Week, setWeek] = useState(["Select Weekday"]);
    const [BackupPeriodTime, setBackupPeriodTime] = useState('');
    const [BackupPeriod, setBackupPeriod] = useState(["Daily"]);
    const [AutoUpdateStatus, setAutoUpdateStatus] = useState(false);
    const [updateAvailable, setUpdateAvailable] = useState([]);
    const [updateHistory, setUpdateHistory] = useState([]);

    const dispatch = useDispatch();

    const timeOption = [
        "Daily", "Weekly", "Monthly"
    ]

    const SoftwareUpdateDataGet = useSelector((state) => state.SoftwareUpdateDataGet);
    const { success: softwareUpdateData } = SoftwareUpdateDataGet;

    const SoftwareUpdateInstant = useSelector((state) => state.SoftwareUpdateInstant);
    const { loading: instantSoftwareUpdateLoad } = SoftwareUpdateInstant;

    useEffect(() => {
        if (softwareUpdateData?.[0]?.software_updates) {
            const availableUpdate = softwareUpdateData?.[0]?.software_updates?.filter(e => e.update_status === false || e.update_status === "loading")
            setUpdateAvailable(availableUpdate)
        }
    }, [softwareUpdateData])

    const isUpdating = useMemo(() => softwareUpdateData?.[0]?.software_updates?.filter(e => { return e.update_status === "loading"; }), [softwareUpdateData])

    useEffect(() => {
        if (softwareUpdateData?.[0]?.software_updates) {
            const historyUpdate = softwareUpdateData?.[0]?.software_updates?.filter(e => e.update_status === true)
            setUpdateHistory(historyUpdate)
        }
    }, [softwareUpdateData])

    useEffect(() => {
        dispatch(getSoftwareUpdateData());
    }, [dispatch]);

    useEffect(() => {
        if (isUpdating?.length > 0) {
            const getSoftwareUpdateInterval = setInterval(() => {
                dispatch(getSoftwareUpdateData());
            }, 10000);

            return () => {
                clearInterval(getSoftwareUpdateInterval)
            }
        }
    }, [dispatch, isUpdating]);

    useEffect(() => {
        if (softwareUpdateData?.length > 0) {
            setAutoUpdateStatus(softwareUpdateData?.[0]?.auto_update)
        }
    }, [softwareUpdateData])

    return (
        <div className='software-update-container h-100'>
            <div className='organization-headings mb-3 text-nowrap'>Software Update</div>
            <div className='border rounded-3 p-3 mb-3 overflow-auto'>
                <div className='d-flex align-items-start mb-2 flex-wrap'>
                    <div className="d-flex align-items-center gap-2">
                        <Switch
                            disabled={instantSoftwareUpdateLoad || isUpdating?.length > 0}
                            checked={AutoUpdateStatus}
                            size="small"
                            onClick={(e) => {
                                setAutoUpdateStatus(e)
                                dispatch(putSoftwareUpdateData(softwareUpdateData?.[0]?.id, e))
                            }}
                        />
                        <div className='fw-bold fs-6 text-nowrap'>Auto Update</div>
                    </div>
                    <BackupTimePeriod
                        disabled={!AutoUpdateStatus || (instantSoftwareUpdateLoad || isUpdating?.length > 0)}
                        value={softwareUpdateData?.[0]?.auto_update}
                        className="ms-lg-auto"
                        onBackupPeriodChange={(e) => {
                            if (BackupPeriod[0] === "Weekly") {
                                if (Week[0].includes("Select")) return;
                            }

                            if (BackupPeriodTime.length === 0) return;

                            if (JSON.stringify(e) === JSON.stringify(softwareUpdateData?.[0]?.auto_update)) return;

                            dispatch(putSoftwareUpdateData(softwareUpdateData?.[0]?.id, e))
                        }}
                        Week={Week}
                        setWeek={setWeek}
                        BackupPeriodTime={BackupPeriodTime}
                        setBackupPeriodTime={setBackupPeriodTime}
                        BackupPeriod={BackupPeriod}
                        setBackupPeriod={setBackupPeriod}
                        timeOption={timeOption}
                    />
                </div>
                <p className='text-black-50 m-0 bold'>The application will update automatically, and during the update process, your application's performance may temporarily decrease.</p>
            </div>
            <div className='updates-available-container px-2'>
                {updateAvailable?.length > 0 && (
                    <Fragment>
                        <div className='fw-bold fs-6'>New Updates</div>
                        <Accordion className='mb-3'>
                            {updateAvailable?.map(e => (
                                <div className='border-bottom pb-2' key={e.id}>
                                    <div className='d-flex align-items-end'>
                                        <ContextAwareToggle eventKey={e.id} >
                                            Version {e.software_version}
                                        </ContextAwareToggle>
                                        <Button
                                            type="primary"
                                            className='btn bg-warning ms-auto bold'
                                            size='middle'
                                            onClick={() => dispatch(instantSoftwareUpdate(e.id))}
                                            loading={instantSoftwareUpdateLoad || e.update_status === "loading"}
                                        >
                                            Update Now
                                        </Button>
                                    </div>
                                    <Accordion.Collapse eventKey={e.id}>
                                        <div className='px-4 mx-3 pb-2 update-description-container text-black-50 bold' dangerouslySetInnerHTML={{ __html: e.update_description }}></div>
                                    </Accordion.Collapse>
                                </div>
                            ))}
                        </Accordion>
                    </Fragment>
                )}
                {updateHistory?.length > 0 && (
                    <Fragment>
                        <div className='fw-bold fs-6'>Updates History</div>
                        <Accordion>
                            {[...updateHistory].reverse()?.map(e => (
                                <div className='border-bottom pb-2' key={e.id}>
                                    <ContextAwareToggle eventKey={e.id}>
                                        Version {e.software_version}
                                        <div className='ms-auto d-flex align-items-center gap-1 bold'>
                                            <i className="fas fa-check rounded-pill"></i>
                                            Updated
                                        </div>
                                    </ContextAwareToggle>
                                    <Accordion.Collapse eventKey={e.id}>
                                        <div className='px-4 mx-3 pb-2 update-description-container text-black-50 bold' dangerouslySetInnerHTML={{ __html: e.update_description }}></div>
                                    </Accordion.Collapse>
                                </div>
                            ))}
                        </Accordion>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default SoftwareUpdate