export const GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST = 'GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST'
export const GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS = 'GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS'
export const GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL = 'GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL'
export const GET_SERVICE_CAMERA_FITER_OPTIONS_RESET = 'GET_SERVICE_CAMERA_FITER_OPTIONS_RESET'

export const GET_EXCEL_EXPORT_DATA_REQUEST = 'GET_EXCEL_EXPORT_DATA_REQUEST'
export const GET_EXCEL_EXPORT_DATA_SUCCESS = 'GET_EXCEL_EXPORT_DATA_SUCCESS'
export const GET_EXCEL_EXPORT_DATA_FAIL = 'GET_EXCEL_EXPORT_DATA_FAIL'

export const POST_DOUBTFUL_EVENT_DATA_REQUEST = 'POST_DOUBTFUL_EVENT_DATA_REQUEST'
export const POST_DOUBTFUL_EVENT_DATA_SUCCESS = 'POST_DOUBTFUL_EVENT_DATA_SUCCESS'
export const POST_DOUBTFUL_EVENT_DATA_FAIL = 'POST_DOUBTFUL_EVENT_DATA_FAIL'
export const POST_DOUBTFUL_EVENT_DATA_RESET = 'POST_DOUBTFUL_EVENT_DATA_RESET'

export const RETRAIN_DATA_SAVE_REQUEST = 'RETRAIN_DATA_SAVE_REQUEST'
export const RETRAIN_DATA_SAVE_SUCCESS = 'RETRAIN_DATA_SAVE_SUCCESS'
export const RETRAIN_DATA_SAVE_FAIL = 'RETRAIN_DATA_SAVE_FAIL'
export const RETRAIN_DATA_SAVE_RESET = 'RETRAIN_DATA_SAVE_RESET'