import axios from 'axios'
import {
    GET_STORAGE_MANAGEMENT_DATA_REQUEST,
    GET_STORAGE_MANAGEMENT_DATA_SUCCESS,
    GET_STORAGE_MANAGEMENT_DATA_FAIL,

    PUT_STORAGE_MANAGEMENT_DATA_REQUEST,
    PUT_STORAGE_MANAGEMENT_DATA_SUCCESS,
    PUT_STORAGE_MANAGEMENT_DATA_FAIL,
} from '../../constants/organizationConstant/storageManagementConstants'

import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { getAPI, putAPI } from '../../global/ApiManager';


export const getStorageManagementData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_STORAGE_MANAGEMENT_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/backup_manager/storage_manager_api/`, dispatch)

        dispatch({
            type: GET_STORAGE_MANAGEMENT_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_STORAGE_MANAGEMENT_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const putStorageManagementData = (formData, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_STORAGE_MANAGEMENT_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await putAPI(
            `/backup_manager/storage_manager_api/${id}/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: PUT_STORAGE_MANAGEMENT_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PUT_STORAGE_MANAGEMENT_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};