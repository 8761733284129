import axios from 'axios';
import {
    SERVICES_DATA_LIST_REQUEST,
    SERVICES_DATA_LIST_SUCCESS,
    SERVICES_DATA_LIST_FAIL,

    SERVICES_DATA_REQUEST,
    SERVICES_DATA_SUCCESS,
    SERVICES_DATA_FAIL,

    GET_SINGLE_SERVICES_DATA_REQUEST,
    GET_SINGLE_SERVICES_DATA_SUCCESS,
    GET_SINGLE_SERVICES_DATA_FAIL,

    CAMERA_DATA_REQUEST,
    CAMERA_DATA_SUCCESS,
    CAMERA_DATA_FAIL,

    ALL_SERVICES_DELETE_REQUEST,
    ALL_SERVICES_DELETE_SUCCESS,
    ALL_SERVICES_DELETE_FAIL,

    ADD_SERVICE_REQUEST,
    ADD_SERVICE_SUCCESS,
    ADD_SERVICE_FAIL,

    SERVICE_CONFIG_DATA_REQUEST,
    SERVICE_CONFIG_DATA_SUCCESS,
    SERVICE_CONFIG_DATA_FAIL,

    SERVICE_CONFIG_DELETE_REQUEST,
    SERVICE_CONFIG_DELETE_SUCCESS,
    SERVICE_CONFIG_DELETE_FAIL,

    SERVICE_DELETE_REQUEST,
    SERVICE_DELETE_SUCCESS,
    SERVICE_DELETE_FAIL,

    SERVICES_CREATE_REQUEST,
    SERVICES_CREATE_SUCCESS,
    SERVICES_CREATE_FAIL,

    CREATE_SERVICE_CONFIG_USER_REQUEST,
    CREATE_SERVICE_CONFIG_USER_SUCCESS,
    CREATE_SERVICE_CONFIG_USER_FAIL,

    SERVICES_UPDATE_REQUEST,
    SERVICES_UPDATE_SUCCESS,
    SERVICES_UPDATE_FAIL,

    GET_SERVICE_USER_REQUEST,
    GET_SERVICE_USER_SUCCESS,
    GET_SERVICE_USER_FAIL,

    GET_ALERT_REQUEST,
    GET_ALERT_SUCCESS,
    GET_ALERT_FAIL,

    BUY_SERVICE_REQUEST,
    BUY_SERVICE_SUCCESS,
    BUY_SERVICE_FAIL,
    SERVICE_ON_OFF_REQUEST,
    SERVICE_ON_OFF_SUCCESS,
    SERVICE_ON_OFF_FAIL,

    GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST,
    GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
    GET_TIME_WISE_CAMERA_SCHEDULE_FAIL,


    UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST,
    UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
    UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL,

    UPDATE_SERVICE_VERSION_REQUEST,
    UPDATE_SERVICE_VERSION_SUCCESS,
    UPDATE_SERVICE_VERSION_FAIL,
    UPDATE_SERVICE_VERSION_RESET,

    RETRAIN_PROCESS_REQUEST,
    RETRAIN_PROCESS_SUCCESS,
    RETRAIN_PROCESS_FAIL,
    RETRAIN_PROCESS_RESET,

    CHECK_RETRAIN_STATUS_REQUEST,
    CHECK_RETRAIN_STATUS_SUCCESS,
    CHECK_RETRAIN_STATUS_FAIL
}
    from "../constants/servicesConstant.js"

import { API_ENDPOINT } from '../constants/apiEndpoint'
import { deleteAPI, getAPI, postAPI } from '../global/ApiManager.js';

export const getTimeWiseCameraScheduleData = (conf_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }


        const { data } = await getAPI(config, `/service_app/time_wise_service_activation/get_timwise_serive_data/${conf_id}/`, dispatch)


        dispatch({
            type: GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_TIME_WISE_CAMERA_SCHEDULE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const updateTimeWiseCameraScheduleData = (FormData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/time_wise_service_activation/add_service_to_user/`,
            FormData,
            config,
            dispatch
        )

        dispatch({
            type: UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const listServices = (search) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICES_DATA_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            params: {
                search: search
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }


        const { data } = await getAPI(config, `/service_app/market_place/get_services_data_list/`, dispatch)


        dispatch({
            type: SERVICES_DATA_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICES_DATA_LIST_FAIL,
            payload: error
                ? error?.data
                : error?.message,
        })
    }
}


export const getServicesData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICES_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }


        const { data } = await getAPI(config, `/service_app/get_services_page_data/`, dispatch)


        dispatch({
            type: SERVICES_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICES_DATA_FAIL,
            error: error
        })
    }
}


export const getSingleServicesData = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SINGLE_SERVICES_DATA_REQUEST
        })


        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }


        const { data } = await getAPI(config, `/service_app/get_services_page_data/${id}/`, dispatch)


        dispatch({
            type: GET_SINGLE_SERVICES_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_SINGLE_SERVICES_DATA_FAIL,
            error: error
        })
    }
}


export const configService = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICE_CONFIG_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get-services-config-data/${id}`, dispatch)

        dispatch({
            type: SERVICE_CONFIG_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_CONFIG_DATA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const serviceCreate = (image, serviceName, serviceDisc) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICES_CREATE_REQUEST
        })

        const formData = new FormData()
        formData.append('image', image)
        formData.append('service_name', serviceName)
        formData.append('information', serviceDisc)


        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/add-service/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: SERVICES_CREATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SERVICES_CREATE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const serviceCreateWithCamera = (image, serviceName, serviceDisc, emails, cameras, area) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICES_CREATE_REQUEST
        })

        const formData = new FormData()
        formData.append('image', image)
        formData.append('service_name', serviceName)
        formData.append('information', serviceDisc)
        formData.append('camera', cameras)
        formData.append('image_selection', area)
        formData.append('emails_to_send_alert', emails)

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/add-service/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: SERVICES_CREATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SERVICES_CREATE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const serviceDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/app/delete-service/${id}`,
            config,
            dispatch
        )

        dispatch({
            type: SERVICE_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_DELETE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const serviceConfigDelete = (camear_id, service_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICE_CONFIG_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/detach_service_from_camera/`,
            {
                'camera_id': camear_id,
                'service_id': service_id
            },
            config,
            dispatch
        )

        dispatch({
            type: SERVICE_CONFIG_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SERVICE_CONFIG_DELETE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const serviceConfigCreateUser = (UserId, serviceId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_SERVICE_CONFIG_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/create-services-config-user/`,
            { 'UserId': UserId, 'serviceId': serviceId },
            config,
            dispatch
        )

        dispatch({
            type: CREATE_SERVICE_CONFIG_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_SERVICE_CONFIG_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }

}

export const serviceUpdateWithCamera = (service_id, cameras) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SERVICES_UPDATE_REQUEST
        })

        const sendData = {
            'service_id': service_id,
            'camera': cameras,
        }

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/attach_service_to_camera/`,
            sendData,
            config,
            dispatch
        )

        dispatch({
            type: SERVICES_UPDATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SERVICES_UPDATE_FAIL,
            payload: error
                ? error?.data?.detail
                : error?.message,
        })
    }
}

export const getServiceConfigUser = (serviceId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SERVICE_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get-service-config-user/${serviceId}`, dispatch)

        dispatch({
            type: GET_SERVICE_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_SERVICE_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }

}


export const getAlertData = (selectedData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ALERT_REQUEST

        })

        const {
            userLogin: { userInfo },
        } = getState()

        const params = {
            ...selectedData,
            service_name: selectedData?.service_name || JSON.stringify([]),
            camera_name: selectedData?.camera_name || JSON.stringify([]),
            dateRange: selectedData?.dateRange || JSON.stringify([]),
            class_values: JSON.stringify(selectedData?.class_values),
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get-alert-data/`, dispatch)

        dispatch({
            type: GET_ALERT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALERT_FAIL,
            payload: error
        })
    }

}

export const addService = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_SERVICE_REQUEST

        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/market_place/add_service_to_user/${id}`, dispatch)

        dispatch({
            type: ADD_SERVICE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ADD_SERVICE_FAIL,
            payload: error.data
        })
    }
}

export const getCamera = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CAMERA_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/dropdown_camera_select/${id}`, dispatch)

        dispatch({
            type: CAMERA_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CAMERA_DATA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}



export const deleteAllService = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ALL_SERVICES_DELETE_REQUEST

        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/app/delete_all_services/${id}`,
            config,
            dispatch
        )

        dispatch({
            type: ALL_SERVICES_DELETE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_SERVICES_DELETE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const buyAllServices = (serviceId) => async (dispatch, getState) => {
    try {
        dispatch({ type: BUY_SERVICE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/send-service-buy-email/${serviceId}/`, dispatch)

        dispatch({ type: BUY_SERVICE_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: BUY_SERVICE_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const refreshServiceActiveInactive = () => async (dispatch, getState) => {
    try {

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        await getAPI(config, `/service_app/refresh_service_active_inactive_status_on_page_refresh`, dispatch)

    } catch (error) {
        console.error(error)
    }
}

export const Services_On_Off = (ServiceData) => async (dispatch, getState) => {
    try {
        dispatch({ type: SERVICE_ON_OFF_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/service_active_inactive_on_off_switch/`,
            ServiceData,
            config,
            dispatch
        )

        dispatch({ type: SERVICE_ON_OFF_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: SERVICE_ON_OFF_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}


export const Update_Service_version = (ServiceVersionData) => async (dispatch, getState) => {
    try {
        dispatch({ type: UPDATE_SERVICE_VERSION_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/set_service_version/`,
            ServiceVersionData,
            config,
            dispatch
        )

        dispatch({ type: UPDATE_SERVICE_VERSION_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: UPDATE_SERVICE_VERSION_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}


export const Retrain_process_service = (retrainData) => async (dispatch, getState) => {
    try {
        dispatch({ type: RETRAIN_PROCESS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await postAPI(
            `/app/trigger_training_process/`,
            retrainData,
            config,
            dispatch
        )
        dispatch({ type: RETRAIN_PROCESS_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: RETRAIN_PROCESS_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}

export const Check_retrain_status = (retrainData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CHECK_RETRAIN_STATUS_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await postAPI(
            `/app/check_retraining_status/`,
            retrainData,
            config,
            dispatch
        )
        dispatch({ type: CHECK_RETRAIN_STATUS_SUCCESS, payload: data })

    } catch (error) {
        dispatch({
            type: CHECK_RETRAIN_STATUS_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}