import {
    GET_ADD_ON_DATA_REQUEST,
    GET_ADD_ON_DATA_SUCCESS,
    GET_ADD_ON_DATA_FAIL,
    POST_ADD_ON_INSTALL_DATA_REQUEST,
    POST_ADD_ON_INSTALL_DATA_SUCCESS,
    POST_ADD_ON_INSTALL_DATA_FAIL,
    GET_ADD_ON_INSTALL_DATA_REQUEST,
    GET_ADD_ON_INSTALL_DATA_SUCCESS,
    GET_ADD_ON_INSTALL_DATA_FAIL,
    DELETE_ADD_ON_INSTALL_DATA_REQUEST,
    DELETE_ADD_ON_INSTALL_DATA_SUCCESS,
    DELETE_ADD_ON_INSTALL_DATA_FAIL,
    ADD_ADD_ON_TO_SHORTCUT_REQUEST,
    ADD_ADD_ON_TO_SHORTCUT_SUCCESS,
    ADD_ADD_ON_TO_SHORTCUT_FAIL
} from '../../constants/organizationConstant/addOnsConstant'

export const getAddOnDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_ADD_ON_DATA_REQUEST:
            return { loading: true };

        case GET_ADD_ON_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_ADD_ON_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const getAddOnDataInstallReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_ADD_ON_INSTALL_DATA_REQUEST:
            return { loading: true, success: [...state.success] };

        case GET_ADD_ON_INSTALL_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_ADD_ON_INSTALL_DATA_FAIL:
            return { loading: false, error: action.error, success: [] };

        default:
            return state;
    }
};

export const postAddOnDataInstallReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case POST_ADD_ON_INSTALL_DATA_REQUEST:
            return { loading: true };

        case POST_ADD_ON_INSTALL_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case POST_ADD_ON_INSTALL_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const deleteAddOnDataInstallReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case DELETE_ADD_ON_INSTALL_DATA_REQUEST:
            return { loading: true };

        case DELETE_ADD_ON_INSTALL_DATA_SUCCESS:
            return { success: true, loading: false };

        case DELETE_ADD_ON_INSTALL_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const addToShortcutReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case ADD_ADD_ON_TO_SHORTCUT_REQUEST:
            return { loading: true };

        case ADD_ADD_ON_TO_SHORTCUT_SUCCESS:
            return { success: true, loading: false };

        case ADD_ADD_ON_TO_SHORTCUT_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};