export const SUBSCRIPTION_SERVICES_ALERT_REQUEST = 'SUBSCRIPTION_SERVICES_ALERT_REQUEST'
export const SUBSCRIPTION_SERVICES_ALERT_SUCCESS = 'SUBSCRIPTION_SERVICES_ALERT_SUCCESS'
export const SUBSCRIPTION_SERVICES_ALERT_FAIL = 'SUBSCRIPTION_SERVICES_ALERT_FAIL'


export const SUBSCRIPTION_SERVICES_LIST_REQUEST = 'SUBSCRIPTION_SERVICES_LIST_REQUEST'
export const SUBSCRIPTION_SERVICES_LIST_SUCCESS = 'SUBSCRIPTION_SERVICES_LIST_SUCCESS'
export const SUBSCRIPTION_SERVICES_LIST_FAIL = 'SUBSCRIPTION_SERVICES_LIST_FAIL'


export const SEND_SUBSCRIPTION_REQUEST = 'SEND_SUBSCRIPTION_REQUEST'
export const SEND_SUBSCRIPTION_SUCCESS = 'SEND_SUBSCRIPTION_SUCCESS'
export const SEND_SUBSCRIPTION_FAIL = 'SEND_SUBSCRIPTION_FAIL'
export const SEND_SUBSCRIPTION_RESET = 'SEND_SUBSCRIPTION_RESET'


export const GET_SERVICE_DATA_REQUEST = 'GET_SERVICE_DATA_REQUEST'
export const GET_SERVICE_DATA_SUCCESS = 'GET_SERVICE_DATA_SUCCESS'
export const GET_SERVICE_DATA_FAIL = 'GET_SERVICE_DATA_FAIL'
export const GET_SERVICE_DATA_RESET = 'GET_SERVICE_DATA_RESET'
