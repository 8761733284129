import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./SettingScreen.css";
import { Image, Modal } from "react-bootstrap";
import noUserProfile from '../../images/no-user-profile.png'
import { UPDATE_USER_RESET } from "../../constants/settingConstant";
import { getUserProfile, updateExistingUser } from "../../actions/settingActions";
import { imgValidation } from "../../global/Helper";
import { toast } from "react-toastify";
import { ImgFormatAlert } from "../../components/ToastAlert";
import { logout } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";

const SettingProfile = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userProfileGet = useSelector((state) => state.userProfileGet);
  const { userProfile } = userProfileGet;

  const updateUser = useSelector((state) => state.updateUser);
  const { updateUserSuccess } = updateUser;

  const [editProfileModalShow, setEditProfileModalShow] = useState(false);
  const [choseImage, setChoseImage] = useState(undefined);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [isProfilePhoto, setisProfilePhoto] = useState(false)

  let userEmail = userInfo?.email;
  let nameMatch = userEmail?.match(/^([^@]*)@/);
  let userName = nameMatch?.[1];

  useEffect(() => {
    if (updateUserSuccess) {
      dispatch({ type: UPDATE_USER_RESET });
      dispatch(getUserProfile())
    }
  }, [dispatch, updateUserSuccess]);

  useEffect(() => {
    if (userProfile?.profile_pic) {
      setChoseImage(userProfile?.profile_pic)
      setisProfilePhoto(true)
    } else {
      setChoseImage(undefined)
      setisProfilePhoto(false)
    }
  }, [userProfile]);

  const firstLetter = userName?.charAt(0).toUpperCase();

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setChoseImage(URL.createObjectURL(file));
  };
  const saveUserPhoto = () => {
    if (profilePhoto && !imgValidation.test(profilePhoto.name)) {
      const photoFormat = profilePhoto.name?.split('.').pop();
      toast(<ImgFormatAlert photoFormat={photoFormat} />)
    }
    else {
      dispatch(updateExistingUser({ profile_pic: profilePhoto }));
      setEditProfileModalShow(false)
    }
  };

  const removeImageHandler = () => {
    dispatch(updateExistingUser({ profile_pic: '' }))
    setChoseImage(undefined)
    setProfilePhoto('')
  }

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex logo-container align-items-center py-4">
          {!isProfilePhoto ? (
            <h2
              className="mb-0 namelogo pe-auto rounded-circle d-flex align-items-center justify-content-center position-relative"
              onClick={() => setEditProfileModalShow(true)}
            >
              {firstLetter}
              <i className="fas fa-camera position-absolute"></i>
            </h2>
          ) : (
            <div
              className="position-relative cursor-pointer"
              onClick={() => setEditProfileModalShow(true)}
            >
              <Image
                className="border_radius_sm user_profile_photo"
                style={{ objectFit: "cover" }}
                src={userProfile?.profile_pic ? userProfile?.profile_pic : noUserProfile}
                width={40}
                height={30}
              />

              <i className="fas fa-camera position-absolute" />
            </div>
          )}
          {userName && (
            <div className=" ms-3 flex flex-column">
              <h3 className=" mb-0 Username_text "><b>{userName.split('.').join(" ")}</b></h3>
              <h6 className="mb-0">
                <b>{userInfo?.name ? userInfo.username : null}</b>
              </h6>
            </div>
          )}
        </div>
        <div >
          <button
            className="logout-btn text-nowrap mb-3 mb-lg-0"
            onClick={() => {
              dispatch(logout())
              navigate("/", { replaces: true });
            }}
          >
            Logout{" "}
            <i className="fal fa-sign-out"></i>
          </button>
        </div>
      </div>
      <Modal
        scrollable={false}
        show={editProfileModalShow}
        onHide={() => { setEditProfileModalShow(false) }}
        size="md"
        // animation={userConfigurationModalAnimation}
        centered
      >
        <div className="text-end me-2 mt-2">
          <button type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setEditProfileModalShow(false)}
          ></button>
        </div>
        <Modal.Header className='custom-modal-styling align-items-end' style={{ padding: "0 1rem 0px" }} >
          <Modal.Title className="fs-4" id="example-custom-modal-styling-title">
            Profile Photo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="profile-photo">
            <div className={choseImage ? "d-flex flex-column justify-content-center align-items-center upload-profile-container mb-2" :
              "d-flex flex-column justify-content-center align-items-center upload-profile-container py-4"}>
              <div className={choseImage ? "position-relative w-100" : "position-relative"}>
                <Image
                  style={{
                    width: choseImage ? "100%" : "130px",
                    height: choseImage ? "289px" : "130px",

                    objectFit: "cover",
                    backgroundPosition: "center",
                  }}
                  src={choseImage || noUserProfile}
                  // src={setProfile}
                  htmlFor="upload-photo"
                >
                </Image>
                {/* {!choseImage && (
                  // <i className="fas fa-camera position-absolute"></i>
                )} */}
              </div>
              {!choseImage && (
                <div className="d-flex align-items-center bg-primary border_radius_sm pt-1 pb-1 pe-2 ps-2 text-white mt-3">
                  <span className={'upload-white-icon me-1'}></span>
                  <label htmlFor="upload-photo" className="fs-6 m-0 cursor-pointer">
                    Upload Image
                  </label>
                </div>
              )}

              <input
                type="file"
                id="upload-photo"
                accept='.jpg,.jpeg,.png'
                className=" d-none"
                onChange={(e) => {
                  setProfilePhoto(e.target.files[0])
                  onImageChange(e)
                }}
              // accept=".jpg, .jpeg, .png"
              />
            </div>
            {choseImage && (
              <div className="d-flex gap-2">
                <label
                  className="bg-color-button border-radius-sm w-100 btn"
                  htmlFor="upload-photo"
                >
                  <i className="far fa-edit me-1" style={{
                    fontSize: "20px",
                    fontWeight: "400"
                  }}
                  ></i>
                  Update
                </label>
                <button
                  className="bg-color-button w-100 border-radius-sm btn"
                  onClick={removeImageHandler}
                >
                  <i className="fas fa-trash-alt me-2" style={{
                    color: "red",
                    fontSize: "18px"
                  }}></i>
                  Remove
                </button>
              </div>
            )}

          </div>
          <div
            className="d-flex align-itmes-center justify-content-end mt-4"
          >
            <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => {
              setEditProfileModalShow(false)
              dispatch(getUserProfile())
            }}>
              Cancel
            </button>
            <button
              className="btn px-4 py-2 bg-warning border text-dark"
              type='button'
              onClick={saveUserPhoto}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingProfile;