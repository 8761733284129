import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Col, Row, Form, Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { addUserInFaceRecognition, getFaceRecognitionUsers } from '../../../actions/faceRecognitionActions'
import { ADD_FACE_RECOGNITION_USER_RESET } from '../../../constants/faceRecognitionConstant'
import { AddFaceAlert, FaceDetectionWarning, DefaultUpdateAlerts, AllAngleFaceAlert, CommonErrorAlert } from "../../../components/ToastAlert";
import { toast } from "react-toastify";
import { Button, Radio } from 'antd';
import CaptureFace from './CaptureFace';
import UpdateCaptureFace from './UpdateCaptureFace';
import { switchWebCamera } from '../../../global/Helper';


function AddSingleUserProfileModal(props) {
    const userDetails = props.userData
    const serviceId = props.serviceId
    const workerFields = props.workerFields
    const userLabel = props.userLabel

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ defaultValues: userDetails });

    const dispatch = useDispatch();

    const [choseImage, setChoseImage] = useState(undefined);
    const [imageValidation, setImageValidation] = useState(false);
    const [designation, setDesignation] = useState("employee");
    const [videoStream, setVideoStream] = useState(false);
    const [leftFace, setLeftFace] = useState();
    const [rightFace, setRightFace] = useState();
    const [frontFace, setFrontFace] = useState();
    const [username, setUserName] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [refresh, doRefresh] = useState(0);
    const [issueDate, setIssueDate] = useState(undefined);
    const [dateValidation, setDateValidation] = useState(false);
    const [switchCamera, setSwitchCamera] = useState(0);
    const [webCameraId, setWebCameraId] = useState([]);

    const userAddInFaceRecognition = useSelector((state) => state.userAddInFaceRecognition);
    const { loading, error, addFaceSuccess } = userAddInFaceRecognition;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (userDetails?.id) {
            setChoseImage(userDetails.image)
            setDesignation(userDetails.user_type)
        }
    }, [userDetails, userInfo])

    useEffect(() => {
        async function startFetching() {
            const deviceArray = await switchWebCamera()
            setWebCameraId(deviceArray.filter((data) => data !== undefined))
        }
        startFetching();
    }, [])

    const videoConstraints = useMemo(() => {
        const videoObj = {
            width: { min: 1280 },
            height: { min: 720 },
        };
        if (webCameraId.length === 0) {
            videoObj.facingMode = 'environment';
        } else {
            videoObj.deviceId = { exact: webCameraId[switchCamera] }
        }
        return videoObj
    }, [webCameraId, switchCamera])

    const onSwitchCamera = () => setSwitchCamera((prev) => (prev === webCameraId.length - 1) ? 0 : prev + 1)

    const userAddForm = (event) => {
        if (!choseImage && (!leftFace || !rightFace || !frontFace)) {
            setImageValidation(true)
            toast(<AllAngleFaceAlert />);
            return;
        }
        setImageValidation(false)
        setUserName(event.name)
        event.id = userDetails?.id ? userDetails?.id : ''
        event.user_type = designation
        event.left_face = leftFace
        event.right_face = rightFace
        event.front_face = frontFace
        event.service_id = serviceId
        delete event.image
        delete event.image_left
        delete event.image_right
        if (designation === "visitor") {
            let validity_start = new Date(event.validity_start);
            let valid_till = new Date(event.valid_till);
            if (validity_start.getTime() >= valid_till.getTime()) {
                setDateValidation(true)
                return false
            } else {
                setDateValidation(false)
            }
        } else {
            delete event.validity_start
            delete event.valid_till
        }
        dispatch(addUserInFaceRecognition(event))
    }

    useEffect(() => {
        if (addFaceSuccess) {
            if (userDetails?.id) {
                toast(<DefaultUpdateAlerts name={username} />)
            } else {
                toast(<AddFaceAlert message={username} />)
            }
            dispatch({ type: ADD_FACE_RECOGNITION_USER_RESET })
            dispatch(getFaceRecognitionUsers(props.UsersTablePageNumber))
            props.closeAddSingleUserModal(true)
        }
        if (error) {
            toast(<CommonErrorAlert message={error} />)
            dispatch({
                type: ADD_FACE_RECOGNITION_USER_RESET
            })
        }
    }, [addFaceSuccess, dispatch, error])

    const stopLiveCamera = () => {
        setVideoStream(false);
    };

    const startStreaming = () => {
        setVideoStream(true);
    };

    return (
        <div>
            <Row>
                <Col md={5} className="upload-image-container p-0 d-flex flex-column justify-content-center">
                    <div className="text-center d-flex justify-content-center" >
                        <div className={imageValidation ? 'image-upload-container position-relative image-upload-warning' : 'image-upload-container position-relative'} >
                            {!userDetails?.id ?
                                <CaptureFace
                                    startStreaming={startStreaming}
                                    videoStream={videoStream}
                                    choseImage={choseImage}
                                    frontFace={frontFace}
                                    setLeftFace={setLeftFace}
                                    setRightFace={setRightFace}
                                    setFrontFace={setFrontFace}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    refresh={refresh}
                                    serviceId={serviceId}
                                    onSwitchCamera={onSwitchCamera}
                                    videoConstraints={videoConstraints}
                                /> :
                                <UpdateCaptureFace
                                    setLeftFace={setLeftFace}
                                    setRightFace={setRightFace}
                                    setFrontFace={setFrontFace}
                                    leftFace={leftFace}
                                    rightFace={rightFace}
                                    frontFace={frontFace}
                                    userDetails={userDetails}
                                    videoStream={videoStream}
                                    startStreaming={startStreaming}
                                    stopLiveCamera={stopLiveCamera}
                                    serviceId={serviceId}
                                    onSwitchCamera={onSwitchCamera}
                                    videoConstraints={videoConstraints}
                                />
                            }
                            {imageValidation && (
                                <div className='my-3'>
                                    <span className='text-danger'>Image is Compulsory*</span>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                <Col md={7}>
                    <div className='px-3' style={{ paddingBottom: "70px" }}>
                        <h5 className='text-capitalize m-0 fw-semibold text-black-50'>{`${designation} Details`}</h5>
                        <div className="employee-detail-container my-3">
                            <Radio.Group
                                value={designation}
                                className='d-flex flex-nowrap w-100'
                                disabled={!userInfo?.isAdmin}
                                onChange={(e) => {
                                    !userDetails && setChoseImage(undefined);
                                    stopLiveCamera()
                                    setDesignation(e.target.value)
                                    setImageValidation(false)
                                    doRefresh(prev => prev + 1)
                                    setIssueDate(undefined)
                                    reset()
                                }}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="employee">Employee</Radio.Button>
                                <Radio.Button value="visitor">Visitor</Radio.Button>
                                <Radio.Button value="worker">{userLabel || 'Worker'}</Radio.Button>
                            </Radio.Group>
                        </div>
                        <Form className='overflow-auto employee-detail-inputs' style={{ maxHeight: "300px" }} noValidate onSubmit={handleSubmit(userAddForm)}>
                            <Form.Group className='mb-3'>
                                <Form.Label className='m-0' htmlFor="name"><b>Name</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    className='border-input'
                                    id="username"
                                    placeholder={`Enter Fullname of ${designation}`}
                                    {...register("name", {
                                        required: "Please provide Name."
                                    })}
                                    isInvalid={errors.name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                    {`Please Enter ${designation} Name.`}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label className='m-0 text-capitalize' htmlFor="user_id"><b>{`${designation} ID`}</b></Form.Label>
                                <Form.Control
                                    name="user_id"
                                    className={userDetails?.user_id ? 'is-disable border-input text-capitalize' : 'border-input text-capitalize'}
                                    id="user_id"
                                    maxLength={12}
                                    placeholder={`Enter ${designation} Code.`}
                                    {...register("user_id", {
                                        required: "Please Enter Visitor Code or Designation.",
                                        pattern: {
                                            value: /^[A-Za-z0-9]*$/
                                        },
                                    })}
                                    isInvalid={errors.user_id}
                                    disabled={!!userDetails?.user_id}
                                    required
                                />
                                <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                    {`${designation} id must contain alphanumeric characters only.`}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label className='m-0 text-capitalize' htmlFor="department"><b>Department</b></Form.Label>
                                <Form.Control
                                    name="department"
                                    type='text'
                                    className={'border-input text-capitalize'}
                                    id="department"
                                    maxLength={30}
                                    placeholder={`Enter Department.`}
                                    {...register("department", {
                                        required: "Please Enter Department.",
                                    })}
                                    isInvalid={errors.department}
                                    required
                                />
                                <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                    {errors?.department?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {designation === 'worker' && (
                                <Fragment>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='m-0 text-capitalize' htmlFor="contractor"><b>Contractor</b></Form.Label>
                                        <Form.Control
                                            name="contractor"
                                            type='text'
                                            className={'border-input text-capitalize'}
                                            id="contractor"
                                            maxLength={30}
                                            placeholder={`Enter Contractor.`}
                                            {...register("contractor", {
                                                required: "Please Enter Contractor.",
                                            })}
                                            isInvalid={errors.contractor}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                            {errors?.contractor?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='m-0 text-capitalize' htmlFor="contractor_code"><b>Contractor Code</b></Form.Label>
                                        <Form.Control
                                            name="contractor_code"
                                            type='text'
                                            className={'border-input text-capitalize'}
                                            id="contractor_code"
                                            maxLength={30}
                                            placeholder={`Enter Contractor Code.`}
                                            {...register("contractor_code", {
                                                required: "Please Enter Contractor Code.",
                                            })}
                                            isInvalid={errors.contractor_code}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                            {errors?.contractor_code?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {workerFields?.map((e, i) => (
                                        <Form.Group className='mb-3' key={`${i + 1}`}>
                                            <Form.Label className='m-0 text-capitalize' htmlFor={e}><b>{e.split('_').join(" ")}</b></Form.Label>
                                            <Form.Control
                                                name={e}
                                                type='text'
                                                className={'border-input text-capitalize'}
                                                id={e}
                                                maxLength={30}
                                                placeholder={`Enter ${e.split('_').join(" ")}.`}
                                                {...register(e, {
                                                    required: `Please Enter ${e.split('_').join(" ")}.`,
                                                })}
                                                isInvalid={errors[e]}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className='text-capitalize'>
                                                {errors[e]?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    ))}
                                </Fragment>
                            )}
                            {userDetails &&
                                <Form.Group className='mb-3'>
                                    <Form.Check
                                        type="checkbox"
                                        name="username"
                                        label="Add to Blacklist"
                                        className='fs-6'
                                        id="username"
                                        placeholder='Enter Department of Employee'
                                        {...register("blacklisted")}
                                    />
                                </Form.Group>}
                            {designation === 'visitor' &&
                                <Form.Group className="" >
                                    <div className="slot_inputs w-100 text-start mb-3">
                                        <Form.Label className="m-0"><b>Date and Time of Issue</b></Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            className="pr-0"
                                            min={new Date().toISOString()}
                                            name="start time"
                                            placeholder="Enter Date and Time of Issue"
                                            {...register("validity_start", {
                                                required: "Please Enter Start Date and Time of Visitor.",
                                                onChange: (e) => setIssueDate(e.target.value)
                                            })}
                                            isInvalid={(dateValidation || errors.validity_start)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.validity_start ? errors.validity_start.message : "Issue time must be lesser than Date of Expiry."}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="slot_inputs w-100 text-start">
                                        <Form.Label className="m-0"><b>Date and Time of Expiry</b></Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            className={!issueDate ? 'is-disable pr-0' : 'pr-0'}
                                            name="end time"
                                            placeholder="Enter Date and Time of Expiry"
                                            {...register("valid_till", {
                                                required: "Please Enter End Date and Time of Visitor."
                                            })}
                                            min={issueDate}
                                            disabled={!issueDate}
                                            isInvalid={(dateValidation || errors.valid_till)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.valid_till ? errors.valid_till.message : "Expire time must be higher than Date of issue."}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>}
                            <div className="d-flex align-itmes-center justify-content-end mt-4 face-modal-btn">
                                <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => {
                                    props.closeAddSingleUserModal(true)
                                    stopLiveCamera()
                                }}>
                                    Cancel
                                </button>
                                {loading ? (<Button type="primary" size="large" loading>
                                    Save
                                </Button>) : (
                                    <button
                                        className="btn px-4 py-2 bg-warning border text-dark"
                                        type='submit'
                                        onClick={() => {
                                            setImageValidation(false)
                                        }}
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AddSingleUserProfileModal