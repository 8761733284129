import React from 'react'
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import CreateService from './CreateService'



function ServiceConfigModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.closeServiceConfigModal}
            size="lg"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <div className='p-5 pb-0 pt-4'>
                <h5>Setup</h5>
                <h3>Configuring {props.service_name}</h3>
            </div>
            <Modal.Body className='custom-modal-body-styling'>
                <CreateService
                    onHide={() => props.closeServiceConfigModal()}
                    service_id={props.service_id}
                    service_name={props.service_name}
                >
                </CreateService>
            </Modal.Body>
        </Modal>
    )
}

export default ServiceConfigModal

