import React, { useState, useRef } from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form } from "react-bootstrap";
import dayjs from 'dayjs';

function DateComparison(props) {
    const {
        StartDate,
        setStartDate,
        EndDate,
        setEndDate
    } = props

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const [CalendarType, setCalendarType] = useState('start');

    const startData = StartDate ? `${StartDate.$D}/${StartDate.$M + 1}/${StartDate.$y}` : ''
    const endData = EndDate ? `${EndDate?.$D}/${EndDate?.$M + 1}/${EndDate?.$y}` : ''

    const DisableStartDate = (date) => {
        return date.$d.toLocaleDateString() === EndDate.$d.toLocaleDateString();
    }

    const DisableEndDate = (date) => {
        return date.$d.toLocaleDateString() === StartDate.$d.toLocaleDateString();
    }

    return (
        <div>
            <h6>Select Dates for Comparison</h6>
            <div className="d-flex align-items-center justify-content-center gap-2" style={{ width: "320px" }}>
                <Form.Control
                    ref={startDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={startData || ""}
                    placeholder='dd/mm/yyyy'
                    onFocus={() => setCalendarType('start')}
                />
                -
                <Form.Control
                    ref={endDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={endData || ""}
                    placeholder='dd/mm/yyyy'
                    onFocus={() => setCalendarType('end')}
                    autoFocus
                />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {CalendarType === 'start' ? (
                    <DateCalendar value={StartDate} maxDate={dayjs()} shouldDisableDate={DisableStartDate} onChange={(newValue) => {
                        setStartDate(newValue)
                        startDateRef.current.focus()
                    }} />

                ) : (
                    <DateCalendar value={EndDate} maxDate={dayjs()} shouldDisableDate={DisableEndDate} onChange={(newValue) => {
                        setEndDate(newValue)
                        endDateRef.current.focus()
                    }} />
                )}
            </LocalizationProvider>
        </div>
    )
}

export default DateComparison