import { Button } from 'antd'
import React, { useState } from 'react'
import { exportBackup } from '../../../actions/organizationActions/backupAndRestoreActions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CommonErrorAlert } from '../../../components/ToastAlert';

function ExportBackupModal(props) {

    const [ExportDataLoading, setExportDataLoading] = useState(false);
    const [Content, setContent] = useState(null);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const onExportBackupHistory = async () => {
        setExportDataLoading(true)
        if (Content?.includes("These export files are already")) {
            const { status, success } = await exportBackup(userInfo?.token, { create_new_file: true });

            if (status === 200) {
                setContent(success)
            } else {
                toast(<CommonErrorAlert message={status} />);
            }
        } else {
            const { status, success } = await exportBackup(userInfo?.token);
            if (status === 200) {
                setContent(success)
            } else {
                toast(<CommonErrorAlert message={status} />);
            }
        }
        setExportDataLoading(false)
    }

    return (
        <div className='text-center'>
            <div className='exclamation-mark mb-3'>
                <i className="fas fa-exclamation bg-warning text-white"></i>
            </div>
            {!Content?.includes("Zipping process started") && (
                <h5 className='bold' style={{ fontSize: "20px" }}>{Content?.includes("These export files are already") ? "Proceed with creating new export file?" : "Proceed with the export?"}</h5>
            )}
            <div style={{ maxHeight: "200px" }} className='overflow-auto'>
                {!Content ? (
                    <p className='export-content bold mb-4' style={{ color: "rgba(0, 0, 0, 0.40)", fontSize: "17px" }} >
                        The duration of the export process varies depending on the backup's size, and it will continue running in the background once you click <b className='text-black-50'>'Yes, Proceed'</b>.
                    </p>
                ) : (
                    <p className='export-content bold mb-4' style={{ color: "rgba(0, 0, 0, 0.40)", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: Content }} ></p>
                )}
            </div>
            {!Content?.includes("Zipping process started") && (
                <Button
                    className='btn bg-light-grey border-none px-3 me-3 text-dark'
                    size="large"
                    onClick={() => props.CloseExportBackupModalShow()}
                >
                    Cancel
                </Button>
            )}
            {Content?.includes("Zipping process started") ? (
                <Button
                    type="primary"
                    className='bg-warning border text-dark border_radius_sm px-3'
                    size="large"
                    onClick={() => props.CloseExportBackupModalShow()}
                >
                    OK
                </Button>
            ) : (
                <Button
                    type="primary"
                    className='bg-warning border text-dark border_radius_sm px-3'
                    size="large"
                    onClick={onExportBackupHistory}
                    loading={ExportDataLoading}
                >
                    Yes, Proceed
                </Button>)}
        </div>
    )
}

export default ExportBackupModal