import axios from 'axios'
import {
    HOMEPAGE_DATA_GET_REQUEST,
    HOMEPAGE_DATA_GET_SUCCESS,
    HOMEPAGE_DATA_GET_FAIL,

    ADD_CAMERA_REQUEST,
    ADD_CAMERA_SUCCESS,
    ADD_CAMERA_FAIL,

    UPDATE_CAMERA_REQUEST,
    UPDATE_CAMERA_SUCCESS,
    UPDATE_CAMERA_FAIL,

    DELETE_CAMERA_REQUEST,
    DELETE_CAMERA_SUCCESS,
    DELETE_CAMERA_FAIL,

    GET_CAMERA_USER_REQUEST,
    GET_CAMERA_USER_SUCCESS,
    GET_CAMERA_USER_FAIL,

    CREATE_CAMERA_USER_REQUEST,
    CREATE_CAMERA_USER_SUCCESS,
    CREATE_CAMERA_USER_FAIL,

    GET_NOTIFIACTION_REQUEST,
    GET_NOTIFIACTION_SUCCESS,
    GET_NOTIFIACTION_FAIL,

    CAMERA_FRAME_REFRESH_REQUEST,
    CAMERA_FRAME_REFRESH_SUCCESS,
    CAMERA_FRAME_REFRESH_FAIL,

    WEBCAM_DETAIL_REQUEST,
    WEBCAM_DETAIL_SUCCESS,
    WEBCAM_DETAIL_FAIL,

    GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST,
    GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS,
    GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL,

    GET_PAGE_ACCESS_REQUEST,
    GET_PAGE_ACCESS_SUCCESS,
    GET_PAGE_ACCESS_FAIL,
} from "../constants/dataConstants";

import { API_ENDPOINT } from '../constants/apiEndpoint'
import { getSingleServicesData } from "../actions/servicesActions";
import { deleteAPI, getAPI, postAPI } from '../global/ApiManager';

export const homePageData = (keyword, userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: HOMEPAGE_DATA_GET_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/homepage/`, dispatch)

        dispatch({
            type: HOMEPAGE_DATA_GET_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: HOMEPAGE_DATA_GET_FAIL,
            payload: error
        })
    }
}


export const addCamera = (camera_details) => async (dispatch, getState) => {
    try {
        const { camera_id, group_id, camera_name, manufacture_name, rtsp_url, type_label } = camera_details
        const formData = new FormData();
        formData.append("camera_id", camera_id);
        formData.append("group_id", group_id);
        formData.append("camera_name", camera_name);
        formData.append("manufacture_name", manufacture_name);
        formData.append("rtsp_url", rtsp_url);
        formData.append("type_label", type_label);

        dispatch({
            type: ADD_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/addcamera/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: ADD_CAMERA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const updateCamera = (camera_details) => async (dispatch, getState) => {
    try {
        const formData = new FormData();

        const entries = Object.entries(camera_details)
        for (const [key, value] of entries) {
            formData.append(key, value);
        }

        dispatch({
            type: UPDATE_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/update_camera/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: UPDATE_CAMERA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const cameraDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/app/delete-camera/${id}`,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_CAMERA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const cameraUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CAMERA_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get-camera-user/${id}`, dispatch)

        dispatch({
            type: GET_CAMERA_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_CAMERA_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const CreateCameraUser = (UserId, cameraId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_CAMERA_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/add-camera-user/`,
            { 'UserId': UserId, 'cameraId': cameraId },
            config,
            dispatch
        )

        dispatch({
            type: CREATE_CAMERA_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_CAMERA_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }

}

export const getNotification = (total_item) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_NOTIFIACTION_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            params: {
                "total-item": total_item
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/alerts/`, dispatch)

        dispatch({
            type: GET_NOTIFIACTION_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_NOTIFIACTION_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}



export const cameraFrameRefresh =
    (camera_details, service_config_id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: CAMERA_FRAME_REFRESH_REQUEST,
            });

            const {
                userLogin: { userInfo },
            } = getState();

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo?.token}`,
                },
            };

            const { data } = await postAPI(
                `/app/update_camera/`,
                camera_details,
                config,
                dispatch
            );

            dispatch(getSingleServicesData(service_config_id));

            dispatch({
                type: CAMERA_FRAME_REFRESH_SUCCESS,
                payload: data,
            });

        } catch (error) {
            dispatch({
                type: CAMERA_FRAME_REFRESH_FAIL,
                payload:
                    error
                        ? error.data.detail
                        : error.message,
            });
        }
    };


export const webCamDetail = (cameraId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: WEBCAM_DETAIL_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/webcam_detail/${cameraId}`, dispatch)

        dispatch({
            type: WEBCAM_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: WEBCAM_DETAIL_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const getNavigationBarPageAccess = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/pages_api`,
            config
        )

        dispatch({
            type: GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}


export const getPageAccess = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PAGE_ACCESS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/pages_api`, dispatch)

        dispatch({
            type: GET_PAGE_ACCESS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_PAGE_ACCESS_FAIL,
            payload: error
                ? error.data
                : error.message,
        })
    }
}

export const getEventDetails = async (id, token) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        const { data } = await axios.get(
            `${API_ENDPOINT}/service_app/event_seen/${id} `,
            config
        );

        return { "data": data }
    } catch (error) {

        return { "data": {} }
    }
}