import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RenewConfirmModal from '../../HomePage/RenewConfirmModal';
import Loader from "../../../components/Loader";
import { getServicesDetail, listSubscribeServices } from '../../../actions/subscriptionActions';
import { Alert, Badge, Modal } from "react-bootstrap";
import moment from "moment";
import { Table } from "antd";
import 'moment-timezone';
import SearchInput from '../../../components/SearchInput';
import noRequestFound from '../../../images/no-camera-request-found.gif';
import { capitalizeFirstLater } from "../../../global/Helper";

function SubscriptionManagementTable() {

    const [showRenewModal, setShowRenewModal] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [search, setSearch] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(listSubscribeServices())
    }, [])

    const subscribeServicesList = useSelector((state) => state.subscribeServicesList);
    const { error: subError, loading: subLoad, serviceData } = subscribeServicesList;

    const current_time = new Date()

    const subscriptionTableColumns = [
        {
            title: 'Service Name',
            dataIndex: 'service_name',
            key: 'service_name',
            ellipsis: true,
            width: "35%",
        },
        {
            title: 'Camera Limit',
            dataIndex: 'camera_limit',
            key: 'camera_limit',
            ellipsis: true,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            ellipsis: true,
        },
        {
            title: 'Remaining Days',
            dataIndex: 'remaining_days',
            key: 'remaining_days',
            ellipsis: true,
        },
    ];

    const subscriptionTableData = Object.keys(serviceData)?.filter(e => (e?.toLowerCase().includes(search?.toLowerCase())))?.map(e => {
        return {
            key: e,
            service_name: <div className="d-flex align-items-center subscription-service-name">
                {e?.service_icon ? (
                    <img src={e.service_icon} width={24} className="me-2" alt="..." />
                ) : (
                    <i className="ri-tools-fill me-2"></i>
                )}
                <div className="text-truncate text-capitalize" title={capitalizeFirstLater(e)}>{e}</div>
                {(current_time >= new Date(serviceData[e].expiry_date) && !serviceData[e].is_lifetime) ? (<Badge className="low-badge">Inactive</Badge>) : (<Badge className="high-badge">Active</Badge>)}
            </div>,
            camera_limit: serviceData[e].no_camera_limit ? <i className="fas fa-infinity"></i> : serviceData[e].camera_limit,
            start_date: <>{!serviceData[e].is_lifetime ? moment(new Date(serviceData[e].start_date)).format("DD-MM-YYYY") : '-'}</>,
            end_date: <>{!serviceData[e].is_lifetime ? moment(new Date(serviceData[e].expiry_date)).format("DD-MM-YYYY") : '-'}</>,
            remaining_days: <>
                {serviceData[e].is_lifetime ? 'Life Time' : (
                    <>
                        {
                            current_time >= new Date(serviceData[e].expiry_date) ?
                                (<button
                                    type="button"
                                    className="renew-btn p-0"
                                    onClick={() => {
                                        setShowRenewModal(true);
                                        setServiceName(e);
                                        dispatch(getServicesDetail(e));
                                    }}
                                >
                                    Renew
                                </button>) :
                                moment
                                    .tz(new Date(serviceData[e].expiry_date), "Asia/Kolkata")
                                    .local()
                                    .startOf('seconds').fromNow().slice(2) + ' ' + 'left'
                        }
                    </>
                )}
            </>,
        }
    })

    return (
        <Fragment>
            <div
                className="subscription-table h-100"
            >
                <div className='organization-headings mb-2'>Subscription</div>
                <SearchInput
                    onChange={(e) => setSearch(e)}
                    placeholder="Search by service name"
                />
                <div className="mt-2" style={{ height: "calc(100% - 65px)" }}>
                    {subError ?
                        (<Alert variant="danger">{subError}</Alert>) : (
                            <>
                                <Table
                                    columns={subscriptionTableColumns}
                                    dataSource={subscriptionTableData} pagination={false}
                                    scroll={{ x: 800, y: "calc(100vh - 292px)" }}
                                    rowClassName={(record) => !serviceData[record.key].is_lifetime && (`${current_time >= new Date(serviceData[record.key].expiry_date) && 'event_unseen'}`)}
                                />
                                {subLoad && (
                                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "56vh" }}>
                                        <Loader margin="0" />
                                    </div>
                                )}
                                {(!subLoad && subscriptionTableData?.length === 0) && (
                                    <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 190px)" }}>
                                        <div className="text-center background-none">
                                            <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                                            <p className='opacity'>No Subscription Found!</p>
                                        </div>
                                    </div>)}
                            </>
                        )}
                </div>

            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showRenewModal}
                onHide={() => setShowRenewModal(false)}
            >
                <Modal.Body>
                    <RenewConfirmModal
                        onHide={() => setShowRenewModal(false)}
                        item_name={serviceName}
                    />
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default SubscriptionManagementTable