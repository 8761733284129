import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tab, Tabs, Image, Badge, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchInput from '../../../components/SearchInput'
import Multiselect from 'multiselect-react-dropdown';
import 'antd/dist/antd.css';
import { Table, Pagination } from "antd";
import noUserFound from '../../../images/no-users-found.gif'
import noCameraFound from '../../../images/no-camera-found.gif'
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import { getCamera, getSingleServicesData, getTimeWiseCameraScheduleData } from "../../../actions/servicesActions";
import { useDispatch, useSelector } from "react-redux";
import ServiceConfigModal from '.././ServiceConfigModal'
import AddSingleUserProfileModal from './AddSingleUserProfileModal'
import UserCameraAccessModal from './UserCameraAccessModal'
import UsersAddedOnCameraModal from './UsersAddedOnCameraModal'
import UserConfigurationModal from './UserConfigurationModal'
import { Link } from 'react-router-dom';
import { getFaceRecognitionCamera, deleteFaceRecognitionCamera, getFaceRecognitionUsers, deleteFaceRecognitionUser, bulkUploadUsersCSVFile } from '../../../actions/faceRecognitionActions'
import { CameraDeleteAlert, CommonErrorAlert, CommonSuccessAlert, DeleteUserAlert } from "../../../components/ToastAlert";
import moment from "moment";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { DELETE_FACE_RECOGNITION_CAMERA_RESET, DELETE_FACE_RECOGNITION_USER_RESET } from '../../../constants/faceRecognitionConstant'
import ServiceConfigurationScreen from '../ServiceConfigurationScreen';
import TimeWiseServiceModal from '../TimeWiseCameraService/TimeWiseServiceModal';
import UploadCSV from '../../../components/UploadCSV';
import sampleUserDataCSV from '../../../components/sample_user_data.csv';
import userSlash from '../../../images/add-face-icon.svg';
import noFace from "../../../images/profile_image.png";

function FaceRecognitionTab({ service_name, service_id, pageAccess }) {

    const targetButton = useRef(null);

    const [userType, setUserType] = useState(undefined);
    const [department, setDepartment] = useState(undefined);
    const [camera, setCamera] = useState(undefined);
    const [usersPageNumber, setUsersPageNumber] = useState(1);
    const [userSearch, setUserSearch] = useState(null);
    const [camerasPageNumber, setCamerasPageNumber] = useState(1);
    const [camerasSearch, setCamerasSearch] = useState(null);
    const [selectCameraAccess, setSelectCameraAccess] = useState([]);

    const [selectCameraName, setSelectCameraName] = useState([]);
    const [selectCameraStatus, setSelectCameraStatus] = useState([]);

    const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);
    const [deleteCameraModalShow, setDeleteCameraModalShow] = useState(false);
    const [cameraId, setCameraId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [showServiceConfig, setShowServiceConfig] = useState(false);
    const [userName, setUserName] = useState('');
    const [cameraName, setCameraName] = useState('');
    const [addSingleUserModalShow, setAddSingleUserModalShow] = useState(false);
    const [userDetails, setUserDetails] = useState(false);
    const [userCameraAccessModalShow, setUserCameraAccessModalShow] = useState(false);
    const [userAddedCameraModalShow, setUserAddedCameraModalShow] = useState(false);
    const [userAddedCamera, setUserAddedCamera] = useState([]);
    const [userConfigurationModal, setUserConfigurationModal] = useState(false);
    const [userConfigurationModalAnimation, setUserConfigurationModalAnimation] = useState(true);
    const [liveCamera, setLiveCamera] = useState(undefined);
    const [showButton, setShowButton] = useState(false);
    const [configurationModalShow, setConfigurationModalShow] = useState(false);
    const [timeWiseServiceModalShow, setTimeWiseServiceModalShow] = useState(false);
    const [cameraServiceConfigId, setCameraServiceConfigId] = useState(null);
    const [cameraOption, setCameraOption] = useState(null);
    const [csvUploadLoading, setCsvUploadLoading] = useState(null);

    const dispatch = useDispatch();

    const faceRecognitionCameraGet = useSelector((state) => state.faceRecognitionCameraGet);
    const { loading, faceCameraData } = faceRecognitionCameraGet;

    const faceRecognitionDeleteCamera = useSelector((state) => state.faceRecognitionDeleteCamera);
    const { loading: deleteCameraLoader, cameraDeleteSuccess } = faceRecognitionDeleteCamera;

    const faceRecognitionDeleteUser = useSelector((state) => state.faceRecognitionDeleteUser);
    const { loading: deleteFaceRecognitionLoader, userDeleteSuccess } = faceRecognitionDeleteUser;

    const faceRecognitionUsersGet = useSelector((state) => state.faceRecognitionUsersGet);
    const { loading: getUserLoad, faceUsersData } = faceRecognitionUsersGet;

    const ServicesDataGetSingle = useSelector((state) => state.ServicesDataGetSingle);
    const { loading: getSingleCameraServiceLoading } = ServicesDataGetSingle;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const userTableHandleChange = (pagination, filters, sorter) => {
        setUserType(filters.user_type)
        setDepartment(filters.department)
        setCamera(filters.camera)
    };

    useEffect(() => {
        dispatch(getFaceRecognitionUsers(usersPageNumber, userSearch, JSON.stringify(selectCameraAccess), JSON.stringify(userType), JSON.stringify(department), JSON.stringify(camera)))
    }, [dispatch, userType, department, camera, userSearch, selectCameraAccess, usersPageNumber, userDeleteSuccess])

    useEffect(() => {
        dispatch(getFaceRecognitionCamera(service_id, camerasPageNumber, camerasSearch, JSON.stringify(selectCameraName), JSON.stringify(selectCameraStatus)))
    }, [dispatch, camerasPageNumber, camerasSearch, selectCameraName, selectCameraStatus, cameraDeleteSuccess])

    const camera_access_option = [
        {
            name: "Authorized"
        },
        {
            name: "Unauthorized"
        },
        {
            name: "Blacklisted"
        }
    ]


    const camera_status_options = [
        {
            name: "Active"
        },
        {
            name: "Inactive"
        },
    ]

    const camera_options = faceUsersData?.camera_data_filter?.map((camera, index) => ({
        "camera_name": camera.value, "id": index
    }))

    const stopLiveCamera = () => {
        if (liveCamera) {
            liveCamera.getTracks().forEach((track) => {
                track.stop()
                setLiveCamera(undefined);
            })
        }
    }

    useEffect(() => {
        if (cameraDeleteSuccess) {
            dispatch({ type: DELETE_FACE_RECOGNITION_CAMERA_RESET })
            toast(<CameraDeleteAlert camera_name={cameraName} />);
            setDeleteCameraModalShow(false)
        }
        if (userDeleteSuccess) {
            dispatch({ type: DELETE_FACE_RECOGNITION_USER_RESET })
            toast(<DeleteUserAlert username={userName} />);
            setDeleteUserModalShow(false)
        }

    }, [service_id, dispatch, cameraDeleteSuccess, userDeleteSuccess])

    const getUserAccessStyle = (type) => {
        if (type.blacklisted) {
            return 'blacklisted'
        } else if (type.authorized) {
            return 'authorized'
        } else return 'unauthorized'
    }

    const openAddUsersModal = (user) => {
        setAddSingleUserModalShow(true)
        setUserDetails({
            "id": user.id,
            "name": user.username,
            "blacklisted": user.blacklisted,
            "image": user.user_profile,
            "image_left": user.user_profile_left,
            "image_right": user.user_profile_right,
            "user_id": user.user_id,
            "user_type": user.user_type,
            "department": user.department,
            "validity_start": user.validity_start,
            "valid_till": user.valid_till,
            "aadhar_number": user.adhaar_number,
            "email": !user.email?.includes("null") ? user.email : '',
            "pan_number": user.pan_number,
            "phone_number": user.phone_number,
            "company_name": user.company_name,
            ...user.extra_data,
        })
    }

    const getWorkerFields = useMemo(() => faceUsersData?.all_face_recognition_users?.filter(e => e.user_type === 'worker')?.map(e => Object.keys(e.extra_data))[0]?.filter(e => !e.includes("contractor") && !e.includes("contractor_code")), [faceUsersData?.all_face_recognition_users])

    const userTableData = faceUsersData?.all_face_recognition_users?.map((user) => ({
        key: user.id,
        user_id: (
            <span
                className={`${getUserAccessStyle(user)} border_radius_sm padding-badge fs-8`}
            >
                {user.user_id}
            </span>),
        username:
            <>
                <Image
                    title={user.username}
                    height={30}
                    width={40}
                    className="me-1 border_radius_sm"
                    src={user.user_profile || userSlash}
                    onError={e => e.target.src = userSlash}
                />{" "}
                {user.username}
            </>,
        user_type: user.user_type,
        department: user.department,
        camera:
            <div
                style={{ textAlign: "-webkit-center" }}
                onClick={(e) => {
                    setUserCameraAccessModalShow(true)
                    setUserAddedCamera(user.camera)
                    setUserId(user.id)
                }}
            >
                <span
                    className='added-camera-icon'
                >
                    <Badge bg="dark">{user.camera.length < 10 ? user.camera.length : 10}{user.camera.length > 10 && "+"}</Badge>
                </span>
            </div>,
        valid_till: user.valid_till ? moment(new Date(user.valid_till)).format("DD-MM-YYYY") : <span className=''>--</span>,
        action:
            <div className="d-flex">
                <OverlayTrigger overlay={!pageAccess?.['update'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                    <span className="">
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                            onClick={() => openAddUsersModal(user)}
                            disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                            style={
                                {
                                    pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '',
                                }
                            }
                        >
                            <i className="ri-edit-line"></i>
                        </button>
                    </span>
                </OverlayTrigger>
                <OverlayTrigger overlay={!pageAccess?.['delete'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                    <span className="">
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            onClick={() => {
                                setDeleteUserModalShow(true)
                                setUserName(user.username)
                                setUserId(user.id)
                            }}
                            disabled={!pageAccess?.['delete'].includes(userInfo?.user_role)}
                            style={
                                {
                                    pointerEvents: !pageAccess?.['delete'].includes(userInfo?.user_role) ? 'none' : '',
                                }
                            }
                        >
                            <i className="ri-delete-bin-5-line "></i>
                        </button>
                    </span>
                </OverlayTrigger>
            </div>,
    }))

    const userTableColumns = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: "14%",
            ellipsis: true,
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            width: '17%',
            ellipsis: true,
        },
        {
            title: 'User Type',
            dataIndex: 'user_type',
            key: 'user_type',
            filters: faceUsersData?.user_type_data_filter,
            onFilter: (value, record) => record.user_type,
            ellipsis: true,
            width: '14%',
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            filters: faceUsersData?.department_data_filter,
            onFilter: (value, record) => record.department,
            filterSearch: true,
            ellipsis: true,
            width: '16%',
        },
        {
            title: 'Camera',
            dataIndex: 'camera',
            key: 'camera',
            filters: faceUsersData?.camera_data_filter,
            onFilter: (value, record) => record.camera,
            filterSearch: true,
            ellipsis: true,
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_till',
            key: 'valid_till',
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "12%",
            ellipsis: true,
        },
    ];
    const cameraTableData = faceCameraData?.all_face_camera?.map((camera) => ({
        key: camera.id,
        camera_name: (
            <div className='d-flex align-items-center gap-2'
            >
                <span>{camera.camera_name} </span>
            </div>
        ),
        user: camera.user?.length > 1 ? (
            <div
                className='position-relative'
                style={{ cursor: "pointer" }}
                onClick={() => {
                    setUserAddedCamera(camera.user)
                    setUserAddedCameraModalShow(true)
                    setCameraId(camera.id)
                }}
            >
                {
                    camera.user.slice(0, 4)?.map((user, index) => (
                        <Image
                            key={`${index + 1}`}
                            title={user.username}
                            height={30}
                            width={40}
                            className="round_border_radius added-user-profile"
                            src={user.user_profile}
                            onError={e => e.target.src = userSlash}
                        />)
                    )}
                <button
                    className='more-user-btn'
                    onClick={() => {
                        setUserAddedCamera(camera.user)
                        setUserAddedCameraModalShow(true)
                        setCameraId(camera.id)
                    }}
                >
                    {
                        camera.user?.length > 4 && "+"
                    }
                    {camera.user?.length > 4 ? camera.user?.length - 4 + " more" : null}
                </button>
            </div>
        ) :
            <>
                {camera.user.length ? (
                    <div
                        onClick={() => {
                            setUserAddedCamera(camera.user)
                            setUserAddedCameraModalShow(true)
                            setCameraId(camera.id)
                        }}
                        title={camera.user[0]?.username}
                        style={{ cursor: "pointer" }}
                    >
                        <Image
                            height={30}
                            width={40}
                            className="me-1 round_border_radius"
                            src={camera.user[0]?.user_profile}
                            onError={e => e.target.src = noFace}
                        />{" "}
                        {camera.user[0]?.username}
                    </div>
                ) : (<p className='m-0 text-start'>--</p>)}

            </>,

        configuration_date: moment(new Date(camera.configuration_date)).format("DD-MM-YYYY   h:mm A"),
        status:
            (<div className='d-flex align-items-center '>
                {camera.status ?
                    <>
                        <i
                            className="ri-checkbox-blank-circle-fill me-1"
                            style={{
                                color: "var(--success)",
                                fontSize: "10px"
                            }}
                        ></i>
                        Active
                    </> :
                    <>
                        <i
                            className="ri-checkbox-blank-circle-fill me-1"
                            style={{
                                color: "var(--danger)",
                                fontSize: "10px"
                            }}
                        >
                        </i>
                        Inactive
                    </>
                }
            </div>),
        action:
            <div className="d-flex justify-content-center">
                <OverlayTrigger overlay={!pageAccess?.['update'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                    <span className="">
                        <button
                            type='button'
                            className='add-user-btn'
                            style={{ padding: "7px 7px", pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '', }}
                            onClick={() => {
                                setUserConfigurationModal(true)
                                setUserConfigurationModalAnimation(true)
                                setUserAddedCamera(camera.user)
                                setCameraId(camera.id)
                            }}
                            disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                        >
                            <span className='add-user-icon'></span>
                        </button>
                    </span>
                </OverlayTrigger>
                <OverlayTrigger overlay={!pageAccess?.['delete'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                    <span className="">
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            onClick={() => {
                                setDeleteCameraModalShow(true)
                                setCameraName(camera.camera_name)
                                setCameraId(camera.id)
                            }}
                            disabled={!pageAccess?.['delete'].includes(userInfo?.user_role)}
                            style={{
                                pointerEvents: !pageAccess?.['delete'].includes(userInfo?.user_role) ? 'none' : '',
                            }}
                        >
                            <i className="ri-delete-bin-5-line "></i>
                        </button>
                    </span>
                </OverlayTrigger>
                <OverlayTrigger placement={'bottom'} rootClose trigger={['click']} containerPadding={80} overlay={
                    <Tooltip className='bg-white text-black overlay-dropdown px-2'>
                        <div
                            className='dropdown-row d-flex align-items-center py-2 border-bottom px-1'
                            onClick={() => {
                                dispatch(getTimeWiseCameraScheduleData(camera.id));
                                setCameraServiceConfigId(camera.id)
                                setTimeWiseServiceModalShow(true)
                                setCameraOption([camera.camera_name])
                            }}
                        >
                            <i className="ri-time-line me-2 fs-6"></i>
                            Time Setup
                        </div>
                        <div
                            className='dropdown-row d-flex align-items-center py-2 px-1'
                            onClick={() => {
                                dispatch(getSingleServicesData(camera.id))
                                setConfigurationModalShow(true)
                            }}
                        >
                            <i className="ri-edit-line me-2 text-black fs-6"></i>
                            Edit
                        </div>
                    </Tooltip>
                }>
                    <button
                        className="btn secondary_small_btn bg-light text-dark d-flex align-items-center justify-content-center ms-2"
                        onClick={() => setShowButton(!showButton)}
                        ref={targetButton}
                        disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                        style={{
                            pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '',
                        }}
                    >
                        <i className="fas fa-ellipsis-h"></i>
                    </button>
                </OverlayTrigger>

            </div>
    }))

    const cameraTableColumns = [
        {
            title: 'Camera Name',
            dataIndex: 'camera_name',
            key: 'camera_name',
            ellipsis: true,
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            width: "20%",
            ellipsis: true,
        },
        {
            title: 'Configuration Date',
            dataIndex: 'configuration_date',
            key: 'configuration_date',
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: "16%",
            ellipsis: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "15%",
            ellipsis: true,
        },
    ];

    const onCameraAccessRemove = (e) => {
        const removeCamera = e?.map((access) => access.name)
        setSelectCameraAccess(removeCamera)
    }

    const onCameraAccessSelect = (e) => {
        const selectedCamera = e?.map((access) => access.name)
        setSelectCameraAccess(selectedCamera)
    }

    const uploadCSVOnChange = async (e) => {
        setCsvUploadLoading(true)
        const { data, status } = await bulkUploadUsersCSVFile(userInfo?.token, e.target.files[0])
        if (status === 200) {
            toast(<CommonSuccessAlert message={data.detail} />);
            dispatch(getFaceRecognitionUsers())
        } else {
            toast(<CommonErrorAlert message={<>{data.response.data.error}</>} />);
        }
        e.target.value = null;
        setCsvUploadLoading(false)
    }

    return (
        <>
            <Tabs
                defaultActiveKey="users"
                id="justify-tab-example"
                className="face-recognition-tab-panel"
                variant="tabs"
                justify
            >
                <Tab eventKey="users" title="User" className='h-100 position-relative tab-container' style={{ paddingBottom: userTableData?.length !== 0 ? "66px" : "0" }}>
                    <div className='d-flex justify-content-between align-items-end flex-wrap flex-lg-nowrap gap-2'>

                        <SearchInput width='50%' onChange={(e) => setUserSearch(e)} />

                        <Multiselect
                            options={camera_access_option}
                            onSelect={onCameraAccessSelect} // Function will 
                            onRemove={onCameraAccessRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            id='live-cam-access'
                            placeholder='Access'
                            showArrow={true}
                            hideSelectedList={true}
                            showCheckbox={true}
                            avoidHighlightFirstOption={true}
                            disablePreSelectedValues={false}
                        />
                        <div className='d-flex'>
                            <UploadCSV
                                onChange={uploadCSVOnChange}
                                loading={csvUploadLoading}
                                tooltipContent={<>User Bulk Upload in XLSX file.< br /> <a className='text-primary cursor-pointer' href={sampleUserDataCSV} download="sample_user_data.csv">Download</a> the sample file</>}
                            />
                            <OverlayTrigger overlay={!pageAccess?.['create'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                                <span className="">
                                    <button
                                        type='button'
                                        className='add-user-btn ms-2'
                                        onClick={() => {
                                            setAddSingleUserModalShow(true)
                                            setUserDetails({
                                                "id": null,
                                                "name": null,
                                                "blacklisted": null,
                                                "image": null,
                                                "image_left": null,
                                                "image_right": null,
                                                "user_id": null,
                                                "user_type": null,
                                                "department": null,
                                                "validity_start": null,
                                                "valid_till": null,
                                                "aadhar_number": null,
                                                "email": null,
                                                "pan_number": null,
                                                "phone_number": null,
                                                "company_name": null,
                                            })
                                        }}
                                        disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                                        style={{
                                            pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                                        }}
                                    >
                                        <span
                                            className='add-user-icon'
                                        >
                                        </span>
                                    </button>
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                    {userTableData?.length !== 0 ? (
                        <div className="d-flex mt-2 mb-1">
                            <span className='authorized border_radius_xsm padding-badge'>Authorized</span>
                            <span className='blacklisted border_radius_xsm mx-2 padding-badge'>Blacklisted</span>
                            <span className='unauthorized border_radius_xsm padding-badge'>Unauthorized</span>
                        </div>
                    ) : null}
                    <Table
                        onRow={(record) => {
                            return {
                                onClick: (e) => {
                                    const user = faceUsersData?.all_face_recognition_users.filter(e => e.id === record.key)[0]
                                    openAddUsersModal(user)
                                },
                            };
                        }}
                        rowClassName={`cursor-pointer`}
                        columns={userTableColumns}
                        dataSource={!getUserLoad ? userTableData : null}
                        pagination={false}
                        onChange={userTableHandleChange}
                        scroll={{ x: 800, y: "calc(100vh - 521px)" }}
                    />
                    {userTableData?.length !== 0 && (
                        <div
                            className="d-flex align-items-center justify-content-end pagination_bottom"
                        >
                            <Pagination
                                defaultCurrent={1}
                                current={Number(faceUsersData?.page) ? Number(faceUsersData?.page) : 1}
                                pageSize={10}
                                total={faceUsersData?.face_recognition_users_count}
                                onChange={(e) => setUsersPageNumber(e)}
                                showLessItems={true}
                            />
                        </div>
                    )}

                    {getUserLoad && (
                        <div style={{ height: "calc(100vh - 525px)" }} className='d-flex justify-content-center align-items-center'>
                            <Loader margin="0" />
                        </div>
                    )
                    }
                    {userTableData?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 100px)", padding: "20px 0" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity no-user-found' src={noUserFound} alt="" />
                                <p className='opacity'>No Users Found!<br />
                                    Add Users to start building the User List.</p>
                                <OverlayTrigger overlay={!pageAccess?.['create'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                                    <span className="">
                                        <button
                                            className='no-user-btn btn'
                                            onClick={() => {
                                                setAddSingleUserModalShow(true)
                                                setUserDetails({
                                                    "id": null,
                                                    "name": null,
                                                    "blacklisted": null,
                                                    "image": null,
                                                    "image_left": null,
                                                    "image_right": null,
                                                    "user_id": null,
                                                    "user_type": null,
                                                    "department": null,
                                                    "validity_start": null,
                                                    "valid_till": null,
                                                    "aadhar_number": null,
                                                    "email": null,
                                                    "pan_number": null,
                                                    "phone_number": null,
                                                    "company_name": null,
                                                })
                                            }}
                                            disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                                            style={{
                                                pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                                            }}
                                        >
                                            <i className="fas fa-plus me-2"></i>Add User
                                        </button>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>) : null}
                </Tab>
                <Tab eventKey="camera" title="Camera" className='h-100 position-relative tab-container' style={{ paddingBottom: "62px" }}>
                    <div className='d-flex justify-content-between align-items-end flex-wrap flex-lg-nowrap mb-2'>
                        <SearchInput width='60%' onChange={(e) => setCamerasSearch(e)} />
                        <div className="me-2">
                            <Multiselect
                                options={camera_options} // Options to display in the dropdown
                                onSelect={(e) => setSelectCameraName(e?.map((camera) => camera.camera_name))} // Function will trigger on select event
                                onRemove={(e) => setSelectCameraName(e?.map((camera) => camera.camera_name))} // Function will trigger on remove event
                                displayValue="camera_name" // Property name to display in the dropdown options
                                id='live-cam-access'
                                placeholder='Camera Name'
                                showArrow={true}
                                hideSelectedList={true}
                                showCheckbox={true}
                                avoidHighlightFirstOption={true}
                                disablePreSelectedValues={false}
                                className=""
                            />
                        </div>
                        <Multiselect
                            options={camera_status_options} // Options to display in the dropdown
                            onSelect={(e) => setSelectCameraStatus(e?.map((camera) => camera.name))} // Function will trigger on select event
                            onRemove={(e) => setSelectCameraStatus(e?.map((camera) => camera.name))}
                            displayValue="name" // Property name to display in the dropdown options
                            id='live-cam-access'
                            placeholder='Status'
                            showArrow={true}
                            singleSelect={false}
                            selectionLimit={1}
                            avoidHighlightFirstOption={true}
                            className={`camera-status-multiselect ${selectCameraStatus.length > 0 && 'remove-placeholder'}`}
                            customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                        />
                    </div>
                    <Table
                        columns={cameraTableColumns}
                        dataSource={!loading ? cameraTableData : null} pagination={false}
                        scroll={{ x: 800, y: "calc(100vh - 491px)" }}
                    // scroll={{ x: 800 }}
                    />
                    {loading && (
                        <div style={{ height: "calc(100vh - 497px)" }} className='d-flex justify-content-center align-items-center'>
                            <Loader margin="0" />
                        </div>
                    )
                    }
                    {cameraTableData?.length !== 0 && (
                        <div
                            className="d-flex align-items-center justify-content-end pagination_bottom"
                        >
                            <Pagination
                                defaultCurrent={1}
                                current={Number(faceCameraData?.page) ? Number(faceCameraData?.page) : 1}
                                pageSize={10}
                                total={faceCameraData?.face_camera_count}
                                onChange={(e) => setCamerasPageNumber(e)}
                                showLessItems={true}
                            />
                        </div>
                    )}


                    {cameraTableData?.length === 0 ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 100px)" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity no-camera-found' src={noCameraFound} alt="" />
                                <p className='opacity'>No details were found. Once you add a camera,<br /> all the information will appear here.</p>
                                <OverlayTrigger overlay={!pageAccess?.['create'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                                    <span className="">
                                        <button
                                            className='no-user-btn btn'
                                            onClick={() => {
                                                dispatch(getCamera(service_id));
                                                setShowServiceConfig(true);
                                            }}
                                            disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                                            style={{
                                                pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                                            }}
                                        >
                                            <i className="fas fa-plus me-2"></i>
                                            Add Camera
                                        </button>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    ) : null}
                </Tab>
            </Tabs>
            {/* For deleting user */}
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteUserModalShow}
                onEnter={e => setAddSingleUserModalShow(false)}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        item_name={userName}
                        dispatch_item={deleteFaceRecognitionUser(userId)}
                        onHide={() => { setDeleteUserModalShow(false) }}
                        loading={deleteFaceRecognitionLoader}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteCameraModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        item_name={cameraName}
                        dispatch_item={deleteFaceRecognitionCamera(cameraId)}
                        onHide={() => { setDeleteCameraModalShow(false) }}
                        loading={deleteCameraLoader}
                    />
                </Modal.Body>
            </Modal>
            <ServiceConfigModal
                show={showServiceConfig}
                closeServiceConfigModal={() => setShowServiceConfig(false)}
                service_name={service_name}
                service_id={service_id}
            >
            </ServiceConfigModal>
            {/* for adding user profile for face recognition */}
            <Modal
                scrollable={true}
                show={addSingleUserModalShow}
                onHide={() => {
                    setAddSingleUserModalShow(false)
                    stopLiveCamera()
                    setUserDetails(undefined)
                }}
                size="xl"
                fullscreen='lg-down'
                centered
            >
                <Modal.Header className='custom-modal-styling' style={{ padding: "1rem 1rem 0px" }} closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Face Recognition Solution
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddSingleUserProfileModal
                        userData={userDetails}
                        UsersTablePageNumber={usersPageNumber}
                        workerFields={getWorkerFields ? [...getWorkerFields] : []}
                        closeAddSingleUserModal={(e) => { e === true && setAddSingleUserModalShow(false); setUserDetails(undefined) }}
                        closeLiveCame={(e) => setLiveCamera(e)}
                        serviceId={service_id}
                        userLabel={faceUsersData?.user_label}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                scrollable={false}
                show={userCameraAccessModalShow}
                onEnter={e => setAddSingleUserModalShow(false)}
                onHide={() => { setUserCameraAccessModalShow(false) }}
                size="lg"
                centered
            >
                <Modal.Header className='custom-modal-styling' style={{ padding: "1rem 2rem 0px" }} closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Camera Configuration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserCameraAccessModal
                        closeCameraAccessModal={(e) => e === true && setUserCameraAccessModalShow(false)}
                        UsersTablePageNumber={usersPageNumber}
                        addedCamera={userAddedCamera}
                        serviceId={service_id}
                        userId={userId}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                scrollable={false}
                show={userAddedCameraModalShow}
                onHide={() => { setUserAddedCameraModalShow(false) }}
                size="lg"
                centered
            >
                <Modal.Header className='custom-modal-styling' style={{ padding: "1rem 2rem 0px" }} closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Users Added
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UsersAddedOnCameraModal
                        closeAddedUserCameraModal={(e) => e === true && setUserAddedCameraModalShow(false)}
                        cameraTablePageNumber={camerasPageNumber}
                        showAddedUserCameraModal={(e) => {
                            e === true && setUserConfigurationModal(true)
                            setUserConfigurationModalAnimation(false)
                        }}
                        addedUser={userAddedCamera}
                        cameraId={cameraId}
                        serviceId={service_id}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                scrollable={false}
                show={userConfigurationModal}
                onHide={() => { setUserConfigurationModal(false) }}
                size="lg"
                animation={userConfigurationModalAnimation}
                centered
            >
                <Modal.Header className='custom-modal-styling' style={{ padding: "1rem 2rem 0px" }} closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Users Configuration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserConfigurationModal
                        closeAddedUserCameraModal={(e) => e === true && setUserAddedCameraModalShow(false)}
                        closeUserConfigurationModal={(e) => e === true && setUserConfigurationModal(false)}
                        cameraTablePageNumber={camerasPageNumber}
                        addedUser={userAddedCamera}
                        cameraId={cameraId}
                        serviceId={service_id}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                scrollable={true}
                show={configurationModalShow}
                size="xl"
                onHide={() => setConfigurationModalShow(false)}
                centered
            >
                {!getSingleCameraServiceLoading && (
                    <ServiceConfigurationScreen
                        setShow={() => setConfigurationModalShow(false)}
                    />
                )}
            </Modal>

            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                className="time-wise-service-modal"
                centered
                show={timeWiseServiceModalShow}
                onHide={() => setTimeWiseServiceModalShow(false)}
            >
                <TimeWiseServiceModal
                    serviceName={service_name}
                    CameraOption={cameraOption}
                    onHide={() => setTimeWiseServiceModalShow(false)}
                    DisabledDropdown={true}
                    CameraServiceConfigId={cameraServiceConfigId}
                />
            </Modal>
        </>
    )
}

export default FaceRecognitionTab