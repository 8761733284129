import React, { useState, useEffect, useMemo } from 'react'
import LabelTooltip from './LabelTooltip';
import { Slider, Button, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStorageManagementData, putStorageManagementData } from '../../../actions/organizationActions/storageManagementActions';
import { DefaultUpdateAlerts, CommonErrorAlert } from '../../../components/ToastAlert'
import { PUT_STORAGE_MANAGEMENT_DATA_RESET } from '../../../constants/organizationConstant/storageManagementConstants';
import { toast } from "react-toastify";
import { getProgressBarColor, sortObjectByKey } from '../../../global/Helper';
import Multiselect from 'multiselect-react-dropdown';


function StorageManagement() {
    const dispatch = useDispatch();

    const [FreeStorage, setFreeStorage] = useState(80);
    const [FreeStorageContent, setFreeStorageContent] = useState("");
    const [UsedStorageContent, setUsedStorageContent] = useState("");
    const [EventQuality, setEventQuality] = useState(null);
    const [StorageThresholdValue, setStorageThresholdValue] = useState('');
    const [EventDetectionValue, setEventDetectionValue] = useState('');
    const [EventDelete, setEventDelete] = useState(false);
    const [DisabledButton, setDisabledButton] = useState(false);
    const [ImageType, setImageType] = useState('');

    const StorageManagementDataGet = useSelector((state) => state.StorageManagementDataGet);
    const { /*error: getStorageManagementError, loading: getStorageManagementLoad,*/ success: getStorageManagementSuccess } = StorageManagementDataGet;

    const StorageManagementDataPut = useSelector((state) => state.StorageManagementDataPut);
    const { error: putStorageManagementError, loading: putStorageManagementLoad, success: putStorageManagementSuccess } = StorageManagementDataPut;

    const marks = {
        0: '0%',
        100: '100%',
    };

    useEffect(() => {
        dispatch(getStorageManagementData())
    }, [])

    useEffect(() => {
        setEventQuality(getStorageManagementSuccess?.[0]?.event_quality)
        setEventDelete(getStorageManagementSuccess?.[0]?.event_delete)
        setFreeStorageContent(getStorageManagementSuccess?.[0]?.storage_info?.free_storage)
        setUsedStorageContent(getStorageManagementSuccess?.[0]?.storage_info?.used_storage)
        setImageType([getStorageManagementSuccess?.[0]?.image_type])
        setFreeStorage(getStorageManagementSuccess?.[0]?.storage_info?.used_storage?.split(/[()]/)[1].split("%")[0])
        if (getStorageManagementSuccess?.[0]?.event_delete) {
            setStorageThresholdValue(getStorageManagementSuccess?.[0]?.event_delete?.storage_limit)
            setEventDetectionValue(getStorageManagementSuccess?.[0]?.event_delete?.event_count)
        }
    }, [getStorageManagementSuccess])

    const formData = useMemo(() => ({
        "id": getStorageManagementSuccess?.[0]?.id,
        "event_quality": EventQuality,
        "image_type": ImageType?.[0],
        "event_delete": EventDelete ? { "storage_limit": StorageThresholdValue, "event_count": EventDetectionValue } : EventDelete
    }), [EventDelete, StorageThresholdValue, EventDetectionValue, EventQuality, getStorageManagementSuccess, ImageType])

    useEffect(() => {
        setDisabledButton(true)
        const getStorageManagementData = { ...getStorageManagementSuccess?.[0] }
        delete getStorageManagementData?.storage_info

        if ((StorageThresholdValue.length > 0 && String(EventDetectionValue).length > 0) || JSON.stringify(sortObjectByKey(formData)) !== JSON.stringify(sortObjectByKey(getStorageManagementData))) {
            setDisabledButton(false)
        }
    }, [EventDelete, StorageThresholdValue, EventDetectionValue, getStorageManagementSuccess, EventQuality, ImageType])

    const OnChangeStorageThreshold = (e) => {
        if (Number.isInteger(Number(e.target.value)) || e.target.value.includes("%")) {
            if ((e.target.value).trim().length > 0) {
                setStorageThresholdValue(e.target.value)
            } else {
                setStorageThresholdValue('')
            }
        }
    }
    const OnChangeEventDetection = (e) => {
        if (Number.isInteger(Number(e.target.value))) {
            if ((e.target.value).trim().length > 0) {
                setEventDetectionValue(Number(e.target.value))
            } else {
                setEventDetectionValue('')
            }
        }
    }

    const StorageManagementOnSubmit = () => {
        dispatch(putStorageManagementData(formData, getStorageManagementSuccess?.[0]?.id))
    }

    useEffect(() => {
        if (putStorageManagementSuccess) {
            dispatch(getStorageManagementData())
            dispatch({ type: PUT_STORAGE_MANAGEMENT_DATA_RESET })
            toast(<DefaultUpdateAlerts name={"Storage Management"} />);
        }
        if (putStorageManagementError) {
            toast(<CommonErrorAlert message={putStorageManagementError} />);
            dispatch({ type: PUT_STORAGE_MANAGEMENT_DATA_RESET })
        }
    }, [dispatch, putStorageManagementSuccess, putStorageManagementError])

    const ImageTypeOption = [
        'webp',
        'png',
        'JPEG'
    ]

    const ImageTypeOnChange = (e) => {
        setImageType(e)
    }

    return (
        <div className='storage-management-container'>
            <div className='organization-headings'>Storage Management</div>
            {FreeStorage && (
                <div className="storage-progress mt-4">
                    <div className='d-flex'>
                        <div className='storage-text'>{UsedStorageContent}</div>
                        <div className='ms-auto storage-text'>{FreeStorageContent}</div>
                    </div>
                    <div className="progress" style={{ height: "7px" }}>
                        <div className={`progress-bar ${getProgressBarColor(FreeStorage)}`} role="progressbar" style={{ width: `${FreeStorage}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            )}
            <div className="threshold-container mt-4 d-flex gap-4 flex-lg-nowrap flex-wrap">
                <div style={{ width: "360px" }}>
                    <div className='d-flex align-items-center'>
                        <div className='me-auto d-flex align-items-center'>
                            <label className='backup-input-label'>Storage Threshold (%) and Event Deletion</label>
                            <LabelTooltip content={"Set storage threshold and number of events for automatic deletion upon reaching capacity."} placement={"top"} />
                        </div>
                        <Switch
                            checked={EventDelete}
                            size="small"
                            onChange={(e) => setEventDelete(e)}
                        />
                    </div>
                    <div className="d-flex">
                        <div className='position-relative'>
                            <input
                                disabled={!EventDelete}
                                type="text"
                                className={`bg-light-grey border rounded-left p-2 text-truncate w-100 ${!EventDelete && 'is-disable'}`}
                                placeholder='Storage Threshold (%)'
                                maxLength="4"
                                value={StorageThresholdValue || ''}
                                onChange={OnChangeStorageThreshold}
                            />
                            <div className={`d-flex flex-column position-absolute top-0 bottom-0 end-0 m-auto me-2 ${!EventDelete && 'is-disable'}`}>
                                <i className="fas fa-caret-up"></i>
                                <i className="fas fa-caret-down"></i>
                            </div>
                        </div>
                        <div className='position-relative'>
                            <input
                                disabled={!EventDelete}
                                type="text"
                                className={`bg-light-grey border rounded-right p-2 text-truncate w-100 ${!EventDelete && 'is-disable'}`}
                                placeholder='Event Deletion'
                                maxLength="10"
                                value={EventDetectionValue || ''}
                                onChange={OnChangeEventDetection}
                            />
                            <div className={`d-flex flex-column position-absolute top-0 bottom-0 end-0 m-auto me-2 ${!EventDelete && 'is-disable'}`}>
                                <i className="fas fa-caret-up"></i>
                                <i className="fas fa-caret-down"></i>
                            </div>
                        </div>
                    </div>
                    <div className='text bold'>
                        Upon reaching {StorageThresholdValue} capacity, the application will automatically delete the last {EventDetectionValue} events.
                    </div>
                </div>
                <div className='slider-container w-50'>
                    <div className='d-flex align-items-center'>
                        <label className='backup-input-label'>Event Image Quality (%)</label>
                        <LabelTooltip content={"Set storage threshold and number of events for automatic deletion upon reaching capacity."} placement={"top"} />
                    </div>
                    <div className='accuracy-range-slider'>
                        {EventQuality >= 0 && (
                            <Slider
                                marks={marks}
                                dots={false}
                                className='m-0'
                                tooltip={{ formatter: (e) => e }}
                                trackStyle={{ backgroundColor: "rgba(254, 190, 0, 0.5)" }} railStyle={{ background: "#E5E5E5" }}
                                onChange={e => setEventQuality(e)}
                                defaultValue={EventQuality}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-4 image-type">
                <div className='d-flex align-items-center'>
                    <label className='backup-input-label'>Event Image Type</label>
                    <LabelTooltip content={"This stores the events generated in the selected image format"} placement={"top"} />
                </div>
                <Multiselect
                    onSelect={ImageTypeOnChange}
                    onRemove={ImageTypeOnChange}
                    selectedValues={ImageType}
                    className='filterBox bg-light-grey'
                    singleSelect={true}
                    options={ImageTypeOption}
                    // placeholder='Hourly'
                    // id='frequency-input'
                    // emptyRecordMsg="No Status available."
                    isObject={false}
                    // avoidHighlightFirstOption={true}
                    customArrow={<><i className="fs-5 ri-arrow-down-s-fill"></i></>}
                // disable={disabled}
                />
            </div>
            <div className='mt-5'>
                {putStorageManagementLoad ? (
                    <Button type="primary" className='bg-warning border text-dark border_radius_sm' size="large" loading>
                        Save
                    </Button>
                ) : (
                    <button
                        className='btn bg-warning px-3'
                        onClick={StorageManagementOnSubmit}
                        disabled={DisabledButton}
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    )
}

export default StorageManagement