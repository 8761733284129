import { Checkbox } from 'antd';

function CheckBoxGroup(props) {

    const {
        option,
        parent,
        CheckBoxValue,
        setCheckBoxValue,
        allCheckBoxSelected,
        displayValue,
        selectedIdName,
        disabled
    } = props

    return (
        <>
            <div className='ps-2 mb-2'>
                <Checkbox
                    onChange={(event) => {
                        if (event.target.checked) {
                            setCheckBoxValue(option?.map(e => e[selectedIdName]))
                        } else {
                            setCheckBoxValue([])
                        }
                    }}
                    checked={allCheckBoxSelected}
                    disabled={disabled}
                >
                    {parent}
                </Checkbox>
            </div>
            <div className='ps-4 ms-2'>
                {option?.map(e => (
                    <div key={e[selectedIdName]} className='mb-2'>
                        <Checkbox
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setCheckBoxValue([...new Set([...CheckBoxValue, e[selectedIdName]])])
                                } else {
                                    setCheckBoxValue(CheckBoxValue.filter(v => v !== e[selectedIdName]))
                                }
                            }}
                            checked={CheckBoxValue.includes(e[selectedIdName])}
                            disabled={disabled}
                        >
                            {e[displayValue]}
                        </Checkbox>
                    </div>
                ))}
            </div>
        </>
    )
}

export default CheckBoxGroup