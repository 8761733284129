import axios from "axios";
import {
  SUBSCRIPTION_SERVICES_ALERT_REQUEST,
  SUBSCRIPTION_SERVICES_ALERT_SUCCESS,
  SUBSCRIPTION_SERVICES_ALERT_FAIL,

  SUBSCRIPTION_SERVICES_LIST_REQUEST,
  SUBSCRIPTION_SERVICES_LIST_SUCCESS,
  SUBSCRIPTION_SERVICES_LIST_FAIL,

  SEND_SUBSCRIPTION_REQUEST,
  SEND_SUBSCRIPTION_SUCCESS,
  SEND_SUBSCRIPTION_FAIL,

  GET_SERVICE_DATA_REQUEST,
  GET_SERVICE_DATA_SUCCESS,
  GET_SERVICE_DATA_FAIL,
} from "../constants/subscriptionConstant";

import { API_ENDPOINT, API_ENDPOINT_AWS } from '../constants/apiEndpoint'
import { getAPI } from "../global/ApiManager";

export const alertSubscribeServices =
  (username) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBSCRIPTION_SERVICES_ALERT_REQUEST,
      });

      const config = {
        headers: {
          // 'Access-Control-Allow-Headers': '*',
          "Content-Type": "application/json",
          // 'X-USERNAME': `${username}`
        },
      };

      const { data } = await axios.get(
        `${API_ENDPOINT_AWS}/service/${username}`,
        config
      );

      dispatch({
        type: SUBSCRIPTION_SERVICES_ALERT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUBSCRIPTION_SERVICES_ALERT_FAIL,
        payload:
          error
            ? error.data.detail
            : error.message,
      });
    }
  };

export const listSubscribeServices = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUBSCRIPTION_SERVICES_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      params: { username: userInfo.email },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.token}`
      },
    };

    const { data } = await getAPI(config, `/service_manager/get_user_subscription_details/`, dispatch)

    dispatch({
      type: SUBSCRIPTION_SERVICES_LIST_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_SERVICES_LIST_FAIL,
      payload:
        error
          ? error?.data?.detail
          : error?.message,
    });
  }
};

export const sendSubscribeMail =
  (service_details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SEND_SUBSCRIPTION_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_ENDPOINT_AWS}/service/send-active-message/${userInfo?.email}`,
        service_details,
        config
      );

      dispatch({
        type: SEND_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SEND_SUBSCRIPTION_FAIL,
        payload:
          error
            ? error.data.detail
            : error.message,
      });
    }
  };

export const getServicesDetail = (service_name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_SERVICE_DATA_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${API_ENDPOINT_AWS}/service/get-service-detail/${userInfo?.email}/${service_name}`,
      config
    );

    dispatch({
      type: GET_SERVICE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SERVICE_DATA_FAIL,
      payload:
        error
          ? error.data.detail
          : error.message,
    });
  }
};
