import axios from 'axios'
import {
    GET_MASTER_LIST_USER_DATA_REQUEST,
    GET_MASTER_LIST_USER_DATA_SUCCESS,
    GET_MASTER_LIST_USER_DATA_FAIL,

    POST_MASTER_LIST_USER_DATA_REQUEST,
    POST_MASTER_LIST_USER_DATA_SUCCESS,
    POST_MASTER_LIST_USER_DATA_FAIL,

    UPDATE_MASTER_LIST_USER_DATA_REQUEST,
    UPDATE_MASTER_LIST_USER_DATA_SUCCESS,
    UPDATE_MASTER_LIST_USER_DATA_FAIL,

    DELETE_MASTER_LIST_USER_DATA_REQUEST,
    DELETE_MASTER_LIST_USER_DATA_SUCCESS,
    DELETE_MASTER_LIST_USER_DATA_FAIL,

} from '../../constants/organizationConstant/masterListConstant'

import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { deleteAPI, getAPI, postAPI, putAPI } from '../../global/ApiManager';


export const getMasterListUserData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_MASTER_LIST_USER_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/master_list_api/`, dispatch)

        dispatch({
            type: GET_MASTER_LIST_USER_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_MASTER_LIST_USER_DATA_FAIL,
            error: error
                ? error.data.detail
                : error.message,
        })
    }
};


export const postMasterListUserData = (postData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_MASTER_LIST_USER_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/master_list_api/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: POST_MASTER_LIST_USER_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_MASTER_LIST_USER_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const updateMasterListUserData = (postData, userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_MASTER_LIST_USER_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await putAPI(
            `/app/master_list_api/${userId}/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: UPDATE_MASTER_LIST_USER_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: UPDATE_MASTER_LIST_USER_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};

export const deleteMasterListUserData = (userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_MASTER_LIST_USER_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/app/master_list_api/${userId}`,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_MASTER_LIST_USER_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_MASTER_LIST_USER_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const getUserDepartment = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/department_api/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}


export const getUserLocation = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/location_api/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}

export const createUserDepartment = async (token, department) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        await axios.post(
            `${API_ENDPOINT}/app/department_api/`,
            { department: department },
            config
        )

        return { 'status': 200 }

    } catch (error) {
        return { 'status': 400 }

    }
}

export const createUserLocation = async (token, location) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        await axios.post(
            `${API_ENDPOINT}/app/location_api/`,
            { location: location },
            config
        )

        return { 'status': 200 }

    } catch (error) {
        return { 'status': 400 }

    }
}


export const uploadMasterListUsersCSVFile = async (token, file) => {
    try {
        const formData = new FormData();
        formData.append("csv_file", file);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        await axios.post(
            `${API_ENDPOINT}/app/master_list_api/`,
            formData,
            config
        )

        return { status: 200 }

    } catch (error) {
        return { 'error': error, status: 400 }

    }
}