import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Button } from "antd";
import LabelTooltip from './LabelTooltip';
import BackupTimePeriod from './BackupTimePeriod';
import Modal from 'react-bootstrap/Modal';
import SetNetworkModal from './SetNetworkModal';
import ExportBackupModal from './ExportBackupModal';
import BackupHistory from './BackupHistory';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBackupAndRestoreData, putBackupAndRestoreData } from '../../../actions/organizationActions/backupAndRestoreActions';
import { DefaultUpdateAlerts, CommonErrorAlert } from '../../../components/ToastAlert'
import { PUT_BACKUP_AND_RESTORE_DATA_RESET } from '../../../constants/organizationConstant/backupAndRestoreConstant';
import { getProgressBarColor, sortObjectByKey } from '../../../global/Helper';



function BackupAndRestore() {

    const dispatch = useDispatch();

    const [BackupRetentionValue, setBackupRetentionValue] = useState('');
    const [NetworkModalShow, setNetworkModalShow] = useState(false);
    const [ExportBackupModalShow, setExportBackupModalShow] = useState(false);
    const [FreeStorage, setFreeStorage] = useState('');
    const [BackupStatus, setBackupStatus] = useState(false);
    const [Retention, setRetention] = useState(false);
    const [NetworkDeriveData, setNetworkDeriveData] = useState(false);
    const [BackupPeriodData, setBackupPeriodData] = useState({});
    const [FreeStorageContent, setFreeStorageContent] = useState("");
    const [UsedStorageContent, setUsedStorageContent] = useState("");
    const [DisabledButton, setDisabledButton] = useState(false);
    const [BackupPeriodTime, setBackupPeriodTime] = useState('');
    const [Week, setWeek] = useState([]);
    const [BackupPeriod, setBackupPeriod] = useState(["Hourly"]);

    const BackupAndRestoreDataGet = useSelector((state) => state.BackupAndRestoreDataGet);
    const { /*error: getBackupAndRestoreError,*/ loading: getBackupAndRestoreLoad, success: getBackupAndRestoreSuccess } = BackupAndRestoreDataGet;

    const BackupAndRestoreDataPut = useSelector((state) => state.BackupAndRestoreDataPut);
    const { error: putBackupAndRestoreError, loading: putBackupAndRestoreLoad, success: putBackupAndRestoreSuccess } = BackupAndRestoreDataPut;

    useEffect(() => {
        dispatch(getBackupAndRestoreData())
    }, [])

    const timeOption = [
        "Hourly", "Daily", "Weekly", "Monthly"
    ]

    useEffect(() => {
        if (getBackupAndRestoreLoad === false) {
            setFreeStorage(getBackupAndRestoreSuccess?.[0]?.storage_info?.used_storage?.split(/[()]/)[1].split("%")[0])
            setBackupStatus(getBackupAndRestoreSuccess?.[0]?.backup_status)
            setRetention(getBackupAndRestoreSuccess?.[0]?.retention)
            setBackupRetentionValue(getBackupAndRestoreSuccess?.[0]?.retention)
            setNetworkDeriveData(getBackupAndRestoreSuccess?.[0]?.network_drive)
            setUsedStorageContent(getBackupAndRestoreSuccess?.[0]?.storage_info?.used_storage)
            setFreeStorageContent(getBackupAndRestoreSuccess?.[0]?.storage_info?.free_storage)
        }
    }, [getBackupAndRestoreSuccess])

    useEffect(() => {
        if (putBackupAndRestoreSuccess) {
            dispatch(getBackupAndRestoreData())
            dispatch({ type: PUT_BACKUP_AND_RESTORE_DATA_RESET })
            toast(<DefaultUpdateAlerts name={"Backup And Restore"} />);
        }
        if (putBackupAndRestoreError) {
            toast(<CommonErrorAlert message={putBackupAndRestoreError} />);
            dispatch({ type: PUT_BACKUP_AND_RESTORE_DATA_RESET })
        }
    }, [dispatch, putBackupAndRestoreSuccess, putBackupAndRestoreError])

    const OnChangeBackupRetention = (e) => {
        if (Number.isInteger(Number(e.target.value))) {
            if ((e.target.value).trim().length > 0) {
                setBackupRetentionValue(Number(e.target.value))
            } else {
                setBackupRetentionValue('')
            }
        }
    }

    const formData = useMemo(() => ({
        "backup_status": BackupStatus,
        "period": BackupPeriodData,
        "retention": Retention ? BackupRetentionValue : Retention,
    }), [BackupStatus, BackupPeriodData, Retention, BackupRetentionValue, NetworkDeriveData])

    useEffect(() => {
        setDisabledButton(true)
        let getBackupAndRestoreData = { ...getBackupAndRestoreSuccess?.[0] }
        delete getBackupAndRestoreData?.backup_history
        delete getBackupAndRestoreData?.id
        delete getBackupAndRestoreData?.storage_info
        delete getBackupAndRestoreData?.file_path
        delete getBackupAndRestoreData?.network_drive

        if (BackupPeriodTime.length > 0 && String(BackupRetentionValue).length > 0 && JSON.stringify(sortObjectByKey(formData)) !== JSON.stringify(sortObjectByKey(getBackupAndRestoreData))) {
            setDisabledButton(false)
        }
        if (BackupPeriod[0] === "Weekly" && Week.length === 0) {
            setDisabledButton(true)
        }
    }, [formData, getBackupAndRestoreSuccess])

    const BackupAndRestoreOnSubmit = (NetworkData) => {
        const submitFormData = { ...formData }
        submitFormData["network_drive"] = NetworkData === undefined ? NetworkDeriveData : NetworkData
        dispatch(putBackupAndRestoreData(submitFormData, getBackupAndRestoreSuccess?.[0]?.id))
    }

    return (
        <div className='backup-and-restore-container'>
            <div className="d-flex align-items-center flex-wrap gap-2">
                <div className='d-flex align-items-center gap-2 mb-2 mb-lg-0'>
                    <div className='organization-headings text-nowrap'>Backup And Restore</div>
                    <Switch
                        checked={BackupStatus}
                        size="small"
                        onChange={(e) => setBackupStatus(e)}
                    />
                </div>
                <div className="ms-auto">
                    <button
                        type="button"
                        className="d-flex align-items-center btn-light-grey btn"
                        onClick={() => setExportBackupModalShow(true)}>
                        <i className="fas fa-file-export me-2"></i>
                        Export
                    </button>
                </div>
                <BackupHistory
                    data={getBackupAndRestoreSuccess?.[0]?.backup_history}
                />
            </div>
            <div className='mt-4 mw-100 w-75 storage-path'>
                <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center'>
                        <label className='backup-input-label text-nowrap'>Storage Location</label>
                        <LabelTooltip content={"Select the file location for backup storage to ensure secure and accessible data backups."} placement={"top"} />
                    </div>
                    {NetworkDeriveData ? (
                        <div
                            className='ms-auto network-location-info cursor-pointer text-nowrap'
                            style={{ textDecoration: "underline" }}
                            onClick={() => BackupAndRestoreOnSubmit(false)}
                        >
                            Set Default Location
                        </div>
                    ) : (
                        <div className='ms-auto network-location-info text-truncate'>Data will be store in default location if network is not configured</div>
                    )}
                </div>
                <div className='bg-light-grey border border_radius_sm bold text-truncate'>
                    <button
                        className='bg-dark btn text-white fs-medium me-2 py-2'
                        onClick={() => setNetworkModalShow(true)}
                    >
                        {NetworkDeriveData ? (
                            <>
                                <i className="fal fa-pencil fs-6 bold me-2"></i>
                                Edit Network
                            </>
                        ) : (
                            <>
                                <i className="fas fa-plus me-2"></i>
                                Set Network
                            </>
                        )}
                    </button>
                    {!NetworkDeriveData && (
                        <span className='opacity'>Default Location :</span>
                    )}
                    {getBackupAndRestoreSuccess?.[0]?.file_path}
                </div>
                {(FreeStorage && !NetworkDeriveData) && (
                    <div className="storage-progress">
                        <div className="progress mt-2" style={{ height: "5px" }}>
                            <div className={`progress-bar ${getProgressBarColor(FreeStorage)}`} role="progressbar" style={{ width: `${FreeStorage}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className='d-flex'>
                            <div className='storage-text'>{UsedStorageContent}</div>
                            <div className='ms-auto storage-text'>{FreeStorageContent}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className='mt-4'>
                <div className='me-auto d-flex align-items-center'>
                    <label className='backup-input-label'>Backup Period and Time</label>
                    <LabelTooltip content={"Customize the backup period to your preference."} placement={"top"} />
                </div>
                <BackupTimePeriod
                    value={getBackupAndRestoreSuccess?.[0]?.period}
                    onBackupPeriodChange={(e) => setBackupPeriodData(e)}
                    Week={Week}
                    setWeek={setWeek}
                    BackupPeriodTime={BackupPeriodTime}
                    setBackupPeriodTime={setBackupPeriodTime}
                    BackupPeriod={BackupPeriod}
                    setBackupPeriod={setBackupPeriod}
                    timeOption={timeOption}
                />
            </div>
            <div className='mt-4'>
                <div className='d-flex gap-5'>
                    <div style={{ width: "285px" }}>
                        <div className='d-flex align-items-center'>
                            <div className='me-auto d-flex align-items-center'>
                                <label className='backup-input-label'>Backup Retention in days</label>
                                <LabelTooltip content={"If the number of data files exceeds this limit, the system will automatically delete the oldest events to maintain the specified storage capacity."} placement={"top"} />
                            </div>
                            <Switch
                                checked={Retention}
                                size="small"
                                onChange={(e) => setRetention(e)}
                            />
                        </div>
                        <input
                            disabled={!Retention}
                            type="text"
                            className={`bg-light-grey border rounded p-2 text-truncate w-100 ${!Retention && 'is-disable'}`}
                            placeholder='Enter No. for Backup Retention'
                            maxLength="3"
                            value={BackupRetentionValue || ''}
                            onChange={OnChangeBackupRetention}
                        />
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                {putBackupAndRestoreLoad ? (
                    <Button type="primary" className='bg-warning border text-dark border_radius_sm' size="large" loading>
                        Save
                    </Button>
                ) : (
                    <button
                        disabled={DisabledButton}
                        className='btn bg-warning px-3'
                        onClick={() => BackupAndRestoreOnSubmit()}
                    >
                        Save
                    </button>
                )}
            </div>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={NetworkModalShow}
                onHide={() => setNetworkModalShow(false)}
            >
                <Modal.Header className="position-relative justify-content-center border-none px-4" closeButton>
                    <Modal.Title className="fs-4">
                        Setup IP Address
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <SetNetworkModal
                        formDefaultValue={NetworkDeriveData}
                        onFormSubmit={(e) => BackupAndRestoreOnSubmit(e)}
                        onHide={() => setNetworkModalShow(false)}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ExportBackupModalShow}
                onHide={() => setExportBackupModalShow(false)}
            >
                <Modal.Body className='p-4'>
                    <ExportBackupModal
                        CloseExportBackupModalShow={() => setExportBackupModalShow(false)}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BackupAndRestore