import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import ServiceType from './analyticsSetupComponents/ServiceType';
import SelectCamera from './analyticsSetupComponents/SelectCamera';
import NotificationType from './analyticsSetupComponents/NotificationType';
import { DefaultAddAlert, CommonErrorAlert, DefaultUpdateAlerts } from '../../components/ToastAlert'
import { toast } from "react-toastify";
import { ALERT_MECHANISM_DATA_POST_RESET, ALERT_MECHANISM_DATA_UPDATE_RESET } from '../../constants/alertMechanismConstant'
import { useDispatch, useSelector } from "react-redux";
import { postAlertMechanismData, getListAlertMechanismData, updateAlertMechanismData } from '../../actions/alertMechanismActions'
import { Button } from 'antd';
import { emailValidator, capitalizeFirstLater, dummyNotificationData } from '../../global/Helper'



function InsightsSetupModal(props) {
    const [current, setCurrent] = useState(0);
    const [configureValue, setConfigureValue] = useState("");
    const [accuracy, setAccuracy] = useState("");
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [serviceHandler, setServiceHandler] = useState([]);
    const [serviceValueHandler, setServiceValueHandler] = useState([]);
    const [SelectedCamera, setSelectedCamera] = useState([]);
    const [addNewUser, setAddNewUser] = useState([{ ...dummyNotificationData }]);
    const [refresh, setComponentsRefresh] = useState(0);
    const [notificationUserName, setNotificationUserName] = useState('');
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();

    const closeInsightsSetupModalShow = () => props.closeInsightsSetupModalShow()
    const analyticConfigId = props.analyticConfigId
    const analyticConfigName = props.analyticConfigName
    const token = props.token

    const alertMechanismPostData = useSelector((state) => state.alertMechanismPostData);
    const { loading: alertMechPostLoad, error: alertMechPostError, success: alertMechPostSuccess } = alertMechanismPostData;

    const alertMechanismUpdateData = useSelector((state) => state.alertMechanismUpdateData);
    const { loading: alertMechUpdateLoad, error: alertMechUpdateError, success: alertMechUpdateSuccess } = alertMechanismUpdateData;

    const analyticsSingleDataGet = useSelector((state) => state.analyticsSingleDataGet);
    const { success: getSingleAnalyticSuccess } = analyticsSingleDataGet;

    const accuracyBackendDetector = {
        70: 0,
        80: 33,
        90: 66,
        100: 100,
    };

    useEffect(() => {
        if (getSingleAnalyticSuccess) {
            setConfigureValue(getSingleAnalyticSuccess.config_name)
            setAccuracy(accuracyBackendDetector[getSingleAnalyticSuccess.accuracy])
            setServiceHandler([{ service_name: getSingleAnalyticSuccess.service_name, id: getSingleAnalyticSuccess.service_id }])
            setServiceValueHandler(getSingleAnalyticSuccess.value_name)
            setSelectedCamera(getSingleAnalyticSuccess.camera_id)
            setAddNewUser(getSingleAnalyticSuccess.user_name_to_send)
        }
    }, [getSingleAnalyticSuccess])


    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        setDisableNextButton(true)
        if (current === 0 && serviceHandler?.length > 0 && ((String(serviceValueHandler).length > 0) && configureValue.trim().length > 0)) {
            setDisableNextButton(false)
        }
        if (current === 1 && SelectedCamera?.length > 0) {
            setDisableNextButton(false)
        }
        if (current === 2) {
            for (const element of addNewUser) {
                const e = element;

                if (e.to_user_name.trim() !== ''
                    && (emailValidator.test(e.alert_email) || e.alert_api?.length > 0 || String(e.alert_whatsapp)?.length > 0)
                    && (
                        e.on_time_alerts ||
                        (e.report_frequency?.daily?.status && e.report_frequency?.daily?.time?.length > 0) ||
                        (e.report_frequency?.weekly?.status && e.report_frequency?.weekly?.time?.length > 0 && e.report_frequency?.weekly?.day?.length > 0) ||
                        (e.report_frequency?.monthly?.status && e.report_frequency?.monthly?.time?.length > 0)
                    )
                ) {
                    setDisableNextButton(false);
                } else {
                    setDisableNextButton(true);
                    break;
                }

                if (e.report_frequency?.daily?.status) {
                    if (!e.pdf_file && !e.excel_file) {
                        setDisableNextButton(true);
                        break;
                    }
                    if (e.report_frequency.daily.status && !e.report_frequency.daily.time) {
                        setDisableNextButton(true);
                        break;
                    }
                }

                if (e.report_frequency?.weekly?.status) {
                    if (!e.pdf_file && !e.excel_file) {
                        setDisableNextButton(true);
                        break;
                    }
                    if (e.report_frequency.weekly.status && !e.report_frequency.weekly.time) {
                        setDisableNextButton(true);
                        break;
                    }
                    if (e.report_frequency.weekly.status && !e.report_frequency.weekly.day) {
                        setDisableNextButton(true);
                        break;
                    }
                }

                if (e.report_frequency?.monthly?.status) {
                    if (!e.pdf_file && !e.excel_file) {
                        setDisableNextButton(true);
                        break;
                    }
                    if (e.report_frequency.monthly.status && !e.report_frequency.monthly.time) {
                        setDisableNextButton(true);
                        break;
                    }
                }
            }
        }
    }, [configureValue, serviceHandler, serviceValueHandler, SelectedCamera, addNewUser, current, refresh])

    useEffect(() => {
        if (alertMechPostSuccess) {
            dispatch(getListAlertMechanismData())
            closeInsightsSetupModalShow()
            toast(<DefaultAddAlert name={configureValue} />);
            dispatch({ type: ALERT_MECHANISM_DATA_POST_RESET })
        }
        if (alertMechPostError) {
            toast(<CommonErrorAlert message={alertMechPostError} />);
            dispatch({ type: ALERT_MECHANISM_DATA_POST_RESET })
        }
        if (alertMechUpdateError) {
            toast(<CommonErrorAlert message={alertMechUpdateError} />);
            dispatch({ type: ALERT_MECHANISM_DATA_UPDATE_RESET })
        }
        if (alertMechUpdateSuccess) {
            dispatch(getListAlertMechanismData())
            closeInsightsSetupModalShow()
            dispatch({ type: ALERT_MECHANISM_DATA_UPDATE_RESET })
            toast(<DefaultUpdateAlerts name={configureValue} />);
        }
    }, [alertMechPostSuccess, dispatch, alertMechPostError, alertMechUpdateError, alertMechUpdateSuccess])

    const accuracyFrontendDetector = {
        0: 70,
        33: 80,
        66: 90,
        100: 100,
    };

    const analyticSubmitHandler = () => {
        const duplicateUsers = addNewUser.filter(e => e.to_user_name.toLowerCase() === notificationUserName.toLowerCase())
        const duplicateEmail = addNewUser.filter(e => e.alert_email.toLowerCase() === email.toLowerCase())

        if (duplicateUsers.length > 1) {
            toast(<CommonErrorAlert
                message={<><b>{capitalizeFirstLater(notificationUserName)}</b> user name is duplicate.</>}
            />);
        } else if (email.length > 1 && duplicateEmail.length > 1) {
            toast(<CommonErrorAlert
                message={<><b>{email}</b> email is duplicate.</>}
            />);
        }
        else {
            const postInsightSetupData = {
                config_name: configureValue,
                service_name: serviceHandler?.[0]?.service_name,
                service_id: serviceHandler?.[0]?.id,
                value_name: serviceValueHandler,
                accuracy: accuracyFrontendDetector[accuracy],
                camera_id: SelectedCamera,
                user_name_to_send: addNewUser,
            }
            if (analyticConfigName) {
                dispatch(postAlertMechanismData(postInsightSetupData))
                return true
            }
            if (getSingleAnalyticSuccess) {
                dispatch(updateAlertMechanismData(postInsightSetupData, analyticConfigId))
            } else {
                dispatch(postAlertMechanismData(postInsightSetupData))
            }
        }
    }

    const steps = [
        {
            title: 'Add Configuration and Service',
            content: <ServiceType
                setConfigureValue={(e) => setConfigureValue(e)}
                configureValue={configureValue}
                setServiceHandler={(e) => setServiceHandler(e)}
                serviceHandler={serviceHandler}
                setServiceValueHandler={(e) => setServiceValueHandler(e)}
                serviceValueHandler={serviceValueHandler}
                setAccuracy={e => setAccuracy(e)}
                accuracy={accuracy}
                token={token}
            />,
        },
        {
            title: 'Add Camera',
            content: <SelectCamera
                selectedService={serviceHandler}
                SelectedCamera={SelectedCamera}
                setSelectedCamera={(e) => setSelectedCamera(e)}
                userAccess={false}
            />,
        },
        {
            title: 'Notifications',
            content: <NotificationType
                setComponentsRefresh={() => setComponentsRefresh(key => key + 1)}
                allNotificationLength={addNewUser?.length}
                setAddNewUser={e => setAddNewUser(e)}
                addNewUser={addNewUser}
                analyticConfigId={analyticConfigId}
                setNotificationUserName={e => setNotificationUserName(e)}
                setEmail={e => setEmail(e)}
            />,
        },
    ];

    return (
        <>
            <Modal.Header className="position-relative justify-content-center insight-header" closeButton>
                <Modal.Title className="fs-4">
                    {analyticConfigName ? `Create ${analyticConfigName} Duplicate Insights Setup` : "Insights Setup"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="alert-enter-container">
                <div className="row stepper-container mt-4">
                    {steps?.map((element, index) => (
                        <div key={element.title} className={`col-md-4 mb-md-0 mb-3 px-1 ${getSingleAnalyticSuccess && "cursor-pointer"}`} onClick={() => getSingleAnalyticSuccess && setCurrent(index)}>
                            <h6 className='text-nowrap'>{index + 1}.{" "}{element.title}</h6>
                            <div className='insight-progress-bar'>
                                <div className={current + 1 > index ? "insight-progress-bar-fill active" : "insight-progress-bar-fill"}></div>
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{ height: "calc(100% - 53px)" }}>{steps[current].content}</div>

            </Modal.Body>
            <Modal.Footer className='insight-btn-container'>
                <div>
                    {current > 0 ? (

                        <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => prev()}>
                            Previous
                        </button>
                    ) : (
                        <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => closeInsightsSetupModalShow()}>
                            Cancel
                        </button>
                    )}
                    {current < steps.length - 1 && (
                        <button
                            className="btn px-4 py-2 bg-warning border text-dark"
                            type='submit'
                            disabled={disableNextButton}
                            onClick={() => next()}
                        >
                            Next
                        </button>
                    )}
                    {current === steps.length - 1 && (
                        alertMechPostLoad || alertMechUpdateLoad ? (
                            <Button type="primary" className='bg-warning border text-dark' size="large" loading>
                                Complete
                            </Button>
                        ) : (
                            <button
                                className="btn px-4 py-2 bg-warning border text-dark"
                                type='submit'
                                disabled={disableNextButton}
                                onClick={analyticSubmitHandler}
                            >
                                Complete
                            </button>
                        )
                    )}

                </div>
            </Modal.Footer>
        </>
    )
}

export default InsightsSetupModal