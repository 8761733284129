import {
    GET_ANALYTICS_SERVICE_REQUEST,
    GET_ANALYTICS_SERVICE_SUCCESS,
    GET_ANALYTICS_SERVICE_FAIL,

    POST_ANALYTICS_FILTER_DATA_REQUEST,
    POST_ANALYTICS_FILTER_DATA_SUCCESS,
    POST_ANALYTICS_FILTER_DATA_FAIL,
    POST_ANALYTICS_FILTER_DATA_RESET,

    GET_SINGLE_ANALYTICS_DATA_REQUEST,
    GET_SINGLE_ANALYTICS_DATA_SUCCESS,
    GET_SINGLE_ANALYTICS_DATA_FAIL,
    GET_SINGLE_ANALYTICS_DATA_RESET,
} from '../constants/analyticsConstant'



export const getAnalyticsServicesReducer = (state = { success: {} }, action) => {
    switch (action.type) {
        case GET_ANALYTICS_SERVICE_REQUEST:
            return { loading: true, success: {} };

        case GET_ANALYTICS_SERVICE_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_ANALYTICS_SERVICE_FAIL:
            return { loading: false, error: action.error };


        default:
            return state;
    }
};


export const postAnalyticsFilterDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case POST_ANALYTICS_FILTER_DATA_REQUEST:
            return { loading: true, success: [] };

        case POST_ANALYTICS_FILTER_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case POST_ANALYTICS_FILTER_DATA_FAIL:
            return { loading: false, error: action.error };

        case POST_ANALYTICS_FILTER_DATA_RESET:
            return {};

        default:
            return state;
    }
};


export const getSingleAnalyticsDataReducer = (state = { success: {} }, action) => {
    switch (action.type) {
        case GET_SINGLE_ANALYTICS_DATA_REQUEST:
            return { loading: true };

        case GET_SINGLE_ANALYTICS_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_SINGLE_ANALYTICS_DATA_FAIL:
            return { loading: false, error: action.error };

        case GET_SINGLE_ANALYTICS_DATA_RESET:
            return {};

        default:
            return state;
    }
};