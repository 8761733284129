import React, { useState } from 'react'

function TextToCopy({ text, className, style }) {

    const [IsCopy, setIsCopy] = useState(false);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setIsCopy(true)

                setTimeout(() => {
                    setIsCopy(false)
                }, 1500);
            })
            .catch((error) => {
                setIsCopy(false)
                console.error('Failed to copy:', error);
            });
    };

    return (
        <>
            <div className='text-truncate me-1 cursor-pointer' onClick={() => handleCopy(text)}>
                {text}
            </div>
            {IsCopy && (
                <div className={`position-absolute top-0 end-0 ${className}`}>Copied</div>
            )}
            <i className={`cursor-pointer ${IsCopy ? 'fas fa-clipboard' : 'far fa-clipboard'} ${className}`} style={style} onClick={() => handleCopy(text)}></i>
        </>
    )
}

export default TextToCopy