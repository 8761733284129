export const GET_ANALYTICS_SERVICE_REQUEST = 'GET_ANALYTICS_SERVICE_REQUEST'
export const GET_ANALYTICS_SERVICE_SUCCESS = 'GET_ANALYTICS_SERVICE_SUCCESS'
export const GET_ANALYTICS_SERVICE_FAIL = 'GET_ANALYTICS_SERVICE_FAIL'


export const POST_ANALYTICS_FILTER_DATA_REQUEST = 'POST_ANALYTICS_FILTER_DATA_REQUEST'
export const POST_ANALYTICS_FILTER_DATA_SUCCESS = 'POST_ANALYTICS_FILTER_DATA_SUCCESS'
export const POST_ANALYTICS_FILTER_DATA_FAIL = 'POST_ANALYTICS_FILTER_DATA_FAIL'
export const POST_ANALYTICS_FILTER_DATA_RESET = 'POST_ANALYTICS_FILTER_DATA_RESET'


export const GET_SINGLE_ANALYTICS_DATA_REQUEST = 'GET_SINGLE_ANALYTICS_DATA_REQUEST'
export const GET_SINGLE_ANALYTICS_DATA_SUCCESS = 'GET_SINGLE_ANALYTICS_DATA_SUCCESS'
export const GET_SINGLE_ANALYTICS_DATA_FAIL = 'GET_SINGLE_ANALYTICS_DATA_FAIL'
export const GET_SINGLE_ANALYTICS_DATA_RESET = 'GET_SINGLE_ANALYTICS_DATA_RESET'
