export const ALERT_MECHANISM_DATA_GET_REQUEST = 'ALERT_MECHANISM_DATA_GET_REQUEST'
export const ALERT_MECHANISM_DATA_GET_SUCCESS = 'ALERT_MECHANISM_DATA_GET_SUCCESS'
export const ALERT_MECHANISM_DATA_GET_FAIL = 'ALERT_MECHANISM_DATA_GET_FAIL'


export const ALERT_MECHANISM_DATA_POST_REQUEST = 'ALERT_MECHANISM_DATA_POST_REQUEST'
export const ALERT_MECHANISM_DATA_POST_SUCCESS = 'ALERT_MECHANISM_DATA_POST_SUCCESS'
export const ALERT_MECHANISM_DATA_POST_FAIL = 'ALERT_MECHANISM_DATA_POST_FAIL'
export const ALERT_MECHANISM_DATA_POST_RESET = 'ALERT_MECHANISM_DATA_POST_RESET'


export const ALERT_MECHANISM_DATA_GET_LIST_REQUEST = 'ALERT_MECHANISM_DATA_GET_LIST_REQUEST'
export const ALERT_MECHANISM_DATA_GET_LIST_SUCCESS = 'ALERT_MECHANISM_DATA_GET_LIST_SUCCESS'
export const ALERT_MECHANISM_DATA_GET_LIST_FAIL = 'ALERT_MECHANISM_DATA_GET_LIST_FAIL'


export const ALERT_MECHANISM_DATA_DELETE_REQUEST = 'ALERT_MECHANISM_DATA_DELETE_REQUEST'
export const ALERT_MECHANISM_DATA_DELETE_SUCCESS = 'ALERT_MECHANISM_DATA_DELETE_SUCCESS'
export const ALERT_MECHANISM_DATA_DELETE_FAIL = 'ALERT_MECHANISM_DATA_DELETE_FAIL'
export const ALERT_MECHANISM_DATA_DELETE_RESET = 'ALERT_MECHANISM_DATA_DELETE_RESET'


export const ALERT_MECHANISM_DATA_UPDATE_REQUEST = 'ALERT_MECHANISM_DATA_UPDATE_REQUEST'
export const ALERT_MECHANISM_DATA_UPDATE_SUCCESS = 'ALERT_MECHANISM_DATA_UPDATE_SUCCESS'
export const ALERT_MECHANISM_DATA_UPDATE_FAIL = 'ALERT_MECHANISM_DATA_UPDATE_FAIL'
export const ALERT_MECHANISM_DATA_UPDATE_RESET = 'ALERT_MECHANISM_DATA_UPDATE_RESET'
