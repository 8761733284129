import React from 'react';
import pageNotFound from './images/403-access-denied.svg'
import { useNavigate } from "react-router-dom";

function PageNotAccess() {

    let navigate = useNavigate();

    return (
        <div className='not-access-error-page'>
            <div className='d-flex flex-column align-items-center justify-content-center error-page-child-container'>
                <img src={pageNotFound} style={{ maxWidth: "415px" }} />
                <h3 className='fw-bolder mb-1 text-center'>Oops! Access Denied</h3>
                <p className='mb-1 text-center'>You are not authorized to access this Page. Please contact your administrator for help.</p>
                <div className="d-flex gap-3">
                    <button className='try-error-btn' type='primary' onClick={() => window.location.reload()}>Try Again</button>
                    <button className='error-btn' type='primary' onClick={() => navigate(-1)}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

export default PageNotAccess