import React, { useState } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import EditPasswordModal from "./EditPasswordModal";
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";

const borderBottom = {
  borderBottom: "1px solid rgba(51, 51, 51, 0.1)",
};

function UpdateProfileDetailInput({
  type,
  maxlength,
  disabled,
  label,
  placeholder,
  value,
  onBlur,
  onChange,
  onKeyDown,
  border = true,
  // onClickFunction
}) {

  const [editable, setEditable] = useState(true);
  const [editPasswordModalShow, setEditPasswordModalShow] = useState(false);

  const profileInput = document.getElementById(`update-profile-input-${label}`);

  const pageAccessGet = useSelector((state) => state.pageAccessGet);
  const { success: accessPage } = pageAccessGet;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const windowPath = window.location.pathname?.replaceAll("/", "")
  const pageAccess = accessPage?.[`${windowPath}`]

  const inputHandler = () => {
    setEditable(!editable);
    if (editable) {
      profileInput.disabled = false;
      profileInput.focus();
    } else {
      profileInput.disabled = true;
    }
  };

  const closeUpdate = () => {
    if (editable) {
      profileInput.disabled = false;
      profileInput.focus();
    } else {
      profileInput.disabled = true;
    }
  };

  return (
    <>
      <div className="py-1" style={border ? borderBottom : null}>
        <Form.Group as={Row}>
          <Form.Label
            column
            sm="2"
            className="fw-bold d-flex align-items-center"
          >
            {label}
          </Form.Label>
          <Col sm="8" className="d-flex align-items-center">
            <Form.Control
              type={type}
              // onFocus={(e) => e.target.select()}
              onChange={onChange}
              onBlur={(e) => {
                onBlur(e);
                profileInput.disabled = true;
                setEditable(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // onKeyDown(e)
                  profileInput.disabled = true;
                  setEditable(true);
                  return false
                }
              }}
              style={{ background: "#ede6e6" }}
              maxLength={maxlength}
              className="edit-profile-input"
              size="md"
              id={`update-profile-input-${label}`}
              placeholder={placeholder}
              value={value || ''}
              disabled
            />
          </Col>
          <Col
            sm="2"
            className="text-center d-flex align-items-center justify-content-end" style={{ justifyContent: !editable ? "flex-end" : "center" }}
          >
            {pageAccess?.['update'].includes(userInfo?.user_role) && (
              !disabled ? (
                <div className="">
                  {!editable && label !== "Password" ? <div className="UpdateButton ">
                    <button className="closeBtn btn_update" onClick={() => {
                      setEditable(!editable);
                      closeUpdate();
                    }}>
                      <CloseOutlined className="closeIcon_u" />
                    </button>
                  </div> : <button
                    className="edit-profile-btn"
                    type="button"
                    onClick={() => {
                      label === "Password"
                        ? setEditPasswordModalShow(true)
                        : inputHandler();
                    }}
                  >
                    <i className="far fa-edit" />
                  </button>

                  }

                </div>

              ) : (
                <button className="edit-profile-btn not-allowed" type="button" >
                  <i className="far fa-edit" />
                </button>
              )
            )}
          </Col>
        </Form.Group>
      </div>
      <Modal
        scrollable={false}
        show={editPasswordModalShow}
        onHide={() => {
          setEditPasswordModalShow(false);
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <EditPasswordModal
            closeEditPasswordModal={(e) =>
              e === true && setEditPasswordModalShow(false)
            }
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateProfileDetailInput;
