import React, { useState, useEffect, useMemo, Fragment } from "react";
import Header from "../../components/Header";
import "./analytics.css";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { exportAnalyticsReport, getAnalyticsCustomBarChartData, getAnalyticsServices, getFilteredServices } from '../../actions/analyticsActions'
import AnalyticsFIlter from './analyticsComponents/AnalyticsFIlter'
import Multiselect from 'multiselect-react-dropdown';
import PageNotAccess from '../404PageNotFound/PageNotAccess';
import { Button, Radio } from 'antd';
import ReportSummaryCard from "./analyticsComponents/ReportSummaryCard";
import bucketImage from '../../images/report-summay/bucket-image.svg';
import cameraImage from '../../images/report-summay/camera-image.svg';
import barImage from '../../images/report-summay/bar-image.svg';
import BarChart from './analyticsComponents/BarChart';
import DoughnutChart from "./analyticsComponents/DoughnutChart";
import DateTimeRangeModal from './analyticsComponents/DateTimeRangeModal';
import CompareFilterButton from "./analyticsComponents/CompareFilterButton";
import { reportSummaryColors, reportSummaryDoughnutColors } from '../../global/Colors';
import dayjs from 'dayjs';
import { getFormattedDate, getFormattedDateTime, isObjectLike, monthNames } from '../../global/Helper';
import { getConfigServices } from '../../actions/organizationActions/rolesAndResponsibilityActions'
import AnalyticsLoading from "./analyticsComponents/AnalyticsLoading";
import noDataFound from '../../images/report-summay/no-data-found.gif'
import { CommonErrorAlert, CommonSuccessAlert } from "../../components/ToastAlert";
import { toast } from "react-toastify";
import { GET_ANALYTICS_SERVICE_REQUEST } from "../../constants/analyticsConstant";

function Analytics() {
    const dispatch = useDispatch();
    const [dateTimeRangeModalShow, setDateTimeRangeModalShow] = useState(false);
    const [DisabledBtn, setDisabledBtn] = useState(false);
    const [ReportDay, setReportDay] = useState('day');
    const [CalendarDate, setCalendarDate] = useState(0);
    const [servicesOption, setServicesOption] = useState([])
    const [ServiceName, setServiceName] = useState([])
    const [CustomDateTimeValue, setCustomDateTimeValue] = useState('')

    const [DateFilter, setDateFilter] = useState([`${dayjs().$y}-${dayjs().$M + 1}-${dayjs().$D}-0-0-00`, `${dayjs().$y}-${dayjs().$M + 1}-${dayjs().$D}-23-59-00`])
    const [Frequency, setFrequency] = useState('daily')

    const [CameraTypeFilter, setCameraTypeFilter] = useState('camera')
    const [CheckBoxCameraValue, setCheckBoxCameraValue] = useState([]);
    const [CheckBoxGroupValue, setCheckBoxGroupValue] = useState([]);
    const [CustomStartDate, setCustomStartDate] = useState(0);
    const [CustomEndDate, setCustomEndDate] = useState(0);
    const [CustomMonths, setCustomMonths] = useState([]);
    const [ChartMonths, setChartMonths] = useState(0);
    const [AnalyticsCustomBarChartData, setAnalyticsCustomBarChartData] = useState({});
    const [CamerasOption, setCamerasOption] = useState([]);
    const [CamerasGroupOption, setCamerasGroupOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clickTimeout, setClickTimeout] = useState(null);
    const [refreshComponent, setRefreshComponent] = useState(0);

    const analyticsGetServices = useSelector((state) => state.analyticsGetServices);
    const { loading: analyticsServiceLoad, success: analyticsService, error: analyticsServiceError } = analyticsGetServices;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        let ignore = false;
        async function startFetching() {
            setServicesOption([])

            let formData = {
                date_filter: DateFilter
            }

            const { data } = await getFilteredServices(formData, userInfo?.token)
            if (!ignore) {
                setServicesOption([{ service_name: "All Services", id: "#" }].concat(data))
            }
        }
        startFetching()
        return () => {
            ignore = true;
        };
    }, [DateFilter])

    const allServicesSelected = useMemo(() => ServiceName?.[0]?.service_name?.includes("All Services"), [ServiceName])

    useEffect(() => {
        let formData = {
            date_filter: DateFilter,
            frequency: Frequency,
            service_filter: (allServicesSelected === undefined || allServicesSelected === true) ? true : ServiceName?.[0]?.service_name,
        }

        if (CameraTypeFilter === 'camera') {
            formData.camera_filter = CheckBoxCameraValue.length > 0 ? CheckBoxCameraValue : true
        } else {
            formData.group_filter = CheckBoxGroupValue.length > 0 ? CheckBoxGroupValue : true
        }
        dispatch(getAnalyticsServices(formData));
    }, [dispatch, refreshComponent, Frequency, ServiceName, CheckBoxCameraValue, CheckBoxGroupValue, CameraTypeFilter]);

    const BarChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 2.9,
        barPercentage: 0.6,
        categoryPercentage: 0.7,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: "rgba(0, 0, 0, 0)",
                    offset: true
                }
            },
            y: {
                stacked: true,
                grid: {
                    color: "rgba(0, 0, 0, 0)",
                    offset: true
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                }
            },
        },
    };

    useEffect(() => {
        async function startFetching() {
            setAnalyticsCustomBarChartData({})
            if (ReportDay === "custom") {
                let formData = {
                    date_filter: [getFormattedDateTime(CustomMonths[ChartMonths], "month", 'start'), getFormattedDateTime(CustomMonths[ChartMonths], "month", 'end')],
                    frequency: "monthly",
                    service_filter: (allServicesSelected === undefined || allServicesSelected === true) ? true : ServiceName?.[0]?.service_name,
                }
                if (CameraTypeFilter === 'camera') {
                    formData.camera_filter = CheckBoxCameraValue.length > 0 ? CheckBoxCameraValue : true
                } else {
                    formData.group_filter = CheckBoxGroupValue.length > 0 ? CheckBoxGroupValue : true
                }

                const { data } = await getAnalyticsCustomBarChartData(formData, userInfo?.token)
                if (!ignore) {
                    setAnalyticsCustomBarChartData(data)
                }
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        };

    }, [ChartMonths, CustomMonths, ServiceName, CheckBoxCameraValue, CheckBoxGroupValue, CameraTypeFilter]);


    const BarChartData = useMemo(() => {
        if (ReportDay === "custom") {
            if (Object.keys(AnalyticsCustomBarChartData).length > 0) {

                const getKeys = Object.keys(AnalyticsCustomBarChartData?.bar_chart_data?.datasets)

                const getStartMonthIndex = AnalyticsCustomBarChartData?.bar_chart_data?.labels.indexOf(String(CustomStartDate))
                const getEndMonthIndex = AnalyticsCustomBarChartData?.bar_chart_data?.labels.indexOf(String(CustomEndDate)) + 1

                const labels = AnalyticsCustomBarChartData?.bar_chart_data?.labels;
                const datasets = getKeys?.map((e, index) => ({
                    label: e,
                    data: AnalyticsCustomBarChartData?.bar_chart_data?.datasets?.[e],
                    backgroundColor: getKeys.length === 1 ? reportSummaryColors[2] : reportSummaryColors[index],
                }));

                const startIndex = ChartMonths === 0 ? getStartMonthIndex : 0;
                const endIndex = CustomMonths.length === ChartMonths + 1 ? getEndMonthIndex : labels?.length;

                return {
                    labels: labels?.slice(startIndex, endIndex),
                    datasets: datasets?.map(dataset => ({
                        ...dataset,
                        data: dataset.data?.slice(startIndex, endIndex),
                    })),
                };
            }
        } else if (isObjectLike(analyticsService) && Object.keys(analyticsService)?.length > 0) {
            const getKeys = Object.keys(analyticsService?.bar_chart_data?.datasets)
            return ({
                labels: analyticsService?.bar_chart_data?.labels,
                datasets: getKeys?.map((e, index) => ({
                    label: e,
                    data: analyticsService?.bar_chart_data?.datasets[e],
                    backgroundColor: getKeys.length === 1 ? reportSummaryColors[2] : reportSummaryColors[index],
                }))
            })
        }
        return {}
    }, [analyticsServiceLoad, analyticsService, CustomStartDate, CustomEndDate, AnalyticsCustomBarChartData, ChartMonths])

    const doughnutData = useMemo(() => ({
        labels: analyticsService?.pie_chart_data?.map(e => e.camera_name),
        datasets: [
            {
                label: '# Events Generated by Cameras',
                data: analyticsService?.pie_chart_data?.map(e => e.total_events),
                hoverOffset: 10,
                backgroundColor: reportSummaryDoughnutColors,
                borderColor: ["#fff"],
                borderWidth: 3,
            },
        ],

    }), [analyticsService])

    const dateTimeObject = useMemo(() => dayjs().add(CalendarDate, ReportDay), [ReportDay, CalendarDate])

    useEffect(() => {
        setDisabledBtn(false)
        if (ReportDay === 'day') {
            setFrequency('daily')
            setDateFilter([getFormattedDateTime(dateTimeObject, ReportDay, 'start'), getFormattedDateTime(dateTimeObject, ReportDay, 'end')])
            if (dayjs().$d.toLocaleDateString() === dateTimeObject.$d.toLocaleDateString()) {
                setDisabledBtn(true)
            }
        }
        if (ReportDay === 'week') {
            setFrequency('weekly')
            setDateFilter([getFormattedDateTime(dateTimeObject, ReportDay, 'start'), getFormattedDateTime(dateTimeObject, ReportDay, 'end')])
            if (dayjs().startOf('week').$d.toLocaleDateString() === dateTimeObject.startOf('week').$d.toLocaleDateString()) {
                setDisabledBtn(true)
            }
        }
        if (ReportDay === 'month') {
            setFrequency('monthly')
            setDateFilter([getFormattedDateTime(dateTimeObject, ReportDay, 'start'), getFormattedDateTime(dateTimeObject, ReportDay, 'end')])
            if (dayjs().startOf('month').$d.toLocaleDateString() === dateTimeObject.startOf('month').$d.toLocaleDateString()) {
                setDisabledBtn(true)
            }
        }
    }, [ReportDay, dateTimeObject])

    function getMonthsBetweenDates(startDate, endDate) {
        const months = [];

        let currentMonth = new Date(startDate);

        while (currentMonth <= endDate) {
            months.push(dayjs(currentMonth))
            currentMonth.setDate(1);
            currentMonth.setMonth(currentMonth.getMonth() + 1);
        }

        return months;
    }

    const CustomDateFilterHandlerChange = (startCustomDate, endCustomDate) => {
        if (startCustomDate && endCustomDate) {
            const [H, M, , B] = startCustomDate.$d.toLocaleTimeString().split(/[: ]/)

            const [_H, _M, , _B] = endCustomDate.$d.toLocaleTimeString().split(/[: ]/)

            setCustomDateTimeValue(`${startCustomDate.$D} ${monthNames[startCustomDate.$M]}, ${startCustomDate.$y} : ${H}:${M} ${B} - ${endCustomDate.$D} ${monthNames[endCustomDate.$M]}, ${endCustomDate.$y} : ${_H}:${_M} ${_B}`)

            setDateFilter([`${startCustomDate.$y}-${startCustomDate.$M + 1}-${startCustomDate.$D}-${startCustomDate.$H}-${startCustomDate.$m}-00`, `${endCustomDate.$y}-${endCustomDate.$M + 1}-${endCustomDate.$D}-${endCustomDate.$H}-${endCustomDate.$m}-00`])
            setRefreshComponent(prev => prev + 1)
            setReportDay('custom')
            setFrequency('monthly')
            setDateTimeRangeModalShow(false)
            setCustomStartDate(startCustomDate.$D)
            setCustomEndDate(endCustomDate.$D)
            setCustomMonths(getMonthsBetweenDates(startCustomDate, endCustomDate))
            setChartMonths(0)
        }
    }

    const exportData = async () => {

        function formatDate(date) {
            if (date) {
                const [_year, _month, _day, _hours, _minutes, _seconds] = date.split('-');
                const dateTimeObject = new Date(_year, _month - 1, _day, _hours, _minutes, _seconds);

                const year = dateTimeObject.getFullYear();
                const month = String(dateTimeObject.getMonth() + 1).padStart(2, '0');
                const day = String(dateTimeObject.getDate()).padStart(2, '0');
                const hours = String(dateTimeObject.getHours()).padStart(2, '0');
                const minutes = String(dateTimeObject.getMinutes()).padStart(2, '0');
                const seconds = String(dateTimeObject.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
            }
        }
        setLoading(true)
        let formData = {
            start_date: formatDate(DateFilter[0]),
            end_date: formatDate(DateFilter[1]),
            frequency: Frequency,
            service_name: ServiceName?.[0]?.service_name,
        }

        if (CameraTypeFilter === 'camera') {
            formData.config_camera_id = CheckBoxCameraValue.length > 0 ? CheckBoxCameraValue : CamerasOption?.map(e => e.camera_id)
        } else {
            formData.config_camera_group = CheckBoxGroupValue.length > 0 ? CheckBoxGroupValue : CamerasGroupOption?.map(e => e.id)
        }

        const { status } = await exportAnalyticsReport(formData, userInfo?.token)
        if (status === 200) {
            toast(<CommonSuccessAlert message={'Data Export Successfully!'} />)
        } else {
            toast(<CommonErrorAlert message={'No Data Export!'} />)
        }
        setLoading(false)
    }

    useEffect(() => {
        // Clear timeout if component unmounts or CalendarDate changes
        return () => {
            if (clickTimeout) {
                clearTimeout(clickTimeout);
            }
        };
    }, [CalendarDate, clickTimeout]);

    const handleClick = () => {
        dispatch({
            type: GET_ANALYTICS_SERVICE_REQUEST
        })
        // Clear the previous timeout
        if (clickTimeout) {
            clearTimeout(clickTimeout);
        }

        // Set a new timeout
        const newTimeout = setTimeout(() => {
            setRefreshComponent(prev => prev + 1)
        }, 2000);

        setClickTimeout(newTimeout);
    };

    return (
        <>
            {analyticsServiceError?.status === 403 ? (
                <PageNotAccess />
            ) : (
                <div className="analytics-container">
                    {/* {!analyticsServiceLoad && ( */}
                    <div className="d-flex justify-content-between flex-wrap align-items-center mb-3 mb-xl-0">
                        <div>
                            <Header props={{ page_name: "Report Summary" }} />
                            <div className="date-slider mb-3 mt-2 text-nowrap ms-auto ms-xl-0">
                                {ReportDay === "custom" ? (
                                    <h6 className="mb-4">{CustomDateTimeValue}</h6>
                                ) : (
                                    <>

                                        <i
                                            className="fas fa-caret-left cursor-pointer text-center p-2"
                                            onClick={() => {
                                                setCalendarDate(prev => prev - 1)
                                                handleClick()
                                            }}
                                        >
                                        </i>
                                        {ReportDay === 'day' ? (
                                            <span className="date-tag text-nowrap">
                                                {getFormattedDate(dateTimeObject, ReportDay, "start")} {dayjs().$D === dateTimeObject.$D && '- Today'}
                                            </span>
                                        ) : (
                                            <span className="date-tag text-nowrap">
                                                {getFormattedDate(dateTimeObject, ReportDay, "start")} - {getFormattedDate(dateTimeObject, ReportDay, "end")}
                                            </span>
                                        )}
                                        <i
                                            className={`fas fa-caret-right cursor-pointer p-2 ${DisabledBtn === true && 'is-disable'}`}
                                            onClick={() => {
                                                if (!DisabledBtn) {
                                                    setCalendarDate(prev => prev + 1)
                                                    handleClick()
                                                }
                                            }}
                                        >
                                        </i>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="service-type-select-container text-end ms-auto">
                            <div className="d-flex justify-content-end gap-2">
                                <Radio.Group value={ReportDay} onChange={(e) => {
                                    if (e.target.value !== 'custom') {
                                        setReportDay(e.target.value)
                                        setCalendarDate(0)
                                    }
                                }} className="mb-3">
                                    <Radio.Button value="day">Daily</Radio.Button>
                                    <Radio.Button value="week">Weekly</Radio.Button>
                                    <Radio.Button value="month">Monthly</Radio.Button>
                                    <Radio.Button value="custom" onClick={() => setDateTimeRangeModalShow(true)}>
                                        <i className="far fa-calendar" style={{ fontWeight: "400" }}></i>
                                    </Radio.Button>
                                </Radio.Group>
                                <div>
                                    <Button
                                        type="primary"
                                        className={`bg-warning border text-dark d-flex align-items-center rounded-1 ${(allServicesSelected === undefined || allServicesSelected === true) && ' is-disable'}`}
                                        size='middle'
                                        loading={loading}
                                        onClick={exportData}
                                    >
                                        <i className="ri-download-2-line fs-6 me-2"></i>
                                        Download Report
                                    </Button>
                                </div>
                            </div>
                            <div className="d-flex gap-2">
                                <Multiselect
                                    onSelect={(service) => setServiceName(service)}
                                    // onRemove={(service) => setServiceName(service)}
                                    selectedValues={ServiceName}
                                    className={`${ServiceName.length !== 0 && 'remove-placeholder'}`}
                                    selectionLimit={1}
                                    disable={analyticsServiceLoad}
                                    // singleSelect={true}
                                    options={servicesOption}
                                    displayValue="service_name"
                                    placeholder='All Services'
                                    emptyRecordMsg="No Group available."
                                    avoidHighlightFirstOption={true}
                                    showArrow={true}
                                    customArrow={<><i className="fas fa-caret-down"></i></>}
                                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                                />
                                <AnalyticsFIlter
                                    CameraTypeFilter={CameraTypeFilter}
                                    setCameraTypeFilter={setCameraTypeFilter}
                                    CheckBoxCameraValue={CheckBoxCameraValue}
                                    setCheckBoxCameraValue={setCheckBoxCameraValue}
                                    CheckBoxGroupValue={CheckBoxGroupValue}
                                    setCheckBoxGroupValue={setCheckBoxGroupValue}
                                    DateFilter={DateFilter}
                                    setCamerasOption={setCamerasOption}
                                    CamerasOption={CamerasOption}
                                    setCamerasGroupOption={setCamerasGroupOption}
                                    CamerasGroupOption={CamerasGroupOption}
                                    disable={analyticsServiceLoad}
                                />
                                <CompareFilterButton
                                    calendarType={ReportDay}
                                    compareScreen={false}
                                    ServiceName={(allServicesSelected === undefined || allServicesSelected === true) ? "All Services" : ServiceName?.[0]?.service_name}
                                    StartDateObject={dateTimeObject}
                                    StartYearCompare={dateTimeObject.startOf(ReportDay).$y}
                                    EndYearCompare={dateTimeObject.endOf(ReportDay).$y}
                                />
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                    {analyticsServiceLoad === true ? (
                        <AnalyticsLoading />
                    ) :
                        <Fragment>
                            {isObjectLike(analyticsService) && Object.keys(analyticsService).length === 0 ? (
                                <div className="d-flex justify-content-center align-items-center" style={
                                    {
                                        height: "fit-content",
                                        position: "absolute",
                                        top: "100px",
                                        bottom: "0",
                                        left: "0",
                                        right: "0",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}>
                                    <div className="text-center">
                                        <img src={noDataFound} className="" alt="" height={"200px"} width={"200px"} />
                                        <h4 className="opacity fw-bold">No Results Found.</h4>
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <Row>
                                        <Col lg={4} className="mb-3">
                                            <ReportSummaryCard
                                                tooltipData={analyticsService?.card_section_1?.max_event}
                                                cardHeading={analyticsService?.card_section_1?.card_heading}
                                                summaryDetail={analyticsService?.card_section_1?.max_label}
                                                count={analyticsService?.card_section_1?.total_events}
                                                summaryContent={`Highest Events Generated ${ReportDay === 'week' ? 'on' : 'Between'}`}
                                                backgroundImage={bucketImage}
                                            />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                            <ReportSummaryCard
                                                tooltipData={analyticsService?.card_section_2?.max_event}
                                                cardHeading={analyticsService?.card_section_2?.card_heading}
                                                summaryDetail={analyticsService?.card_section_2?.max_label}
                                                count={analyticsService?.card_section_2?.total_cameras}
                                                summaryContent={"Highest Events Generated in"}
                                                backgroundImage={cameraImage}
                                            />
                                        </Col>
                                        <Col lg={4} className="mb-3">
                                            <ReportSummaryCard
                                                tooltipData={analyticsService?.card_section_3?.max_event}
                                                cardHeading={analyticsService?.card_section_3?.card_heading}
                                                summaryDetail={analyticsService?.card_section_3?.max_label}
                                                count={analyticsService?.card_section_3?.total_services ? analyticsService?.card_section_3?.total_services : analyticsService?.card_section_3?.total_classes}
                                                summaryContent={"Highest Events Detected for"}
                                                backgroundImage={barImage}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={7} lg={12} className="mb-xl-0 mb-3">
                                            <BarChart
                                                data={BarChartData}
                                                options={BarChartOptions}
                                                xAxisName={ReportDay}
                                                MonthsName={CustomMonths}
                                                setChartMonths={setChartMonths}
                                                ChartMonths={ChartMonths}
                                            />
                                        </Col>
                                        <Col xl={5} lg={12}>
                                            <DoughnutChart data={doughnutData} />
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}

                        </Fragment>
                    }
                    <DateTimeRangeModal
                        show={dateTimeRangeModalShow}
                        closeDateTimeRangeModalShow={() => setDateTimeRangeModalShow(false)}
                        CustomDateHandlerChange={CustomDateFilterHandlerChange}
                    />
                </div>
            )}
        </ >
    );
}

export default Analytics;
