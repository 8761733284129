import React from 'react'

function YearSliderBar(props) {
    const {
        setCurrentYear,
        CurrentYear
    } = props

    return (
        <div className="mt-2 d-flex align-items-center justify-content-between px-4 py-2">
            <i
                className="fas fa-caret-left cursor-pointer pe-2 py-1"
                onClick={() => setCurrentYear(prev => prev - 1)}
            ></i>
            <span style={{ fontWeight: "600" }}>{CurrentYear}</span>
            <i
                className={`${CurrentYear === parseInt(new Date().getFullYear()) && 'is-disable'} fas fa-caret-right cursor-pointer ps-2 py-1`}
                onClick={() => {
                    if (CurrentYear !== parseInt(new Date().getFullYear())) {
                        setCurrentYear(prev => prev + 1)
                    }
                }}
            ></i>
        </div>
    )
}

export default YearSliderBar