import React, { useState, useEffect } from 'react'
import SearchInput from "../../../components/SearchInput";
import { Table, Button } from "antd";
import noCameraFound from '../../../images/no-camera-found.gif'
import { useDispatch, useSelector } from 'react-redux'
import { applyCamerasInFaceRecognitionUser, getFaceRecognitionCamera, getCamerasConfiguration, getFaceRecognitionUsers } from '../../../actions/faceRecognitionActions';
import Loader from "../../../components/Loader";
import { APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_RESET } from '../../../constants/faceRecognitionConstant'

export default function UserCameraAccessModal(props) {

    const addedCamera = props.addedCamera
    const serviceId = props.serviceId
    const userId = props.userId

    const dispatch = useDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [search, setSearch] = useState('');

    const camerasGetConfiguration = useSelector((state) => state.camerasGetConfiguration);
    const { loading, camerasData } = camerasGetConfiguration;

    const camerasApplyInFaceRecognitionUser = useSelector((state) => state.camerasApplyInFaceRecognitionUser);
    const { loading: applyCamerasLoad, applyCamerasSuccess } = camerasApplyInFaceRecognitionUser;

    useEffect(() => {
        if (applyCamerasSuccess) {
            dispatch({ type: APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_RESET })
            dispatch(getFaceRecognitionUsers(props.UsersTablePageNumber))
            dispatch(getFaceRecognitionCamera(serviceId))
            props.closeCameraAccessModal(true)
        }
    }, [applyCamerasSuccess, dispatch])

    useEffect(() => {
        dispatch(getCamerasConfiguration(serviceId, userId))
        const usersId = addedCamera?.map((users) => users.id)
        setSelectedRowKeys(usersId)
    }, [dispatch, addedCamera, serviceId, userId])

    const applyCamerasToUsersHandler = () => {
        dispatch(applyCamerasInFaceRecognitionUser({ "user_id": userId, "camera_id": selectedRowKeys, "service_id": serviceId }))
    }

    const columns = [
        {
            title: 'Camera Name',
            dataIndex: 'camera_name',
            key: 'camera_name',
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
        },
    ];

    const onSingleRowSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys([...selectedRowKeys, record.key])
        } else {
            const selected = selectedRowKeys.filter(e => e !== record.key)
            setSelectedRowKeys(selected)
        }
    }

    const access_camera_data = camerasData?.filter((camera) => camera.camera_name.toLowerCase().includes(search?.toLowerCase()))?.map((camera) => ({
        key: camera.id,
        camera_name: camera.camera_name,
        action:
            <div className={selectedRowKeys.includes(camera.id) ? "d-none" : 'text-end'}>
                <button
                    onClick={() => setSelectedRowKeys([...selectedRowKeys, camera.id])}
                >
                    <span className='add-item-button border_radius_xsm'>
                        <i className="fas fa-plus" />
                    </span>
                </button>
            </div>
    }))

    return (
        <div className={access_camera_data?.length === 0 ? 'height-0' : 'user-camera-access-container px-3'}>
            <SearchInput onChange={(e) => setSearch(e)} />

            <Table
                rowSelection={{
                    selectedRowKeys,
                    onSelect: onSingleRowSelect,
                    onSelectAll: (items) => items ? setSelectedRowKeys(camerasData?.map(e => e.id)) : setSelectedRowKeys([])
                }}
                columns={columns}
                dataSource={!loading ? access_camera_data : null}
                pagination={{
                    pageSize: 10,
                }}
                scroll={{ x: 700, y: "300px" }}
            />
            {
                loading ?
                    <Loader margin="120" /> : null
            }
            {access_camera_data?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "300px" }}>
                    <div className="text-center background-none">
                        <img className='background-none opacity no-camera-found' src={noCameraFound} alt="" />
                        <p className='opacity'>No details were found. Once you add a camera,<br /> all the information will appear here.</p>
                    </div>
                </div>
            ) : null}
            <div
                className="text-end mt-4"
            >{applyCamerasLoad ? (<Button type="primary" size="large" loading>
                Save
            </Button>) :
                <button
                    className="btn px-4 py-2 bg-warning border text-dark"
                    type='submit'
                    onClick={applyCamerasToUsersHandler}
                >
                    Save
                </button>}

            </div>
        </div>
    )
}
