import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateExistingUser } from "../../actions/settingActions";
import { toast } from "react-toastify";
import { ResetPasswordAlert } from "../../components/ToastAlert";
import { UPDATE_USER_RESET } from "../../constants/settingConstant";
import { passwordValidator } from "../../global/Helper";




function EditPasswordModal(props) {

  const dispatch = useDispatch();
  const updateUser = useSelector(state => state.updateUser)
  const { loading, error, updateUserSuccess } = updateUser;

  useEffect(() => {
    if (updateUserSuccess) {
      props.closeEditPasswordModal(true)
      dispatch({ type: UPDATE_USER_RESET });
      toast(<ResetPasswordAlert />)
    }
  }, [updateUserSuccess])

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [passwordNotMatch, setPasswordNotMatch] = useState(false);

  const passwordReset = (resetPasswords) => {
    if (resetPasswords.newPassword !== resetPasswords.confirmPassword) {
      setPasswordNotMatch(true)
    } else {
      dispatch(updateExistingUser(resetPasswords));
    }
  };

  return (
    <div>
      <h3 className="text-center">Reset Password</h3>
      <Form className="px-3" noValidate onSubmit={handleSubmit(passwordReset)}>
        <Form.Group className="mb-3">
          <Form.Label className="m-0" htmlFor="">
            <b>Old Password</b>
          </Form.Label>
          <Form.Control
            type="password"
            name="oldPassword"
            className="border-input"
            id="oldPassword"
            placeholder="Enter Old Password"
            {...register("oldPassword", {
              required: "Please Enter Valid Old Password.",
            })}
            isInvalid={(error?.includes("Wrong old password") || errors.oldPassword)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Old Password.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="m-0" htmlFor="">
            <b>New Password</b>
          </Form.Label>
          <Form.Control
            type="password"
            name="newPassword"
            className="border-input"
            id="newPassword"
            placeholder="Enter New Password"
            {...register("newPassword", {
              required: " Please Enter New Password.",
              pattern: {
                value: passwordValidator,
                message: "Password contain at least alphabet, one number and minimum 8 or more characters."
              }
            })}
            isInvalid={errors.newPassword}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors?.newPassword?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="m-0" htmlFor="">
            <b>Confirm Password</b>
          </Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            className="border-input"
            id="confirmPassword"
            placeholder="Enter Confirm Password"
            {...register("confirmPassword", {
              required: "Please Enter confirm Password.",
            })}
            isInvalid={(passwordNotMatch || errors.confirmPassword)}
            required
          />
          <Form.Control.Feedback type="invalid">
            {passwordNotMatch ? "Password Didn't Match." : `${errors?.confirmPassword?.message}`}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="d-flex align-itmes-center justify-content-end mt-4">
          <button
            className="btn me-3 px-3 py-2 border close"
            type="button"
            onClick={() => {
              props.closeEditPasswordModal(true);
            }}
          >
            Cancel
          </button>

          {loading ?
            (<Button type="primary" size="large" loading>Save</Button>)
            :
            (<button
              className="btn px-4 py-2 bg-warning border text-dark"
              type="submit"
              onClick={() => setPasswordNotMatch(false)
              }
            >
              Save
            </button>)}
        </div>
      </Form>
    </div>
  );
}

export default EditPasswordModal;