import axios from 'axios'
import {
    GET_SOFTWARE_UPDATE_DATA_REQUEST,
    GET_SOFTWARE_UPDATE_DATA_SUCCESS,
    GET_SOFTWARE_UPDATE_DATA_FAIL,

    PUT_SOFTWARE_UPDATE_DATA_REQUEST,
    PUT_SOFTWARE_UPDATE_DATA_FAIL,
    PUT_SOFTWARE_UPDATE_DATA_RESET,

    INSTANT_SOFTWARE_UPDATE_REQUEST,
    INSTANT_SOFTWARE_UPDATE_FAIL,
    INSTANT_SOFTWARE_UPDATE_RESET,

} from '../../constants/organizationConstant/softwareUpdateConstant'

import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { getAPI, postAPI, putAPI } from '../../global/ApiManager';


export const getSoftwareUpdateData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SOFTWARE_UPDATE_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_manager/software_update_api/`, dispatch)

        dispatch({
            type: GET_SOFTWARE_UPDATE_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_SOFTWARE_UPDATE_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const instantSoftwareUpdate = (software_id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: INSTANT_SOFTWARE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        await postAPI(
            `/service_manager/instant_software_update/`,
            { software_id: software_id },
            config,
            dispatch
        )

        dispatch({
            type: INSTANT_SOFTWARE_UPDATE_RESET
        });

        dispatch(getSoftwareUpdateData())

    } catch (error) {
        dispatch({
            type: INSTANT_SOFTWARE_UPDATE_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const putSoftwareUpdateData = (software_id, auto_update) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_SOFTWARE_UPDATE_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        await putAPI(
            `/service_manager/software_update_api/${software_id}/`,
            { "auto_update": auto_update },
            config,
            dispatch
        )

        dispatch({
            type: PUT_SOFTWARE_UPDATE_DATA_RESET
        });

    } catch (error) {
        dispatch({
            type: PUT_SOFTWARE_UPDATE_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};
