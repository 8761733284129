import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import AccuracySlider from './components/AccuracySlider';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, ClassesName, theme) {
    return {
        fontWeight:
            ClassesName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectPlaceholder(props) {
    const {
        setAccuracyLevel,
        AccuracyLevel,
        ClassesName,
        setClassesName,
        showAccuracySlider,
        className,
        options,
        multiple
    } = props;
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setClassesName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl sx={{ width: '240px' }}>
            {showAccuracySlider && (
                <div className='d-flex text-dark'>
                    <label className="fw-bold">Event Preference</label>
                    <AccuracySlider
                        setAccuracyLevel={setAccuracyLevel}
                        AccuracyLevel={AccuracyLevel}
                    />
                </div>
            )}
            <Select
                multiple={multiple}
                displayEmpty
                value={ClassesName}
                onChange={handleChange}
                className={`service-config-input ${className}`}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <div>All</div>;
                    }

                    return selected.join(', ');
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem disabled value="">
                    <em>All</em>
                </MenuItem>

                {options?.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, ClassesName, theme)}
                    >
                        <Checkbox checked={ClassesName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}