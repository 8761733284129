import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateExistingUser } from '../../actions/settingActions'
import UpdateProfileDetailInput from './UpdateProfileDetailInput';
import { UserDetailsAlert } from "../../components/ToastAlert";
import { toast } from "react-toastify";


function SettingProfileData(props) {
  const userProfile = props.userProfile
  const updateStatus = useSelector((state) => state.updateUser)
  const { updateUserSuccess } = updateStatus;

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  useEffect(() => {
    if (!company || !phone || !email) {
      setPhone(userProfile?.phone)
      setCompany(userProfile?.company)
      setEmail(userProfile?.email)
    }
  }, [userProfile]);

  const phoneNumberOnChange = (e) => {
    if (Number.isInteger(Number(e.target.value))) {
      setPhone(e.target.value)
    }
  }

  if (updateUserSuccess) {
    toast(<UserDetailsAlert />);
  }

  const handleUpdate = (label, currentValue, updateValue) => {
    if (currentValue !== updateValue) {

      dispatch(updateExistingUser({ [label]: updateValue }))
    } else {
      return null
    }
  }

  return (
    <>
      <div className="profile-heading mb-3">
        <h4 className="mb-0 fw-bold">Profile Details</h4>
      </div>
      <div
        className="border_radius_sm mb-4"
        style={{
          background: "#C4C4C417",
        }}
      >
        <UpdateProfileDetailInput
          disabled={true}
          type="email"
          name="email"
          value={email}
          label="Email"
          placeholder="Enter Your Email"
        />
        <UpdateProfileDetailInput
          type="password"
          value="password"
          label="Password"
          placeholder="Enter Your Password"
        />
        <UpdateProfileDetailInput
          type="text"
          maxlength={12}
          name="phone"
          value={phone}
          onChange={phoneNumberOnChange}
          label="Phone"

          onKeyDown={(e) => {
            if (e.key === 'Enter') {

              handleUpdate("phone", userProfile?.phone, e.target.value)

            }
          }}
          onBlur={(e) => {
            handleUpdate("phone", userProfile?.phone, e.target.value)
          }}


          placeholder="Enter Your Phone Number"
        // onClickFunction={() => UpdateDetails(phone, 'phone')}
        />
        <UpdateProfileDetailInput
          className="input"
          label="Company"
          name="company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          border={false}
          placeholder="Enter Your Company Name"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleUpdate("company", userProfile?.company, e.target.value)
            }
          }
          }
          onBlur={(e) => {
            handleUpdate("company", userProfile?.company, e.target.value)
          }


          }
        // onClickFunction={() => UpdateDetails(company, 'company')}

        />
      </div>
    </>
  );
}

export default SettingProfileData