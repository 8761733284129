import React, { useEffect, useState, useRef } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { useDispatch, useSelector } from "react-redux";
import { getMasterListUserData } from '../../../actions/organizationActions/masterListActions';
import { getUserRoles } from '../../../actions/organizationActions/rolesAndResponsibilityActions';
import { capitalizeFirstLater } from '../../../global/Helper'

function AddUserRoleStep(props) {
    const {
        setDepartmentValue,
        DepartmentValue,
        setLocationValue,
        LocationValue,
        setUserValue,
        UserValue,
        setRolesValue,
        RolesValue,
    } = props

    const dispatch = useDispatch();
    const [DepartmentOption, setDepartmentOption] = useState([]);
    const [LocationOption, setLocationOption] = useState([]);
    const [UserOption, setUserOption] = useState([]);
    const [RoleOption, setRoleOption] = useState([]);

    const locationRef = useRef(null)
    const userRef = useRef(null)

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const masterListUserDataGet = useSelector((state) => state.masterListUserDataGet);
    const { /*error: getMasterListUserError, */loading: getMasterListUserLoad, success: getMasterListUserSuccess } = masterListUserDataGet;

    const RolesAndResponsibilityDataGetSingle = useSelector((state) => state.RolesAndResponsibilityDataGetSingle);
    const { success: getSingleRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataGetSingle;


    useEffect(() => {
        dispatch(getMasterListUserData())
    }, [])

    useEffect(() => {
        const getDepartment = getMasterListUserSuccess?.map((e) => (e.department))
        setDepartmentOption([...new Set(getDepartment)])
    }, [getMasterListUserSuccess])

    useEffect(() => {
        const getLocation = getMasterListUserSuccess?.filter((e) => e.department === DepartmentValue?.[0])?.map((e) => (e.location))
        setLocationOption([...new Set(getLocation)])
    }, [getMasterListUserSuccess, DepartmentValue])

    useEffect(() => {
        const getUser = getMasterListUserSuccess?.filter((e) => e.department === DepartmentValue?.[0] && e.location === LocationValue?.[0])?.map((e) => ({ user_name: e.username, id: e.id }))
        setUserOption(getUser)
    }, [getMasterListUserSuccess, DepartmentValue, LocationValue])

    useEffect(() => {
        async function startFetching() {
            setRoleOption([])
            const { data } = await getUserRoles(userInfo?.token)
            if (!ignore) {
                setRoleOption(data?.map(e => capitalizeFirstLater(e)))
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [])

    function departmentOnSelect(e) {
        setDepartmentValue(e)
        locationRef?.current?.resetSelectedValues();
        userRef?.current?.resetSelectedValues();
        setLocationValue([])
        setUserValue([])
    }

    function locationOnSelect(e) {
        setLocationValue(e);
        userRef?.current?.resetSelectedValues();
        setUserValue([]);
    }

    return (
        <div className='w-50 service-type-select-container'>
            <div className='mb-4'>
                <label className='m-0'>Department</label>
                <Multiselect
                    onSelect={departmentOnSelect}
                    onRemove={departmentOnSelect}
                    selectedValues={DepartmentValue}
                    loading={getMasterListUserLoad}
                    isObject={false}
                    singleSelect={false}
                    showCheckbox={true}
                    options={DepartmentOption}
                    placeholder={DepartmentValue?.length === 0 ? 'Select Department' : ''}
                    selectionLimit="1"
                    emptyRecordMsg="No Departments Found."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    disable={!!getSingleRolesAndResponsibilitySuccess?.id}
                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
            <div className='mb-4'>
                <label className='m-0'>Location</label>
                <Multiselect
                    ref={locationRef}
                    onSelect={locationOnSelect}
                    onRemove={locationOnSelect}
                    selectedValues={LocationValue}
                    isObject={false}
                    showCheckbox={true}
                    singleSelect={false}
                    options={LocationOption}
                    placeholder={LocationValue?.length === 0 ? 'Select Location' : ''}
                    selectionLimit="1"
                    emptyRecordMsg="No Locations Found."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    disable={!!getSingleRolesAndResponsibilitySuccess?.id}
                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
            <div className='mb-4'>
                <label className='m-0'>User</label>
                <Multiselect
                    ref={userRef}
                    onSelect={(e) => {
                        setUserValue(e)
                    }}
                    onRemove={(e) => {
                        setUserValue(e)
                    }}
                    selectedValues={UserValue}
                    singleSelect={false}
                    selectionLimit="1"
                    showCheckbox={true}
                    options={UserOption}
                    displayValue="user_name"
                    placeholder={UserValue?.length === 0 ? 'Select User' : ''}
                    emptyRecordMsg="No Users Found."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    disable={!!getSingleRolesAndResponsibilitySuccess?.id}
                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
            <div className='mb-4'>
                <label className='m-0'>Roles</label>
                <Multiselect
                    onSelect={(e) => {
                        setRolesValue(e)
                    }}
                    onRemove={(e) => {
                        setRolesValue(e)
                    }}
                    selectedValues={RolesValue}
                    isObject={false}
                    className={`${RolesValue.length !== 0 && 'remove-placeholder'}`}
                    selectionLimit={1}
                    // singleSelect={true}
                    showCheckbox={true}
                    options={RoleOption}
                    displayValue="department"
                    placeholder='Select Roles'
                    emptyRecordMsg="No Roles Available."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
        </div>
    )
}

export default AddUserRoleStep