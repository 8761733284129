import React, { useState, useEffect } from 'react'
import SearchInput from '../../../components/SearchInput'
import { Table } from "antd";
import noRequestFound from '../../../images/no-camera-request-found.gif'
import { Alert, Modal } from 'react-bootstrap';
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import SetupRolesAndResponsibilityModal from './SetupRolesAndResponsibilityModal';
import { getRolesAndResponsibilityData, getSingleRolesAndResponsibilityData, deleteRolesAndResponsibilityData } from '../../../actions/organizationActions/rolesAndResponsibilityActions';
import { GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_RESET, DELETE_ROLES_AND_RESPONSIBILITY_DATA_RESET } from '../../../constants/organizationConstant/rolesAndResponsibilityConstants';
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../../components/Loader';
import { toast } from "react-toastify";
import { DeleteServiceAlert, CommonErrorAlert } from '../../../components/ToastAlert'
import { capitalizeFirstLater } from '../../../global/Helper'
import moment from "moment";
import TextToCopy from '../../../components/TextToCopy';



function RolesAndResponsibility() {
    const [search, setSearch] = useState("");
    const [UserDeleteModalShow, setUserDeleteModalShow] = useState(false);
    const [SetupRolesAndResponsibilityModalShow, setSetupRolesAndResponsibilityModalShow] = useState(false);
    const [UserName, setUserName] = useState("");
    const [UserId, setUserId] = useState("");

    const dispatch = useDispatch();

    const RolesAndResponsibilityDataGet = useSelector((state) => state.RolesAndResponsibilityDataGet);
    const { error: getRolesAndResponsibilityError, loading: getRolesAndResponsibilityLoad, success: getRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataGet;


    const RolesAndResponsibilityDataDelete = useSelector((state) => state.RolesAndResponsibilityDataDelete);
    const { error: deleteRolesAndResponsibilityError, loading: deleteRolesAndResponsibilityLoad, success: deleteRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataDelete;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    useEffect(() => {
        dispatch(getRolesAndResponsibilityData())
    }, [])

    useEffect(() => {
        if (deleteRolesAndResponsibilitySuccess) {
            setUserDeleteModalShow(false)
            dispatch(getRolesAndResponsibilityData())
            toast(<DeleteServiceAlert service_name={UserName} />);
            dispatch({ type: DELETE_ROLES_AND_RESPONSIBILITY_DATA_RESET })
        }
        if (deleteRolesAndResponsibilityError) {
            toast(<CommonErrorAlert message={deleteRolesAndResponsibilityError} />);
            dispatch({ type: DELETE_ROLES_AND_RESPONSIBILITY_DATA_RESET })
        }
    }, [deleteRolesAndResponsibilitySuccess, deleteRolesAndResponsibilityError])

    const userRoleTableColumns = [
        {
            title: 'User Id',
            dataIndex: 'user_id',
            key: 'user_id',
            ellipsis: true,
            width: "10%"
        },
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
            ellipsis: true,
            width: "12%"
        },
        {
            title: 'Email',
            dataIndex: 'email_id',
            key: 'email_id',
            ellipsis: true,
            width: "15%"
        },

        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            ellipsis: true,
            width: "15%"
        },
        {
            title: 'Service Access',
            dataIndex: 'service_access',
            key: 'contact',
            ellipsis: true,
        },
        // {
        //     title: 'Camera Access',
        //     dataIndex: 'camera_access',
        //     key: 'camera_access',
        //     ellipsis: true,
        // },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            ellipsis: true,
            width: "12%"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            ellipsis: true,
            width: "15%"
        },
    ];

    const userRoleTableData = getRolesAndResponsibilitySuccess?.filter((e) => (e.date?.toLowerCase().includes(search?.toLowerCase())) || e.user_id?.toLowerCase().includes(search?.toLowerCase()) || e.email_id?.toLowerCase().includes(search?.toLowerCase()) || String(e.camera_access?.map((camera) => camera.toLowerCase())).includes(search?.toLowerCase()) || /*String(e.service_access.map((camera) => camera.toLowerCase())).includes(search?.toLowerCase()) ||*/ e.user_name?.toLowerCase().includes(search?.toLowerCase()) || (e.role).toLowerCase()?.includes((search?.toLowerCase())))?.map((e) => {

        return {
            key: e.id,
            user_id: e.user_id,
            user_name: e.user_name,
            email_id: (
                <div className='d-flex align-items-center clipboard-container' title={e.email_id}>
                    <TextToCopy text={e.email_id} className='clipboard-text' style={{ fontSize: "14px" }} />
                </div>
            ),
            role: capitalizeFirstLater(e.role),
            service_access: <>{Array.isArray(e.service_access) ? e.service_access?.slice(0, 2).join(", ") : 'All Services'}</>,
            // camera_access: <>{e.camera_access?.slice(0, 2).join(", ")}</>,
            date: moment(new Date(e.date)).format("DD-MM-YYYY"),
            action: <>
                <div className="d-flex action-btn">
                    {pageAccess?.['update'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                            onClick={() => {
                                dispatch(getSingleRolesAndResponsibilityData(e.id))
                                setSetupRolesAndResponsibilityModalShow(true)
                            }}
                        >
                            <i className="ri-edit-line" style={{ fontSize: "14px" }}></i>
                        </button>
                    )}
                    {pageAccess?.['delete'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            onClick={() => {
                                setUserDeleteModalShow(true)
                                setUserName(e.user_name)
                                setUserId(e.id)
                            }}
                        >
                            <i className="ri-delete-bin-5-line" style={{ fontSize: "14px" }}></i>
                        </button>
                    )}
                </div>
            </>,
        }
    })

    return (
        <div className='roles-and-responsibility-container h-100'>
            <div className='organization-headings mb-2'>Roles & Responsibilities</div>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <SearchInput
                    onChange={(e) => setSearch(e)}
                />
                {pageAccess?.['create'].includes(userInfo?.user_role) && (
                    <button
                        className="add-user-btn text-white text-nowrap"
                        onClick={() => {
                            setSetupRolesAndResponsibilityModalShow(true)
                            dispatch({ type: GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_RESET })
                        }}
                    >
                        <i className="fas fa-plus me-2"></i>
                        Add Role
                    </button>
                )}
            </div>
            {getRolesAndResponsibilityError ? (
                <Alert variant="danger">{getRolesAndResponsibilityError}</Alert>
            ) : (
                <>
                    <Table
                        columns={userRoleTableColumns}
                        dataSource={userRoleTableData} pagination={false}
                        scroll={{ x: 800, y: "calc(100vh - 292px)" }}
                    />
                    {getRolesAndResponsibilityLoad && (
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "56vh" }}>
                            <Loader margin="0" />
                        </div>
                    )}
                    {userRoleTableData?.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 190px)" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                                <p className='opacity'>No User Found!</p>
                            </div>
                        </div>)}
                </>
            )}
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                show={SetupRolesAndResponsibilityModalShow}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
                onHide={() => setSetupRolesAndResponsibilityModalShow(false)}
                fullscreen='lg-down'
                scrollable
            >
                <SetupRolesAndResponsibilityModal
                    closeSetupRolesAndResponsibilityModal={() => setSetupRolesAndResponsibilityModalShow(false)}
                />
            </Modal>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={UserDeleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        onHide={() => setUserDeleteModalShow(false)}
                        item_name={UserName}
                        dispatch_item={deleteRolesAndResponsibilityData(UserId)}
                        loading={deleteRolesAndResponsibilityLoad}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RolesAndResponsibility