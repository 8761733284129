import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GoBackButton from '../../../components/GoBackButton';
import SearchInput from '../../../components/SearchInput';
import { Image, Badge, Modal } from 'react-bootstrap';
import { Table } from "antd";
// import noUserFound from '../../../images/no-users-found.gif'
import noRequestFound from '../../../images/no-camera-request-found.gif'
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import RecoverConfirmModal from './RecoverConfirmModal';
import { toast } from "react-toastify";
import {
    recycledFaces,
    deleteFacePermanent,
    restoreFace,
} from "../../../actions/faceRecognitionActions";
import { RESTORE_FACE_RESET, DELETE_FACE_PERMANENT_RESET } from '../../../constants/faceRecognitionConstant'
import { DeleteUserAlert, RestoreUserAlert } from '../../../components/ToastAlert';

function UserRecycleBinPage() {

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState("");
    const [deleteUserModalShow, setDeleteUserModalShow] = useState(false);
    const [recycleModalShow, setRecycleModalShow] = useState(false);

    const recycledFace = useSelector((state) => state.recycledFace);
    const { recycledData } = recycledFace;

    const FaceDeletePermanent = useSelector((state) => state.FaceDeletePermanent);
    const { loading: deleteFaceLoader, deleteUserFaceSuccess } = FaceDeletePermanent;

    const restorFace = useSelector((state) => state.restorFace);
    const { loading: restoreFaceLoader, restoreUserFaceSuccess } = restorFace;

    useEffect(() => {
        dispatch(recycledFaces());
    }, []);

    useEffect(() => {
        if (restoreUserFaceSuccess) {
            dispatch(recycledFaces())
            dispatch({ type: RESTORE_FACE_RESET })
            toast(<RestoreUserAlert username={userName} />);
            setRecycleModalShow(false)
        }
        if (deleteUserFaceSuccess) {
            dispatch(recycledFaces());
            dispatch({ type: DELETE_FACE_PERMANENT_RESET })
            toast(<DeleteUserAlert username={userName} />);
            setDeleteUserModalShow(false)
        }
    }, [dispatch, restoreUserFaceSuccess, deleteUserFaceSuccess])


    const userTableColumns = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: 130,
            ellipsis: true
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            width: 230,
            ellipsis: true
        },
        {
            title: 'User Type',
            dataIndex: 'user_type',
            key: 'user_type',
            width: 100,
            ellipsis: true
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            width: 200,
            ellipsis: true
        },
        {
            title: 'Camera',
            dataIndex: 'camera',
            key: 'camera',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_till',
            key: 'valid_till',
            width: 150,
            ellipsis: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            ellipsis: true,
        },
    ];

    const userTableData = recycledData?.all_face_recognition_users?.filter((user) => user.username.toLowerCase().includes(search?.toLowerCase()) || String(user.user_id)?.includes(String(search)) || String(user.user_type)?.toLowerCase().includes(String(search.toLowerCase())) || String(user.department)?.toLowerCase().includes(String(search.toLowerCase())))?.map((user) => ({
        key: user.id,
        user_id: <span className='authorized border_radius_sm padding-badge fs-8'>{user.user_id}</span>,
        username:
            <div>
                <Image
                    height={30}
                    width={40}
                    className="me-1 border_radius_sm"
                    src={user.user_profile}
                />{" "}
                {user.username}
            </div>,
        user_type: user.user_type,
        department: user.department,
        camera:
            <div>
                <span
                    className='added-camera-icon'
                >
                    <Badge bg="dark">{user.camera.length}+</Badge>
                </span>
            </div>,
        valid_till: user.valid_till,
        action:
            <>
                <div className="d-flex justify-content-center">
                    <button
                        style={{ background: "#4AC237" }}
                        className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                        onClick={() => {
                            setRecycleModalShow(true)
                            setUserName(user.username)
                            setUserId(user?.id);
                        }}
                    >
                        <i className="fas fa-sync-alt"></i>                    </button>
                    <button
                        className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                        onClick={() => {
                            setDeleteUserModalShow(true)
                            setUserName(user.username)
                            setUserId(user?.id);
                        }}
                    >
                        <i className="ri-delete-bin-5-line "></i>
                    </button>
                </div>
            </>,
    }))

    const crumbHandler = () => {
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                        Services
                    </li>
                    <li className="breadcrumb-item active p-0">
                        All Services
                    </li>
                    <li className="breadcrumb-item active text-dark">
                        Recycle Bin
                    </li>
                </ol>
            </nav>
        );
    };
    return (
        <div>
            <div>{crumbHandler()}</div>
            <label className="fs-4">Recycle Bin</label>
            <GoBackButton />
            <div className="my-3">
                <SearchInput onChange={(e) => { setSearch(e) }} />
            </div>
            <Table className={userTableData?.length && 'mb-5'} columns={userTableColumns} dataSource={userTableData} pagination={false} scroll={{ x: true, y: 300 }} />
            {userTableData?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "300px" }}>
                    <div className="text-center background-none">
                        <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                        {/* <p className='opacity'>No Users Found!</p> */}
                    </div>
                </div>) : null}
            <div className={userTableData?.length ? 'fs-6 d-flex justify-content-center align-items-center' : 'd-none'} style={{ color: "#333333BF" }}>
                <span className='permanent-delete-icon'></span>
                Users in Recycle Bin are permanently deleted after 90 days
            </div>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteUserModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        item_name={userName}
                        dispatch_item={deleteFacePermanent(userId)}
                        onHide={() => setDeleteUserModalShow(false)}
                        loading={deleteFaceLoader}
                        permanent={true}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={recycleModalShow}
            >
                <Modal.Body>
                    <RecoverConfirmModal
                        item_name={userName}
                        dispatch_item={restoreFace(userId)}
                        onHide={() => setRecycleModalShow(false)}
                        loading={restoreFaceLoader}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default UserRecycleBinPage