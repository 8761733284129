import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BUY_SERVICE_RESET } from "../../constants/servicesConstant";
import { listServices, addService, buyAllServices } from "../../actions/servicesActions";
import AlertMesaage from "../../components/AlertMesaage";
import noServiceFound from "../../components/noData.gif";
import Loader from "../../components/Loader";
import { Tab, Nav, Tooltip, OverlayTrigger, Card, Modal } from "react-bootstrap";
import SearchInput from "../../components/SearchInput";
import ServiceConfirmModal from "../../components/ServiceConfirmModal";
import { Button } from 'antd';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSearchParams } from "react-router-dom";
import { checkOnlineStatus } from "../../actions/userActions";
import NoInternetModal from "../../components/NoInternetModal";
import noServiceIcon from '../../images/no-service-icon.jpeg';

function ServiceOptions(props) {

  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [serviceName, setServiceName] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [buyServiceModal, setBuyServiceModal] = useState(false);
  const [buttonNames, setButtonNames] = useState({});
  const [serviceLoad, setServiceLoad] = useState(false);
  const [buyServiceLoading, setBuyServiceLoading] = useState({});
  const [isOnline, setIsOnline] = useState(true);
  const [noInternetModal, setNoInternetModal] = useState(false);

  let service_id = searchParams.get("service-id")

  const servicesList = useSelector((state) => state.servicesList);
  const { loading, error, serviceData } = servicesList;

  const serviceAdd = useSelector((state) => state.serviceAdd);
  const { error: addServiceError, loading: addServiceLoad } = serviceAdd;

  const buyServices = useSelector((state) => state.buyServices);
  const { loading: buyServiceSuccess } = buyServices;

  useEffect(() => {
    setBuyServiceLoading((prevState) => ({
      ...prevState,
      [serviceId]: true,
    }));

  }, [serviceId]);


  useEffect(() => {
    if (addServiceError) {
      setServiceLoad(false)
    }
  }, [addServiceError])

  useEffect(() => {
    if (buyServiceSuccess) {

      setBuyServiceModal(false);
      dispatch({ type: BUY_SERVICE_RESET });
    }
    setBuyServiceLoading((prevState) => ({
      ...prevState,
      [serviceId]: false,
    }));
  }, [buyServiceSuccess]);


  useEffect(() => {
    dispatch(listServices());
  }, [isOnline]);

  useEffect(() => {
    const checkInternet = async () => setIsOnline(await checkOnlineStatus())
    checkInternet();

    const intervalId = setInterval(checkInternet, 30000); // Check every 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  const searchFilter = (e) => {
    dispatch(listServices(e))
  }

  return (
    <div className="all-services-option-container">
      <div className="service-search">
        <SearchInput onChange={(e) => searchFilter(e)} />
      </div>
      {loading ? (
        <Loader />
      ) :
        (
          <>
            {error ? (
              <AlertMesaage variant="danger" children={error} />
            ) : (
              <Tab.Container
                id="left-tabs-example"
                onSelect={(e) => {
                  searchParams.set("service-id", e)
                  setSearchParams(searchParams)
                }}
                defaultActiveKey={service_id || serviceData?.all_service?.[0]?.id}
              >
                <div className="d-flex gap-3">
                  <div className="pills-side-bar">
                    <Nav variant="pills" className="flex-column border-radius">
                      <Nav.Item>
                        <Nav.Link className="p-0 mb-0 text-center">
                          <div
                            className="add-service-btn bg-dark border-top-right border-top-left"
                          >
                            <h4 className=" text-white m-0">All Service</h4>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <div className="all-services-tab">
                        {serviceData?.all_service?.map((service) => (
                          <Nav.Item key={service.id}>
                            <Nav.Link eventKey={service.id} className="d-flex align-items-center text-capitalize">
                              <img
                                src={service.icon}
                                style={{
                                  width: "30px",
                                  height: '30px',
                                  marginRight: "7px"
                                }}
                                className="rounded-circle"
                                onError={(e) => e.target.src = noServiceIcon}
                              />
                              {(service.service_name)}</Nav.Link>
                          </Nav.Item>
                        ))}
                      </div>
                    </Nav>
                  </div>

                  <div className="tab-side-bar">
                    {serviceData?.all_service?.length !== 0 ? (
                      <Tab.Content className="service-model-tab-content">
                        {serviceData?.all_service?.map((items) => (
                          <Tab.Pane key={items.id} eventKey={items.id}>
                            <h4 className="service-heading text-capitalize">{(items.service_name)} Applications</h4>
                            <div className="row" style={{ maxHeight: "calc(100vh - 257px)", overflow: "auto" }}>
                              {items.services?.map((service) => (
                                <div className="col-xl-4 col-md-6 col-12 mb-4  position-relative" key={service.id} >
                                  <Card className={`${!service.available && 'filter-brightness'} shadow_box border-radius border-0 h-100`}>
                                    {(serviceLoad && serviceLoad === service.id || service.is_loading) && (
                                      <Box sx={{
                                        width: '100%',
                                        position: "absolute",
                                        top: "0px",
                                      }}>
                                        <LinearProgress />
                                      </Box>
                                    )}
                                    <Card.Img variant="top" src={service.image} onError={(e) => e.target.src = noServiceIcon} />
                                    <Card.Body className="position-relative">
                                      <Card.Title className="text-capitalize">{(service.label)}</Card.Title>
                                      <Card.Text className="pb-4">
                                        {service.information}
                                      </Card.Text>
                                      <div
                                        className="d-flex justify-content-end card_bottom"
                                      >
                                        <div className={service.available === false ? "mr-auto d-flex align-items-end" : "d-none"}>
                                          <h6 style={{ color: "black", marginBottom: "0" }}><b>Coming Soon...</b></h6>
                                        </div>
                                        {!service.is_purchased && (
                                          <div className={service.available ? "ml-auto d-flex align-items-end" : "d-none"}>

                                            {buyServiceLoading[service.id] ? (

                                              <Button
                                                style={{ width: '100%', display: 'flex', alignItems: 'center', backgroundColor: "black", color: "white" }}
                                                type="text"
                                                className={service.available && !service.installed ? "btn secondary_small_btn" : "d-none"}
                                                size="medium"

                                                key={service.id}
                                              >
                                                {buttonNames[service.id] || "Buy"}
                                              </Button>
                                            ) : (
                                              <button
                                                key={service.id}

                                                className={service.available && !service.installed ? "btn secondary_small_btn" : "d-none"}
                                                onClick={() => {
                                                  if (isOnline) {
                                                    const newButtonNames = { ...buttonNames };
                                                    if (!newButtonNames[service.id] || newButtonNames[service.id] === "Buy") {
                                                      newButtonNames[service.id] = "Service Requested!";
                                                    } else {
                                                      newButtonNames[service.id] = "Buy";
                                                    }
                                                    setButtonNames(newButtonNames);
                                                    setServiceName(service.label)
                                                    setServiceId(service.id)
                                                    setBuyServiceModal(true);
                                                    dispatch(buyAllServices(service.id));
                                                  } else {
                                                    setNoInternetModal(true)
                                                  }
                                                }
                                                }
                                                style={{ width: '100%', display: 'flex', alignItems: 'center', backgroundColor: "black", color: "white" }}
                                              >
                                                {buttonNames[service.id] || "Buy"}
                                              </button>
                                            )
                                            }
                                          </div>
                                        )}

                                        <div className={service.installed ? "ml-auto d-flex align-items-end" : "d-none"}>
                                          <h6 style={{ color: "green", marginBottom: "0" }}>Installed</h6>
                                        </div>
                                        {service.is_purchased && (
                                          <div className={!service.installed ? "ml-auto d-flex align-items-end" : "d-none"}>
                                            {(serviceLoad && serviceLoad === service.id) || service.is_loading ? (
                                              <Button
                                                key={service.id}
                                                className={service.available === false ? "d-none" : "btn secondary_small_btn d-flex justify-content-center"}
                                                style={{
                                                  padding: "0px 6px",
                                                  backgroundColor: "var(--secondary_bg)",
                                                  color: "white",
                                                }}
                                                type="text"
                                                loading
                                              >
                                              </Button>
                                            ) : (
                                              <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                  (serviceData?.service_installing_status || addServiceLoad) ? (
                                                    <Tooltip className="service-install-tooltip" id="tooltip-disabled">
                                                      {serviceName} installation is in progress.
                                                    </Tooltip>
                                                  ) : <></>
                                                }
                                              >
                                                <span className="d-inline-block">
                                                  <button
                                                    key={service.id}
                                                    disabled={!!((serviceData?.service_installing_status || addServiceLoad))}
                                                    className={service.available === false ? "d-none" : "btn secondary_small_btn"}
                                                    onClick={function (event) {
                                                      if (isOnline) {
                                                        setServiceLoad(service.id);
                                                        dispatch(addService(service.id));
                                                        setServiceName(service.label);
                                                        setServiceId(service.id)
                                                        props.serviceName(service.label);
                                                        dispatch(listServices());
                                                      } else {
                                                        setNoInternetModal(true)
                                                      }
                                                    }}

                                                    style={{
                                                      padding: "0px 6px",
                                                      backgroundColor: "var(--secondary_bg)",
                                                      pointerEvents: (serviceData?.service_installing_status || addServiceLoad) ? 'none' : 'auto',
                                                    }}
                                                  >
                                                    <i
                                                      className="ri-add-fill"
                                                      style={{ color: "var(--white)" }}
                                                    ></i>
                                                  </button>
                                                </span>
                                              </OverlayTrigger>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Card.Body>
                                    {!service.available && (
                                      <div className="position-absolute" style={{ top: "38px" }}>
                                        <h6 className="coming-soon-banner" style={{ color: "white", backgroundColor: "red", padding: "5px 9px 5px 8px" }}>Coming Soon...</h6>
                                      </div>
                                    )}
                                  </Card>
                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    ) : (
                      <div className="d-flex justify-content-center mt-5">
                        <div className="text-center w-25">
                          <img src={noServiceFound} alt="" height={"200px"} width={"200px"} />
                          <h4>No Services Found.</h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={buyServiceModal}
                >
                  <Modal.Body>
                    <ServiceConfirmModal
                      item_name={serviceName}
                      onHide={() => setBuyServiceModal(false)}
                    />
                  </Modal.Body>
                </Modal>
                <NoInternetModal
                  show={noInternetModal}
                  onHide={() => setNoInternetModal(false)}
                />
              </Tab.Container>
            )}
          </>
        )}
    </div >
  );
}

export default ServiceOptions;
