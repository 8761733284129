import {
    POST_SETUP_OCCUPANCY_DATA_REQUEST,
    POST_SETUP_OCCUPANCY_DATA_SUCCESS,
    POST_SETUP_OCCUPANCY_DATA_FAIL,
    POST_SETUP_OCCUPANCY_DATA_RESET,
} from '../constants/occupancyMonitoringConstants'

export const postOccupancySetupDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case POST_SETUP_OCCUPANCY_DATA_REQUEST:
            return { loading: true };

        case POST_SETUP_OCCUPANCY_DATA_SUCCESS:
            return { success: true, loading: false };

        case POST_SETUP_OCCUPANCY_DATA_FAIL:
            return { loading: false, error: action.error };

        case POST_SETUP_OCCUPANCY_DATA_RESET:
            return {};

        default:
            return state;
    }
};