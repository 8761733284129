import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const { options, onReady } = props;

    React.useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
            const videoElement = document.createElement("video-js");

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);

            const player = playerRef.current = videojs(videoElement, options, () => {
                if (onReady) {
                    onReady(player);
                    playerLoadingHandler(player)
                }
            });

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        }
    }, [options, videoRef]);

    const playerLoadingHandler = (player) => {
        let videoElement = videoRef.current.querySelector('video-js')

        const newParentElement = document.createElement('div');
        newParentElement.className = "position-absolute top-50 start-50 translate-middle"

        const spinner = videoRef.current.querySelector('.vjs-loading-spinner')
        spinner.classList.remove('vjs-loading-spinner')
        spinner.classList.add('dot-pulse')

        player.on('waiting', function () {
            spinner.classList.remove('d-none')
        });

        player.on('canplay', function () {
            spinner.classList.add('d-none')
        });

        newParentElement.appendChild(spinner)
        videoElement.appendChild(newParentElement)
    }

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    );
}

export default VideoJS;