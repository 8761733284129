import React, { Fragment, useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import RegisterScreenLeftSideBar from '../../components/AuthenticationComponents/RegisterScreenLeftSideBar';
import RegisterScreenRightSideBar from '../../components/AuthenticationComponents/RegisterScreenRightSideBar';
import { emailValidator } from '../../global/Helper';
import { USER_FORGET_PASSWORD_RESET } from '../../constants/userConstants';
import { forgetPassword } from "../../actions/userActions";
import Loading from "../../components/Loading";
import AlertMesaage from "../../components/AlertMesaage";
import axios from "axios";
import { API_ENDPOINT } from '../../constants/apiEndpoint'

function ForgotPasswordScreen() {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let emailInputRef
    let verificationCodeRef

    useEffect(() => {
        emailInputRef = document.getElementById("email")
        verificationCodeRef = document.getElementById("verificationCode")
    })

    const [validEmail, setValidEEmail] = useState(false);
    const [validVerificationCode, setValidVerificationCode] = useState(false);
    const [timer, setTimer] = useState(false);
    const [verificationCodeSendSuccess, setVerificationCodeSendSuccess] = useState(false);

    const passwordForget = useSelector((state) => state.passwordForget);
    const { error, loading, success, } = passwordForget;

    const startTimer = () => {
        let timeLeft = 30;
        const timerId = setInterval(() => {
            if (timeLeft == 0) {
                clearTimeout(timerId);
                setTimer(false)
            } else {
                setTimer(timeLeft)
                timeLeft--;
            }
        }, 1000);
    }

    useEffect(() => {
        if (success) {
            startTimer()
            setVerificationCodeSendSuccess(true)
        }
        if (error || success) {
            setTimeout(() => {
                dispatch({ type: USER_FORGET_PASSWORD_RESET });
            }, 5000);
        }
    }, [success, error])

    const resetPassword = async (event) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            await axios.post(
                `${API_ENDPOINT}/password_reset/validate_token/`,
                { 'token': event.verificationCode },
                config
            );
            reset()
            navigate(`/password-reset/${event.verificationCode}`, { replace: true });
        } catch (error) {
            setValidVerificationCode(true)
            verificationCodeRef.focus()
        }

    }

    const sendVerificationCode = () => {
        if (!emailValidator.test(emailInputRef.value)) {
            emailInputRef.focus()
            setValidEEmail(true)
        } else {
            setValidEEmail(false);
            dispatch(forgetPassword(emailInputRef.value));
        }
    };

    return (
        <div>
            <div className="row g-0 auth-container">
                <div className="col-md-5">
                    <RegisterScreenLeftSideBar />
                </div>
                <div className="col-md-7">
                    <RegisterScreenRightSideBar>
                        <h5 className='mb-5 text-center register_headline'>Forgot Password</h5>
                        {success ? (
                            <AlertMesaage
                                variant="success"
                                children={"Verification Code Sent Successfully."}
                            />
                        ) : (
                            <Fragment>
                                {error && (
                                    <AlertMesaage variant="danger" children={error} />
                                )}
                            </Fragment>)}
                        <Form noValidate onSubmit={handleSubmit(resetPassword)}>
                            <Form.Group className='mb-2'>
                                <Form.Label className='m-0' htmlFor=""><b>Email</b></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    className='border-input'
                                    id="email"
                                    placeholder='Enter Your Email Address'
                                    {...register("email", {
                                        required: "Please provide a valid Email ID.",
                                        pattern: {
                                            value: emailValidator
                                        }
                                    })}
                                    isInvalid={(validEmail || errors.email)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email ID.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="mb-3 mt-2 d-flex justify-content-between">
                                <button
                                    type='button'
                                    onClick={sendVerificationCode}
                                    className={(timer || loading) ? 'text-primary verify-btn is-disable' : 'text-primary verify-btn'}
                                    disabled={!!((timer || loading))}
                                >
                                    {verificationCodeSendSuccess ? "Resend" : "Send"} Verification Code
                                </button>
                                {timer && (
                                    <div className="stop-watch d-flex align-items-center">
                                        <span className="stop-watch-icon"></span>
                                        00:{`${timer}`.length === 1 && 0}{timer}
                                    </div>
                                )}
                            </div>
                            {verificationCodeSendSuccess && <Form.Group className='mb-5'>
                                <Form.Label className='m-0' htmlFor=""><b>Verification Code</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    name="verificationCode"
                                    className='border-input'
                                    id="verificationCode"
                                    placeholder='Enter Verification Code'
                                    {...register("verificationCode", {
                                        required: "Please Enter Verification Code.",

                                    })}
                                    isInvalid={(validVerificationCode || errors.verificationCode)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.verificationCode ? errors.verificationCode.message : "Verification Code is Wrong."}
                                </Form.Control.Feedback>
                            </Form.Group>}
                            <div className="text-center mb-4">
                                <button
                                    type="submit"
                                    onClick={() => {
                                        setValidEEmail(false)
                                        setValidVerificationCode(false)
                                    }}
                                    className={`btn ${(loading) && 'disabled position-relative reset-password-btn'}  ${!verificationCodeSendSuccess && "disabled"}`}
                                >
                                    <b>Reset Password</b>
                                    {loading ? <Loading className="reset-password-loading" /> : null}
                                </button>
                            </div>
                            <p className="text-center m-0">
                                Did you recall your Password?
                                <Link to="/"> Sign In</Link>
                            </p>
                        </Form>
                    </RegisterScreenRightSideBar>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordScreen