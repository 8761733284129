import {
    GET_MASTER_LIST_USER_DATA_REQUEST,
    GET_MASTER_LIST_USER_DATA_SUCCESS,
    GET_MASTER_LIST_USER_DATA_FAIL,

    POST_MASTER_LIST_USER_DATA_REQUEST,
    POST_MASTER_LIST_USER_DATA_SUCCESS,
    POST_MASTER_LIST_USER_DATA_FAIL,
    POST_MASTER_LIST_USER_DATA_RESET,

    UPDATE_MASTER_LIST_USER_DATA_REQUEST,
    UPDATE_MASTER_LIST_USER_DATA_SUCCESS,
    UPDATE_MASTER_LIST_USER_DATA_FAIL,
    UPDATE_MASTER_LIST_USER_DATA_RESET,

    DELETE_MASTER_LIST_USER_DATA_REQUEST,
    DELETE_MASTER_LIST_USER_DATA_SUCCESS,
    DELETE_MASTER_LIST_USER_DATA_FAIL,
    DELETE_MASTER_LIST_USER_DATA_RESET,

} from '../../constants/organizationConstant/masterListConstant'

export const getMasterListUserDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_MASTER_LIST_USER_DATA_REQUEST:
            return { loading: true };

        case GET_MASTER_LIST_USER_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_MASTER_LIST_USER_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const postMasterListUserDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case POST_MASTER_LIST_USER_DATA_REQUEST:
            return { loading: true };

        case POST_MASTER_LIST_USER_DATA_SUCCESS:
            return { success: true, loading: false };

        case POST_MASTER_LIST_USER_DATA_FAIL:
            return { loading: false, error: action.error };

        case POST_MASTER_LIST_USER_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const updateMasterListUserDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case UPDATE_MASTER_LIST_USER_DATA_REQUEST:
            return { loading: true };

        case UPDATE_MASTER_LIST_USER_DATA_SUCCESS:
            return { success: true, loading: false };

        case UPDATE_MASTER_LIST_USER_DATA_FAIL:
            return { loading: false, error: action.error };

        case UPDATE_MASTER_LIST_USER_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const deleteMasterListUserDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case DELETE_MASTER_LIST_USER_DATA_REQUEST:
            return { loading: true };

        case DELETE_MASTER_LIST_USER_DATA_SUCCESS:
            return { success: true, loading: false };

        case DELETE_MASTER_LIST_USER_DATA_FAIL:
            return { loading: false, error: action.error };

        case DELETE_MASTER_LIST_USER_DATA_RESET:
            return {};

        default:
            return state;
    }
};