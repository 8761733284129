import React from 'react';

function ReportSummaryCard({ tooltipData, cardHeading, summaryContent, summaryDetail, count, backgroundImage }) {

  const plottingList = [100, 70, 50, 80, 30, 90, 35, 60]

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  return (
    <div className='summary-card-container position-relative h-100'>
      <div className='summary-card-heading mb-4'>
        <div className='heading ps-2'>
          {count}
          <span className='ms-2'>{cardHeading}</span>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-end px-3" style={{ height: "calc(100% - 62px)" }}>
        <div className='mini-bar-graph d-flex align-items-end gap-2'>
          {shuffle(plottingList)?.map((e, index) => (
            <div key={`${index + 1}`} className='h-100 d-flex align-items-end gap-2'>
              {100 === e ? (
                <div className='chart position-relative' key={`${index + 1}`} style={{ background: 100 === e ? "#E73F49" : "#D6D6D6", height: `${e}%` }}>
                  <div className='summary-tooltip position-absolute text-white'>{tooltipData}</div>
                </div>
              ) : (
                <div className='chart' key={`${index + 1}`} style={{ background: 100 === e ? "#E73F49" : "#D6D6D6", height: `${e}%` }}></div>
              )}
            </div>
          ))}
        </div>
        <div className='summary-status text-end'>
          <div className='fst-italic text'>{summaryContent}</div>
          <div className='fst-italic text-dark'>{summaryDetail}</div>
        </div>
      </div>
      <img src={backgroundImage} alt="loading" className='position-absolute bottom-0 end-0' />
    </div >
  )
}

export default ReportSummaryCard