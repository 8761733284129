import axios from "axios";
import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { Spinner, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'antd';
import { cameraFrameRefresh } from "../../../actions/dataActions";
import CropKonva from "../../../components/jCrop/CropKonva";
import { API_ENDPOINT } from '../../../constants/apiEndpoint'
import { getServicesData } from "../../../actions/servicesActions";
import { DefaultUpdateAlerts } from "../../../components/ToastAlert";
import { toast } from "react-toastify";
import SeatCard from "./SeatCard";
import addArea from '../../../components/jCrop/icons/addArea.png'
import noDataFound from '../../../images/report-summay/no-data-found.gif'
import { areaImageHeight, areaImageWidth } from "../../../global/Helper";
import ClassesDropdown from "../ClassesDropdown";
import { postAPI } from "../../../global/ApiManager";

function SeatingArrangement(props) {

    const {
        setShow,
    } = props

    const [imageSelectionParams, setImageSelectionParams] = useState([]);
    const [redoPoint, setRedoPoints] = useState([]);
    const [disableSaveBtn, setDisableSaveBtn] = useState(false);

    const [updateServiceLoading, setUpdateServiceLoading] = useState(false);
    const [rectangles, setRectangles] = useState([]);
    const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
    const [selectedEditableIndex, setSelectedEditableIndex] = useState(null);
    const [ClassesName, setClassesName] = useState([]);
    const [AccuracyLevel, setAccuracyLevel] = useState(0.75);

    const dispatch = useDispatch();

    const RefreshCameraFrame = useSelector((state) => state.RefreshCameraFrame);
    const { loading: frameLoad } = RefreshCameraFrame;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const getNotDeleteRectangle = useMemo(() => rectangles.filter(e => !e.is_delete), [rectangles])

    const ServicesDataGetSingle = useSelector((state) => state.ServicesDataGetSingle);
    const { success: configureServiceData } = ServicesDataGetSingle;

    useEffect(() => {
        if (configureServiceData.extra_fields?.occupancy_monitoring_seats) {
            setRectangles(configureServiceData.extra_fields?.occupancy_monitoring_seats)
        }
    }, [configureServiceData])

    useEffect(() => {
        setDisableSaveBtn(true)
        if (getNotDeleteRectangle.length > 0 && JSON.stringify(configureServiceData?.extra_fields?.occupancy_monitoring_seats?.sort()) !== JSON.stringify(getNotDeleteRectangle.sort())) {
            setDisableSaveBtn(false)
        }
    }, [getNotDeleteRectangle, configureServiceData])

    const camera_area_selection_handler = async (e) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };

        let post_data = {
            service_id: configureServiceData.service.id,
            camera_id: configureServiceData.camera.camera_id,
            image_selection_params: imageSelectionParams,
            selected_classes: ClassesName,
            confidence_score: Number(AccuracyLevel.toFixed(2)),
            occupancy_monitoring_seats: getNotDeleteRectangle,
        };

        setUpdateServiceLoading(true)
        await postAPI(`/service_app/update_service_on_camera/`, post_data, config, dispatch);
        setShow(false);
        dispatch(getServicesData())
        toast(<DefaultUpdateAlerts name={configureServiceData.camera.camera_name} />);
        setUpdateServiceLoading(false)
    };

    const onSeatInputChange = (e, index) => {
        const updatedRectangles = [...rectangles];
        updatedRectangles[index] = {
            ...updatedRectangles[index],
            text: e.target.value
        };
        setRectangles(updatedRectangles);
    }

    const onRectangleChange = (index) => {
        const newRectangle = rectangles?.map(rect => ({
            ...rect,
            stroke: "#4AC237",
            textBackground: "#4AC237"
        }))

        const updatedRectangles = [...newRectangle];
        if (index >= 0) {
            updatedRectangles[index] = {
                ...updatedRectangles[index],
                stroke: "#333",
                textBackground: "#333",
            };
        }
        setRectangles(updatedRectangles);
    }

    const onSeatEdit = (index) => {
        setSelectedShapeIndex(index)
        selectedEditableIndex === index ? setSelectedEditableIndex(null) : setSelectedEditableIndex(index)
        onRectangleChange(index)
    }

    const deleteRectangle = (index, status) => {
        const updatedRectangles = [...rectangles];
        updatedRectangles[index] = {
            ...updatedRectangles[index],
            is_delete: status
        };
        setRectangles(updatedRectangles);
    }

    const permanentDelete = (index) => {
        setRectangles(rectangles.filter((e, i) => i !== index))
    }

    const addRectangle = () => {
        const newRectangle = {
            x: areaImageWidth / 2 - 50,
            y: areaImageHeight / 2 - 50,
            width: 110,
            height: 130,
            fill: '#4AC2374D',
            stroke: '#4AC237',
            textBackground: '#4AC237',
            strokeWidth: 1,
            draggable: true,
            is_delete: false,
            text: `Seat No. ${rectangles.length + 1}`,
        };
        setRectangles([...rectangles, newRectangle]);
    };

    const undoAddedSeats = useCallback(() => {
        const updatedRectangles = [...getNotDeleteRectangle];

        const getDeleteSeat = updatedRectangles.pop()

        setRectangles(updatedRectangles);

        setRedoPoints([...redoPoint, getDeleteSeat])
    }, [rectangles])

    const redoAddedSeats = useCallback(() => {
        const updatedRectangles = [...rectangles];

        const getDeleteSeat = redoPoint.pop()

        updatedRectangles.push(getDeleteSeat)

        setRectangles(updatedRectangles);
    }, [rectangles, redoPoint])

    useEffect(() => {
        const getRectangleArea = rectangles?.map((rect) => {
            const x = Math.round(rect.x)
            const y = Math.round(rect.y)
            const width = Math.round(rect.width)
            const height = Math.round(rect.height)

            return (
                [
                    {
                        x: x,
                        y: y,
                    },
                    {
                        x: x + width,
                        y: y,
                    },
                    {
                        x: x + width,
                        y: y + height,
                    },
                    {
                        x: x,
                        y: y + height,
                    },
                ]
            );
        })

        setImageSelectionParams(getRectangleArea)
    }, [rectangles])

    useEffect(() => {
        configureServiceData.extra_fields?.selected_classes && setClassesName(configureServiceData.extra_fields.selected_classes)
        configureServiceData.extra_fields?.confidence_score && setAccuracyLevel(configureServiceData.extra_fields.confidence_score)
    }, [configureServiceData.extra_fields])

    const options = useMemo(() => configureServiceData.service?.all_classes?.map(e => e.class_name), [configureServiceData])

    return (
        <Modal.Body className="p-0 service-config-modal">
            <Fragment>
                <Modal.Header className="position-relative justify-content-between pt-3 px-4" closeButton>
                    <Modal.Title className="fs-5">
                        {configureServiceData?.service?.label} - {configureServiceData?.camera?.camera_name}
                    </Modal.Title>
                </Modal.Header>
                <div className="row m-0 pt-3 pb-4">
                    <div className="col-md-8 px-4">
                        <div className="position-relative">
                            <div className={`jcrop_modal d-flex justify-content-center overflow-auto ${frameLoad && 'filter-class'}`}>
                                <CropKonva
                                    width={areaImageWidth}
                                    height={areaImageHeight}
                                    send_image={configureServiceData?.camera?.image}
                                    rectangles={rectangles}
                                    setRectangles={setRectangles}
                                    selectedShapeIndex={selectedShapeIndex}
                                    setSelectedShapeIndex={setSelectedShapeIndex}
                                    deleteRectangle={deleteRectangle}
                                    editRectangle={onSeatEdit}
                                    setSelectedEditableIndex={setSelectedEditableIndex}
                                    onRectangleChange={onRectangleChange}
                                    squareName='Seat No.'
                                />
                            </div>
                            {frameLoad &&
                                <div className="position-absolute spinner-position">
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                            }
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4 gap-3 overflow-hidden m-auto" style={{ maxWidth: `${areaImageWidth}px` }}>
                            <button
                                className="refresh-frame-btn border_radius_sm d-flex align-items-center justify-content-center"
                                title="Refresh Frame"
                                onClick={() => dispatch(cameraFrameRefresh({ camera_id: configureServiceData?.camera?.camera_id }, configureServiceData.id))}
                                disabled={frameLoad}
                            >
                                <i className={`ri-refresh-line ${frameLoad && 'rotate'}`}></i>
                            </button>
                            <button
                                className={`undo-btn border_radius_sm ${!getNotDeleteRectangle?.length > 0 && 'is-disable'}`}
                                title="Undo"
                                disabled={getNotDeleteRectangle?.length <= 0}
                                onClick={undoAddedSeats}
                            >
                                <i className="ri-arrow-go-back-line"></i>
                            </button>
                            <button
                                className={`undo-btn border_radius_sm ${redoPoint.length === 0 && 'is-disable'}`}
                                title="Redo"
                                disabled={redoPoint.length === 0}
                                onClick={redoAddedSeats}
                            >
                                <i className="ri-arrow-go-forward-line"></i>
                            </button>
                            <button
                                className={`select_again_txt border_radius_sm border-0 text-nowrap ${!getNotDeleteRectangle?.length > 0 && 'is-disable'}`}
                                disabled={!getNotDeleteRectangle?.length > 0}
                                onClick={() => {
                                    if (window.confirm("Do you really want to remove all seats?")) {
                                        setRectangles([])
                                    }
                                }}
                            >
                                Select Again
                            </button>
                            <ClassesDropdown
                                options={options}
                                ClassesName={ClassesName}
                                setClassesName={setClassesName}
                                setAccuracyLevel={setAccuracyLevel}
                                AccuracyLevel={AccuracyLevel}
                                showAccuracySlider={true}
                                multiple={true}
                            />
                            {updateServiceLoading ? (
                                <Button
                                    type="primary"
                                    className="btn btn_primary ms-auto"
                                    size="large"
                                    loading
                                >
                                    Save
                                </Button>
                            ) : (
                                <button
                                    className="btn btn_primary px-4 ms-auto"
                                    disabled={disableSaveBtn}
                                    onClick={camera_area_selection_handler}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4 ps-0 pe-4">
                        <div className="all-seats-container w-100 h-100 p-3">
                            <div className="d-flex mb-3">
                                <div className="fs-5" style={{ fontWeight: "500" }}>All Seats</div>
                                <button
                                    className="btn bg-grey border_radius_sm add-seat-btn ms-auto"
                                    onClick={addRectangle}
                                >
                                    <img
                                        src={addArea}
                                        width={20}
                                        height={20}
                                        className="me-2"
                                    />
                                    Add New Seat
                                </button>
                            </div>
                            <div className="seat-card-container position-relative h-100">
                                {rectangles?.length !== 0 ? (
                                    rectangles?.map((e, index) => (
                                        <SeatCard
                                            key={`${e.text + index}`}
                                            values={e}
                                            isSelected={selectedShapeIndex === index}
                                            isEditable={selectedEditableIndex === index}
                                            onInputChange={(e) => onSeatInputChange(e, index)}
                                            onEdit={() => onSeatEdit(index)}
                                            onDelete={() => deleteRectangle(index, true)}
                                            onUndo={() => deleteRectangle(index, false)}
                                            permanentDelete={() => permanentDelete(index)}
                                        />
                                    ))
                                ) : (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            height: "fit-content",
                                            position: "absolute",
                                            top: "50px",
                                            bottom: "0",
                                            left: "0",
                                            right: "0",
                                        }}
                                    >
                                        <div className="text-center">
                                            <img src={noDataFound} height={"180px"} width={"180px"} />
                                            <h6 className="opacity fw-bold">No Seats Available.</h6>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </Modal.Body>
    )
}

export default SeatingArrangement