import {
    HOMEPAGE_DATA_GET_REQUEST,
    HOMEPAGE_DATA_GET_SUCCESS,
    HOMEPAGE_DATA_GET_FAIL,
    HOMEPAGE_DATA_GET_RESET,

    ADD_CAMERA_REQUEST,
    ADD_CAMERA_SUCCESS,
    ADD_CAMERA_FAIL,

    UPDATE_CAMERA_REQUEST,
    UPDATE_CAMERA_SUCCESS,
    UPDATE_CAMERA_FAIL,
    UPDATE_CAMERA_RESET,

    ADD_CAMERA_RESET,
    DELETE_CAMERA_REQUEST,
    DELETE_CAMERA_SUCCESS,
    DELETE_CAMERA_FAIL,
    DELETE_CAMERA_RESET,

    GET_CAMERA_USER_REQUEST,
    GET_CAMERA_USER_SUCCESS,
    GET_CAMERA_USER_FAIL,
    GET_CAMERA_USER_RESET,

    CREATE_CAMERA_USER_REQUEST,
    CREATE_CAMERA_USER_SUCCESS,
    CREATE_CAMERA_USER_FAIL,

    GET_NOTIFIACTION_REQUEST,
    GET_NOTIFIACTION_SUCCESS,
    GET_NOTIFIACTION_FAIL,

    WEBCAM_DETAIL_REQUEST,
    WEBCAM_DETAIL_SUCCESS,
    WEBCAM_DETAIL_FAIL,
    WEBCAM_DETAIL_RESET,

    CAMERA_FRAME_REFRESH_REQUEST,
    CAMERA_FRAME_REFRESH_SUCCESS,
    CAMERA_FRAME_REFRESH_FAIL,

    GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST,
    GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS,
    GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL,

    GET_PAGE_ACCESS_REQUEST,
    GET_PAGE_ACCESS_SUCCESS,
    GET_PAGE_ACCESS_FAIL,

} from "../constants/dataConstants";
import { toast } from "react-toastify";
import { CmeraAlert } from "../components/ToastAlert";

export const dataListReducer = (state = { homedata: [] }, action) => {
    switch (action.type) {
        case HOMEPAGE_DATA_GET_REQUEST:
            return {
                // homedata: state.homedata,
                loading: true,
            };

        case HOMEPAGE_DATA_GET_SUCCESS:
            return {
                homedata: action.payload,
                loading: false,
            };

        case HOMEPAGE_DATA_GET_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        // case DATA_GET_RESET:
        //     return {
        //         homedata: []
        //     }

        default:
            return state;
    }
};

export const addCameraReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_CAMERA_REQUEST:
            return { loading: true };

        case ADD_CAMERA_SUCCESS:
            return { loading: false, cameraAddSuccess: true };

        case ADD_CAMERA_FAIL:
            toast(<CmeraAlert message={action.payload} />);
            return { loading: false, error: action.payload };

        case ADD_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};


export const updateCameraReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CAMERA_REQUEST:
            return { loading: true };

        case UPDATE_CAMERA_SUCCESS:
            return { loading: false, cameraUpdateSuccess: true };

        case UPDATE_CAMERA_FAIL:
            toast(<CmeraAlert message={action.payload} />);
            return { loading: false, error: action.payload };

        case UPDATE_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};


export const cameraDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_CAMERA_REQUEST:
            return { loading: true };

        case DELETE_CAMERA_SUCCESS:
            return { loading: false, cameraDeleteSuccus: true }

        case DELETE_CAMERA_FAIL:
            return { loading: false, error: action.payload };

        case DELETE_CAMERA_RESET:
            return {};

        default:
            return state;
    }
};

export const cameraUserReducer = (state = { userGetSuccess: [] }, action) => {
    switch (action.type) {
        case GET_CAMERA_USER_REQUEST:
            return { loading: true };

        case GET_CAMERA_USER_SUCCESS:
            return { loading: false, userGetSuccess: action.payload }

        case GET_CAMERA_USER_FAIL:
            return { loading: false, error: action.payload };

        case GET_CAMERA_USER_RESET:
            return {};

        default:
            return state;
    }
};

export const createCameraUserReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CAMERA_USER_REQUEST:
            return { loading: true };

        case CREATE_CAMERA_USER_SUCCESS:
            return { loading: false, userAddSuccess: true }

        case CREATE_CAMERA_USER_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};

export const GetNotification = (state = { notifications: {} }, action) => {
    switch (action.type) {
        case GET_NOTIFIACTION_REQUEST:
            return {
                notifications: { ...state.notifications },
                loading: true,
            };

        case GET_NOTIFIACTION_SUCCESS:
            return {
                notifications: action.payload,
                loading: false,
            };

        case GET_NOTIFIACTION_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case HOMEPAGE_DATA_GET_RESET:
            return {
                notifications: [],
            };

        default:
            return state;
    }
};


export const webCamDetailReducer = (state = { cameraGetSuccess: {} }, action) => {
    switch (action.type) {
        case WEBCAM_DETAIL_REQUEST:
            return { loading: true };

        case WEBCAM_DETAIL_SUCCESS:
            return { loading: false, cameraGetSuccess: action.payload };

        case WEBCAM_DETAIL_FAIL:
            return { loading: false, error: action.payload };

        case WEBCAM_DETAIL_RESET:
            return {};

        default:
            return state;
    }
};


export const cameraFrameRefreshReducer = (state = {}, action) => {
    switch (action.type) {
        case CAMERA_FRAME_REFRESH_REQUEST:
            return { loading: true };

        case CAMERA_FRAME_REFRESH_SUCCESS:
            return { loading: false, success: true }

        case CAMERA_FRAME_REFRESH_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const getNavigationBarPageAccessReducer = (state = { success: {} }, action) => {
    switch (action.type) {
        case GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST:
            return { loading: true, success: {} };

        case GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS:
            return { loading: false, success: action.payload }

        case GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const getPageAccessReducer = (state = { success: {} }, action) => {
    switch (action.type) {
        case GET_PAGE_ACCESS_REQUEST:
            return { loading: true, success: { ...state.success } };

        case GET_PAGE_ACCESS_SUCCESS:
            return { loading: false, success: action.payload }

        case GET_PAGE_ACCESS_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};