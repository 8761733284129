import React, { useEffect, useState } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from "react-redux";
import SelectCamera from '../../analyticsSetupPage/analyticsSetupComponents/SelectCamera';
import { getConfigServices } from '../../../actions/organizationActions/rolesAndResponsibilityActions';

function AccessStep(props) {

    const {
        setServicesValue,
        ServicesValue,
        setSelectedCamera,
        SelectedCamera,
        setSelectedCameraServices,
        SelectedCameraServices,
        setSelectServiceCameraId,
        SelectServiceCameraId,
        setSelectAllCamera,
        SelectAllCamera,
        SelectedServicesId,
    } = props

    const [ServicesOption, setServicesOption] = useState([]);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        async function startFetching() {
            setServicesOption([])
            const { data } = await getConfigServices(userInfo?.token)
            if (!ignore) {
                setServicesOption([{ service_name: "All Services", id: "#", service_icon: '' }].concat(data))
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [])


    useEffect(() => {
        if (SelectedServicesId) {
            setServicesValue(ServicesOption?.filter(e => e?.id === SelectedServicesId))
        }
    }, [ServicesOption])

    return (
        <div className='user-access-container mt-3 h-100'>
            <div className='mb-3 service-type-select-container w-50'>
                <label className='m-0'>Services</label>
                <Multiselect
                    onSelect={(e) => {
                        setServicesValue(e);
                        if (e[0].service_name.toLowerCase() === "all services") {
                            setSelectedCamera([])
                            setSelectedCameraServices([])
                            setSelectServiceCameraId([])
                            setSelectAllCamera(false)
                        }
                        if (e[0].service_name.toLowerCase() !== "all services" && !SelectedCameraServices?.map(s => s.service_id).includes(e[0].id)) {
                            setSelectServiceCameraId([])
                            setSelectedCameraServices([...SelectedCameraServices, { service_id: e[0].id, all_cameras: [] }])
                        }
                    }}
                    onRemove={(e) => {
                        setSelectedCamera([])
                        setSelectedCameraServices([])
                        setSelectServiceCameraId([])
                        setServicesValue(e);
                        setSelectAllCamera(false)
                    }}
                    selectedValues={ServicesValue}
                    isObject={true}
                    className={`${ServicesValue.length !== 0 && 'remove-placeholder'}`}
                    singleSelect={true}
                    showCheckbox={true}
                    options={ServicesOption}
                    placeholder='Select Services'
                    displayValue="service_name"
                    emptyRecordMsg="No Services Available."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    customArrow={<i className="fas fa-angle-down iconDrop"></i>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
            <SelectCamera
                ServicesOption={ServicesOption}
                selectedService={ServicesValue}
                SelectedCamera={SelectedCamera}
                setSelectedCamera={(e) => setSelectedCamera(e)}
                userAccess={true}
                SelectedCameraServices={SelectedCameraServices}
                setSelectServiceCameraId={(e) => setSelectServiceCameraId(e)}
                SelectServiceCameraId={SelectServiceCameraId}
                SelectAllCamera={SelectAllCamera}
                setSelectAllCamera={(e) => setSelectAllCamera(e)}
            />
        </div>
    )
}

export default AccessStep