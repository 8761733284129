import {
    SUBSCRIPTION_SERVICES_ALERT_REQUEST,
    SUBSCRIPTION_SERVICES_ALERT_SUCCESS,
    SUBSCRIPTION_SERVICES_ALERT_FAIL,

    SUBSCRIPTION_SERVICES_LIST_REQUEST,
    SUBSCRIPTION_SERVICES_LIST_SUCCESS,
    SUBSCRIPTION_SERVICES_LIST_FAIL,

    SEND_SUBSCRIPTION_REQUEST,
    SEND_SUBSCRIPTION_SUCCESS,
    SEND_SUBSCRIPTION_FAIL,
    SEND_SUBSCRIPTION_RESET,

    GET_SERVICE_DATA_REQUEST,
    GET_SERVICE_DATA_SUCCESS,
    GET_SERVICE_DATA_FAIL,
    GET_SERVICE_DATA_RESET,
}
    from '../constants/subscriptionConstant'


export const subscribeServicesReducer = (state = { serviceData: [] }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_SERVICES_ALERT_REQUEST:
            return { loading: true };

        case SUBSCRIPTION_SERVICES_ALERT_SUCCESS:
            return { loading: false, serviceData: action.payload };

        case SUBSCRIPTION_SERVICES_ALERT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
};


export const subscribeServicesListReducer = (state = { serviceData: {} }, action) => {

    switch (action.type) {

        case SUBSCRIPTION_SERVICES_LIST_REQUEST:
            return { loading: true, serviceData: { ...state.serviceData } };

        case SUBSCRIPTION_SERVICES_LIST_SUCCESS:
            return { loading: false, serviceData: action.payload };

        case SUBSCRIPTION_SERVICES_LIST_FAIL:
            return {
                loading: false, error: action.payload, serviceData: {}
            };

        default:
            return state;
    }
};


export const sendSubscribeEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_SUBSCRIPTION_REQUEST:
            return { loading: true };

        case SEND_SUBSCRIPTION_SUCCESS:
            return { loading: false, success: true };

        case SEND_SUBSCRIPTION_FAIL:
            return { loading: false, error: action.payload };

        case SEND_SUBSCRIPTION_RESET:
            return {};

        default:
            return state;
    }
};


export const getServiceDetailReducer = (state = { serviceData: [] }, action) => {
    switch (action.type) {
        case GET_SERVICE_DATA_REQUEST:
            return { loading: true };

        case GET_SERVICE_DATA_SUCCESS:
            return { loading: false, serviceData: action.payload };

        case GET_SERVICE_DATA_FAIL:
            return { loading: false, error: action.payload };

        case GET_SERVICE_DATA_RESET:
            return {};

        default:
            return state;
    }
};