import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
    getAlertMechanismDataReducer,
    postAlertMechanismDataReducer,
    getListAlertMechanismDataReducer,
    alertMechanismDeleteReducer,
    updateAlertMechanismDataReducer,
} from './reducers/alertMechanismReducers'

import {
    userLoginReducer,
    userRegisterReducer,
    userListReducer,
    forgetPasswordReducer,
    newPasswordReducer,
    userLiveCamAccessReducer,
    userRemoveLiveCamAccessReducer,
    userDeleteReducer,
    createUserReducer,
    createUserEmailVerificationReducer,
} from './reducers/userReducers'

import {
    servicesListReducer,
    getServicesDataReducer,
    getSingleServicesDataReducer,
    servicesCreateReducer,
    servicesDeleteReducer,
    serviceConfigReducer,
    serviceConfigDeleteReducer,
    servicesConfigCreateReducer,
    servicesUpdateReducer,
    getServicesUserReducer,
    getAlertDataReducer,
    addServicesReducer,
    getCameraReducer,
    allServiceDeleteReducer,
    addFaceReducer,
    buyServicesReducer,
    ServicesOn_Off_Reducer,
    Services_version_update_Reducer,
    getTimeWiseCameraScheduleReducer,
    updateTimeWiseCameraScheduleReducer,
    Retrain_process_Reducer,
    Check_retrain_status_Reducer,
} from './reducers/servicesReducers'

import {
    dataListReducer,
    addCameraReducer,
    GetNotification,
    cameraDeleteReducer,
    cameraUserReducer,
    createCameraUserReducer,
    updateCameraReducer,
    webCamDetailReducer,
    cameraFrameRefreshReducer,
    getNavigationBarPageAccessReducer,
    getPageAccessReducer,
} from "./reducers/dataReducers";

import {
    subscribeServicesReducer,
    subscribeServicesListReducer,
    sendSubscribeEmailReducer,
    getServiceDetailReducer,
} from './reducers/subscriptionReducers'

import { getLivecamDataReducer, getVideoRefReducer } from './reducers/livecamReducer'
import { getUserProfileReducer, updateUserReducer } from './reducers/settingReducer'

import {
    getAnalyticsServicesReducer,
    postAnalyticsFilterDataReducer,
    getSingleAnalyticsDataReducer,
} from './reducers/analyticsReducers'

import {
    getFaceRecognitionCameraReducer,
    deleteFaceRecognitionCameraReducer,
    getFaceRecognitionUsersReducer,
    deleteFaceRecognitionUserReducer,
    addUserInFaceRecognitionReducer,
    getUsersConfigurationReducer,
    applyUsersInFaceRecognitionCameraReducer,
    removeUsersInFaceRecognitionCameraReducer,
    getCamerasConfigurationReducer,
    applyCamerasInFaceRecognitionUserReducer,
    restoreFaceReducer,
    recycledFacesReducer,
    deleteFacePermanentReducer,
    multipleAngleFaceValidationReducer,
} from './reducers/faceRecognitionReducers'

import { EventFilterOptionsReducer, getExcelExportEventDataReducer, postDoubtfulEventReducer, postRetrainDataReducer } from './reducers/EventReducer'

import {
    getMasterListUserDataReducer,
    postMasterListUserDataReducer,
    updateMasterListUserDataReducer,
    deleteMasterListUserDataReducer,
} from './reducers/organizationReducers/masterListReducers'

import {
    getRolesAndResponsibilityDataReducer,
    postRolesAndResponsibilityDataReducer,
    updateRolesAndResponsibilityDataReducer,
    deleteRolesAndResponsibilityDataReducer,
    getSingleRolesAndResponsibilityDataReducer,
} from './reducers/organizationReducers/rolesAndResponsibilityReducers'

import { postOccupancySetupDataReducer } from './reducers/occupancyMonitoringReducers'

import {
    getBackupAndRestoreDataReducer,
    putBackupAndRestoreDataReducer,
} from './reducers/organizationReducers/backupAndRestoreReducers'

import {
    getStorageManagementDataReducer,
    putStorageManagementDataReducer,
} from './reducers/organizationReducers/storageManagementReducers'

import {
    getSoftwareUpdateReducer, instantSoftwareUpdateReducer, putSoftwareUpdateDataReducer,
} from './reducers/organizationReducers/softwareUpdateReducers'

import {
    getAddOnDataReducer,
    postAddOnDataInstallReducer,
    getAddOnDataInstallReducer,
    deleteAddOnDataInstallReducer,
    addToShortcutReducer
} from './reducers/organizationReducers/addOnsReducers'

const reducer = combineReducers({
    alertMechanismGetData: getAlertMechanismDataReducer,
    alertMechanismPostData: postAlertMechanismDataReducer,
    alertMechanismGetListData: getListAlertMechanismDataReducer,
    deleteAlertMechanism: alertMechanismDeleteReducer,
    alertMechanismUpdateData: updateAlertMechanismDataReducer,

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userList: userListReducer,
    passwordForget: forgetPasswordReducer,
    liveUserCamAccess: userLiveCamAccessReducer,
    removeUserLiveCamAccess: userRemoveLiveCamAccessReducer,
    deleteUser: userDeleteReducer,
    userCreate: createUserReducer,
    userCreateEmailVerification: createUserEmailVerificationReducer,

    changePassword: newPasswordReducer,
    HomePageData: dataListReducer,
    LivecamData: getLivecamDataReducer,
    AddCameraData: addCameraReducer,
    deleteCamera: cameraDeleteReducer,
    userCamera: cameraUserReducer,
    createCameraUser: createCameraUserReducer,
    CameraUpdate: updateCameraReducer,
    detailWebcam: webCamDetailReducer,
    videoRefGet: getVideoRefReducer,

    navigationBarPageAccessGet: getNavigationBarPageAccessReducer,
    pageAccessGet: getPageAccessReducer,

    servicesList: servicesListReducer,
    ServicesData: getServicesDataReducer,
    ServicesDataGetSingle: getSingleServicesDataReducer,
    serviceConfig: serviceConfigReducer,
    createService: servicesCreateReducer,
    servicesUpdate: servicesUpdateReducer,
    deleteConfigService: serviceConfigDeleteReducer,
    configServiceCreate: servicesConfigCreateReducer,
    getConfigUsers: getServicesUserReducer,
    AlertData: getAlertDataReducer,
    serviceAdd: addServicesReducer,
    cameraGet: getCameraReducer,
    AllServiceDelete: allServiceDeleteReducer,
    GetNotification: GetNotification,
    deleteService: servicesDeleteReducer,
    faceAdd: addFaceReducer,
    buyServices: buyServicesReducer,
    RefreshCameraFrame: cameraFrameRefreshReducer,
    subscribeServices: subscribeServicesReducer,
    subscribeServicesList: subscribeServicesListReducer,
    sendSubscribeEmail: sendSubscribeEmailReducer,
    cameraFrameRefresh: getServiceDetailReducer,
    serviceGetDetail: getServiceDetailReducer,

    On_Off_Services: ServicesOn_Off_Reducer,
    SetServiceVersion: Services_version_update_Reducer,
    retrain_process: Retrain_process_Reducer,
    retrain_status: Check_retrain_status_Reducer,
    retrain_save_data: postRetrainDataReducer,

    TimeWiseCameraScheduleGet: getTimeWiseCameraScheduleReducer,
    TimeWiseCameraScheduleUpdate: updateTimeWiseCameraScheduleReducer,

    userProfileGet: getUserProfileReducer,
    updateUser: updateUserReducer,

    faceRecognitionCameraGet: getFaceRecognitionCameraReducer,
    faceRecognitionUsersGet: getFaceRecognitionUsersReducer,
    faceRecognitionDeleteCamera: deleteFaceRecognitionCameraReducer,
    faceRecognitionDeleteUser: deleteFaceRecognitionUserReducer,
    userAddInFaceRecognition: addUserInFaceRecognitionReducer,
    usersGetConfiguration: getUsersConfigurationReducer,
    usersApplyInFaceRecognitionCamera: applyUsersInFaceRecognitionCameraReducer,
    usersRemoveInFaceRecognitionCamera: removeUsersInFaceRecognitionCameraReducer,
    camerasGetConfiguration: getCamerasConfigurationReducer,
    camerasApplyInFaceRecognitionUser: applyCamerasInFaceRecognitionUserReducer,
    restorFace: restoreFaceReducer,
    recycledFace: recycledFacesReducer,
    FaceDeletePermanent: deleteFacePermanentReducer,
    faceValidation: multipleAngleFaceValidationReducer,

    GetEventFilterOption: EventFilterOptionsReducer,
    excelExportEventGetData: getExcelExportEventDataReducer,
    doubtfulEventPost: postDoubtfulEventReducer,

    analyticsGetServices: getAnalyticsServicesReducer,
    analyticsFilterPostData: postAnalyticsFilterDataReducer,
    analyticsSingleDataGet: getSingleAnalyticsDataReducer,

    masterListUserDataGet: getMasterListUserDataReducer,
    masterListUserDataPost: postMasterListUserDataReducer,
    masterListUserDataUpdate: updateMasterListUserDataReducer,
    masterListUserDataDelete: deleteMasterListUserDataReducer,

    RolesAndResponsibilityDataGet: getRolesAndResponsibilityDataReducer,
    RolesAndResponsibilityDataGetSingle: getSingleRolesAndResponsibilityDataReducer,
    RolesAndResponsibilityDataPost: postRolesAndResponsibilityDataReducer,
    RolesAndResponsibilityDataUpdate: updateRolesAndResponsibilityDataReducer,
    RolesAndResponsibilityDataDelete: deleteRolesAndResponsibilityDataReducer,

    OccupancySetupDataPost: postOccupancySetupDataReducer,
    BackupAndRestoreDataGet: getBackupAndRestoreDataReducer,
    BackupAndRestoreDataPut: putBackupAndRestoreDataReducer,
    StorageManagementDataGet: getStorageManagementDataReducer,
    StorageManagementDataPut: putStorageManagementDataReducer,

    SoftwareUpdateDataGet: getSoftwareUpdateReducer,
    SoftwareUpdateDataPut: putSoftwareUpdateDataReducer,
    SoftwareUpdateInstant: instantSoftwareUpdateReducer,

    AddOnData: getAddOnDataReducer,
    InstallAddOnPost: postAddOnDataInstallReducer,
    InstalledAddOnData: getAddOnDataInstallReducer,
    InstallAddOnDelete: deleteAddOnDataInstallReducer,
    AddToShortCut: addToShortcutReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null


const initialState = {

    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store