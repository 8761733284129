import React, { Fragment, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function DeleteCircularProgress(props) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
        }, 50);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (progress >= 100) {
            props.permanentDelete()
        }
    }, [progress])

    return (
        <Fragment>
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: "#33333326",
                    }}
                    size={36}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: "var(--warning)",
                        position: 'absolute',
                        left: 0,
                    }}
                    size={36}
                    thickness={4}
                    value={progress}
                />
                <div className='position-absolute top-0 bottom-0 text-center start-0 end-0' style={{ height: "fit-content", marginTop: "5.5px" }}>
                    <i className="ri-delete-bin-5-line fs-6"></i>
                </div>
            </Box>
            <div style={{ fontSize: "14px" }}>{props.text} has been deleted</div>
            <button
                className='text-decoration ms-auto undo-btn'
                onClick={props.onUndo}
            >
                Undo
            </button>
        </Fragment>
    );
}
function SeatCard(props) {
    const {
        values,
        onEdit,
        onDelete,
        isEditable,
        isSelected,
        onInputChange,
        permanentDelete,
        onUndo,
    } = props;

    const inputRef = useRef(null);

    useEffect(() => {
        if (isEditable) {
            inputRef.current.focus()
        }
    }, [isEditable, inputRef])


    return (
        <div className={`d-flex seat-card align-items-center gap-2 p-2 mb-2 ${isSelected && 'border'}`}>
            {!values.is_delete ? (
                <Fragment>
                    <input
                        ref={inputRef}
                        type="text"
                        value={values.text || ''}
                        autoComplete={'false'}
                        onFocus={(e) => e.target.select()}
                        onChange={onInputChange}
                        disabled={!isEditable}
                    />
                    <div className="d-flex gap-2 ms-auto">
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center"
                            onClick={onEdit}
                        >
                            <i className="ri-edit-line fs-5"></i>
                        </button>
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center"
                            onClick={onDelete}
                        >
                            <i className="ri-delete-bin-5-line fs-5"></i>
                        </button>
                    </div>
                </Fragment>
            ) : (
                <DeleteCircularProgress
                    onUndo={onUndo}
                    permanentDelete={permanentDelete}
                    text={values.text}
                />
            )}
        </div>
    )
}

export default SeatCard