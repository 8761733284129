import emailIcon from "../images/email.png";

const ServiceConfirmModal = (props) => {

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center mt-2 text-center">
                <h4 className="mb-0 email_icon"><img src={emailIcon} /></h4>
                <h3 className='mt-3'>Thank you for showing interest in {props.item_name} Service.</h3>
                <h5 className="mb-0">Our team will get in touch with you shortly!</h5>
            </div>
            <div className="d-flex align-itmes-center justify-content-center mt-4">
                <button
                    className="btn btn_primary px-3 px-4"
                    onClick={() => {
                        props.onHide()
                    }}
                >
                    Ok
                </button>

            </div>
        </>
    );
}

export default ServiceConfirmModal;
