import React from "react";
import "./SidebarContainer.css";
import RightContainer from "./RightContainer";
import Sidebar from "./Sidebar";


function SidebarContainer(props) {

    return (
        <div className="sidebar_outer_container d-flex">
            <div className="d-md-block d-none h-100">
                <Sidebar props={props.props.page} />
            </div>
            <RightContainer props={props.props} />
        </div>
    );
}

export default SidebarContainer;
