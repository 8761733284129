import axios from 'axios'
import {
    LIVECAM_DATA_GET_REQUEST,
    LIVECAM_DATA_GET_SUCCESS,
    LIVECAM_DATA_GET_FAIL,
    GET_LIVECAM_VIDEO_REF,
} from "../constants/livecamConstant";

import { API_ENDPOINT, API_ENDPOINT_STREAM } from '../constants/apiEndpoint'
import { getAPI } from '../global/ApiManager';

//cameraData
export const getLivecamData = (searchParams) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LIVECAM_DATA_GET_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            params: searchParams,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };

        const { data } = await getAPI(config, `/app/get_camera_page_data/`, dispatch)

        dispatch({
            type: LIVECAM_DATA_GET_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LIVECAM_DATA_GET_FAIL,
            payload: error
        });
    }
};
export const getVideoRef = (videoObj) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_LIVECAM_VIDEO_REF,
            payload: videoObj,
        });

    } catch (error) { console.error(error) }
};

export const getAllCameraTypes = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/get_all_camera_types/`,
            config
        )

        return { 'data': data.camera_types }
    } catch (error) {
        let errors = error
            ? error.data.message
            : error.message
        return { 'data': errors }

    }
}

export const createCameraGroup = async (token, groupName) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/app/create_camera_group/`,
            { group_name: groupName },
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': error }

    }
}

export const getCameraGroups = async (token, groupName) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/get_camera_groups/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }

    }
}

export const getAllLivecam = async (token) => {
    try {
        const config = {
            params: {
                group_ids: [],
                service_ids: [],
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/get_camera_page_data/`,
            config,
        );

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
};

export const startStreaming = async (formData) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(
            `${API_ENDPOINT_STREAM}/create_stream`,
            formData,
            config,
        );

        return { 'data': data, status: 200 }
    } catch (error) {
        return { 'data': 'User VMS Server is Offline', status: 400 }
    }
};