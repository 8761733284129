import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,

} from '../constants/settingConstant'


export const getUserProfileReducer = (state = { userProfile: [] }, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return { loading: true };

    case GET_USER_PROFILE_SUCCESS:
      return { userProfile: action.payload, loading: false };

    case GET_USER_PROFILE_FAIL:
      return { loading: false, error: action.error };

    case GET_USER_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const updateUserReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { loading: true };

    case UPDATE_USER_SUCCESS:
      return { loading: false, updateUserSuccess: true };

    case UPDATE_USER_FAIL:
      return { loading: false, error: action.payload };

    case UPDATE_USER_RESET:
      return {};

    default:
      return state;
  }
};
