import React, { useEffect, useRef, useState, useMemo } from 'react'
import Form from 'react-bootstrap/Form';

function AddSelectDropdown(props) {
    const {
        placeholder,
        selectedValue = '',
        required,
        handleAddBtn,
        onChange,
        onSelect,
        isInvalid,
        inValidMessage,
        onSelectValue,
        addBtnText = "Add More",
        option = [],
    } = props

    const [duplicateGroup, setDuplicateGroup] = useState(false);

    const optionsRef = useRef(null);

    const onFocusHandler = () => {
        optionsRef.current.style.visibility = "visible";
    }

    const onBlurHandler = () => {
        setTimeout(() => {
            optionsRef.current.style.visibility = "hidden";
        }, 230);
    }

    useEffect(() => {
        setDuplicateGroup(option?.filter((e) => e.name.toLowerCase() === selectedValue?.trim().toLowerCase())?.length >= 1)
    }, [option, selectedValue])

    const allOptions = useMemo(() =>
        onSelectValue ? option?.filter((e) => e.name != onSelectValue) : option?.filter((e) => e.name?.toLowerCase().includes(selectedValue?.trim().toLowerCase())), [selectedValue, onSelectValue, option]
    )

    return (
        <div className='position-relative'>
            <Form.Control
                type="text"
                autoComplete="off"
                className="input_box group_name_arrow"
                name="camera_group_name"
                placeholder={placeholder}
                title="Select or Add Group Name"
                value={selectedValue || ''}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                maxLength={60}
                onChange={e => {
                    onChange(e.target.value)
                }}
                isInvalid={isInvalid}
                required={required}
            />
            <Form.Control.Feedback type="invalid">
                {inValidMessage}
            </Form.Control.Feedback>
            <div ref={optionsRef} className="group-options position-absolute">
                <ul >
                    {allOptions?.map((e) => {
                        return (
                            <li key={e.id} id={e.id} value={e.name} onClick={(e) => {
                                onChange(e.target.getAttribute("value"));
                                onSelect({
                                    id: e.target.getAttribute("id"),
                                    name: e.target.getAttribute("value")
                                });
                            }}>
                                {e.name}
                            </li>
                        )
                    })}
                    {(!duplicateGroup && selectedValue?.trim().length > 0) && (
                        <li className='text-center add-grp-btn' onClick={handleAddBtn}>
                            <i className="fas fa-plus me-1"></i>
                            {addBtnText}
                        </li>
                    )}
                </ul >
            </div>
        </div>
    )
}

export default AddSelectDropdown