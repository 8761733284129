import React from 'react'
import './RegisterLogin.css'

function RegisterScreenRightSideBar({ children }) {
    return (
        <div className={'right-side-bar d-flex flex-column justify-content-center'}>
            <div className="admin-register-form shadow border-radius">
                {children}
            </div>
        </div>
    )
}

export default RegisterScreenRightSideBar
