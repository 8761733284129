import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getLivecamData, getAllLivecam } from '../../../actions/livecamAction'
import AllCameraTabItems from "../../livecamPage/components/AllCameraTabItems";
import LivecamFilter from "../../livecamPage/LivecamFilter";
import { Checkbox } from 'antd';
import Loader from "../../../components/Loader";
import noCameraFound from '../../../images/no-camera-found.gif'
import noVideo from "../../../components/inActive.png";
import noCameraLarge from '../../../images/noCameraLarge.png'
import noServiceIcon from '../../../images/no-service-icon.jpeg'

function SelectCamera(props) {

    const {
        selectedService,
        SelectedCamera,
        setSelectedCamera,
        userAccess,
        SelectedCameraServices,
        SelectServiceCameraId,
        setSelectServiceCameraId,
        setSelectAllCamera,
        SelectAllCamera,
        ServicesOption,
    } = props

    const dispatch = useDispatch();
    const [selectedCameraImage, setSelectedCameraImage] = useState([]);
    const [SelectedServiceIcon, setSelectedServiceIcon] = useState([]);
    const [AllCameraObject, setAllCameraObject] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [groupValue, setGroupValue] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const LivecamData = useSelector((state) => state.LivecamData);
    const { loading: cameraLoading, livecamData: all_camera_data } = LivecamData;

    const RolesAndResponsibilityDataGetSingle = useSelector((state) => state.RolesAndResponsibilityDataGetSingle);
    const { success: getSingleRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataGetSingle;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    function getIndexByValue(arr, value) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].service_id === value) {
                return i;
            }
        }
        return -1;  // If the value is not found in the array
    }

    const all_service_select = useMemo(() => (selectedService?.[0]?.service_name?.toLowerCase().includes("all services")), [selectedService])

    const serviceIndex = useCallback(() => getIndexByValue(SelectedCameraServices, selectedService?.[0]?.id), [selectedService, SelectedCameraServices])

    useEffect(() => {
        const params = {}
        searchValue && (params.query = searchValue);
        (groupValue.length !== 0 && (groupValue?.[0]?.camera_group !== "All Group")) && (params.group_ids = JSON.stringify([groupValue[0]?.id]));

        dispatch(getLivecamData(params));

    }, [dispatch, searchValue, groupValue]);

    const getAllCameraId = useCallback(() => all_camera_data?.cameras_data?.map(e => e.camera_id), [all_camera_data])

    useEffect(() => {
        async function startFetching() {
            setAllCameraObject([])
            const { data } = await getAllLivecam(userInfo?.token)
            if (!ignore) {
                setAllCameraObject(data?.cameras_data)
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [userInfo])

    useEffect(() => {
        if (!all_service_select && userAccess) {
            if (serviceIndex() !== -1 && Array.isArray(SelectServiceCameraId)) {
                SelectedCameraServices[serviceIndex()].all_cameras = SelectServiceCameraId;
            }
        }
    }, [SelectServiceCameraId])

    useEffect(() => {
        if (userAccess) {
            SelectedCameraServices?.filter(e => {
                if (e.service_id === selectedService?.[0]?.id) {
                    setSelectServiceCameraId(e.all_cameras)
                    if (e.all_cameras === true) {
                        setSelectedCamera(getAllCameraId())
                        setSelectAllCamera(true)
                    } else {
                        setSelectAllCamera(false)
                    }
                }
            })
        }
    }, [getAllCameraId, selectedService, SelectedCameraServices, refresh])

    useEffect(() => {
        if (all_service_select) {
            if (Array.isArray(getSingleRolesAndResponsibilitySuccess?.all_cameras)) {
                setSelectedCamera(getSingleRolesAndResponsibilitySuccess?.all_cameras)
                setSelectAllCamera(false)
            } else if (!Array.isArray(getSingleRolesAndResponsibilitySuccess?.all_cameras) && getSingleRolesAndResponsibilitySuccess?.all_services) {
                setSelectedCamera(getAllCameraId())
                setSelectAllCamera(true)
            } else {
                setSelectAllCamera(false)
            }
        }
    }, [getSingleRolesAndResponsibilitySuccess, all_service_select, getAllCameraId])

    useEffect(() => {
        if (userAccess) {
            const selectedCameraId = SelectedCameraServices?.filter(e => e.service_id === selectedService?.[0]?.id)[0]?.all_cameras

            if (Array.isArray(selectedCameraId)) {
                setSelectedCamera(selectedCameraId)
            }
        }
    }, [selectedService, SelectServiceCameraId])

    const onCheckboxSelect = (cameraId) => {
        if (!userAccess) {
            setSelectedCamera([...new Set([...SelectedCamera, cameraId])])
        } else if (!SelectAllCamera) {
            if (all_service_select) {
                setSelectedCamera([...new Set([...SelectedCamera, cameraId])])
            } else {
                setSelectServiceCameraId([...new Set([...SelectServiceCameraId, cameraId])])
            }
        }
    }

    const onCheckboxRemove = (cameraId) => {
        if (!userAccess) {
            setSelectedCamera(SelectedCamera.filter(e => e !== cameraId))
        } else if (!SelectAllCamera) {
            if (all_service_select) {
                setSelectedCamera(SelectedCamera.filter(e => e !== cameraId))
            } else {
                const all_camera_ids = SelectedCameraServices[serviceIndex()]?.all_cameras
                if (Array.isArray(all_camera_ids)) {
                    const removeSelectedCameraFromService = all_camera_ids?.filter(e => e !== cameraId)
                    SelectedCameraServices[serviceIndex()].all_cameras = removeSelectedCameraFromService
                }
                setSelectServiceCameraId(SelectServiceCameraId.filter(e => e !== cameraId))
            }
        }
    }

    const selectAllCameraHandler = (e) => {
        if (e.target.checked) {
            const allSelectedCamera = getAllCameraId()
            setSelectedCamera(allSelectedCamera)
        } else {
            setSelectedCamera([])
        }
    }

    const selectAllCameraUserAccessHandler = (e) => {
        setRefresh(e => e + 1)
        if (!all_service_select) {
            if (serviceIndex() !== -1) {
                if (e.target.checked) {
                    setSelectAllCamera(true)
                    SelectedCameraServices[serviceIndex()].all_cameras = true;
                }
                else {
                    setSelectAllCamera(false)
                    SelectedCameraServices[serviceIndex()].all_cameras = [];
                }
            }
        } else if (e.target.checked) {
            setSelectedCamera(getAllCameraId())
            setSelectAllCamera(true)
        } else {
            setSelectedCamera([])
            setSelectAllCamera(false)
        }
    }

    useEffect(() => {
        if (!userAccess) {
            if (SelectedCamera && (JSON.stringify(SelectedCamera.sort()) === JSON.stringify(getAllCameraId()?.sort()))) {
                getAllCameraId().length > 0 && setCheckAll(true)
            } else {
                setCheckAll(false)
            }
        }
    }, [userAccess, SelectedCamera, all_camera_data])

    useEffect(() => {
        if (!userAccess) {
            const selectedCamerasImage = all_camera_data?.cameras_data?.filter(e => SelectedCamera?.includes(e.camera_id))
            setSelectedCameraImage(selectedCamerasImage)
        }
    }, [SelectedCamera, all_camera_data])

    useEffect(() => {
        if (userAccess) {
            if (all_service_select) {
                const all_service_camera_select = SelectedCamera?.map(e => ({
                    all_cameras: e,
                    service_id: ServicesOption?.map(e => {
                        if (e.service_name !== "All Services") {
                            return e.id
                        }
                    })
                }))

                setSelectedServiceIcon(all_service_camera_select)

            } else {
                const convertedData = {};

                SelectedCameraServices?.map(element => {
                    if (typeof (element.all_cameras) === 'object') {
                        element.all_cameras?.map(camera => {
                            if (convertedData[camera]) {
                                convertedData[camera].push(element.service_id);
                            } else {
                                convertedData[camera] = [element.service_id];
                            }
                        });
                    } else {
                        AllCameraObject?.map(camera => {
                            if (convertedData[camera.camera_id]) {
                                convertedData[camera.camera_id].push(element.service_id);
                            } else {
                                convertedData[camera.camera_id] = [element.service_id];
                            }
                        });
                    }
                });

                const outputArray = Object.entries(convertedData)?.map(([all_camera, service_id]) => {
                    return {
                        all_cameras: (all_camera?.toLowerCase?.() === 'true' ? true : parseInt(all_camera)),
                        service_id
                    }
                });

                setSelectedServiceIcon(outputArray)
            }
        }
    }, [ServicesOption, SelectedCameraServices, SelectedCamera, AllCameraObject])

    return (
        <div className='d-flex gap-3 mt-3 h-100'>
            <div className='h-100 pills-side-bar'>
                <div className={`camera-select-container h-100`}>
                    <div style={{ padding: "10px" }}>
                        {!userAccess ? (
                            <Checkbox
                                onChange={selectAllCameraHandler}
                                checked={checkAll}
                            >
                                {checkAll ? "All Camera Selected" : "Select All Camera"}
                            </Checkbox>

                        ) : (
                            <Checkbox
                                onChange={selectAllCameraUserAccessHandler}
                                checked={SelectAllCamera}
                                disabled={all_camera_data?.cameras_data?.length === 0}
                            >
                                {SelectAllCamera ? "All Camera Selected" : "Select All Camera"}
                            </Checkbox>
                        )}
                    </div>
                    <LivecamFilter
                        groupData={all_camera_data?.group_data}
                        className="mb-3 px-2"
                        searchValueHandler={(e) => setSearchValue(e)}
                        groupNameFilterHandler={(e) => setGroupValue(e)}
                        advanceFilter={false}
                    />
                    {cameraLoading ? (
                        <div className="d-flex flex-column align-items-center justify-content-center h-75">
                            <Loader margin="0" />
                        </div>
                    ) : (
                        <Fragment>
                            {all_camera_data?.cameras_data?.length === 0 ? (
                                <div
                                    style={{
                                        color: "var(--light-grey)",
                                        fontSize: "12px",
                                        height: "calc(100% - 100px)",
                                    }}
                                    className="d-flex flex-column align-items-center justify-content-center"
                                >
                                    No Camera’s Found
                                </div>) : (<div className='insight-camera-select'>
                                    {all_camera_data?.cameras_data?.map((camera) => (
                                        <div
                                            key={camera.camera_id}
                                            className={`mb-2 cursor-pointer insight-camera-tab ${SelectAllCamera && 'is-disable'}`}
                                            title={camera.camera_name}
                                            onClick={() => {
                                                if (SelectedCamera?.includes(camera.camera_id)) {
                                                    onCheckboxRemove(camera.camera_id)
                                                } else {
                                                    onCheckboxSelect(camera.camera_id)
                                                }
                                            }}
                                        >
                                            <AllCameraTabItems
                                                SelectedCamera={SelectedCamera}
                                                checkbox={true}
                                                disabled={SelectAllCamera}
                                                camera={camera}
                                                onCheckboxSelect={onCheckboxSelect}
                                                onCheckboxRemove={onCheckboxRemove}
                                                className={`align-items-center ps-2 ${SelectedCamera?.includes(camera?.camera_id) && "insight-selected-camera"}`}
                                                userAccess={userAccess}
                                                all_service_select={all_service_select}
                                                selectedService={selectedService}
                                            />
                                        </div>
                                    ))}
                                </div>)}
                        </Fragment>
                    )}
                </div>

            </div>
            <div className='tab-side-bar'>
                <div className="camera-select-container pt-2 h-100">
                    {!userAccess ? (
                        <Fragment>
                            {(selectedCameraImage?.length === 0 || !selectedCameraImage) ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "100%" }}>
                                    <div className="text-center background-none">
                                        <img className='background-none opacity no-camera-found' src={noCameraFound} alt="" />
                                        <p className='opacity'>No details were found. Once you select a camera,<br /> all the information will appear here.</p>
                                    </div>
                                </div>) : (
                                <Row className='m-0 pe-2 selected-camera-container'>
                                    {selectedCameraImage?.map(e => (
                                        <Col md={4} key={e.camera_id} className='pe-0 mb-2 position-relative'>
                                            <img
                                                src={e.image || noCameraLarge}
                                                onError={e => e.target.src = noCameraLarge}
                                                alt="loading"
                                            />
                                            <h6 className='position-absolute'>{e.camera_name}</h6>
                                        </Col>
                                    ))}
                                </Row>)}
                        </Fragment>
                    ) : (
                        <>
                            {SelectedServiceIcon?.length === 0 ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "100%" }}>
                                    <div className="text-center background-none">
                                        <img className='background-none opacity no-camera-found' src={noCameraFound} alt="" />
                                        <p className='opacity'>No details were found. Once you select a camera,<br /> all the information will appear here.</p>
                                    </div>
                                </div>
                            ) : (
                                <Row className='m-0 pe-2 selected-camera-container'>
                                    {
                                        SelectedServiceIcon?.map(e => (
                                            AllCameraObject?.map(camera => {
                                                if (e.all_cameras === camera.camera_id) {
                                                    return (
                                                        <Col md={4} key={camera.camera_id} className='pe-0 mb-2 position-relative'>
                                                            <img
                                                                src={camera.image || noCameraLarge}
                                                                alt="loading"
                                                                onError={e => e.target.src = noCameraLarge}
                                                            />
                                                            <div className="d-flex position-absolute justify-content-between align-items-center bottom-0 mb-1 ps-1" style={{ width: "calc(100% - 12px)" }}>
                                                                <h6 className='text-nowrap'>{camera.camera_name}</h6>
                                                                <div className='text-nowrap'>
                                                                    {e.service_id?.map(service_id => (
                                                                        ServicesOption?.map(e => {
                                                                            if (service_id === e.id) {
                                                                                return (
                                                                                    <OverlayTrigger
                                                                                        key={e.id}
                                                                                        placement="bottom"
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${e.id}`} className="camera-services-tooltip">
                                                                                                {e.service_name}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={e.service_icon || noServiceIcon}
                                                                                            onError={e => e.target.src = noServiceIcon}
                                                                                            width={35}
                                                                                            style={{ cursor: "pointer", width: "20px", height: "20px", }}
                                                                                            className="me-1 rounded-circle"
                                                                                            alt={e.service_name}
                                                                                            key={e.id}
                                                                                        />
                                                                                    </OverlayTrigger>
                                                                                )
                                                                            }
                                                                        })
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                }

                                            })
                                        ))
                                    }
                                </Row>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SelectCamera