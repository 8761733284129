import {
    LIVECAM_DATA_GET_REQUEST,
    LIVECAM_DATA_GET_SUCCESS,
    LIVECAM_DATA_GET_FAIL,
    RESET_LIVECAM_VIDEO_REF,
    GET_LIVECAM_VIDEO_REF,
} from "../constants/livecamConstant";



export const getLivecamDataReducer = (state = { livecamData: {} }, action) => {
    switch (action.type) {
        case LIVECAM_DATA_GET_REQUEST:
            return {
                loading: true, livecamData: { ...state.livecamData }
            };

        case LIVECAM_DATA_GET_SUCCESS:
            return {
                livecamData: action.payload,
                loading: false,
            };

        case LIVECAM_DATA_GET_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const getVideoRefReducer = (state = { videoRefObj: null }, action) => {
    switch (action.type) {
        case GET_LIVECAM_VIDEO_REF:
            return {
                videoRefObj: action.payload
            };

        case RESET_LIVECAM_VIDEO_REF:
            return {
                videoRefObj: null
            };

        default:
            return state;
    }
};

