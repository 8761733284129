import React, { useState, useRef, useEffect } from "react";

const PolygonRect = (props) => {

    const {
        undo_area_selection,
        redo_area_selection,
        background_image,
        area_values,
        setRedoPoints,
        redoPoints,
        get_areas,
        setClear,
        clear,
        width,
        height,
    } = props

    const [context, setContext] = useState(null);
    const [polygonPoints, setPolygonPoints] = useState([]);
    const [points, setPoints] = useState([]);
    const [isCtrlKeyPressed, setIsCtrlKeyPressed] = useState(false);

    const myCanvas = useRef();

    useEffect(() => {
        const canvasEle = myCanvas.current
        canvasEle.width = width;
        canvasEle.height = height;
        canvasEle.style.backgroundSize = '100% 100%';
        canvasEle.style.backgroundRepeat = 'no-repeat';
        canvasEle.style.borderRadius = '8px';
        canvasEle.style.backgroundImage = `url(${background_image})`;
        setContext(canvasEle.getContext("2d"));
    }, []);

    useEffect(() => {
        if (area_values?.length > 0) {
            setPolygonPoints(area_values)
        }
    }, [area_values])

    useEffect(() => {
        if (redo_area_selection) {
            if (redoPoints.length > 0) {
                const removedRedoPoints = redoPoints.pop()
                setPolygonPoints([...polygonPoints, removedRedoPoints])
            }
        }
    }, [redo_area_selection])

    useEffect(() => {
        if (undo_area_selection) {
            if (points.length > 0) {
                setPoints([])
            } else {
                const copyPolygonPoints = [...polygonPoints]
                const removedPoints = copyPolygonPoints.pop()
                setRedoPoints([...redoPoints, removedPoints])
                setPolygonPoints(copyPolygonPoints)
            }
        }
    }, [undo_area_selection])

    const clearCanvas = (context) => {
        context?.clearRect(0, 0, myCanvas.current.width, myCanvas.current.height);
    };

    useEffect(() => {
        clearCanvas(context);
        polygonPoints.forEach((point) => {
            drawPolygon(point, true);
        });

        if (points.length > 0) {
            drawPolygon(points, false);
        }
        get_areas(polygonPoints)
    }, [context, polygonPoints, points]);


    const drawLine = (context, x1, y1, x2, y2, lineColor = "orange") => {
        context.beginPath();
        context.strokeStyle = lineColor;
        context.strokeWidth = 1;
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
        context.closePath();
    }

    const drawPolygon = (rectangle, lastLine) => {
        for (let i = 1; i < (rectangle.length + 1); i++) {
            context.fillStyle = "orange";
            context.beginPath();
            context.arc(rectangle[i - 1]?.x, rectangle[i - 1]?.y, 4, 0, 2 * Math.PI);
            context.fill();
            drawLine(context, rectangle[i - 1]?.x, rectangle[i - 1]?.y, rectangle[i]?.x, rectangle[i]?.y);
        }

        // For drawing the last line 
        if (lastLine) {
            drawLine(
                context,
                rectangle[rectangle.length - 1]?.x,
                rectangle[rectangle.length - 1]?.y,
                rectangle[0]?.x,
                rectangle[0]?.y
            );
        }
    };


    const clickHandler = (e) => {
        if (isCtrlKeyPressed) {
            setRedoPoints([])
            setPoints([...points, { "x": e.nativeEvent.offsetX, "y": e.nativeEvent.offsetY }])

            context.fillStyle = "orange";
            context.beginPath();
            context.arc(e.nativeEvent.offsetX, e.nativeEvent.offsetY, 4, 0, 2 * Math.PI);
            context.fill();
        }
    }

    const handleKeyUp = () => {
        if (isCtrlKeyPressed) {
            setIsCtrlKeyPressed(false)
            if (points.length > 3) {
                setPolygonPoints([...polygonPoints, points])
                setPoints([])
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Control') {
                // Ctrl key pressed, do something
                setIsCtrlKeyPressed(true)
            }
        };
        const handleKeyUp = (event) => {
            if (event.key === 'Control') {
                // Ctrl key pressed, do something
                setIsCtrlKeyPressed(false)
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (clear === true) {
            clearCanvas(context);
            setPoints([])
            setPolygonPoints([])
            setClear(false);
        }
    }, [clear])

    return (
        <div className="position-relative">
            <canvas
                tabIndex="0"
                autoFocus={true}
                ref={myCanvas}
                onClick={clickHandler}
                onKeyUp={handleKeyUp}
                style={{ backgroundColor: '#3333330D' }}
            />
            <div className={`${polygonPoints.length > 0 && 'd-none'} position-absolute top-0 end-0 m-2`}>
                <div
                    className={`${isCtrlKeyPressed && 'd-none'} fw-semibold fs-14 text-white rounded-2 px-2`}
                    style={{
                        background: "rgb(51 51 51 / 53%)",
                        userSelect: "none",
                    }}
                >
                    CTRL + Click to Create Polygon
                </div>
                <div
                    className={`${!(points.length > 3 && isCtrlKeyPressed) && 'd-none'} fw-semibold fs-14 text-white rounded-2 px-2`}
                    style={{
                        background: "rgb(51 51 51 / 53%)",
                        userSelect: "none",
                    }}
                >
                    Release CTRL button to Draw full polygon
                </div>
            </div>
        </div>
    );
};

export default PolygonRect;