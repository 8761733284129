import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import "./SettingScreen.css";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import SettingProfile from "./SettingProfile";
import SettingProfileData from './SettingProfileData';
import HelpMain from "./HelpPage/HelpMain";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions"
import { useNavigate } from "react-router-dom";
import { getUserProfile } from '../../actions/settingActions'
import PageNotAccess from '../404PageNotFound/PageNotAccess';

function SettingScreen() {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [, setBreadCrumb] = useState(userInfo?.isAdmin ? 'Admin Profile' : 'User Profile');

    const userProfileGet = useSelector((state) => state.userProfileGet);
    const { error, userProfile } = userProfileGet;

    useEffect(() => {
        dispatch(getUserProfile());
    }, []);

    return (
        <div>
            {error?.status === 403 ? (
                <PageNotAccess />) : (
                <>
                    <Header props={{ page_name: "Settings" }} />
                    <Tab.Container id="left-tabs-example" defaultActiveKey="admin-profile"
                        onSelect={(e) => { setBreadCrumb(e.split('-')?.map((char) => (char[0].toUpperCase() + char.slice(1, char.length) + " "))) }}>
                        <div className="d-flex gap-3">
                            <div className="pills-side-bar" >
                                <Nav variant="pills" className="flex-column border-top-right border-top-left">
                                    <Nav.Item>
                                        <Nav.Link eventKey="admin-profile" className="d-flex align-items-center gap-2 border-top-right border-top-left">
                                            <span className="profile-icon"></span>

                                            {userInfo?.isAdmin ? 'Admin Profile' : 'User Profile'}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="help" className="d-flex align-items-center gap-2">
                                            <span className="help-icon"></span>Help
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="tab-side-bar">
                                <Tab.Content style={{ padding: "0 1.5rem" }}>
                                    <Tab.Pane eventKey="admin-profile">
                                        <SettingProfile />
                                        <Row>
                                            <SettingProfileData
                                                userProfile={userProfile}
                                            />
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="help" style={{ height: "100%" }}>
                                        <HelpMain />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </>
            )}
        </div >
    );
}

export default SettingScreen;
