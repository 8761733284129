import {
    GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST,
    GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS,
    GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL,
    GET_SERVICE_CAMERA_FITER_OPTIONS_RESET,

    GET_EXCEL_EXPORT_DATA_REQUEST,
    GET_EXCEL_EXPORT_DATA_SUCCESS,
    GET_EXCEL_EXPORT_DATA_FAIL,

    POST_DOUBTFUL_EVENT_DATA_REQUEST,
    POST_DOUBTFUL_EVENT_DATA_SUCCESS,
    POST_DOUBTFUL_EVENT_DATA_FAIL,
    POST_DOUBTFUL_EVENT_DATA_RESET,

    RETRAIN_DATA_SAVE_REQUEST,
    RETRAIN_DATA_SAVE_SUCCESS,
    RETRAIN_DATA_SAVE_FAIL,
    RETRAIN_DATA_SAVE_RESET
} from "../constants/EventConstants";



export const EventFilterOptionsReducer = (state = { EventfilteOptionsData: {} }, action) => {
    switch (action.type) {
        case GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST:
            return { loading: true, EventfilteOptionsData: { ...state.EventfilteOptionsData } };

        case GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS:
            return { loading: false, EventfilteOptionsData: action.payload };

        case GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL:
            return { loading: false, error: action.error };

        case GET_SERVICE_CAMERA_FITER_OPTIONS_RESET:
            return { EventfilteOptionsData: {} };

        default:
            return state;
    }
};


export const getExcelExportEventDataReducer = (state = { succuss: [] }, action) => {
    switch (action.type) {
        case GET_EXCEL_EXPORT_DATA_REQUEST:
            return { loading: true };

        case GET_EXCEL_EXPORT_DATA_SUCCESS:
            return { loading: false, succuss: action.payload };

        case GET_EXCEL_EXPORT_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const postDoubtfulEventReducer = (state = { succuss: false }, action) => {
    switch (action.type) {
        case POST_DOUBTFUL_EVENT_DATA_REQUEST:
            return { loading: true };

        case POST_DOUBTFUL_EVENT_DATA_SUCCESS:
            return { loading: false, succuss: true };

        case POST_DOUBTFUL_EVENT_DATA_FAIL:
            return { loading: false, error: action.error };

        case POST_DOUBTFUL_EVENT_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const postRetrainDataReducer = (state = { succuss: false }, action) => {
    // console.log(action, 'action reduser')
    switch (action.type) {
        case RETRAIN_DATA_SAVE_REQUEST:
            return { loading: true };

        case RETRAIN_DATA_SAVE_SUCCESS:
            return { loading: false, succuss: true };

        case RETRAIN_DATA_SAVE_FAIL:
            return { loading: false, error: action.payload };

        case RETRAIN_DATA_SAVE_RESET:
            return {};
        default:
            return state;
    }
};