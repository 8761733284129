import React, { useState } from 'react'
import { analyticsOnOff } from '../../../actions/analyticsActions';
import { Switch } from "antd";

function AlertOnOffSwitch({ checked, objectId, token }) {
    const [onOff, setOnOff] = useState(checked)
    const [onOffLoading, setOnOffLoading] = useState(false)

    const switchHandler = async (e, alertId) => {
        setOnOffLoading(true)
        const { status } = await analyticsOnOff(alertId, token)
        if (status === 200) {
            setOnOff(e)
            setOnOffLoading(false)
        } else {
            setOnOffLoading(false)
        }
    }

    return (
        <Switch
            checked={onOff}
            size="small"
            loading={onOffLoading}
            onChange={(e) => switchHandler(e, objectId)}
        />
    )
}

export default AlertOnOffSwitch