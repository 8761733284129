export const SERVICES_DATA_LIST_REQUEST = 'SERVICES_DATA_LIST_REQUEST'
export const SERVICES_DATA_LIST_SUCCESS = 'SERVICES_DATA_LIST_SUCCESS'
export const SERVICES_DATA_LIST_FAIL = 'SERVICES_DATA_LIST_FAIL'


export const SERVICES_DATA_REQUEST = 'SERVICES_DATA_REQUEST'
export const SERVICES_DATA_SUCCESS = 'SERVICES_DATA_SUCCESS'
export const SERVICES_DATA_FAIL = 'SERVICES_DATA_FAIL'


export const GET_SINGLE_SERVICES_DATA_REQUEST = 'GET_SINGLE_SERVICES_DATA_REQUEST'
export const GET_SINGLE_SERVICES_DATA_SUCCESS = 'GET_SINGLE_SERVICES_DATA_SUCCESS'
export const GET_SINGLE_SERVICES_DATA_FAIL = 'GET_SINGLE_SERVICES_DATA_FAIL'


export const ALL_SERVICES_DELETE_REQUEST = 'ALL_SERVICES_DELETE_REQUEST'
export const ALL_SERVICES_DELETE_SUCCESS = 'ALL_SERVICES_DELETE_SUCCESS'
export const ALL_SERVICES_DELETE_FAIL = 'ALL_SERVICES_DELETE_FAIL'
export const ALL_SERVICES_DELETE_RESET = 'ALL_SERVICES_DELETE_RESET'


export const CAMERA_DATA_REQUEST = 'CAMERA_DATA_REQUEST'
export const CAMERA_DATA_SUCCESS = 'CAMERA_DATA_SUCCESS'
export const CAMERA_DATA_FAIL = 'CAMERA_DATA_FAIL'
export const CAMERA_DATA_RESET = 'CAMERA_DATA_RESET'


export const SERVICE_CONFIG_DATA_REQUEST = 'SERVICE_CONFIG_DATA_REQUEST'
export const SERVICE_CONFIG_DATA_SUCCESS = 'SERVICE_CONFIG_DATA_SUCCESS'
export const SERVICE_CONFIG_DATA_FAIL = 'SERVICE_CONFIG_DATA_FAIL'


export const CREATE_SERVICE_CONFIG_USER_REQUEST = 'CREATE_SERVICE_CONFIG_USER_REQUEST'
export const CREATE_SERVICE_CONFIG_USER_SUCCESS = 'CREATE_SERVICE_CONFIG_USER_SUCCESS'
export const CREATE_SERVICE_CONFIG_USER_FAIL = 'CREATE_SERVICE_CONFIG_USER_FAIL'


export const SERVICE_CONFIG_DELETE_REQUEST = 'SERVICE_CONFIG_DELETE_REQUEST'
export const SERVICE_CONFIG_DELETE_SUCCESS = 'SERVICE_CONFIG_DELETE_SUCCESS'
export const SERVICE_CONFIG_DELETE_FAIL = 'SERVICE_CONFIG_DELETE_FAIL'
export const SERVICE_CONFIG_DELETE_RESET = 'SERVICE_CONFIG_DELETE_RESET'


export const SERVICE_DELETE_REQUEST = 'SERVICE_DELETE_REQUEST'
export const SERVICE_DELETE_SUCCESS = 'SERVICE_DELETE_SUCCESS'
export const SERVICE_DELETE_FAIL = 'SERVICE_DELETE_FAIL'
export const SERVICE_DELETE_RESET = 'SERVICE_DELETE_RESET'


export const SERVICES_CREATE_REQUEST = 'SERVICES_CREATE_REQUEST'
export const SERVICES_CREATE_SUCCESS = 'SERVICES_CREATE_SUCCESS'
export const SERVICES_CREATE_FAIL = 'SERVICES_CREATE_FAIL'
export const SERVICES_CREATE_RESET = 'SERVICES_CREATE_RESET'


export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST'
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS'
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL'
export const ADD_SERVICE_RESET = 'ADD_SERVICE_RESET'


export const SERVICES_UPDATE_REQUEST = 'SERVICES_UPDATE_REQUEST'
export const SERVICES_UPDATE_SUCCESS = 'SERVICES_UPDATE_SUCCESS'
export const SERVICES_UPDATE_FAIL = 'SERVICES_UPDATE_FAIL'
export const SERVICES_UPDATE_RESET = 'SERVICES_UPDATE_RESET'


export const GET_SERVICE_USER_REQUEST = 'GET_SERVICE_USER_REQUEST'
export const GET_SERVICE_USER_SUCCESS = 'GET_SERVICE_USER_SUCCESS'
export const GET_SERVICE_USER_FAIL = 'GET_SERVICE_USER_FAIL'
export const GET_SERVICE_USER_RESET = 'GET_SERVICE_USER_RESET'


export const GET_ALERT_REQUEST = 'GET_ALERT_REQUEST'
export const GET_ALERT_SUCCESS = 'GET_ALERT_SUCCESS'
export const GET_ALERT_FAIL = 'GET_ALERT_FAIL'

export const ADD_FACE_REQUEST = 'ADD_FACE_REQUEST'
export const ADD_FACE_SUCCESS = 'ADD_FACE_SUCCESS'
export const ADD_FACE_FAIL = 'ADD_FACE_FAIL'
export const ADD_FACE_RESET = 'ADD_FACE_RESET'

export const BUY_SERVICE_REQUEST = 'BUY_SERVICE_REQUEST'
export const BUY_SERVICE_SUCCESS = 'BUY_SERVICE_SUCCESS'
export const BUY_SERVICE_FAIL = 'BUY_SERVICE_FAIL'
export const BUY_SERVICE_RESET = 'BUY_SERVICE_RESET'

export const SERVICE_ON_OFF_REQUEST = 'SERVICE_ON_OFF_REQUEST'
export const SERVICE_ON_OFF_SUCCESS = 'SERVICE_ON_OFF_SUCCESS'
export const SERVICE_ON_OFF_FAIL = 'SERVICE_ON_OFF_FAIL'
export const SERVICE_ON_OFF_RESET = 'SERVICE_ON_OFF_RESET'


export const GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST = 'GET_TIME_WISE_CAMERA_SCHEDULE_REQUEST'
export const GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS = 'GET_TIME_WISE_CAMERA_SCHEDULE_SUCCESS'
export const GET_TIME_WISE_CAMERA_SCHEDULE_FAIL = 'GET_TIME_WISE_CAMERA_SCHEDULE_FAIL'


export const UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST = 'UPDATE_TIME_WISE_CAMERA_SCHEDULE_REQUEST'
export const UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS = 'UPDATE_TIME_WISE_CAMERA_SCHEDULE_SUCCESS'
export const UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL = 'UPDATE_TIME_WISE_CAMERA_SCHEDULE_FAIL'
export const UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET = 'UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET'


export const UPDATE_SERVICE_VERSION_REQUEST = 'UPDATE_SERVICE_VERSION_REQUEST'
export const UPDATE_SERVICE_VERSION_SUCCESS = 'UPDATE_SERVICE_VERSION_SUCCESS'
export const UPDATE_SERVICE_VERSION_FAIL = 'UPDATE_SERVICE_VERSION_FAIL'
export const UPDATE_SERVICE_VERSION_RESET = 'UPDATE_SERVICE_VERSION_RESET'

export const RETRAIN_PROCESS_REQUEST = 'RETRAIN_PROCESS_REQUEST'
export const RETRAIN_PROCESS_SUCCESS = 'RETRAIN_PROCESS_SUCCESS'
export const RETRAIN_PROCESS_FAIL = 'RETRAIN_PROCESS_FAIL'
export const RETRAIN_PROCESS_RESET = 'RETRAIN_PROCESS_RESET'

export const CHECK_RETRAIN_STATUS_REQUEST = 'CHECK_RETRAIN_STATUS_REQUEST'
export const CHECK_RETRAIN_STATUS_SUCCESS = 'CHECK_RETRAIN_STATUS_SUCCESS'
export const CHECK_RETRAIN_STATUS_FAIL = 'CHECK_RETRAIN_STATUS_FAIL'
export const CHECK_RETRAIN_STATUS_RESET = 'CHECK_RETRAIN_STATUS_RESET'