import React, { useEffect, useState } from 'react'
import SearchInput from "../../../components/SearchInput";
import { Table, Button } from "antd";
import { Image } from 'react-bootstrap'
import noUserFound from '../../../images/no-users-found.gif'
import { useDispatch, useSelector } from 'react-redux'
import Loader from "../../../components/Loader";
import { getUsersConfiguration, getFaceRecognitionUsers, getFaceRecognitionCamera, applyUsersInFaceRecognitionCamera } from '../../../actions/faceRecognitionActions';
import { APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET } from '../../../constants/faceRecognitionConstant'

export default function UserConfigurationModal(props) {

    const addedUser = props.addedUser
    const cameraId = props.cameraId
    const serviceId = props.serviceId
    const cameraTablePageNumber = props.cameraTablePageNumber

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const usersGetConfiguration = useSelector((state) => state.usersGetConfiguration);
    const { loading, usersData } = usersGetConfiguration;

    const usersApplyInFaceRecognitionCamera = useSelector((state) => state.usersApplyInFaceRecognitionCamera);
    const { loading: applyUsersLoad, applyUsersSuccess } = usersApplyInFaceRecognitionCamera;

    useEffect(() => {
        dispatch(getUsersConfiguration(cameraId))
        const userId = addedUser?.map((users) => users.id)
        setSelectedRowKeys(userId)
    }, [dispatch, addedUser, cameraId])

    useEffect(() => {
        if (applyUsersSuccess) {
            dispatch({ type: APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET })
            dispatch(getFaceRecognitionCamera(serviceId, cameraTablePageNumber))
            dispatch(getFaceRecognitionUsers())
            props.closeUserConfigurationModal(true)
            props.closeAddedUserCameraModal(true)
        }
    }, [dispatch, applyUsersSuccess, serviceId])
    const addUserInCamera = () => {
        dispatch(applyUsersInFaceRecognitionCamera({ "camera_id": cameraId, "user_id": selectedRowKeys, "service_id": serviceId }))
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: 180,
            ellipsis: true,
        },
        {
            title: "User ID",
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: "",
            dataIndex: 'action',
            key: 'action',
        },
    ];


    const onSingleRowSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys([...selectedRowKeys, record.key])
        } else {
            const selected = selectedRowKeys.filter(e => e !== record.key)
            setSelectedRowKeys(selected)
        }
    }

    const added_camera_data = usersData?.filter((user) => user.username.toLowerCase().includes(search?.toLowerCase()) || String(user.user_id)?.includes(String(search)))?.map((user) => ({
        key: user?.id,
        user_name: <div>
            <Image
                height={30}
                width={40}
                className="me-1 border_radius_sm"
                src={user?.user_profile}
            />{" "}
            {user?.username}
        </div>,
        user_id: user?.user_id,
        action:
            <div className={selectedRowKeys.includes(user?.id) ? "d-none" : 'text-end'}>
                <button
                    onClick={() => setSelectedRowKeys([...selectedRowKeys, user?.id])}
                >
                    <span className='add-item-button border_radius_xsm'>
                        <i className="fas fa-plus" />
                    </span>
                </button>
            </div>
    }))

    return (
        <div className={added_camera_data?.length === 0 ? 'height-0' : 'user-camera-access-container px-3'}>
            <SearchInput onChange={(e) => setSearch(e)} />
            <Table
                rowSelection={{
                    selectedRowKeys,
                    onSelect: onSingleRowSelect,
                    onSelectAll: (items) => items ? setSelectedRowKeys(usersData?.map(e => e.id)) : setSelectedRowKeys([])
                }}
                columns={columns}
                dataSource={!loading ? added_camera_data : null}
                pagination={{
                    pageSize: 10,
                }}
                scroll={{ x: 700, y: "300px" }}
            />
            {
                loading ?
                    <Loader margin="120" /> : null
            }
            {added_camera_data?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "300px" }}>
                    <div className="text-center background-none">
                        <img className='background-none opacity no-user-found' src={noUserFound} alt="" />
                        <p className='opacity'>No Users Found!<br />
                            Add Users to start building the User List.</p>
                    </div>
                </div>) : null}
            <div
                className="text-end mt-4"
            >
                {applyUsersLoad ? (<Button type="primary" size="large" loading>
                    Save
                </Button>) :
                    (<button
                        className="btn px-4 py-2 bg-warning border text-dark"
                        type='submit'
                        // onClick={() => props.closeUserConfigurationModal(true)}
                        onClick={addUserInCamera}
                    >
                        Save
                    </button>)
                }
            </div>
        </div>
    )
}
