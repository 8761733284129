import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";

function SetNetworkModal(props) {
    const {
        formDefaultValue,
        onFormSubmit,
        onHide
    } = props

    const [PasswordShow, setPasswordShow] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({ defaultValues: formDefaultValue });

    const addNetworkForm = (values) => {
        onFormSubmit(values)
        onHide()
    }

    return (
        <Form noValidate onSubmit={handleSubmit(addNetworkForm)}>
            <Form.Group className='mb-4'>
                <Form.Label className='m-0 fw-bold text-dark'>IP Address</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter your IP Address"
                    className='bg-light-grey rounded'
                    size='sm'
                    {...register("ip_address", {
                        required: true
                    })}
                    isInvalid={errors.ip_address}
                    maxLength={60}
                />
            </Form.Group>
            <Form.Group className='mb-4'>
                <Form.Label className='m-0 fw-bold text-dark'>Folder Name</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter/Create Folder Name"
                    className='bg-light-grey rounded'
                    size='sm'
                    {...register("folder_name", {
                        required: true
                    })}
                    isInvalid={errors.folder_name}
                    maxLength={60}
                />
            </Form.Group>
            <Form.Group className='mb-4'>
                <Form.Label className='m-0 fw-bold text-dark'>Username</Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder="Enter your username"
                    className='bg-light-grey rounded'
                    size='sm'
                    {...register("user_name", {
                        required: true
                    })}
                    isInvalid={errors.user_name}
                    maxLength={60}
                />
            </Form.Group>
            <Form.Group className='mb-4 position-relative'>
                <Form.Label className='m-0 fw-bold text-dark'>Password</Form.Label>
                <Form.Control
                    required
                    type={PasswordShow ? 'text' : 'password'}
                    placeholder="Enter password"
                    className='bg-light-grey rounded'
                    size='sm'
                    {...register("password", {
                        required: true
                    })}
                    isInvalid={errors.password}
                    maxLength={60}
                />
                <i
                    onClick={() => setPasswordShow(!PasswordShow)}
                    className={`${PasswordShow ? 'ri-eye-line' : 'ri-eye-close-line'} fs-5 position-absolute text-dark bottom-0 end-0 ${errors.password ? "me-4 pe-2" : "me-3"} mb-1`}
                ></i>
            </Form.Group>
            <div className='mt-4 text-end'>
                <button className='btn bg-warning px-3'>Save</button>
            </div>
        </Form>
    )
}

export default SetNetworkModal