import RegisterScreenLeftSideBar from "../../components/AuthenticationComponents/RegisterScreenLeftSideBar";
import RegisterScreenRightSideBar from "../../components/AuthenticationComponents/RegisterScreenRightSideBar";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register as registerUser, createUserEmailVerification, emailCheck, checkOnlineStatus } from "../../actions/userActions";
import Loading from "../../components/Loading";
import { emailValidator } from "../.././global/Helper";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { USER_REGISTER_RESET, CREATE_USER_EMAIL_VERIFICATION_RESET } from "../../constants/userConstants";
import NoInternetConnection from "../../components/NoInternetConnection";

function AdminRegisterScreen() {
    const { register, setError, handleSubmit, formState: { errors } } = useForm();

    const [email, setEmail] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [isOnline, setIsOnline] = useState(true);

    const [timer, setTimer] = useState(false);

    const [activationKey, setActivationKey] = useState('');
    const [verificationCodeSendSuccess, setVerificationCodeSendSuccess] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, userInfo } = userRegister;

    const userCreateEmailVerification = useSelector((state) => state.userCreateEmailVerification);
    const { loading: emailVerificationLoad, success } = userCreateEmailVerification;

    useEffect(() => {
        const checkInternet = async () => setIsOnline(await checkOnlineStatus())
        checkInternet();

        const intervalId = setInterval(checkInternet, 30000); // Check every 30 seconds

        return () => clearInterval(intervalId);
    }, []);

    const startTimer = () => {
        let timeLeft = 30;
        const timerId = setInterval(() => {
            if (timeLeft == 0) {
                clearTimeout(timerId);
                setTimer(false)
            } else {
                setTimer(timeLeft)
                timeLeft--;
            }
        }, 1000);
    }

    const sendVerificationCode = () => {
        if (!emailValidator.test(email)) {
            setError('email', { type: "manual", message: "Invalid Email!" }, { shouldFocus: true })
        } else {
            dispatch(createUserEmailVerification(email));
        }
    };

    useEffect(() => {
        if (userInfo) {
            navigate("/home/", { replace: true });
        }
    }, [userInfo, navigate]);

    useEffect(() => {
        if (error) {
            if (error.includes("Invalid otp")) {
                setError('verificationCode', { type: "manual", message: "Verification Code is Wrong." }, { shouldFocus: true })
            }
            setTimeout(() => {
                dispatch({ type: USER_REGISTER_RESET })
            }, 6000);
        }
        if (success) {
            startTimer()
            setActivationKey(success?.activation_key)
            dispatch({ type: CREATE_USER_EMAIL_VERIFICATION_RESET })
            setVerificationCodeSendSuccess(true)
        }
    }, [dispatch, error, success])

    const registerAdmin = async (e) => {
        let { data } = await emailCheck(email)

        if (data?.includes('already exists') || data?.includes('Unable to connect')) {
            setError('email', { type: "manual", message: data }, { shouldFocus: true });
            return;
        };
        if (e.password !== e.confirmPassword) {
            setError('confirmPassword', { type: "manual", message: "Passwords do not match, please try again!" }, { shouldFocus: true });
            return;
        };
        dispatch(registerUser(true, e.email, e.password, activationKey, e.verificationCode));
    };

    return (
        <div>
            <div className="row g-0 auth-container">
                <div className="col-md-5">
                    <RegisterScreenLeftSideBar />
                </div>
                <div className="col-md-7 h-100">
                    {isOnline ? (
                        <Form noValidate onSubmit={handleSubmit(registerAdmin)} className="h-100">
                            <RegisterScreenRightSideBar>
                                <h5 className='mb-3 text-center register_headline'>Create Account</h5>
                                <div id="alert-message"></div>
                                <Form.Group className="mb-3">
                                    <Form.Label className="form-label">Email</Form.Label>
                                    <Form.Control
                                        className="border-input"
                                        type="email"
                                        id="email"
                                        placeholder="Enter Your Email Address"
                                        {...register("email", {
                                            required: "Please Enter Email.",
                                            pattern: {
                                                value: /^([_\-.0-9a-zA-Z]+)@([_\-.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/,
                                                message: "Please Enter valid Email."
                                            },
                                            onChange: (e) => setEmail(e.target.value),
                                        })}
                                        isInvalid={errors.email}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <button
                                            type='button'
                                            onClick={sendVerificationCode}
                                            className={(timer || emailVerificationLoad) ? 'text-primary verify-btn is-disable' : 'text-primary verify-btn'}
                                            disabled={(timer || emailVerificationLoad)}
                                        >
                                            {verificationCodeSendSuccess ? "Resend" : "Send"} Verification Code
                                        </button>
                                        {timer && (
                                            <div className="stop-watch d-flex align-items-center">
                                                <span className="stop-watch-icon"></span>
                                                00:{`${timer}`.length === 1 && 0}{timer}
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>
                                <Form.Group className='mb-3' >
                                    <Form.Label className='m-0' htmlFor=""><b>Verification Code</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="verificationCode"
                                        className='border-input'
                                        id="verificationCode"
                                        placeholder='Enter Verification Code'
                                        {...register("verificationCode", {
                                            required: "Please Enter Verification Code.",

                                        })}
                                        isInvalid={errors.verificationCode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.verificationCode?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="password-field mb-4">
                                    <div className="p-relative mb-3">
                                        <Form.Label className="form-label">Password</Form.Label>
                                        <Form.Control
                                            className="border-input"
                                            placeholder="Enter Your Password"
                                            id="password"
                                            type={passwordShow ? 'text' : 'password'}
                                            {...register("password", {
                                                required: "Please Enter Password.",
                                                minLength: {
                                                    value: 8,
                                                    message: "The password contains a minimum of 8 or more characters."
                                                },
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*\d)(?=.{8,})/,
                                                    message: 'The password contains the alphabet and at least one number.'
                                                },
                                            })}
                                            isInvalid={(errors.password)}
                                            required
                                        />
                                        <i
                                            onClick={() => setPasswordShow(!passwordShow)}
                                            className={`${passwordShow ? 'ri-eye-line' : 'ri-eye-close-line'} text-dark`}
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.password?.message}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="p-relative">
                                        <Form.Label className="form-label">Confirm Password</Form.Label>
                                        <Form.Control
                                            className="border-input"
                                            id="confirmPassword"
                                            placeholder="Re-enter the Password"
                                            type={confirmPasswordShow ? 'text' : 'password'}
                                            {...register("confirmPassword", {
                                                required: "Please Enter Confirm Password.",
                                            })}
                                            isInvalid={errors.confirmPassword}
                                            required
                                        />
                                        <i
                                            onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                                            className={`${confirmPasswordShow ? 'ri-eye-line' : 'ri-eye-close-line'} text-dark`}
                                        ></i>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.confirmPassword?.message}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                                <div className="text-center mb-3">
                                    <button
                                        type="submit"
                                        className={
                                            (loading || emailVerificationLoad) ? "disabled border-radius btn" : "border-radius btn"
                                        }
                                    >
                                        <b>Create</b>
                                        {(loading || emailVerificationLoad) ? <Loading /> : null}
                                    </button>
                                </div>
                                <p className="text-center m-0">
                                    Already member?
                                    <Link to="/"> Sign In</Link>
                                </p>
                            </RegisterScreenRightSideBar>
                        </Form>
                    ) : (
                        <NoInternetConnection
                            onTryAgain={() => window.location.reload()}
                        >
                            <p className="text-center my-3">
                                Already member?
                                <Link to="/" className="text-primary"> Sign In</Link>
                            </p>
                        </NoInternetConnection>
                    )}
                </div>
            </div>
        </div>
    );
}
export default AdminRegisterScreen;
