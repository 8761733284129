import React from "react";
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import Rectangle from "./Rectangle";

const Crop = (props) => {

    const {
        send_image,
        rectangles,
        setRectangles,
        selectedShapeIndex,
        setSelectedShapeIndex,
        setSelectedEditableIndex,
        onRectangleChange,
        deleteRectangle,
        editRectangle,
        squareName,
        height,
        width,
    } = props

    const RectangleWidth = 110
    const RectangleHeight = 130

    const [image] = useImage(send_image);

    const addRectangle = (e) => {
        const scale = { ...e.currentTarget.getPointerPosition() }

        const newRectangle = {
            x: scale.x,
            y: scale.y,
            width: RectangleWidth,
            height: RectangleHeight,
            fill: '#4AC2374D',
            stroke: '#4AC237',
            textBackground: '#4AC237',
            strokeWidth: 1,
            draggable: true,
            is_delete: false,
            text: `${squareName} ${rectangles.length + 1}`,
        };
        setRectangles([...rectangles, newRectangle]);
    };

    const handleRectResize = (e, index) => {
        const node = rectangles[index];
        const scaleX = e.target.scaleX();
        const scaleY = e.target.scaleY();

        const newWidth = node.width * scaleX;
        const newHeight = node.height * scaleY;

        const updatedRectangles = [...rectangles];
        updatedRectangles[index] = {
            ...updatedRectangles[index],
            width: newWidth,
            height: newHeight,
            x: e.target.x(),
            y: e.target.y(),
        };
        setRectangles(updatedRectangles);

        e.target.scaleX(1);
        e.target.scaleY(1);
        setSelectedEditableIndex(null)
    };

    const handleDrag = (e, index) => {
        const updatedRectangles = [...rectangles];
        updatedRectangles[index] = {
            ...updatedRectangles[index],
            x: e.target.x(),
            y: e.target.y(),
        };

        setRectangles(updatedRectangles);
        setSelectedEditableIndex(null)
    };

    const checkDeselect = (e) => {
        setSelectedShapeIndex(null)
        setSelectedEditableIndex(null)
        onRectangleChange()
    };

    const onInputEdit = (e, index) => {
        const updatedRectangles = [...rectangles];
        updatedRectangles[index] = {
            ...updatedRectangles[index],
            text: e.target.value
        };
        setRectangles(updatedRectangles);
    }

    return (
        <Stage
            height={height}
            width={width}
            onDblClick={addRectangle}
            style={{ backgroundColor: image ? '#fff' : '#3333330D' }}
            className="position-relative"
        >
            <Layer>
                <Image
                    height={height}
                    width={width}
                    image={image}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    cornerRadius={8}
                />
                {rectangles?.map((rect, index) => (
                    !rect.is_delete && (
                        <Rectangle
                            key={`${index + 1}`}
                            onEditInput={(e) => onInputEdit(e, index)}
                            shapeProps={rect}
                            isSelected={index === selectedShapeIndex}
                            onDelete={() => deleteRectangle(index, true)}
                            onEdit={() => editRectangle(index)}
                            onTransform={(e) => handleRectResize(e, index)}
                            onDragMove={(e) => handleDrag(e, index)}
                            onClick={() => {
                                setSelectedShapeIndex(index)
                                setSelectedEditableIndex(null)
                                onRectangleChange(index)
                            }}
                        />
                    )
                ))}
            </Layer>
        </Stage>
    );
};

export default Crop;