import React, { useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import "./screens/configurepage/ConfigurationPage.css";
import AdminRegisterScreen from "./screens/authenticationPage/AdminRegisterScreen";
import AdminLogInScreen from "./screens/authenticationPage/AdminLogInScreen";
import SidebarContainer from "./components/SidebarContainer";
import Event from "./screens/EventsPage/Event";
import LiveCam from "./screens/livecamPage/livecam";
import ServiceAppliedScreen from "./screens/servicepage/ServiceAppliedScreen";
import ServiceOptions from "./screens/servicepage/ServiceOptions";
import CameraRequestScreen from './screens/settingpage/CameraRequestScreen';
import HomeScreen from "./screens/HomePage/HomeScreen";
import SettingScreen from "./screens/settingpage/SettingScreen";
import Analytics from "./screens/analyticsPage/Analytics";
import AdminPasswordReset from "./screens/authenticationPage/AdminPasswordReset";
import ForgotPasswordScreen from "./screens/authenticationPage/ForgotPasswordScreen";
import { Routes, Route } from "react-router-dom";
import UserRecycleBinPage from './screens/servicepage/FaceRecognitionService/UserRecycleBinPage'
import PageNotFound from "./screens/404PageNotFound/PageNotFound";
import Organization from "./screens/organizationPage/Organization";
import AnalyticsCompare from "./screens/analyticsPage/AnalyticsCompare";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINT } from "./constants/apiEndpoint";
import { getNavigationBarPageAccess } from "./actions/dataActions";
import { getInstalledAddOnData } from "./actions/organizationActions/addOnsActions";
import { jwtDecode } from 'jwt-decode';
import { logout } from "./actions/userActions";

function App() {
    const [addOnRoutes, setAddOnRoutes] = useState([]);
    const [count, setCount] = useState(0);

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const installedAddOnDataGet = useSelector((state) => state.InstalledAddOnData);
    const { loading: getInstalledAddOnDataLoading, success: getInstalledAddOnDataSuccess } = installedAddOnDataGet;

    useEffect(() => {
        dispatch(getNavigationBarPageAccess())
    }, [userInfo, dispatch])

    useLayoutEffect(() => {
        dispatch(getInstalledAddOnData())
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((prevCount) => prevCount + 1);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    // check whether every minutes token is expired or not if expired automatically log out of the application.
    useEffect(() => {
        const token = userInfo?.token
        if (token) {
            const decodedToken = jwtDecode(token)
            const currentTime = Math.floor(Date.now() / 1000)
            if (currentTime >= decodedToken.exp) {
                dispatch(logout());
                window.location.replace("/");
            }
        }
    }, [count, userInfo])

    useEffect(() => {
        setAddOnRoutes(
            getInstalledAddOnDataSuccess?.map(item => {
                if (item.is_pinned) {
                    return {
                        path: `${item.name}/`,
                        screen: `${API_ENDPOINT}/${item.name}/`
                    };
                }
                return null;
            }).filter(Boolean)
        );
    }, [getInstalledAddOnDataSuccess, userInfo?.id]);

    return (
        <div className="App">
            <Routes>
                <Route path="register/" element={<AdminRegisterScreen />} exact />
                <Route path="/" element={<AdminLogInScreen />} exact />
                <Route path="password-reset/:token" element={<AdminPasswordReset />} exact />
                <Route path="forgot-password/" element={<ForgotPasswordScreen />} exact />
                <Route
                    path="home/"
                    element={<SidebarContainer props={{ screen: <HomeScreen /> }} />}
                />
                <Route
                    path="events/"
                    element={<SidebarContainer props={{ screen: <Event /> }} />}
                />
                <Route
                    path="livecam/"
                    element={<SidebarContainer props={{ screen: <LiveCam /> }} />}
                />
                <Route
                    path="services/"
                    element={
                        <SidebarContainer
                            props={{ screen: <ServiceAppliedScreen />, page: "service" }}
                        />
                    }
                />
                <Route path="services/recycle-bin"
                    element={
                        <SidebarContainer
                            props={{ screen: <UserRecycleBinPage />, page: "recycle-bin" }}
                        />
                    }
                />
                <Route
                    path="services/serviceOptions"
                    element={
                        <SidebarContainer
                            props={{ screen: <ServiceOptions />, page: "ServiceOptions" }}
                        />
                    }
                />
                <Route
                    path="services/serviceOptions/:id"
                    element={
                        <SidebarContainer
                            props={{ screen: <ServiceOptions />, page: "ServiceOptions" }}
                        />
                    }
                />
                <Route
                    path="settings/"
                    element={<SidebarContainer props={{ screen: <SettingScreen /> }} />}
                />
                <Route
                    path="settings/user-request"
                    element={<SidebarContainer props={{ screen: <CameraRequestScreen />, page: "SettingScreen" }} />}
                />
                <Route
                    path="analytics/"
                    element={<SidebarContainer props={{ screen: <Analytics /> }} />}
                />
                {addOnRoutes?.map((addOnRoute, index) => (
                    <Route
                        key={index}
                        path={addOnRoute.path}
                        element={<SidebarContainer props={{
                            screen: <iframe
                                title="Your Iframe Title"
                                src={`${addOnRoute.screen}?token=${userInfo?.token}`}
                                className="w-100"
                                frameBorder="0"
                                allowFullScreen
                                style={{ height: "calc(100vh - 85px)" }}
                            />
                        }} />}
                    />
                ))}
                <Route
                    path="analytics/compare/"
                    element={<SidebarContainer props={{ screen: <AnalyticsCompare /> }} />}
                />
                <Route
                    path="organization/"
                    element={<SidebarContainer props={{ screen: <Organization /> }} />}
                />

                <Route
                    path={getInstalledAddOnDataLoading === false ? "*" : ''}
                    element={<PageNotFound />}
                />

            </Routes>
        </div >
    );
}

export default App;
