import React, { useState, useMemo, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import AddUserRoleStep from './AddUserRoleStep';
import AccessStep from './AccessStep';
import { postRolesAndResponsibilityData, getRolesAndResponsibilityData, updateRolesAndResponsibilityData } from '../../../actions/organizationActions/rolesAndResponsibilityActions';
import { useSelector, useDispatch } from "react-redux";
import { POST_ROLES_AND_RESPONSIBILITY_DATA_RESET, UPDATE_ROLES_AND_RESPONSIBILITY_DATA_RESET } from '../../../constants/organizationConstant/rolesAndResponsibilityConstants';
import { DefaultAddAlert, CommonErrorAlert, DefaultUpdateAlerts } from '../../../components/ToastAlert'
import { toast } from "react-toastify";
import { Button } from 'antd';
import { getMasterListUserData } from '../../../actions/organizationActions/masterListActions';
import { capitalizeFirstLater } from '../../../global/Helper'



function SetupRolesAndResponsibilityModal({ closeSetupRolesAndResponsibilityModal }) {
    const [current, setCurrent] = useState(0);
    const [DepartmentValue, setDepartmentValue] = useState([]);
    const [LocationValue, setLocationValue] = useState([]);
    const [UserValue, setUserValue] = useState([]);
    const [RolesValue, setRolesValue] = useState([]);
    const [ServicesValue, setServicesValue] = useState([{ service_name: 'All Services' }]);
    const [SelectedServicesId, setSelectedServicesId] = useState(null);
    const [SelectedCamera, setSelectedCamera] = useState([])
    const [SelectedCameraServices, setSelectedCameraServices] = useState([])
    const [SelectServiceCameraId, setSelectServiceCameraId] = useState([])
    const [SelectAllCamera, setSelectAllCamera] = useState(false)
    const [disableNextButton, setDisableNextButton] = useState(true);

    const dispatch = useDispatch();

    const RolesAndResponsibilityDataPost = useSelector((state) => state.RolesAndResponsibilityDataPost);
    const { error: postRolesAndResponsibilityError, loading: postRolesAndResponsibilityLoad, success: postRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataPost;

    const RolesAndResponsibilityDataUpdate = useSelector((state) => state.RolesAndResponsibilityDataUpdate);
    const { error: updateRolesAndResponsibilityError, loading: updateRolesAndResponsibilityLoad, success: updateRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataUpdate;

    const masterListUserDataGet = useSelector((state) => state.masterListUserDataGet);
    const { success: getMasterListUserSuccess } = masterListUserDataGet;

    const RolesAndResponsibilityDataGetSingle = useSelector((state) => state.RolesAndResponsibilityDataGetSingle);
    const { success: getSingleRolesAndResponsibilitySuccess } = RolesAndResponsibilityDataGetSingle;


    const all_service_select = useMemo(() => (ServicesValue?.[0]?.service_name?.toLowerCase().includes("all services")), [ServicesValue])

    useEffect(() => {
        if (getSingleRolesAndResponsibilitySuccess?.user_id) {
            getMasterListUserSuccess?.filter((e) => {
                if (e.id === getSingleRolesAndResponsibilitySuccess?.user_id) {
                    setUserValue([{ user_name: e.username, id: e.id }])
                    setLocationValue([e.location])
                    setDepartmentValue([e.department])
                }

            })
        }
    }, [getSingleRolesAndResponsibilitySuccess, getMasterListUserSuccess])


    useEffect(() => {
        if (getSingleRolesAndResponsibilitySuccess?.id) {
            setSelectedCameraServices(getSingleRolesAndResponsibilitySuccess?.camera_service_conf)
            setRolesValue([capitalizeFirstLater(getSingleRolesAndResponsibilitySuccess?.role)])
            setSelectedServicesId(getSingleRolesAndResponsibilitySuccess?.camera_service_conf?.[0]?.service_id)
            if (getSingleRolesAndResponsibilitySuccess?.all_services) {
                setServicesValue([{ service_name: "All Services", id: "#" }])
            }
        }
    }, [getSingleRolesAndResponsibilitySuccess])

    useEffect(() => {
        dispatch(getMasterListUserData())
    }, [])


    useEffect(() => {
        setDisableNextButton(true)
        if (current === 0 && UserValue?.length > 0 && RolesValue?.length > 0) {
            setDisableNextButton(false)
        }
        if (current === 1 && ServicesValue?.length > 0 && SelectedCamera?.length > 0) {
            setDisableNextButton(false)
        }
    }, [current, UserValue, RolesValue, ServicesValue, SelectedCamera])

    const steps = [
        {
            title: 'Add User’s & Role',
            content:
                <AddUserRoleStep
                    setDepartmentValue={(e) => setDepartmentValue(e)}
                    DepartmentValue={DepartmentValue}
                    setLocationValue={(e) => setLocationValue(e)}
                    LocationValue={LocationValue}
                    setUserValue={(e) => setUserValue(e)}
                    UserValue={UserValue}
                    setRolesValue={(e) => setRolesValue(e)}
                    RolesValue={RolesValue}
                />,
        },
        {
            title: 'Access',
            content:
                <AccessStep
                    setServicesValue={(e) => setServicesValue(e)}
                    ServicesValue={ServicesValue}
                    SelectedServicesId={SelectedServicesId}
                    setSelectedCamera={(e) => setSelectedCamera(e)}
                    SelectedCamera={SelectedCamera}
                    setSelectedCameraServices={(e) => setSelectedCameraServices(e)}
                    SelectedCameraServices={SelectedCameraServices}
                    setSelectServiceCameraId={(e) => setSelectServiceCameraId(e)}
                    SelectServiceCameraId={SelectServiceCameraId}
                    SelectAllCamera={SelectAllCamera}
                    setSelectAllCamera={(e) => setSelectAllCamera(e)}
                />,
        },
    ]


    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        if (postRolesAndResponsibilitySuccess) {
            dispatch(getRolesAndResponsibilityData())
            closeSetupRolesAndResponsibilityModal()
            toast(<DefaultAddAlert name={UserValue?.[0]?.user_name} />);
            dispatch({ type: POST_ROLES_AND_RESPONSIBILITY_DATA_RESET })
        }
        if (postRolesAndResponsibilityError) {
            toast(<CommonErrorAlert message={postRolesAndResponsibilityError} />);
            dispatch({ type: POST_ROLES_AND_RESPONSIBILITY_DATA_RESET })
        }
        if (updateRolesAndResponsibilityError) {
            toast(<CommonErrorAlert message={updateRolesAndResponsibilityError} />);
            dispatch({ type: UPDATE_ROLES_AND_RESPONSIBILITY_DATA_RESET })
        }
        if (updateRolesAndResponsibilitySuccess) {
            dispatch(getRolesAndResponsibilityData())
            closeSetupRolesAndResponsibilityModal()
            dispatch({ type: UPDATE_ROLES_AND_RESPONSIBILITY_DATA_RESET })
            toast(<DefaultUpdateAlerts name={UserValue?.[0]?.user_name} />);
        }
    }, [dispatch, postRolesAndResponsibilitySuccess, postRolesAndResponsibilityError, updateRolesAndResponsibilityError, updateRolesAndResponsibilitySuccess])

    const roleAndResponsibilitySubmitHandler = () => {
        let rolesAndResponsibilityData = {
            user: UserValue?.[0]?.id,
            role: RolesValue?.[0].toLowerCase(),
            all_services: all_service_select
        }
        if (all_service_select) {
            rolesAndResponsibilityData.camera_service_conf = []
            if (SelectAllCamera) {
                rolesAndResponsibilityData.all_cameras = SelectAllCamera
            } else {
                rolesAndResponsibilityData.all_cameras = SelectedCamera
            }
        } else {
            rolesAndResponsibilityData.camera_service_conf = SelectedCameraServices
        }

        if (getSingleRolesAndResponsibilitySuccess?.id) {
            dispatch(updateRolesAndResponsibilityData(rolesAndResponsibilityData, getSingleRolesAndResponsibilitySuccess?.id))
        } else {
            dispatch(postRolesAndResponsibilityData(rolesAndResponsibilityData))
        }
    }

    return (
        <>
            <Modal.Header className="position-relative justify-content-center insight-header" closeButton>
                <Modal.Title className="fs-4">
                    Roles & Responsibilities
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='role-modal-body-container px-3'>
                <div className="row stepper-container my-2 px-3">
                    {steps?.map((element, index) => (
                        <div key={element.title} className={`col-md-6 mb-md-0 mb-3 px-2 ${getSingleRolesAndResponsibilitySuccess?.id && 'cursor-pointer'}`} onClick={() => getSingleRolesAndResponsibilitySuccess?.id && setCurrent(index)}>
                            <h6 >{index + 1}.{" "}{element.title}</h6>
                            <div className='insight-progress-bar'>
                                <div className={current + 1 > index ? "insight-progress-bar-fill active" : "insight-progress-bar-fill"}></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='px-3' style={{ height: "calc(100% - 120px)" }}>{steps[current].content}</div>

            </Modal.Body>
            <Modal.Footer className='role-modal-footer'>
                <div>
                    {current > 0 ? (
                        <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => prev()}>
                            Previous
                        </button>
                    ) : (
                        <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => closeSetupRolesAndResponsibilityModal()}>
                            Cancel
                        </button>
                    )}
                    {current < steps.length - 1 && (
                        <button
                            className="btn px-4 py-2 bg-warning border text-dark"
                            type='submit'
                            disabled={disableNextButton}
                            onClick={() => next()}
                        >
                            Next
                        </button>
                    )}
                    {current === steps.length - 1 && (
                        postRolesAndResponsibilityLoad || updateRolesAndResponsibilityLoad ? (
                            <Button type="primary" className='bg-warning border text-dark' size="large" loading>
                                Complete
                            </Button>
                        ) : (
                            <button
                                className="btn px-4 py-2 bg-warning border text-dark"
                                type='submit'
                                disabled={disableNextButton}
                                onClick={roleAndResponsibilitySubmitHandler}
                            >
                                Complete
                            </button>
                        )
                    )}

                </div>
            </Modal.Footer>
        </>
    )
}

export default SetupRolesAndResponsibilityModal