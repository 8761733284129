import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart(props) {

    const {
        data
    } = props

    const [GraphData, setGraphData] = useState(data)
    const [selectedLabel, setSelectedLabel] = useState([])

    const analyticsGetServices = useSelector((state) => state.analyticsGetServices);
    const { success: analyticsService } = analyticsGetServices;

    useEffect(() => {
        setGraphData({
            labels: data?.labels?.filter((element, index) => !selectedLabel.includes(index)),
            datasets: [
                {
                    label: data?.datasets?.[0]?.label,
                    data: data?.datasets?.[0]?.data?.filter((element, index) => !selectedLabel.includes(index)),
                    hoverOffset: data?.datasets?.[0]?.hoverOffset,
                    backgroundColor: data?.datasets?.[0]?.backgroundColor?.filter((element, index) => !selectedLabel.includes(index)),
                    borderColor: data?.datasets?.[0]?.borderColor,
                    borderWidth: data?.datasets?.[0]?.borderWidth,
                    borderRadius: 4,
                },
            ],
        })


    }, [data, selectedLabel])

    const datasetHandler = (index) => {
        if (selectedLabel.includes(index)) {
            setSelectedLabel(selectedLabel.filter(e => e !== index))
        } else if (data?.labels?.length - 1 !== selectedLabel.length) {
            setSelectedLabel([...selectedLabel, index])
        }
    }

    return (
        <div className='chart-container-border h-100'>
            <h6 className='fw-bold mt-3 ms-3'>Events Generated by Cameras</h6>
            <div className='d-flex align-items-center py-4' style={{ height: "calc(100% - 50px)" }}>
                <div style={{ width: "260px" }} className='position-relative'>
                    <Doughnut
                        data={GraphData}
                        options={{
                            cutout: "65%",
                            responsive: true,
                            maintainAspectRatio: true,
                            aspectRatio: 1.1,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'right',
                                }
                            },
                            layout: {
                                padding: 0
                            }
                        }}
                    />
                    <div className='position-absolute text-center top-0 bottom-0 m-auto start-0 end-0' style={{ height: "fit-content" }}>
                        <div className='fs-1' style={{ lineHeight: "40px" }}>{analyticsService?.card_section_2?.total_cameras}</div>
                        <div style={{ color: "rgba(0, 0, 0, 0.50)", fontWeight: "500", fontSize: "15px" }}>Cameras</div>
                    </div>
                </div>
                <div className="bar-chart-legends d-flex flex-column top-0 end-0 w-100 overflow-hidden">
                    {data.datasets?.[0]?.data?.map((e, index) => {
                        const getTotalPercentage = `${Math.round((100 * e) / data.datasets?.[0]?.data.reduce((a, b) => a + b))}% (${e} Events)`

                        return (
                            <div className='d-flex align-items-start cursor-pointer' style={{ marginBottom: "12px" }} onClick={() => datasetHandler(index)} key={`${index + 1}`}>
                                <div className='me-2 mt-1 legend-box' style={{ backgroundColor: data?.datasets?.[0]?.backgroundColor[index] }}></div>
                                {selectedLabel.includes(index) ? (
                                    <strike className='d-flex align-items-center flex-wrap text-nowrap opacity' style={{ width: "calc(100% - 30px)" }} title={`${data.labels[index]} - ${getTotalPercentage}`}>
                                        <div className='text-truncate me-1'>{data.labels[index]}</div>
                                        <div className='text-truncate'>
                                            - {getTotalPercentage}
                                        </div>
                                    </strike>
                                ) : (
                                    <div className='d-flex align-items-center text-nowrap flex-wrap' style={{ width: "calc(100% - 30px)" }} title={`${data.labels[index]} - ${getTotalPercentage}`}>
                                        <div className='text-truncate me-1'>{data.labels[index]}</div>
                                        <div className='text-truncate'>
                                            - {getTotalPercentage}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default DoughnutChart