import axios from 'axios';
import { API_ENDPOINT } from '../constants/apiEndpoint';
import {
    GET_FACE_RECOGNITION_CAMERAS_REQUEST,
    GET_FACE_RECOGNITION_CAMERAS_SUCCESS,
    GET_FACE_RECOGNITION_CAMERAS_FAIL,

    GET_FACE_RECOGNITION_USERS_REQUEST,
    GET_FACE_RECOGNITION_USERS_SUCCESS,
    GET_FACE_RECOGNITION_USERS_FAIL,

    DELETE_FACE_RECOGNITION_CAMERA_REQUEST,
    DELETE_FACE_RECOGNITION_CAMERA_SUCCESS,
    DELETE_FACE_RECOGNITION_CAMERA_FAIL,

    DELETE_FACE_RECOGNITION_USER_REQUEST,
    DELETE_FACE_RECOGNITION_USER_SUCCESS,
    DELETE_FACE_RECOGNITION_USER_FAIL,


    ADD_FACE_RECOGNITION_USER_REQUEST,
    ADD_FACE_RECOGNITION_USER_SUCCESS,
    ADD_FACE_RECOGNITION_USER_FAIL,

    GET_USER_CONFIGURATION_REQUEST,
    GET_USER_CONFIGURATION_SUCCESS,
    GET_USER_CONFIGURATION_FAIL,

    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST,
    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
    APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,

    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST,
    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
    REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,

    GET_CAMERAS_CONFIGURATION_REQUEST,
    GET_CAMERAS_CONFIGURATION_SUCCESS,
    GET_CAMERAS_CONFIGURATION_FAIL,

    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_REQUEST,
    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_SUCCESS,
    APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_FAIL,

    DELETE_FACE_PERMANENT_REQUEST,
    DELETE_FACE_PERMANENT_SUCCESS,
    DELETE_FACE_PERMANENT_FAIL,

    RESTORE_FACE_REQUEST,
    RESTORE_FACE_SUCCESS,
    RESTORE_FACE_FAIL,

    RECYCLED_FACE_REQUEST,
    RECYCLED_FACE_SUCCESS,
    RECYCLED_FACE_FAIL,

    MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST,
    MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS,
    MULTIPLE_ANGLE_FACE_VALIDATION_FAIL,
} from '../constants/faceRecognitionConstant.js'
import { deleteAPI, getAPI, postAPI } from '../global/ApiManager.js';
import { removeBase64String } from '../global/Helper.js';


export const getFaceRecognitionCamera = (service_id, page, camerasSearch, cameraName, cameraStatus) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FACE_RECOGNITION_CAMERAS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const params = {
            page: page,
            "camera-search": camerasSearch,
            "camera-name": cameraName,
            "camera-status": cameraStatus
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_face_recognition_cameras/${service_id}`, dispatch)

        dispatch({
            type: GET_FACE_RECOGNITION_CAMERAS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_FACE_RECOGNITION_CAMERAS_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const getFaceRecognitionUsers = (page, search, selectCameraAccess, userType, department, camera) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_FACE_RECOGNITION_USERS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const params = {
            page: page,
            search: search,
            "camera-access": selectCameraAccess,
            user_type: userType,
            department: department,
            camera: camera
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_face_recognition_users/`, dispatch)

        dispatch({
            type: GET_FACE_RECOGNITION_USERS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_FACE_RECOGNITION_USERS_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const deleteFaceRecognitionCamera = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_FACE_RECOGNITION_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/service_app/delete-services-config-data/${id}`,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_FACE_RECOGNITION_CAMERA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_FACE_RECOGNITION_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const deleteFaceRecognitionUser = (user_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_FACE_RECOGNITION_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/service_app/delete_added_user_from_face_recognition/${user_id}`,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_FACE_RECOGNITION_USER_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_FACE_RECOGNITION_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const addUserInFaceRecognition = (userDetails) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_FACE_RECOGNITION_USER_REQUEST
        })

        const formData = new FormData()
        const entries = Object.entries(userDetails)
        for (const [key, value] of entries) {
            if (key.includes('face')) {
                formData.append(key, value?.replace(removeBase64String, "") || '');
            }
            formData.append(key, value || '');
        }

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/add_user_in_face_recognition/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: ADD_FACE_RECOGNITION_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_FACE_RECOGNITION_USER_FAIL,
            payload: JSON.stringify(error.data)
        })
    }
}

export const getUsersConfiguration = (camera_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_USER_CONFIGURATION_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_users_configuration/${camera_id}`, dispatch)

        dispatch({
            type: GET_USER_CONFIGURATION_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_USER_CONFIGURATION_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const applyUsersInFaceRecognitionCamera = (userDetails) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/apply_users_in_face_recognition_camera/`,
            userDetails,
            config,
            dispatch
        )

        dispatch({
            type: APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const removeUsersInFaceRecognitionCamera = (userDetails) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/remove_users_in_face_recognition_camera/`,
            userDetails,
            config,
            dispatch
        )

        dispatch({
            type: REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const getCamerasConfiguration = (service_id, user_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CAMERAS_CONFIGURATION_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_cameras_configuration/${service_id}/${user_id}`, dispatch)


        dispatch({
            type: GET_CAMERAS_CONFIGURATION_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_CAMERAS_CONFIGURATION_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const applyCamerasInFaceRecognitionUser = (camerasDetails) => async (dispatch, getState) => {
    try {
        dispatch({
            type: APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/apply_cameras_in_face_recognition_user/`,
            camerasDetails,
            config,
            dispatch
        )

        dispatch({
            type: APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
};

export const deleteFacePermanent = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: DELETE_FACE_PERMANENT_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };

        const { data } = await postAPI(
            `/app/permanently_delete_deleted_user_from_face_recognition/${id}`,
            id,
            config,
            dispatch
        );

        dispatch({ type: DELETE_FACE_PERMANENT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: DELETE_FACE_PERMANENT_FAIL, payload: error });
    }
};

export const restoreFace = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: RESTORE_FACE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };
        const { data } = await postAPI(
            `/app/restore_deleted_user_from_face_recognition/${id}`,
            id,
            config,
            dispatch
        );
        dispatch({ type: RESTORE_FACE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: RESTORE_FACE_FAIL, payload: error });
    }
};

export const recycledFaces = () => async (dispatch, getState) => {
    try {
        dispatch({ type: RECYCLED_FACE_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
        };


        const { data } = await getAPI(config, `/app/get_all_deleted_user_from_face_recognition/`, dispatch)

        dispatch({ type: RECYCLED_FACE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: RECYCLED_FACE_FAIL, payload: error
        });
    }
};

export const multipleAngleFaceValidation = (faces) => async (dispatch, getState) => {
    try {
        dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
        };

        const { data } = await postAPI(`/service_app/multiple_angle_face_validation/`, faces, config, dispatch);

        dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: MULTIPLE_ANGLE_FACE_VALIDATION_FAIL,
            payload: JSON.stringify(error?.data?.error || error)
        });
    };
};


export const bulkUploadUsersCSVFile = async (token, file) => {
    try {
        const formData = new FormData();
        formData.append("xlsx_file", file);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }
        const { data } = await axios.post(
            `${API_ENDPOINT}/service_app/add_user_in_face_recognition_excel_view/`,
            formData,
            config
        )
        return { 'data': data, 'status': 200 }
    } catch (error) {
        return { 'data': error, 'status': 400 }
    }
}


export const faceAngleValidation = async (token, faceData) => {
    try {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.post(`${API_ENDPOINT}/service_app/multiple_angle_face_validation/`, faceData, config);

        return { 'data': data, 'status': 200 }
    } catch (error) {
        return { 'data': JSON.stringify(error), 'status': 400 }
    }
}

