import React, { useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Button } from "antd";
import RegisterScreenRightSideBar from "../../components/AuthenticationComponents/RegisterScreenRightSideBar";
import PasswordHideShow from "../../components/AuthenticationComponents/PasswordHideShow";
import { Link, useParams, useNavigate } from "react-router-dom";
import { newPassword } from "../../actions/userActions";
import { USER_NEW_PASSWORD_RESET } from "../../constants/userConstants";
import { useSelector, useDispatch } from "react-redux";
import { passwordValidator } from "../../global/Helper";
import RegisterScreenLeftSideBar from "../../components/AuthenticationComponents/RegisterScreenLeftSideBar";
import { toast } from "react-toastify";
import { ResetPasswordAlert } from "../../components/ToastAlert";

const AdminPasswordReset = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [password, setPassword] = useState('');

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = params.token;
  const eyeOpenClose = useRef();
  const ConfirmEyeOpenClose = useRef();

  const changePassword = useSelector((state) => state.changePassword);
  const { loading, success, error } = changePassword;

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_NEW_PASSWORD_RESET })
      navigate("/", { replace: true });
      toast(<ResetPasswordAlert />)
    }
  }, [success, navigate]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch({ type: USER_NEW_PASSWORD_RESET })
      }, 6000);
    }
  }, [dispatch, error])

  let newPasswordInput;
  let confirmPasswordInput;

  useEffect(() => {
    newPasswordInput = document.getElementById("newPassword")
    confirmPasswordInput = document.getElementById("confirmPassword")
  })

  const passwordTypeOne = () => {
    PasswordHideShow(newPasswordInput, eyeOpenClose);
  };

  const passwordTypeTwo = () => {
    PasswordHideShow(confirmPasswordInput, ConfirmEyeOpenClose);
  };

  function confirmPasswordValidator(value) {
    if (password !== value) {
      setPasswordNotMatch(true)
    } else {
      setPasswordNotMatch(false)
    }
  }

  const submitHandler = (resetPasswords) => {
    if (resetPasswords.newPassword !== resetPasswords.confirmPassword) {
      setPasswordNotMatch(true)
    } else {
      dispatch(newPassword(token, resetPasswords.newPassword));

    }
  };
  return (
    <div>
      <div className="row g-0 auth-container">
        <div className="col-md-5">
          <RegisterScreenLeftSideBar />
        </div>
        <div className="col-md-7">
          <RegisterScreenRightSideBar>
            <h5 className='mb-4 text-center register_headline'>Reset Password</h5>
            <Form className="mb-4" noValidate onSubmit={handleSubmit(submitHandler)} >
              <Form.Group className="password-field mb-3">
                <Form.Label className="m-0" htmlFor="">New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  className="border-input"
                  id="newPassword"
                  placeholder="Enter New Password"
                  {...register("newPassword", {
                    required: "Please Enter New Password.",
                    pattern: {
                      value: passwordValidator,
                      message: "Password contain at least one alphabet, one number and minimum 8 or more characters.",
                    },
                    onChange: (e) => setPassword(e.target.value)
                  })}
                  isInvalid={(errors.newPassword ? errors.newPassword : error)}
                  required
                />

                <i
                  onClick={passwordTypeOne}
                  ref={eyeOpenClose}
                  className="ri-eye-close-line text-dark"
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.newPassword?.message || error?.[0]?.replace("username", "Email")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="password-field mb-3">
                <Form.Label className="m-0" htmlFor="">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  className="border-input"
                  id="confirmPassword"
                  placeholder="Enter confirm Password"
                  {...register("confirmPassword", {
                    required: "Please Enter confirm Password.",
                    onChange: (e) => confirmPasswordValidator(e.target.value)
                  })}
                  isInvalid={(passwordNotMatch || errors.confirmPassword)}
                  required
                />

                <i
                  onClick={passwordTypeTwo}
                  ref={ConfirmEyeOpenClose}
                  className="ri-eye-close-line text-dark"
                />
                <Form.Control.Feedback type="invalid">
                  {passwordNotMatch ? "New Password and Confirm Does Not Match." : `${errors?.confirmPassword?.message}.`}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="text-center my-4">
                {loading ?
                  (<Button type="primary" size="large" loading>Reset Password</Button>)
                  :
                  (<button
                    className="btn px-4 py-2 bg-warning border text-dark"
                    type="submit"
                  >
                    Reset Password
                  </button>)}
              </div>
              <p className="text-center m-0">
                Did you recall your Password?
                <Link to="/"> Sign In</Link>
              </p>
            </Form>
          </RegisterScreenRightSideBar>
        </div>
      </div>

    </div >
  );
};

export default AdminPasswordReset;
