
import { useDispatch } from 'react-redux';
import { Button } from 'antd';


function DeleteConfirmModal(props) {

    const dispatch = useDispatch();

    return (
        <>
            <div className="d-flex flex-column align-items-center justify-content-center mt-2 text-center">

                <h4 className="mb-0"><i className="fs-1 ri-close-circle-line text-danger"></i></h4>
                <h3>Are you sure?</h3>
                {props.permanent ? (<h5 className="mb-0">Do you want to delete <b>{props.item_name}</b> permanently ?</h5>) :
                    (<h5 className="mb-0">Do you really want to delete <b>{props.item_name}</b> ?</h5>)}
            </div>
            <div className="d-flex align-itmes-center justify-content-end mt-4">
                <button className="btn me-3 px-3 py-2 border close" onClick={props.onHide}>
                    Cancel
                </button>

                {props?.loading ? (<Button type="danger" size="large" loading>
                    Delete
                </Button>) :
                    (
                        <button
                            className="btn px-3 py-2 bg-danger border text-light close"
                            onClick={(e) => {
                                if (props.notificationDelete) {
                                    props.onClick(e)
                                } else {
                                    dispatch(props.dispatch_item);
                                }
                            }}
                        // onClick={() => dispatch(props.service_id)}
                        >
                            Delete
                        </button>
                    )}
            </div>
        </>
    );
}

export default DeleteConfirmModal;
