import React, { Fragment } from 'react'
import { Slider } from 'antd';
import Tooltip from '@mui/material/Tooltip';

function AccuracySlider(props) {

    const {
        setAccuracyLevel,
        AccuracyLevel,
    } = props;

    const onChange = (value) => {
        if (isNaN(value)) {
            return;
        }
        setAccuracyLevel(value);
    };

    return (
        <Tooltip
            title={
                <Fragment>
                    <div className='d-flex text-dark mb-2 px-2' style={{ fontSize: "14px" }}>
                        <div className='opacity'>Set Accuracy</div>
                        <div className='fw-bold ms-auto'>{AccuracyLevel?.toFixed(2)}</div>
                    </div>
                    <Slider
                        defaultValue={AccuracyLevel}
                        min={0}
                        max={1}
                        onChange={onChange}
                        value={typeof AccuracyLevel === 'number' ? AccuracyLevel : 0}
                        step={0.01}
                        tooltip={{
                            formatter: null,
                        }}
                        trackStyle={{ background: "var(--secondary_bg)", height: "5px", borderRadius: "14px" }}
                        railStyle={{ background: "#D9D9D9", height: "5px", borderRadius: "14px" }}
                        handleStyle={{ background: "var(--secondary_bg)", height: "15px", width: "15px", border: "var(--secondary_bg)" }}
                    />
                </Fragment>
            }
            id='accuracy-modal-tooltip'
            placement="right-end"
            disableFocusListener
        >
            <div
                className="ms-auto cursor-pointer"
                style={{ textDecoration: "underline" }}
            >
                Accuracy : <span className='fw-bold'>{AccuracyLevel?.toFixed(2)}</span>
            </div>
        </Tooltip>
    );
}

export default AccuracySlider