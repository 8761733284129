import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import AnalyticsFIlter from './analyticsComponents/AnalyticsFIlter';
import BarChart from './analyticsComponents/BarChart';
import { lineChartColors, reportSummaryColors, reportSummaryColorsOpacity } from '../../global/Colors';
import LineChart from './analyticsComponents/LineChart';
import { Radio } from 'antd';
import CompareFilterButton from './analyticsComponents/CompareFilterButton';
import dayjs from 'dayjs';
import { capitalizeFirstLater, getFormattedDate, getFormattedDateTime } from '../../global/Helper';
import { useDispatch, useSelector } from "react-redux";
import { postAnalyticsFilterData } from '../../actions/analyticsActions'
import AnalyticsLoading from "./analyticsComponents/AnalyticsLoading";
import noDataFound from '../../images/report-summay/no-data-found.gif'

function AnalyticsCompare() {
    let navigate = useNavigate();
    let location = useLocation();
    let dispatch = useDispatch();
    let chartContainerRef = useRef(null);

    const [GraphOverview, setGraphOverview] = useState("overview");
    const [CameraTypeFilter, setCameraTypeFilter] = useState('camera')
    const [CheckBoxCameraValue, setCheckBoxCameraValue] = useState([]);
    const [CheckBoxGroupValue, setCheckBoxGroupValue] = useState([]);
    const [ChartContainerRefHeight, setChartContainerRefHeight] = useState(NaN);
    const [CamerasOption, setCamerasOption] = useState([]);
    const [CamerasGroupOption, setCamerasGroupOption] = useState([]);

    const calendarType = location.state.calendarType
    const ServiceName = location.state.ServiceName
    const StartYear = location.state.StartYear
    const EndYear = location.state.EndYear
    const StartDateFilter = dayjs(location.state.StartDateFilter.$d)
    const EndDateFilter = dayjs(location.state.EndDateFilter.$d)

    const calendarTypeMappings = {
        "day": "daily",
        "week": "weekly",
    };

    useEffect(() => {

        let formData = {
            date_filter: [getFormattedDateTime(StartDateFilter, calendarType, 'start'), getFormattedDateTime(StartDateFilter, calendarType, 'end')],
            compare_date_filter: [getFormattedDateTime(EndDateFilter, calendarType, 'start'), getFormattedDateTime(EndDateFilter, calendarType, 'end')],
            frequency: calendarTypeMappings[calendarType] || "monthly",
            service_filter: ServiceName.includes("All Service") ? true : ServiceName,
        }
        if (CameraTypeFilter === 'camera') {
            formData.camera_filter = CheckBoxCameraValue.length > 0 ? CheckBoxCameraValue : true
        } else {
            formData.group_filter = CheckBoxGroupValue.length > 0 ? CheckBoxGroupValue : true
        }

        dispatch(postAnalyticsFilterData(formData))

    }, [location, CheckBoxCameraValue, CheckBoxGroupValue])

    const analyticsFilterPostData = useSelector((state) => state.analyticsFilterPostData);
    const { loading: analyticsCompareLoad, success: analyticsCompareData } = analyticsFilterPostData;

    const BarChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 5,
        barPercentage: 1,
        categoryPercentage: 0.4,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    color: "rgba(0, 0, 0, 0)",
                    offset: true
                }
            },
            y: {
                stacked: true,
                grid: {
                    color: "rgba(0, 0, 0, 0)",
                    offset: true
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        if (Math.floor(value) === value) {
                            return value;
                        }
                    }
                }
            },
        },
    };

    const LineChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 5,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: true
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        return Math.round(value);
                    }
                }
            },
        },
        elements: {
            point: {
                radius: 0
            }
        }
    };

    const allServicesSelected = useMemo(() => location.state.ServiceName === "All Services", [location])

    const analyticsCompareDataFirst = useMemo(() => analyticsCompareData?.[0] ? analyticsCompareData?.[0] : {}, [analyticsCompareData])
    const analyticsCompareDataSecond = useMemo(() => analyticsCompareData?.[1] ? analyticsCompareData?.[1] : {}, [analyticsCompareData])

    const ChartData = useMemo(() => {
        const graphLabel = analyticsCompareDataFirst?.graph_data?.labels ? analyticsCompareDataFirst?.graph_data?.labels : analyticsCompareDataSecond?.graph_data?.labels

        let FirstGraphData = analyticsCompareDataFirst?.graph_data?.datasets ? analyticsCompareDataFirst?.graph_data?.datasets : {}
        let SecondGraphData = analyticsCompareDataSecond?.graph_data?.datasets ? analyticsCompareDataSecond?.graph_data?.datasets : {}

        const combineDatasetsValues = { ...FirstGraphData, ...SecondGraphData }

        if (allServicesSelected) {
            return ({
                labels: graphLabel,
                datasets: Object.keys(combineDatasetsValues)?.map((e, index) => {
                    return {
                        label: e,
                        data: combineDatasetsValues[e],
                        borderColor: lineChartColors[index],
                        backgroundColor: lineChartColors[index],
                    }
                })
            })
        } else {
            Object.keys(combineDatasetsValues)?.map(e => {
                if (!FirstGraphData[e]) {
                    FirstGraphData[e] = [0]
                }
                if (!SecondGraphData[e]) {
                    SecondGraphData[e] = [0]
                }
            })

            const sortedFirstGraphData = Object.fromEntries(Object.keys(FirstGraphData).sort()?.map(key => [key, FirstGraphData[key]]))
            const compareGraphOne = Object.keys(sortedFirstGraphData)?.map((e, index) => ({
                label: e,
                data: sortedFirstGraphData[e],
                backgroundColor: reportSummaryColorsOpacity[index],
                stack: "1"
            }))

            const sortedSecondGraphData = Object.fromEntries(Object.keys(SecondGraphData).sort()?.map(key => [key, SecondGraphData[key]]))
            const compareGraphTwo = Object.keys(sortedSecondGraphData)?.map((e, index) => ({
                label: e,
                data: sortedSecondGraphData[e],
                backgroundColor: reportSummaryColors[index],
                stack: "0"
            }))

            const combineGraphOneTwo = [...compareGraphOne, ...compareGraphTwo]

            return (
                {
                    labels: graphLabel,
                    datasets: combineGraphOneTwo
                }
            )
        }
    }, [analyticsCompareData])

    useEffect(() => {
        if (chartContainerRef?.current) {
            setChartContainerRefHeight(chartContainerRef?.current?.clientHeight)
        }
    })

    function containsNumbers(str) {
        return !(/\d/.test(str));
    }

    const DateFilter = useMemo(() => [getFormattedDateTime(StartDateFilter, calendarType, 'start'), getFormattedDateTime(StartDateFilter, calendarType, 'end')], [])

    return (
        <div className='analytics-container'>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className='d-flex my-2 align-items-center'>
                    <i className="ri-arrow-left-line me-2 fs-4 cursor-pointer" onClick={() => navigate(-1)}></i>
                    <CompareFilterButton
                        calendarType={location.state.calendarType}
                        compareScreen={true}
                        ServiceName={ServiceName}
                        StartDateCompare={StartDateFilter}
                        EndDateCompare={EndDateFilter}
                        StartYearCompare={StartYear}
                        EndYearCompare={EndYear}
                    />
                </div>
                <AnalyticsFIlter
                    CameraTypeFilter={CameraTypeFilter}
                    setCameraTypeFilter={setCameraTypeFilter}
                    CheckBoxCameraValue={CheckBoxCameraValue}
                    setCheckBoxCameraValue={setCheckBoxCameraValue}
                    CheckBoxGroupValue={CheckBoxGroupValue}
                    setCheckBoxGroupValue={setCheckBoxGroupValue}
                    DateFilter={DateFilter}
                    setCamerasOption={setCamerasOption}
                    CamerasOption={CamerasOption}
                    setCamerasGroupOption={setCamerasGroupOption}
                    CamerasGroupOption={CamerasGroupOption}
                />
            </div>
            {analyticsCompareLoad ? (
                <AnalyticsLoading />
            ) : (
                <div>
                    {(Object.keys(analyticsCompareDataFirst).length === 0 && Object.keys(analyticsCompareDataSecond)) ? (
                        <div className="d-flex justify-content-center align-items-center" style={
                            {
                                height: "fit-content",
                                position: "absolute",
                                top: "100px",
                                bottom: "0",
                                left: "0",
                                right: "0",
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}>
                            <div className="text-center">
                                <img src={noDataFound} className="" alt="" height={"200px"} width={"200px"} />
                                <h4 className="opacity fw-bold">No Results Found.</h4>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            <div ref={chartContainerRef}>
                                {allServicesSelected ? (
                                    <LineChart
                                        xAxisName={calendarType}
                                        data={ChartData}
                                        options={LineChartOptions}
                                    />
                                ) : (
                                    <BarChart
                                        xAxisName={calendarType}
                                        compareScreen={true}
                                        data={ChartData}
                                        options={BarChartOptions}
                                    />
                                )}
                            </div>
                            <div className="graph-overview-container mt-3">
                                <div className='row graph-overview-table-header pb-1 mx-0'>
                                    <div className='col-6 p-0'>
                                        <Radio.Group value={GraphOverview} onChange={(e) => setGraphOverview(e.target.value)}>
                                            <Radio.Button value="overview">Overview</Radio.Button>
                                            <Radio.Button value="detailed">Detailed</Radio.Button>
                                        </Radio.Group>
                                    </div >
                                    <div className='col-3 p-0'>
                                        <div className='fw-bold text-center bg-light-grey py-2 border-top-left-radius fs-6'>
                                            {calendarType === "day" ? (
                                                getFormattedDate(StartDateFilter, calendarType, "start")) : (
                                                `${getFormattedDate(StartDateFilter, calendarType, "start")} - ${getFormattedDate(StartDateFilter, calendarType, "end")}`
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-3 p-0'>
                                        <div className='fw-bold text-center bg-light-pink py-2 border-top-right-radius fs-6'>
                                            {calendarType === "day" ? (
                                                getFormattedDate(EndDateFilter, calendarType, "start")) : (
                                                `${getFormattedDate(EndDateFilter, calendarType, "start")} - ${getFormattedDate(EndDateFilter, calendarType, "end")}`
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ maxHeight: `calc(100vh - ${ChartContainerRefHeight}px  - 219px)`, overflowY: "auto" }}>
                                    {GraphOverview === 'overview' ? (
                                        <div className="graph-overview-table-body">
                                            {(analyticsCompareDataFirst?.overview_data ? Object.keys(analyticsCompareDataFirst?.overview_data) : Object.keys(analyticsCompareDataSecond?.overview_data))?.map((e, index) => (
                                                <div key={`${index + 1}`} className="row border rounded my-1 mx-0">
                                                    <div className='col-6 p-0 py-2 ps-4' style={{ fontWeight: "500" }}>
                                                        {capitalizeFirstLater(e.split("_").join(" "))}
                                                    </div>
                                                    <div className='col-3 bg-light-grey p-0'>
                                                        <div className='text-center py-2'>
                                                            {analyticsCompareDataFirst?.overview_data && (
                                                                typeof (analyticsCompareDataFirst?.overview_data[e]) === "number" ? (
                                                                    <span className={analyticsCompareDataFirst?.overview_data[e] > analyticsCompareDataSecond?.overview_data?.[e] ? 'text-danger' : ""}>{analyticsCompareDataFirst?.overview_data[e]}</span>
                                                                ) : (
                                                                    <Fragment>
                                                                        {e === "highest_events_detecting_for" ? (
                                                                            <span
                                                                                className={analyticsCompareDataFirst?.overview_data['total_events'] > analyticsCompareDataSecond?.overview_data?.['total_events'] ? 'text-danger' : ""}
                                                                            >
                                                                                {analyticsCompareDataSecond?.overview_data?.[e]}
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className={Number(analyticsCompareDataFirst?.overview_data[e].split(/[( )]/).slice(-2).join('')) > Number(analyticsCompareDataSecond?.overview_data?.[e].split(/[( )]/).slice(-2).join('')) ? 'text-danger' : ""}
                                                                            >
                                                                                {analyticsCompareDataFirst?.overview_data[e]}
                                                                            </span>)}
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='col-3 bg-light-pink p-0'>
                                                        <div className='text-center py-2'>
                                                            {analyticsCompareDataSecond?.overview_data && (
                                                                typeof (analyticsCompareDataSecond?.overview_data[e]) === "number" ? (
                                                                    <span className={analyticsCompareDataSecond?.overview_data[e] > analyticsCompareDataFirst?.overview_data?.[e] ? 'text-danger' : ""}>{analyticsCompareDataSecond?.overview_data[e]}</span>
                                                                ) : (
                                                                    <Fragment>
                                                                        {e === "highest_events_detecting_for" ? (
                                                                            <span
                                                                                className={analyticsCompareDataSecond?.overview_data['total_events'] > analyticsCompareDataFirst?.overview_data?.['total_events'] ? 'text-danger' : ""}
                                                                            >
                                                                                {analyticsCompareDataSecond?.overview_data?.[e]}
                                                                            </span>) : (
                                                                            <span
                                                                                className={Number(analyticsCompareDataSecond?.overview_data[e].split(/[( )]/).slice(-2).join('')) > Number(analyticsCompareDataFirst?.overview_data?.[e].split(/[( )]/).slice(-2).join('')) ? 'text-danger' : ""}
                                                                            >
                                                                                {analyticsCompareDataSecond?.overview_data[e]}
                                                                            </span>)}
                                                                    </Fragment>))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="graph-overview-table-body">
                                            {(analyticsCompareDataFirst?.detailed_data ? Object.keys(analyticsCompareDataFirst?.detailed_data) : Object.keys(analyticsCompareDataSecond?.detailed_data))?.map((e, index) => (
                                                <div className="d-flex" key={`${index + 1}`} >
                                                    <div className='row-group-heading' title={`${capitalizeFirstLater(e)}`}>
                                                        <span className='text-truncate'>{capitalizeFirstLater(e)}</span>
                                                    </div>
                                                    <div className='w-100'>
                                                        {(analyticsCompareDataFirst?.detailed_data[e] ? Object.keys(analyticsCompareDataFirst?.detailed_data[e]) : Object.keys(analyticsCompareDataSecond?.detailed_data[e]))?.map((graphDetail, index) => (
                                                            <div className="row border rounded my-1 me-0" key={`${index + 1}`}>
                                                                <div className='col-6 py-2' style={{ fontWeight: "500", paddingLeft: "60px", width: "calc(50% - 28.6px)" }}>
                                                                    {capitalizeFirstLater(graphDetail.split("_").join(" "))}
                                                                </div>
                                                                <div className='col-3 p-0 bg-light-grey' style={{ width: "calc(25% + 14.4px)" }}>
                                                                    <div className='text-center py-2'>
                                                                        {analyticsCompareDataFirst?.overview_data && (
                                                                            typeof (analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail]) === "number" ? (
                                                                                <span className={analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail] > analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail] ? 'text-danger' : ""}>{analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail]}</span>
                                                                            ) : (
                                                                                <Fragment>
                                                                                    {containsNumbers(analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail]) ? (
                                                                                        <span
                                                                                            className={analyticsCompareDataFirst?.detailed_data?.[e]?.['total_events'] > analyticsCompareDataSecond?.detailed_data?.[e]?.['total_events'] ? 'text-danger' : ""}
                                                                                        >
                                                                                            {analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail]}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span
                                                                                            className={Number(analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail].split(/[( )]/).slice(-2).join('')) > Number(analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail].split(/[( )]/).slice(-2).join('')) ? 'text-danger' : ""}
                                                                                        >
                                                                                            {analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail]}
                                                                                        </span>)}
                                                                                </Fragment>))}
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 p-0 bg-light-pink' style={{ width: "calc(25% + 13.5px)" }}>
                                                                    <div className='text-center py-2'>
                                                                        {analyticsCompareDataSecond?.overview_data && (
                                                                            typeof (analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail]) === "number" ? (
                                                                                <span className={analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail] > analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail] ? 'text-danger' : ""}>{analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail]}</span>
                                                                            ) : (
                                                                                <Fragment>
                                                                                    {containsNumbers(analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail]) ? (
                                                                                        <span
                                                                                            className={analyticsCompareDataSecond?.detailed_data?.[e]?.['total_events'] > analyticsCompareDataFirst?.detailed_data?.[e]?.['total_events'] ? 'text-danger' : ""}
                                                                                        >
                                                                                            {analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail]}
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span
                                                                                            className={Number(analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail].split(/[( )]/).slice(-2).join('')) > Number(analyticsCompareDataFirst?.detailed_data?.[e]?.[graphDetail].split(/[( )]/).slice(-2).join('')) ? 'text-danger' : ""}
                                                                                        >
                                                                                            {analyticsCompareDataSecond?.detailed_data?.[e]?.[graphDetail]}
                                                                                        </span>)}
                                                                                </Fragment>))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            )
            }
        </div >
    )
}

export default AnalyticsCompare