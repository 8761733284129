export const GET_ADD_ON_DATA_REQUEST = 'GET_ADD_ON_DATA_REQUEST'
export const GET_ADD_ON_DATA_SUCCESS = 'GET_ADD_ON_DATA_SUCCESS'
export const GET_ADD_ON_DATA_FAIL = 'GET_ADD_ON_DATA_FAIL'


export const GET_ADD_ON_INSTALL_DATA_REQUEST = 'GET_ADD_ON_INSTALL_DATA_REQUEST'
export const GET_ADD_ON_INSTALL_DATA_SUCCESS = 'GET_ADD_ON_INSTALL_DATA_SUCCESS'
export const GET_ADD_ON_INSTALL_DATA_FAIL = 'GET_ADD_ON_INSTALL_DATA_FAIL'

export const POST_ADD_ON_INSTALL_DATA_REQUEST = 'POST_ADD_ON_INSTALL_DATA_REQUEST'
export const POST_ADD_ON_INSTALL_DATA_SUCCESS = 'POST_ADD_ON_INSTALL_DATA_SUCCESS'
export const POST_ADD_ON_INSTALL_DATA_FAIL = 'POST_ADD_ON_INSTALL_DATA_FAIL'

export const DELETE_ADD_ON_INSTALL_DATA_REQUEST = 'DELETE_ADD_ON_INSTALL_DATA_REQUEST'
export const DELETE_ADD_ON_INSTALL_DATA_SUCCESS = 'DELETE_ADD_ON_INSTALL_DATA_SUCCESS'
export const DELETE_ADD_ON_INSTALL_DATA_FAIL = 'DELETE_ADD_ON_INSTALL_DATA_FAIL'

export const ADD_ADD_ON_TO_SHORTCUT_REQUEST = 'ADD_ADD_ON_TO_SHORTCUT_REQUEST'
export const ADD_ADD_ON_TO_SHORTCUT_SUCCESS = 'ADD_ADD_ON_TO_SHORTCUT_SUCCESS'
export const ADD_ADD_ON_TO_SHORTCUT_FAIL = 'ADD_ADD_ON_TO_SHORTCUT_FAIL'