import {
    GET_STORAGE_MANAGEMENT_DATA_REQUEST,
    GET_STORAGE_MANAGEMENT_DATA_SUCCESS,
    GET_STORAGE_MANAGEMENT_DATA_FAIL,

    PUT_STORAGE_MANAGEMENT_DATA_REQUEST,
    PUT_STORAGE_MANAGEMENT_DATA_SUCCESS,
    PUT_STORAGE_MANAGEMENT_DATA_FAIL,
    PUT_STORAGE_MANAGEMENT_DATA_RESET,

} from '../../constants/organizationConstant/storageManagementConstants'


export const getStorageManagementDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_STORAGE_MANAGEMENT_DATA_REQUEST:
            return { loading: true };

        case GET_STORAGE_MANAGEMENT_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_STORAGE_MANAGEMENT_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};


export const putStorageManagementDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case PUT_STORAGE_MANAGEMENT_DATA_REQUEST:
            return { loading: true };

        case PUT_STORAGE_MANAGEMENT_DATA_SUCCESS:
            return { success: true, loading: false };

        case PUT_STORAGE_MANAGEMENT_DATA_FAIL:
            return { loading: false, error: action.error };

        case PUT_STORAGE_MANAGEMENT_DATA_RESET:
            return {};

        default:
            return state;
    }
};