import React, { useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { capitalizeFirstLater } from '../../../global/Helper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function LineChart(props) {
    const {
        data,
        options,
        xAxisName,
    } = props

    const [GraphData, setGraphData] = useState(data)
    const [selectedLabel, setSelectedLabel] = useState([])

    const datasetHandler = (label) => {
        if (!selectedLabel.includes(label)) {
            setSelectedLabel(label)
            setGraphData({
                labels: data.labels,
                datasets: data.datasets.filter(e => e.label !== label)
            })
        } else {
            setSelectedLabel('')
            setGraphData(data)
        }
    }
    return (
        <div>
            <div className="d-flex">
                <div className='chart-y-axis-tag text-center position-relative'>
                    <span className='position-absolute'>No. of Events</span>
                </div>
                <div className='w-100 position-relative' style={{
                    padding: "45px 15px 10px 20px",
                    width: "calc(100% - 30px)"
                }}
                >
                    <div className="bar-chart-legends d-flex gap-4 position-absolute top-0 end-0 me-4">
                        {data.datasets.length > 1 && data.datasets?.map((e, i) => (
                            <div className='d-flex align-items-center cursor-pointer' onClick={() => datasetHandler(e.label)} key={`${i + 1}`}>
                                <div className='me-2 legend-box' style={{ backgroundColor: e.backgroundColor }}></div>
                                {selectedLabel === e.label ? (
                                    <strike>{e.label} ({e.data.reduce((a, b) => a + b)})</strike>
                                ) : (
                                    <span>{e.label} ({e.data.reduce((a, b) => a + b)})</span>
                                )}
                            </div>
                        ))}
                    </div>
                    <Line options={options} data={GraphData} />
                </div>
            </div>
            <div className='chart-x-axis-tag text-center'>{capitalizeFirstLater(xAxisName === "day" ? "Time" : xAxisName)}</div>
        </div>
    )
}

export default LineChart