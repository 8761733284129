import React from 'react'

function CustomArrow(props) {
    const {
        count,
    } = props

    return (
        <>
            {count > 0 ? (
                <div className='total-count-tag text-center ms-auto my-1'>{count}</div>
            ) : (
                <i className="fs-5 ri-arrow-down-s-fill ms-auto"></i>
            )}
        </>
    )
}

export default CustomArrow