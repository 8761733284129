import axios from 'axios'
import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from '../constants/settingConstant'
import { API_ENDPOINT } from '../constants/apiEndpoint'
import { getAPI, postAPI } from '../global/ApiManager';

export const getUserProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_PROFILE_REQUEST
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo?.token}`
      }
    }

    const { data } = await getAPI(config, `/app/get_user_profile`, dispatch)

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: data
    })


  } catch (error) {
    dispatch({
      type: GET_USER_PROFILE_FAIL,
      error: error
    })
  }
};

export const updateExistingUser = (userData) => async (dispatch, getState) => {
  const {
    profile_pic,
    oldPassword,
    newPassword,
    confirmPassword,
    phone,
    company,
  } = userData;

  try {
    const formData = new FormData();
    'profile_pic' in userData && formData.append("profile_pic", profile_pic);
    oldPassword && formData.append("old_password", oldPassword);
    newPassword && formData.append("new_password", newPassword);
    confirmPassword && formData.append("conf_password", confirmPassword);
    'phone' in userData && formData.append("phone", phone || "");
    'company' in userData && formData.append("company", company || "");

    dispatch({ type: UPDATE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await postAPI(
      `/app/update_profile_details`,
      formData,
      config,
      dispatch
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:
        error
          ? error.data.message
          : error.message,
    });
  }
};

