import React, { useEffect, useState, useMemo, useRef } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { Slider, Switch } from "antd";
import { Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import { getAlertMechanismData } from '../../../actions/alertMechanismActions'
import { getConfigServices } from '../../../actions/organizationActions/rolesAndResponsibilityActions'
import ConfigureName from './ConfigureName';
function ServiceType(props) {

    const {
        setConfigureValue,
        configureValue,
        setServiceHandler,
        serviceHandler,
        setServiceValueHandler,
        serviceValueHandler,
        setAccuracy,
        accuracy,
        token
    } = props


    const [serviceOption, setServiceOption] = useState([])
    const [serviceOptionValues, setServiceOptionValues] = useState([])

    const alertsValueRef = useRef();

    const resetAlertsValues = () => {
        alertsValueRef?.current?.resetSelectedValues();
        setServiceValueHandler([])
    }

    useEffect(() => {
        async function startFetching() {
            setServiceOption([])
            const { data } = await getConfigServices(token)
            if (!ignore) {
                setServiceOption(data)
            }
        }
        startFetching()

        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [])

    useEffect(() => {
        async function startFetching() {
            setServiceOptionValues([])
            if (serviceHandler?.length > 0) {
                const { data, status } = await getAlertMechanismData(serviceHandler?.[0]?.service_name, token)
                if (status === 200) {
                    if (!ignore) {
                        setServiceOptionValues(data)
                    }
                }
            }
        }

        startFetching()

        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [serviceHandler])

    const name_of_alerts = useMemo(() =>
        serviceOptionValues?.map((alert, index) => {
            if (serviceHandler?.[0]?.service_name?.includes("Face Recognition")) {
                return alert?.value
            } else {
                return alert?.class_name

            }
        }), [serviceOptionValues, serviceHandler]);

    const marks = {
        0: '70%',
        33: '80%',
        66: '90%',
        100: '100%',
    };

    const peopleCountOnChange = (e) => {
        if (Number.isInteger(Number(e.target.value))) {
            if ((e.target.value).trim().length > 0) {
                setServiceValueHandler(Number(e.target.value))
            } else {
                setServiceValueHandler([])
            }
        }
    }

    return (
        <div className='mt-4 w-50 service-type-select-container'>
            <ConfigureName
                onChange={(e) => setConfigureValue(e.target.value)}
                value={configureValue}
            />
            <div>
                <label className='m-0'>Service Name</label>
                <Multiselect
                    onSelect={(e) => {
                        resetAlertsValues()
                        setServiceHandler(e)
                    }}
                    onRemove={(e) => {
                        resetAlertsValues()
                        setServiceHandler(e)
                    }}
                    selectedValues={serviceHandler}
                    className={`${serviceHandler.length !== 0 && 'remove-placeholder'}`}
                    selectionLimit={1}
                    // singleSelect={true}
                    options={serviceOption}
                    displayValue="service_name"
                    placeholder='Select Service Name'
                    emptyRecordMsg="No services available."
                    avoidHighlightFirstOption={true}
                    showArrow={false}
                    customArrow={<i className="fas fa-angle-down iconDrop"></i>}
                    customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                />
            </div>
            {(serviceHandler?.length > 0) && (
                <div className='mt-3'>
                    <div className="d-flex justify-content-between align-items-end">
                        <label className='m-0'>{serviceHandler?.[0]?.service_name.includes("People Count") ? 'No. of Max. People' : 'Alert For'}</label>
                        <div className='mb-1'>
                            <label className='me-1'>All Alerts</label>
                            <Switch
                                size="small"
                                checked={serviceValueHandler === true}
                                onChange={(e) => {
                                    if (e) {
                                        setServiceValueHandler(e)
                                    } else {
                                        setServiceValueHandler([])
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {serviceHandler?.[0]?.service_name.includes("People Count") ? (
                        <Form.Control
                            type="text"
                            className={`${serviceValueHandler === true && 'is-disable'} alert-input`}
                            onChange={e => peopleCountOnChange(e)}
                            maxLength={5}
                            placeholder="Enter the threshold for people count"
                            value={serviceValueHandler === true ? '' : (String(serviceValueHandler) || '')}
                            disabled={serviceValueHandler === true}
                        />
                    ) : (
                        <Multiselect
                            ref={alertsValueRef}
                            options={name_of_alerts}
                            showCheckbox={true}
                            selectedValues={serviceValueHandler}
                            onSelect={setServiceValueHandler}
                            onRemove={setServiceValueHandler}
                            isObject={false}
                            displayValue="alerts_name"
                            id='name-of-alerts-multiselect'
                            placeholder={(serviceValueHandler?.length === 0 || serviceValueHandler === true) ? 'Select Alert For' : ''}
                            emptyRecordMsg="No alerts value available."
                            showArrow={true}
                            avoidHighlightFirstOption={true}
                            className={`${serviceValueHandler === true && 'not-allowed'}`}
                            customArrow={<><i className="fas fa-angle-down iconDrop"></i></>}
                            customCloseIcon={<i className="far fa-times-circle ms-1"></i>}
                            disable={serviceValueHandler === true}
                        />
                    )}
                    <div className='mt-3 d-flex align-items-center'>
                        <label className='me-1'>Accuracy</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-info-right" className='info-tooltip'>
                                    <div className='accuracy-info'>
                                        <p className='m-0'>
                                            <b className='font-weight-bold'>AI Confidence Level</b><br />
                                            You can adjust the level of confidence of AI detection with this slider.
                                        </p>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <i className="ri-information-line fs-6 cursor-pointer accuracy-info-icon"></i>
                        </OverlayTrigger>
                    </div>
                    <div className='accuracy-range-slider mt-2'>
                        <Slider
                            marks={marks}
                            step={null}
                            dots={false}
                            className='m-0'
                            tooltip={{ formatter: null }}
                            trackStyle={{ backgroundColor: "rgba(254, 190, 0, 0.5)" }} railStyle={{ background: "#E5E5E5" }}
                            onChange={e => setAccuracy(e)}
                            defaultValue={accuracy}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServiceType