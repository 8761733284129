import {

    GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST,
    GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS,
    GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL,

    GET_EXCEL_EXPORT_DATA_REQUEST,
    GET_EXCEL_EXPORT_DATA_SUCCESS,
    GET_EXCEL_EXPORT_DATA_FAIL,

    POST_DOUBTFUL_EVENT_DATA_REQUEST,
    POST_DOUBTFUL_EVENT_DATA_SUCCESS,
    POST_DOUBTFUL_EVENT_DATA_FAIL,

    RETRAIN_DATA_SAVE_REQUEST,
    RETRAIN_DATA_SAVE_SUCCESS,
    RETRAIN_DATA_SAVE_FAIL,
    RETRAIN_DATA_SAVE_RESET
} from "../constants/EventConstants"
import axios from "axios"
import { API_ENDPOINT } from "../constants/apiEndpoint"
import { getAPI, postAPI } from "../global/ApiManager"

export const GetEventFilterOption = (paramsData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SERVICE_CAMERA_FITER_OPTIONS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            params: paramsData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_alert_filter_data`, dispatch)

        let obj = {
            services: [],
            cameras: []
        }
        data.services?.forEach((item, index) => {
            obj["services"].push({
                id: index,
                title: item,
                value: item,
                key: item
            })
        })
        data.cameras?.forEach((item, index) => {
            obj["cameras"].push({
                id: index,
                title: item,
                value: item,
                key: item
            })
        })

        dispatch({
            type: GET_SERVICE_CAMERA_FITER_OPTIONS_SUCCESS,
            payload: obj
        })

    } catch (error) {
        console.log(error)
        dispatch({
            type: GET_SERVICE_CAMERA_FITER_OPTIONS_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const getExcelExportEventData = (keyword, selectedData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_EXCEL_EXPORT_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const params = {
            search: keyword,
            service_name: selectedData?.service_name ? selectedData?.service_name : JSON.stringify([]),
            camera_name: selectedData?.camera_name ? selectedData?.camera_name : JSON.stringify([]),
            dateRange: selectedData?.daterange ? selectedData?.daterange : JSON.stringify([]),
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/service_app/get_event_for_excel_export/`, dispatch)

        dispatch({
            type: GET_EXCEL_EXPORT_DATA_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_EXCEL_EXPORT_DATA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }

}


export const getExcelExportDataForEvent = async (keyword, selectedData, token) => {
    try {
        const params = {
            search: keyword,
            service_name: selectedData?.service_name ? selectedData?.service_name : JSON.stringify([]),
            camera_name: selectedData?.camera_name ? selectedData?.camera_name : JSON.stringify([]),
            dateRange: selectedData?.daterange ? selectedData?.daterange : JSON.stringify([]),
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/service_app/get_event_for_excel_export/`,
            config
        )

        return { "data": data }

    } catch (error) {
        return { "data": [] }
    }

}


export const getServiceClassName = async (services_name, token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/service_app/get_unique_class_values_for_service/`,
            { service_name: services_name },
            config
        )

        return { "data": data }

    } catch (error) {
        return { "data": [] }
    }

}

export const postDoubtfulEvent = (arg) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_DOUBTFUL_EVENT_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/correct_event_details/`,
            arg,
            config,
            dispatch
        )

        dispatch({
            type: POST_DOUBTFUL_EVENT_DATA_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: POST_DOUBTFUL_EVENT_DATA_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}


export const getFaceRecognitionUser = async (token, params) => {
    try {
        const config = {
            params,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/service_app/get_all_face_recognition_users_for_doubtful_events/`,
            config
        )

        return { "data": data }

    } catch (error) {
        return { "data": [] }
    }

}


export const postRetrainData = (arg) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RETRAIN_DATA_SAVE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/service_app/correct_annotation_of_events/`,
            arg,
            config,
            dispatch
        )

        dispatch({
            type: RETRAIN_DATA_SAVE_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log(error, 'data')
        const data = {
            data: {
                detail: {
                    message: error.data
                }
            },
            message: error.data
        }
        console.log(data, "error data")
        dispatch({
            type: RETRAIN_DATA_SAVE_FAIL,
            payload: data
        })
    }
}