import React from 'react'
import noVideo from "../../../components/inActive.png";
import { Checkbox } from 'antd';
import { capitalizeFirstLater } from '../../../global/Helper';
import noCameraMini from '../../../images/noCameraMini.png'

function AllCameraTabItems(props) {
    const {
        camera,
        checkbox,
        className,
        onCheckboxSelect,
        onCheckboxRemove,
        SelectedCamera,
        disabled,
    } = props

    return (
        <div className={`d-flex gap-3 flex-nowrap camera-details ${className}`}>
            {checkbox && (
                <Checkbox
                    onChange={(e) => {
                        if (SelectedCamera?.includes(camera.camera_id)) {
                            onCheckboxRemove(camera.camera_id)
                        } else {
                            onCheckboxSelect(camera.camera_id)
                        }
                    }}
                    disabled={disabled}
                    checked={SelectedCamera?.includes(camera.camera_id)}
                >
                </Checkbox>
            )}

            {camera.image ? (
                <img src={camera.image} onError={(e) => e.target.src = noCameraMini} height={55} alt="NWarch camera added image" style={
                    {
                        width: "80px",
                        borderRadius: "4px",
                        objectFit: "cover",
                        background: "var(--white)",
                    }
                } />
            ) : (
                <div className='position-relative d-flex justify-content-center align-items-center' style={{ background: "rgba(0, 0, 0, 0.2)", borderRadius: "4px", width: "80px", height: "55px" }}>
                    <img src={noVideo} alt="NWarch camera added image" style={
                        {
                            background: "transparent",
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                        }
                    } />
                </div>
            )}
            <div className="d-flex justify-content-between" style={{ width: `calc(100% - ${checkbox ? '140px' : '96px'})` }}>
                <div className="w-100">
                    <h6 className="m-0 d-inline-block text-truncate mw-100" style={{ lineHeight: "19px" }}>{capitalizeFirstLater(camera.camera_name)}</h6>
                    <div>{camera.group_name}</div>
                    <div className="d-flex align-items-center">
                        {camera?.camera_type?.type_label !== 'video' && (
                            camera?.active_status === true ? (
                                <>
                                    <i
                                        className="ri-checkbox-blank-circle-fill me-1"
                                        style={{ color: "var(--success)" }}
                                    ></i>
                                    Active{" "}
                                </>
                            ) : (
                                <>
                                    <i
                                        className="ri-checkbox-blank-circle-fill me-1"
                                        style={{ color: "var(--danger)" }}
                                    ></i>
                                    Inactive{" "}
                                </>
                            ))
                        }
                        -
                        {/* <i className="ri-camera-line ms-1"></i> */}
                        <span className='ms-1'>{camera?.camera_type?.type_label}</span>
                    </div>
                </div>
                {camera.date}
            </div>
        </div>
    )
}

export default AllCameraTabItems