import React, { useState, useEffect, useMemo, Fragment } from 'react';
import SearchInput from '../../../components/SearchInput';
import { Checkbox } from 'antd';
import { getServiceClassName } from "../../../actions/EventActions";
import { useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { useSearchParams } from 'react-router-dom';

function TooltipDropdown(props) {
    const {
        setRefreshComponents,
        onCheckBoxChange,
        FilterClasses,
        CheckBoxValue,
        dropdownOption,
        displayValue,
    } = props

    const textStyle = {
        fontSize: "13px",
        fontWeight: "500",
    }
    let [searchParams, setSearchParams] = useSearchParams();
    let getDoubtful = searchParams.get("doubtful");
    const [Search, setSearch] = useState('');
    const [ClassOption, setClassOption] = useState([]);
    const [Loading, setLoading] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        async function startFetching() {
            setClassOption([])
            setLoading(true)
            const { data } = await getServiceClassName(dropdownOption[displayValue], userInfo?.token)
            if (!ignore) {
                setClassOption(data?.unique_class_values)
            }
            setLoading(false)
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [])

    const ClassesOptions = useMemo(() => ClassOption.filter(e => e.toLowerCase().includes(Search.toLowerCase())), [ClassOption, Search])

    const onClassesCheckBoxChange = (e) => {
        if (FilterClasses[dropdownOption[displayValue]]?.includes(e)) {
            FilterClasses[dropdownOption[displayValue]] = [...new Set(FilterClasses[dropdownOption[displayValue]].filter(v => v !== e))]
            if (FilterClasses[dropdownOption[displayValue]].length === 0) {
                delete FilterClasses[dropdownOption[displayValue]]
            }
        } else {
            if (!CheckBoxValue.includes(dropdownOption)) {
                onCheckBoxChange(dropdownOption)
            }
            if (!FilterClasses.hasOwnProperty(dropdownOption[displayValue])) {
                FilterClasses[dropdownOption[displayValue]] = [e]
            } else {
                FilterClasses[dropdownOption[displayValue]].push(e)
            }
        }
        setRefreshComponents((prev) => prev + 1)
    }

    const onDoubtfulClick = () => {
        if (!getDoubtful) {
            searchParams.set('doubtful', true)
        } else {
            searchParams.delete('doubtful')
        }
        if (!CheckBoxValue.includes(dropdownOption)) {
            onCheckBoxChange(dropdownOption)
        }
        setSearchParams(searchParams)
    }

    return (
        <div>
            <div className=" px-2 pt-2">
                <SearchInput width='100%' value={Search} onChange={(value) => setSearch(value)} />
            </div>
            <div className='overflow-auto' style={{ maxHeight: "184px" }}>
                {Loading ? (
                    <div className='text-center pt-3 pb-2'>
                        <Spinner animation="border" variant="dark" size="sm" />
                    </div>
                ) : (
                    <>
                        {ClassesOptions.length === 0 ? (
                            <div className='text-center p-2 text-grey' style={textStyle}>
                                No options found
                            </div>) : (
                            <Fragment>
                                {dropdownOption[displayValue].toLowerCase().includes('face recognition') && (
                                    <div
                                        className={`p-2 border-bottom d-flex align-items-center cursor-pointer gap-2 ${getDoubtful ? 'text-black' : 'text-grey'}`}
                                        onClick={onDoubtfulClick}
                                    >
                                        <Checkbox
                                            checked={getDoubtful}
                                        >
                                        </Checkbox>
                                        <div className="d-flex align-items-center gap-3 w-100">
                                            <div className="text-truncate" style={textStyle}>
                                                Unsure Events
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {ClassesOptions?.map((e, index) => (
                                    <div
                                        key={`${index + 1}`}
                                        className={`p-2 d-flex align-items-center cursor-pointer gap-2 ${FilterClasses[dropdownOption[displayValue]]?.includes(e) ? 'text-black' : 'text-grey'}`}
                                        onClick={() => onClassesCheckBoxChange(e)}
                                        title={e}
                                    >
                                        <Checkbox
                                            checked={FilterClasses[dropdownOption[displayValue]]?.includes(e)}
                                        >
                                        </Checkbox>
                                        <div className="d-flex align-items-center gap-3 w-100">
                                            <div className="text-truncate" style={textStyle}>
                                                {e}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default TooltipDropdown