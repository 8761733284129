import React from 'react'
import { Image } from "react-bootstrap";
import contactUs from '../../../images/contact-us.png'

const HelpMain = () => {

  return (
    <div className='h-100'>
      <h3 className='py-3 m-0 border-bottom'>Help Center</h3>
      <div style={{ height: "calc(100% - 100px)" }} className='d-flex flex-column justify-content-center align-items-center'>
        <Image
          src={contactUs}
          height={300}
          width={300}
        >
        </Image>

        <div>
          <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=dw.nwarchai@datenwissen.com" target="_blank" className='warning-btn btn'>
            Contact Support
          </a>
        </div>
      </div>
    </div>
  )
}

export default HelpMain