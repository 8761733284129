import React, { useState, useEffect } from 'react'
import SearchInput from '../../components/SearchInput'
import './analyticsSetup.css'
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { Colors } from '../../global/Colors'
import { Tooltip, OverlayTrigger, Alert, Modal } from 'react-bootstrap';
import noRequestFound from '../../images/no-camera-request-found.gif'
import Loader from '../../components/Loader';
import { getListAlertMechanismData, alertMechanismDelete } from "../../actions/alertMechanismActions";
import AlertOnOffSwitch from './analyticsSetupComponents/AlertOnOffSwitch';
import InsightsSetupModal from './InsightsSetupModal';
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { toast } from "react-toastify";
import { DeleteServiceAlert } from '../../components/ToastAlert'
import { ALERT_MECHANISM_DATA_DELETE_RESET } from '../../constants/alertMechanismConstant'
import { GET_SINGLE_ANALYTICS_DATA_RESET } from '../../constants/analyticsConstant'
import { getSingleAnalyticsData } from '../../actions/analyticsActions'



function AnalyticsSetup() {
    const [Search, setSearch] = useState('');
    const [insightsSetupModalShow, setInsightsSetupModalShow] = useState(false);
    const [analyticDeleteModalShow, setAnalyticDeleteModalShow] = useState(false);
    const [analyticConfigId, setAnalyticConfigId] = useState(null);
    const [analyticConfigName, setAnalyticConfigName] = useState(null);

    const dispatch = useDispatch();

    const alertMechanismGetListData = useSelector((state) => state.alertMechanismGetListData);
    const { error: getListAlertMechError, loading: getListAlertMechLoad, success: getListAlertMechSuccess } = alertMechanismGetListData;

    const deleteAlertMechanism = useSelector((state) => state.deleteAlertMechanism);
    const { loading: analyticDeleteLoad, success: analyticDeleteSuccess } = deleteAlertMechanism;


    useEffect(() => {
        if (analyticDeleteSuccess) {
            setAnalyticDeleteModalShow(false)
            dispatch(getListAlertMechanismData())
            toast(<DeleteServiceAlert service_name={analyticConfigName} />);
            dispatch({ type: ALERT_MECHANISM_DATA_DELETE_RESET })
        }
    }, [analyticDeleteSuccess])

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    const alertsTableColumns = [
        {
            title: 'Configure Name',
            dataIndex: 'configure_name',
            key: 'configure_name',
            ellipsis: true,
        },
        {
            title: 'Service',
            dataIndex: 'service_name',
            key: 'service_name',
            ellipsis: true,
        },
        {
            title: 'Camera',
            dataIndex: 'camera_name',
            key: 'camera_name',
            width: "22%",
            ellipsis: true,
        },
        {
            title: 'Users',
            dataIndex: 'users',
            key: 'users',
            ellipsis: true,
        },
        {
            title: 'On/Off',
            dataIndex: 'on_off',
            key: 'on_off',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            ellipsis: true,
        },
    ];

    useEffect(() => {
        dispatch(getListAlertMechanismData())
    }, [])

    const alertsTableData = getListAlertMechSuccess?.filter((e) => String(e.camera_name?.map((camera) => camera.toLowerCase())).includes(Search?.toLowerCase()) || e.service_name?.toLowerCase().includes(Search?.toLowerCase()) || String(e.configure_name).toLowerCase()?.includes(String(Search?.toLowerCase())))?.map((alert, index) => {
        return {
            key: alert.id,
            configure_name: alert.configure_name,
            service_name: alert.service_name,
            camera_name: <div className='d-flex'>
                <div className='text-truncate'>{alert.camera_name?.join(", ")}</div>
                {alert.camera_name?.length > 2 && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={

                            <Tooltip id={`tooltip-bottom`} className='analytics-tooltip'>
                                <ul>
                                    {alert.camera_name?.map((e, index) => (
                                        <li key={`${index + 1}`}>
                                            {e}
                                        </li>
                                    ))}
                                </ul>
                            </Tooltip>

                        }
                    >
                        <span className='cursor-pointer text-decoration-underline'>+{alert.camera_name.length - 2}</span>
                    </OverlayTrigger>
                )}
            </div>,
            users: <>
                {alert.users?.slice(0, 3)?.map((e, index) => (

                    <div key={`${index + 1}`} className='analytics-table-users' title={e} style={{ background: `${Colors[index]}` }}>
                        {e.split(' ')?.[0]?.[0]}
                        {e.split(' ')?.[1]?.[0]}
                    </div>

                ))}
                {alert.users.length > 3 && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={

                            <Tooltip id={`tooltip-bottom`} className='analytics-tooltip'>
                                <ul>
                                    {alert.users?.map((e, index) => (
                                        <li key={`${index + 1}`}>
                                            {e}
                                        </li>
                                    ))}
                                </ul>
                            </Tooltip>

                        }
                    >
                        <div className='analytics-table-users' style={{ background: `${Colors[3]}`, cursor: "pointer" }}>
                            +{alert.users.length - 3}
                        </div>
                    </OverlayTrigger>

                )}
            </>,
            on_off: <>
                {pageAccess?.['update'].includes(userInfo?.user_role) && (
                    <AlertOnOffSwitch objectId={alert.id} checked={alert.on_off} token={userInfo?.token} />
                )}
            </>,
            action:
                <div className="d-flex action-btn">
                    {pageAccess?.['update'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                            onClick={() => {
                                setInsightsSetupModalShow(true);
                                setAnalyticConfigId(alert.id);
                                dispatch(getSingleAnalyticsData(alert.id))
                                setAnalyticConfigName(undefined)
                            }}

                        >
                            <i className="ri-edit-line"></i>
                        </button>
                    )}
                    {pageAccess?.['create'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2 "
                            onClick={() => {
                                setInsightsSetupModalShow(true);
                                setAnalyticConfigId(alert.id);
                                dispatch(getSingleAnalyticsData(alert.id))
                                setAnalyticConfigName(alert.configure_name)
                            }}
                        >
                            <i className="far fa-clone"></i>
                        </button>
                    )}
                    {pageAccess?.['delete'].includes(userInfo?.user_role) && (
                        <button
                            className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            onClick={() => {
                                setAnalyticDeleteModalShow(true)
                                setAnalyticConfigId(alert.id)
                                setAnalyticConfigName(alert.configure_name)
                            }}
                        >
                            <i className="ri-delete-bin-5-line" style={{ fontSize: "14px" }}></i>
                        </button>
                    )}
                </div>,
        }
    })
    return (
        <div className='analytics-setup-container h-100'>
            {/* <Header props={{ page_name: "Analytics Setup" }} /> */}
            <div className='organization-headings mb-2'>Report Configuration</div>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <SearchInput width='55%' onChange={(e) => setSearch(e)} placeholder="Search by Configure Name, Service Name and Camera Name." />
                <div className=''>
                    {pageAccess?.['create'].includes(userInfo?.user_role) && (
                        <button
                            className="configure-btn text-white"
                            onClick={() => {
                                dispatch({ type: GET_SINGLE_ANALYTICS_DATA_RESET })
                                setInsightsSetupModalShow(true)
                                setAnalyticConfigName(undefined)
                            }}
                        >
                            <i className="fas fa-plus me-2"></i>
                            Insights Setup
                        </button>
                    )}
                </div>
            </div>
            {getListAlertMechError ? (
                <Alert variant="danger">{getListAlertMechError}</Alert>) : (
                <>
                    <Table
                        columns={alertsTableColumns}
                        dataSource={!getListAlertMechLoad ? alertsTableData : null} pagination={false}
                        scroll={{ x: 800, y: "calc(100vh - 292px)" }}
                    />
                    {getListAlertMechLoad && (
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "56vh" }}>
                            <Loader margin="0" />
                        </div>
                    )}
                    {alertsTableData?.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 190px)" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                                <p className='opacity'>No Insights Setup Found!</p>
                            </div>
                        </div>)}
                </>
            )}

            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                show={insightsSetupModalShow}
                dialogClassName="modal-90w"
                backdrop="static"
                keyboard={false}
                onHide={() => setInsightsSetupModalShow(false)}
                fullscreen="lg-down"
            // scrollable
            >
                <InsightsSetupModal
                    closeInsightsSetupModalShow={() => setInsightsSetupModalShow(false)}
                    analyticConfigId={analyticConfigId}
                    token={userInfo?.token}
                    analyticConfigName={analyticConfigName}
                />
            </Modal>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={analyticDeleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        onHide={() => setAnalyticDeleteModalShow(false)}
                        item_name={analyticConfigName}
                        dispatch_item={alertMechanismDelete(analyticConfigId)}
                        loading={analyticDeleteLoad}
                    />
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default AnalyticsSetup