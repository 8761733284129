import React, { useState } from 'react'
import { Table } from "antd";
import { Form, Modal } from 'react-bootstrap';
import CheckBoxWithInput from './CheckBoxWithInput';
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import noRequestFound from '../../../images/no-camera-request-found.gif'
import { dummyNotificationData } from '../../../global/Helper';

const FontStyling = {
    fontSize: "11px"
}

function UserNameInput({ value, onChange }) {
    return (
        <>
            <Form.Label style={FontStyling} className='m-0'>User Name<span className='text-danger'>*</span></Form.Label>
            <Form.Control
                style={{ fontSize: "0.70rem", padding: "10px 20px" }}
                className="alert-input border_radius_sm"
                type="text"
                maxLength={25}
                onChange={e => {
                    onChange(e.target.value)
                }}
                placeholder="Enter User Name"
                value={value || ""}
            />
        </>
    )
}

function NotificationType(props) {

    const {
        setComponentsRefresh,
        setNotificationUserName,
        setAddNewUser,
        addNewUser,
        setEmail,
    } = props;

    const [notificationIndex, setNotificationIndex] = useState(null);
    const [notificationName, setNotificationName] = useState("");
    const [notificationDeleteModalShow, setNotificationDeleteModalShow] = useState(false);
    const [count, setCount] = useState(false);

    const notificationTableColumns = [
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: "20%"
        },
        {
            title: 'Analytics',
            dataIndex: 'analytics',
            key: 'analytics',
            width: "28%"
        },
        {
            title: 'Files',
            dataIndex: 'files',
            key: 'files',
            width: "15%"
        },
        {
            title: 'Notifications',
            dataIndex: 'notifications',
            key: 'notifications',
            width: "28%"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "10%"
        },
    ]

    const notificationDeleteHandler = () => {
        const deleteUserData = addNewUser.filter((e, index) => index !== notificationIndex)
        setAddNewUser(deleteUserData)
        setNotificationDeleteModalShow(false)
        setCount(e => e + 1)
    }

    function createDate(weekday, hours, minutes, seconds) {
        const now = new Date(); // Get the current date and time
        const targetDay = now.getDate() + (weekday - now.getDay() + 7) % 7; // Calculate the target day of the week
        const targetDate = new Date(now.getFullYear(), now.getMonth(), targetDay, hours, minutes, seconds); // Set the target date and time
        return targetDate;
    }

    function getTime(date) {
        if (date) {
            const [, , , time] = date.split(/[/,]/);
            const [hour, minute,] = time.trim().split(':');

            return `${hour}:${minute}`
        }
    }

    function getMonthlyDate(dateTime) {
        if (dateTime) {
            const [day, month, year, time] = dateTime.split(/[/,]/);
            const [hour, minute,] = time.trim().split(':');

            return `${year}-${month}-${day}T${hour}:${minute}:00`;
            // Convert to desired format
        }
    }

    function dateTimeFormat(dateTime) {
        if (dateTime) {
            let day = dateTime.getDate();
            let month = dateTime.getMonth() + 1; // Months are zero-based
            let year = dateTime.getFullYear();
            let hours = dateTime.getHours();
            let minutes = dateTime.getMinutes();
            let seconds = dateTime.getSeconds();

            // Format the components with leading zeros if necessary
            let formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

            // Combine the formatted date and time

            return `${formattedDate}, ${formattedTime}`;
            // Convert to desired format
        }
    }

    const setNotificationData = (key, value, index) => {
        addNewUser[index][key] = value
        setComponentsRefresh()
    }

    const notificationTableData = addNewUser?.map((e, index) => {
        return {
            key: index,
            user_name: <>
                <div className='' style={{
                    position: "absolute",
                    top: "15px",
                }}
                >
                    <UserNameInput
                        value={e?.to_user_name}
                        onChange={e => {
                            addNewUser[index].to_user_name = e;
                            setComponentsRefresh();
                            setNotificationUserName(e);
                        }}
                    />
                </div>
            </>,
            analytics: <>
                <div className='analytic-column'>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel="On time alerts"
                            checkBoxName="on_time_alert"
                            checked={e?.on_time_alerts}
                            onCheckboxChange={e => {
                                addNewUser[index].on_time_alerts = e
                                setComponentsRefresh()
                            }}
                        />
                    </div>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel="Daily Reports"
                            checkBoxName="daily_report"
                            checked={e?.report_frequency?.daily?.status}
                            inputValue={getTime(e?.report_frequency?.daily?.time)}
                            onCheckboxChange={e => {
                                if (e) {
                                    addNewUser[index].report_frequency.daily = {};
                                    addNewUser[index].report_frequency.daily.status = e;
                                } else {
                                    delete addNewUser[index].report_frequency.daily;
                                }
                                setComponentsRefresh()
                            }}
                            onInputChange={e => {
                                const dateTime = new Date();
                                let convertFullDate
                                if (e.length > 0) {
                                    dateTime.setHours(e.split(":")?.[0], e.split(":")?.[1]);
                                    convertFullDate = dateTimeFormat(dateTime)
                                }

                                addNewUser[index].report_frequency.daily.report_flag = false;
                                addNewUser[index].report_frequency.daily.excel_flag = false;
                                addNewUser[index].report_frequency.daily.time = convertFullDate;
                                setComponentsRefresh()
                            }}
                        />
                    </div>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel="Weekly Reports"
                            checkBoxName="weekly_report"
                            checked={e?.report_frequency?.weekly?.status}
                            inputValue={getTime(e?.report_frequency?.weekly?.time)}
                            day={e?.report_frequency?.weekly?.day}
                            onCheckboxChange={e => {
                                if (e) {
                                    addNewUser[index].report_frequency.weekly = {};
                                    addNewUser[index].report_frequency.weekly.status = e
                                } else {
                                    delete addNewUser[index].report_frequency.weekly;
                                }
                                setComponentsRefresh()
                            }}
                            onInputChange={e => {
                                let dateTime
                                if (e.length > 0) {

                                    const days = addNewUser[index].report_frequency.weekly.day

                                    const targetDate = createDate(days, e.split(":")[0], e.split(":")[1], 0)
                                    dateTime = dateTimeFormat(targetDate);
                                }

                                addNewUser[index].report_frequency.weekly.report_flag = false;
                                addNewUser[index].report_frequency.weekly.excel_flag = false;
                                addNewUser[index].report_frequency.weekly.time = dateTime
                                setComponentsRefresh()
                            }}
                            onDayChange={e => {
                                addNewUser[index].report_frequency.weekly.report_flag = false;
                                addNewUser[index].report_frequency.weekly.excel_flag = false;
                                addNewUser[index].report_frequency.weekly.day = e
                                setComponentsRefresh()
                            }}
                        />
                    </div>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel="Monthly Reports"
                            checkBoxName="monthly_report"
                            checked={e?.report_frequency?.monthly?.status}
                            inputValue={getMonthlyDate(e?.report_frequency?.monthly?.time)}
                            onCheckboxChange={e => {
                                if (e) {
                                    addNewUser[index].report_frequency.monthly = {};
                                    addNewUser[index].report_frequency.monthly.status = e
                                } else {
                                    delete addNewUser[index].report_frequency.monthly;
                                }
                                setComponentsRefresh()
                            }}
                            onInputChange={e => {
                                const [year, month, day, time] = e.split(/[T-]/);
                                const [hour, minute] = time.trim().split(':');

                                const datetime = `${day}/${month}/${year}, ${hour}:${minute}:00`; // Backend required this format

                                addNewUser[index].report_frequency.monthly.report_flag = false;
                                addNewUser[index].report_frequency.monthly.excel_flag = false;
                                addNewUser[index].report_frequency.monthly.time = datetime;
                                setComponentsRefresh()
                            }}
                        />
                    </div>
                </div>
            </>,
            notifications: <>
                <div className='d-flex flex-column justify-content-start notifications-column' style={{
                    width: "89%",
                    minHeight: "155px",
                }}>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel={<>Email</>}
                            checkBoxName="email"
                            checked={e?.alert_email?.length > 0}
                            inputValue={e?.alert_email}
                            onCheckboxChange={e => setNotificationData('alert_email', '', index)}
                            onInputChange={e => {
                                setEmail(e)
                                setNotificationData('alert_email', e, index)
                            }}
                        />
                    </div>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel={<>WhatsApp</>}
                            checkBoxName="whatsapp"
                            checked={e?.alert_whatsapp?.length > 0}
                            inputValue={e?.alert_whatsapp}
                            onCheckboxChange={e => setNotificationData('alert_whatsapp', '', index)}
                            onInputChange={e => setNotificationData('alert_whatsapp', e, index)}
                        />
                    </div>
                    <div className='mb-3'>
                        <CheckBoxWithInput
                            checkBoxLabel={<>API</>}
                            checkBoxName="api"
                            checked={e?.alert_api?.length > 0}
                            inputValue={e?.alert_api}
                            onCheckboxChange={e => setNotificationData('alert_api', '', index)}
                            onInputChange={e => setNotificationData('alert_api', e, index)}
                        />
                    </div>
                </div>
            </>,
            files: <>
                <div className='position-absolute' style={{ top: "15px" }}>
                    <div className="mb-3">
                        <CheckBoxWithInput
                            checkBoxLabel="Excel"
                            checkBoxName="excel_file"
                            checked={e?.excel_file}
                            onCheckboxChange={e => setNotificationData('excel_file', e, index)}
                        />
                    </div>
                    <div className="mb-3">
                        <CheckBoxWithInput
                            checkBoxLabel="PDF"
                            checkBoxName="pdf_file"
                            checked={e?.pdf_file}
                            onCheckboxChange={e => setNotificationData('pdf_file', e, index)}
                        />
                    </div>
                </div>
            </>,
            action: <>
                <div className="d-flex action-btn position-absolute" style={{ top: "15px" }}>
                    <button
                        className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center"
                        disabled={addNewUser?.length === 1}
                        onClick={() => {
                            setNotificationIndex(index)
                            setNotificationName(e.user_name)
                            setNotificationDeleteModalShow(true)
                        }}
                    >
                        <i className="ri-delete-bin-5-line" style={{ fontSize: "14px" }}></i>
                    </button>
                </div>
            </>,
        }
    });

    return (
        <div key={count} className='h-100 add-notification-type-container mt-3 p-3'>
            <div className='text-end mb-3'>
                <button
                    className="configure-btn text-white"
                    onClick={() => setAddNewUser([...addNewUser, { ...dummyNotificationData }])}
                >
                    <i className="fas fa-plus me-2"></i>
                    Add User
                </button>
            </div>
            <Table
                columns={notificationTableColumns}
                dataSource={notificationTableData} pagination={false}
                scroll={{ x: 900, y: "calc(100vh - 410px)" }}
            />
            {notificationTableData?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "300px" }}>
                    <div className="text-center background-none">
                        <img className='background-none opacity ' src={noRequestFound} height={"170px"} width={"170px"} alt="" />
                        <p className='opacity'>No details were found. Once you add a new user,<br /> all the information will appear here.</p>
                    </div>
                </div>) : null}
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={notificationDeleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        onHide={() => setNotificationDeleteModalShow(false)}
                        item_name={notificationName}
                        notificationDelete={true}
                        onClick={notificationDeleteHandler}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default NotificationType