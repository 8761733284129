import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import "./TopNavbar.css";
import { Dropdown, Modal, Offcanvas } from "react-bootstrap";
import NotificatonIcon from "./NotificatonIcon";
import user_image from "../images/profile_image.png";
import AddCamera from '../screens/configurepage/AddCamera'
import { logout } from "../actions/userActions"
import { Link, useNavigate } from "react-router-dom";
import add_photo from './add-photo.png'
import { getUserProfile } from "../actions/settingActions";
import Sidebar from "./Sidebar";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {/* Render custom icon here */}
        {children}
        <i className="ri-arrow-down-s-line p-2 logout_arrow"></i>
    </a>
));

function TopNavbar(props) {
    const [drawerShow, setDrawerShow] = useState(false);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userData = useSelector((state) => state.userProfileGet);
    const { userProfile } = userData;

    useEffect(() => {
        dispatch(getUserProfile());
    }, [])

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const logoutHandler = () => {
        dispatch(logout())
        navigate("/", { replaces: true });
    }

    useEffect(() => {
        !userInfo && navigate('/')
    }, [userInfo]);

    const navigationBarPageAccessGet = useSelector((state) => state.navigationBarPageAccessGet);
    const { success: accessNavigationBarPage } = navigationBarPageAccessGet;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="top_navbar d-flex justify-content-end align-items-center py-1 pe-5">
            <button
                className="bg-transparent p-2 d-flex align-items-center me-auto d-md-none"
                onClick={() => setDrawerShow(e => !e)}
            >
                <i className={`ri-menu-${drawerShow ? 'fold' : 'unfold'}-line fs-4`}></i>
            </button>
            <Offcanvas
                show={drawerShow}
                onHide={() => setDrawerShow(false)}
                scroll={true}
                backdrop={true}
            >
                <Sidebar
                    props={props.page}
                    setDrawerShow={() => setDrawerShow(false)}
                    drawerShow={drawerShow}
                />
            </Offcanvas>
            {accessNavigationBarPage?.livecam?.create?.includes(userInfo?.user_role) && (
                <button className="btn btn_primary px-2 d-flex align-items-center"
                    title="Add Camera"
                    onClick={handleShow}>
                    <img src={add_photo} className="w27" alt="" />
                </button>
            )}
            <Modal scrollable={true} show={show} size="lg" onHide={handleClose} centered>
                <Modal.Body>
                    <AddCamera handleClose={(e) => e === true && handleClose()} token={userInfo?.token} />
                </Modal.Body>
            </Modal>
            {accessNavigationBarPage?.events?.read?.includes(userInfo?.user_role) && (
                <NotificatonIcon />
            )}
            <div className="user_box d-flex align-items-center ">
                <img
                    style={{ objectFit: "cover" }}
                    src={userProfile?.profile_pic || user_image}
                    onError={(e) => e.target.src = user_image}
                    alt="User"
                />
                <div className="user_dropdown d-flex align-items-center">
                    <Link to="/settings/">
                        <div className="user_details mx-2">
                            <h5 className="user_name m-0">{userInfo?.username ? userInfo.username : null}</h5>
                            <h6 className="user_status m-0">{userInfo?.user_role ? userInfo.user_role : null}</h6>
                        </div>
                    </Link>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Link to="/">
                                <Dropdown.Item as="button"
                                    className="d-flex justify-content-between align-items-center"
                                    onClick={logoutHandler}
                                >
                                    Logout<i className="ri-logout-box-r-line"></i>
                                </Dropdown.Item>
                            </Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default TopNavbar;
