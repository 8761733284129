import React, { useEffect, useState } from 'react'
import ServiceOptions from './ServiceOptions'
import { useDispatch, useSelector } from "react-redux";
import { ADD_SERVICE_RESET } from '../../constants/servicesConstant'
import { getServicesData } from "../../actions/servicesActions";
import { toast } from "react-toastify";
import { AddServiceAlert, FailServiceAlert } from "../../components/ToastAlert";



function AllServicesModal(props) {
    const serviceAdd = useSelector((state) => state.serviceAdd);
    const { loading: serviceLoad, error: serviceError, success: serviceAdded } = serviceAdd;

    const [serviceName, setServiceName] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (serviceAdded) {
            props.handle_close(true)
            toast(<AddServiceAlert service_name={serviceName} />);
            dispatch(getServicesData())
            dispatch({ type: ADD_SERVICE_RESET })
        } else if (serviceError) { // Show a toast notification with the error message
            toast(<FailServiceAlert error={serviceError} />);
            dispatch({ type: ADD_SERVICE_RESET })
        }
    }, [serviceAdded, serviceError, dispatch])

    return (

        <div>
            <ServiceOptions
                serviceName={(service) => { setServiceName(service) }}
                serviceLoad={serviceLoad}
            />
        </div>

    )
}

export default AllServicesModal
