import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_RESET,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_RESET,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,

    USER_FORGET_PASSWORD_REQUEST,
    USER_FORGET_PASSWORD_SUCCESS,
    USER_FORGET_PASSWORD_FAIL,
    USER_FORGET_PASSWORD_RESET,

    USER_NEW_PASSWORD_REQUEST,
    USER_NEW_PASSWORD_SUCCESS,
    USER_NEW_PASSWORD_FAIL,
    USER_NEW_PASSWORD_RESET,

    USER_LIVE_CAM_ACCESS_REQUEST,
    USER_LIVE_CAM_ACCESS_SUCCESS,
    USER_LIVE_CAM_ACCESS_FAIL,

    REMOVE_LIVE_CAM_ACCESS_REQUEST,
    REMOVE_LIVE_CAM_ACCESS_SUCCESS,
    REMOVE_LIVE_CAM_ACCESS_FAIL,
    REMOVE_LIVE_CAM_ACCESS_RESET,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_RESET,

    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,
    CREATE_USER_RESET,

    CREATE_USER_EMAIL_VERIFICATION_REQUEST,
    CREATE_USER_EMAIL_VERIFICATION_SUCCESS,
    CREATE_USER_EMAIL_VERIFICATION_FAIL,
    CREATE_USER_EMAIL_VERIFICATION_RESET,
} from '../constants/userConstants'


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGIN_RESET:
            return { loading: false, error: false }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_REGISTER_RESET:
            return {}

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIST_REQUEST:
            return { loading: true }

        case USER_LIST_SUCCESS:
            return { loading: false, users: action.payload }

        case USER_LIST_FAIL:
            return { loading: false, error: action.payload }

        case USER_LIST_RESET:
            return { users: [] }

        default:
            return state
    }
}

export const forgetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_FORGET_PASSWORD_REQUEST:
            return { loading: true }

        case USER_FORGET_PASSWORD_SUCCESS:
            return { loading: false, success: action.payload }

        case USER_FORGET_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case USER_FORGET_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}

export const newPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_NEW_PASSWORD_REQUEST:
            return { loading: true }

        case USER_NEW_PASSWORD_SUCCESS:
            return { loading: false, success: action.payload }

        case USER_NEW_PASSWORD_FAIL:
            return { loading: false, error: action.payload }

        case USER_NEW_PASSWORD_RESET:
            return {}

        default:
            return state
    }
}

export const userLiveCamAccessReducer = (state = { getUserLiveCam: [] }, action) => {
    switch (action.type) {
        case USER_LIVE_CAM_ACCESS_REQUEST:
            return { loading: true }

        case USER_LIVE_CAM_ACCESS_SUCCESS:
            return { loading: false, getUserLiveCam: action.payload }

        case USER_LIVE_CAM_ACCESS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const userRemoveLiveCamAccessReducer = (state = {}, action) => {
    switch (action.type) {
        case REMOVE_LIVE_CAM_ACCESS_REQUEST:
            return { loading: true }

        case REMOVE_LIVE_CAM_ACCESS_SUCCESS:
            return { loading: false, removeCameraSuccess: true }

        case REMOVE_LIVE_CAM_ACCESS_FAIL:
            return { loading: false, error: action.payload }

        case REMOVE_LIVE_CAM_ACCESS_RESET:
            return {}
        default:
            return state
    }
}

export const userDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_DELETE_REQUEST:
            return { loading: true }

        case USER_DELETE_SUCCESS:
            return { loading: false, deleteUserSuccess: true }

        case USER_DELETE_FAIL:
            return { loading: false, error: action.payload }

        case USER_DELETE_RESET:
            return {}
        default:
            return state
    }
}

export const createUserReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_USER_REQUEST:
            return { loading: true }

        case CREATE_USER_SUCCESS:
            return { loading: false, createUserSuccess: true }

        case CREATE_USER_FAIL:
            return { loading: false, error: action.payload }

        case CREATE_USER_RESET:
            return {}
        default:
            return state
    }
}

export const createUserEmailVerificationReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_USER_EMAIL_VERIFICATION_REQUEST:
            return { loading: true }

        case CREATE_USER_EMAIL_VERIFICATION_SUCCESS:
            return { loading: false, success: action.payload }

        case CREATE_USER_EMAIL_VERIFICATION_FAIL:
            return { loading: false, error: action.payload }

        case CREATE_USER_EMAIL_VERIFICATION_RESET:
            return {}

        default:
            return state
    }
}