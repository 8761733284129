import React, { Fragment, useEffect, useState } from "react";
import { Card, Alert, Modal } from "react-bootstrap";
import "./home.css";
import { Pie, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from "chart.js";
import { Link } from "react-router-dom";
import { homePageData } from "../../actions/dataActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/Loader";
import noEventsFound from "../../components/noData.gif";
import alert from '../../components/alert.png'
import RenewConfirmModal from './RenewConfirmModal'
import { getServicesDetail, listSubscribeServices } from '../../actions/subscriptionActions'
import moment from "moment";
import PieChartLabels from './PieChartLabels'
import { Colors } from '../../global/Colors'
import { LightenDarkenColor } from '../../components/LightenDarkenColor'
import { capitalizeFirstLater } from '../../global/Helper'
import PageNotAccess from '../404PageNotFound/PageNotAccess';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

export function SubscriptionAlert({ service_name }) {

    const dispatch = useDispatch();

    const [showRenewModal, setShowRenewModal] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [serviceName, setServiceName] = useState('');

    const currentTime = new Date()

    const subscribeServicesList = useSelector((state) => state.subscribeServicesList);
    const { serviceData } = subscribeServicesList;

    return (
        <>
            <Alert
                show={showAlert}
                className="subscription-alert d-flex justify-content-between"
                onClose={() => setShowAlert(false)}
                dismissible
            >
                <div>
                    <img src={alert} className="me-3" alt="..." />
                    <b>
                        {currentTime >= new Date(serviceData[service_name].expiry_date) ?
                            capitalizeFirstLater(service_name) + " is expired" :
                            capitalizeFirstLater(service_name) + " expire " + moment
                                .tz(new Date(serviceData[service_name].expiry_date), "Asia/Kolkata")
                                .fromNow()}
                    </b>
                </div>
                <button
                    type="button"
                    className="renew-btn"
                    onClick={() => {
                        setShowRenewModal(true);
                        setServiceName(service_name);
                        dispatch(getServicesDetail(service_name));
                    }}
                >
                    Renew
                </button>
            </Alert >
            <Modal
                show={showRenewModal}
                onHide={() => setShowRenewModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="p-relative">
                    <RenewConfirmModal
                        onHide={() => setShowRenewModal(false)}
                        item_name={serviceName}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

function HomeScreen() {
    const dispatch = useDispatch();
    const [noOfSubscribedAiService, setNoOfSubscribedAiService] = useState(0)
    const [nameOfSubscribedAiService, setNameOfSubscribedAiService] = useState([])
    const [totalNumberOfAlertsOfSubscribedAiService, setTotalNumberOfAlertsOfSubscribedAiService] = useState([])
    const [totalNoAlerts, setTotalNoAlerts] = useState('')
    const [totalNoOfCamera, setTotalNoOfCamera] = useState('')

    const HomePageData = useSelector((state) => state.HomePageData);
    const { error, loading, homedata } = HomePageData;

    const subscribeServicesList = useSelector((state) => state.subscribeServicesList);
    const { serviceData } = subscribeServicesList;

    useEffect(() => {
        dispatch(homePageData());
        dispatch(listSubscribeServices())
    }, [dispatch]);

    useEffect(() => {
        //FETCHING DATA FOR PIE CHART FOR AI SERVICES COUNT
        setNoOfSubscribedAiService(Number(homedata?.service_alert_count?.length))
        //1. GIVES US NO OF SERVIES ARE ACTIVED  EG: 2 //FOR THE CARD(NO OF SERVICES)
        setNameOfSubscribedAiService(homedata?.service_alert_count?.flatMap(obj => obj.service_name))//2. GIVES US NAME OF SERVICE    Eg: ["interusion detection","face recognition"]

        setTotalNumberOfAlertsOfSubscribedAiService(homedata?.service_alert_count?.flatMap(obj => obj.total))
        //3. GIVES US TOTAL NO OF ALERTS  eg: [23,36]

        //FOR THE CARD (EVENT CARD) WHERE WE SHOW THE TOTAL NUMBER OF ALERTS
        setTotalNoAlerts(homedata?.service_alert_count?.reduce((total, currentItem) => total + currentItem.total, 0));

        //FOR 3RD CARD LIVECAMADDED WHERE WE ARE SHOWING TOTAL NUMBER OF CAMERA ADDED 
        //WE CAN Add ACTIVE AND INCVTIVE CAMERA WHICH WILL GIVE US TOTAL NUMBER OF CAMERA ADDED
        // EARLIER USED METHOD WAS FETCH THE WHOLE CAMERA DATA AND CALCULATE THE LEGNTH 
        if (loading === false) {
            setTotalNoOfCamera(Number(homedata?.active_configure_camera) + Number(homedata?.in_active_configure_camera) + Number(homedata?.active_non_configure_camera) + Number(homedata?.in_active_non_configure_camera))
        }
    }, [homedata]);

    const piedata = {
        labels: nameOfSubscribedAiService,

        datasets: [
            {
                label: "",
                data: totalNumberOfAlertsOfSubscribedAiService,
                backgroundColor: Colors?.slice(0, noOfSubscribedAiService),
                //BORDER COLOR SHOULD BE DARKER THEN THE BACKGROUND COLOR, SO USING FUNCTION TO DARKER THE COLOR 
                //WE ARE SLICING BECAUSE FROM THE COLOR LIST WE ONLY WANT TO PICK UP 'N' NO OF COLOR FOR 'N' SERVICES 
                borderColor: [Colors?.slice(0, noOfSubscribedAiService)?.map((colorcode) => { return LightenDarkenColor(colorcode) })][0],
                borderWidth: 1,
            },
        ],
    };

    const cameradata = {
        labels: ["Active Livecam", "Inactive Livecam"],

        configured: {
            dataSet: [
                homedata?.active_configure_camera, //Active Configured camera length
                homedata?.in_active_configure_camera //Inactive Configured camera length
            ],
        },
        nonConfigured: {
            dataSet: [
                homedata?.active_non_configure_camera,
                homedata?.in_active_non_configure_camera
            ]
        },
    };

    const getMaxValuewithPadding = data => {
        if (!data) return 0;
        return Math.max(...data) + 1;
    };

    return (
        <Fragment>
            {error?.status === 403 ? (
                <PageNotAccess />
            ) : (
                <Fragment>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            {Object.keys(serviceData)?.map((e) => {
                                return (
                                    <div key={e}>
                                        {(!serviceData[e].is_lifetime && new Date() >= new Date(serviceData[e].expiry_date)) && (
                                            <SubscriptionAlert service_name={e} />
                                        )}
                                    </div>
                                )
                            })}
                            <div className="row home_card">
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <Link to="/events/">
                                        <Card className=" home_card_red small_card">
                                            <Card.Body>
                                                <Card.Title className="fs-4 d-flex align-items-center ">
                                                    {" "}
                                                    <i className="ri-layout-masonry-line me-3 icon_green"></i>{" "}
                                                    Events
                                                </Card.Title>

                                                <div className="mt-5 d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div className="d-flex flex-row align-items-center fw-bold">
                                                            <div className="me-2 fs14">
                                                                Events Detected -
                                                            </div>
                                                            <div>
                                                                <b>{totalNoAlerts}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <i className="fas fa-angle-right"></i>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <Link to="/services/">
                                        <Card className="home_card_yellow small_card">
                                            <Card.Body>
                                                <Card.Title className="fs-4 d-flex align-items-center">
                                                    <i className="ri-tools-fill me-3 icon_yellow"></i>
                                                    Services
                                                </Card.Title>
                                                <div className="mt-5 d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div className="d-flex flex-row align-items-center fw-bold">
                                                            <div className="me-2 fs14">
                                                                Services Added -
                                                            </div>
                                                            <div>
                                                                <b>{homedata?.applied_service_count}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <i className="fas fa-angle-right"></i>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link to="/livecam/">
                                        <Card className=" home_card_blue small_card">
                                            <Card.Body>
                                                <Card.Title className="fs-4 d-flex align-items-center ">
                                                    <i className="ri-camera-line me-3 icon_blue"></i>Livecam
                                                </Card.Title>
                                                <div className="mt-5 d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <div className="d-flex flex-row align-items-center fw-bold">
                                                            {" "}
                                                            <div className="me-2 fs14">
                                                                Livecam Added -{" "}
                                                            </div>
                                                            <div>
                                                                <b>{totalNoOfCamera}</b>
                                                            </div>
                                                            {" "}
                                                        </div>
                                                    </div>
                                                    <i className="fas fa-angle-right"></i>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-3 home_card">
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <Card className=" home_chart cards_box">
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="d-flex justify-content-between">
                                                    <h4>Event Summary</h4>{" "}
                                                    <div>
                                                        <small className="sub_text">

                                                        </small>
                                                    </div>
                                                </div>
                                            </Card.Title>
                                            <div className="row show_chart d-flex align-items-start justify-content-center">
                                                {noOfSubscribedAiService === 0 ? (
                                                    <div className="d-flex p-5 align-items-center justify-content-center h-100 ">
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <img
                                                                src={noEventsFound}
                                                                alt=""
                                                                height={"200px"}
                                                                width={"200px"}
                                                            />
                                                            <h5 className="text-center">No Events Found</h5>
                                                        </div>
                                                    </div>
                                                ) :
                                                    (
                                                        <>
                                                            <div className="col-7 service_list_graph">
                                                                {homedata?.service_alert_count?.map((service, index) => (
                                                                    <PieChartLabels key={`${index + 1}`} aiServiceName={service.service_name} color={Colors[index]} count={service.total}
                                                                    />

                                                                ))}
                                                            </div>
                                                            <div className="col-5">
                                                                <Pie
                                                                    className=" "
                                                                    data={piedata}
                                                                    options={{
                                                                        plugins: {
                                                                            legend: { display: false },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-lg-6 mb-3 mb-lg-0">
                                    <Card className="cards_box home_chart">
                                        <Card.Body>
                                            <Card.Title>
                                                <div className="d-flex justify-content-between">
                                                    <h4>Livecam Summary</h4>{" "}

                                                </div>
                                            </Card.Title>
                                            <div>
                                                <div className="row show_chart d-flex align-items-start justify-content-center h-100">
                                                    {homedata?.inactiveActiveConfigNotConfigSTATUSCount?.length === 0 ? (
                                                        <div className="d-flex p-5 align-items-center justify-content-center h-100 ">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <img
                                                                    src={noEventsFound}
                                                                    alt=""
                                                                    height={"200px"}
                                                                    width={"200px"}
                                                                />
                                                                <h5 className="text-center">No Cameras are Added</h5>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="col-8 align-self-end livecam-summary">
                                                                <Bar
                                                                    height="270"
                                                                    data={{
                                                                        labels: cameradata.labels,
                                                                        responsive: true,
                                                                        offset: true,
                                                                        datasets: [
                                                                            {
                                                                                label: "Configured",
                                                                                backgroundColor:
                                                                                    "rgba(74, 194, 55, 0.5)",
                                                                                barThickness: 30,
                                                                                categoryPercentage: 1,
                                                                                data: cameradata.configured
                                                                                    .dataSet,
                                                                            },
                                                                            {
                                                                                label: "Non-Configured",
                                                                                backgroundColor:
                                                                                    "rgba(255, 48, 48, 0.5)",
                                                                                barThickness: 30,
                                                                                categoryPercentage: 1,
                                                                                data: cameradata
                                                                                    .nonConfigured.dataSet,
                                                                            },
                                                                        ],
                                                                    }}
                                                                    options={{
                                                                        offsetGridLines: false,
                                                                        drawTicks: true,
                                                                        layout: {
                                                                            padding: {
                                                                                top: 10,
                                                                                right: 10,
                                                                                bottom: 10,
                                                                                left: 20,
                                                                            },
                                                                        },
                                                                        plugins: {
                                                                            legend: { display: false },
                                                                        },
                                                                        responsive: true,

                                                                        scales: {
                                                                            xAxes: {
                                                                                stacked: false,
                                                                                ticks: {
                                                                                    padding: 1,
                                                                                    font: {
                                                                                        size: 16,
                                                                                        family: "Poppins",
                                                                                        style: "normal",
                                                                                        transform: "translateX(-50%) translateX(- 0.4px) translateY(-50%) translateY(-0.4px)",
                                                                                        weight: "500"

                                                                                    },
                                                                                },
                                                                                grid: {
                                                                                    display: false,
                                                                                    color: "rgba(0, 0, 0, 0)",
                                                                                },

                                                                            },
                                                                            yAxes: {
                                                                                stacked: false,
                                                                                grid: {
                                                                                    display: false,
                                                                                    drawBorder: false,
                                                                                    color: "rgba(0, 0, 0, 0)",
                                                                                },
                                                                                max: getMaxValuewithPadding(cameradata.configured.dataSet.concat(cameradata?.nonConfigured.dataSet)),

                                                                                ticks: {
                                                                                    beginAtZero: true,
                                                                                    maxTicksLimit: 5,
                                                                                    precision: 0,
                                                                                    padding: 0,
                                                                                    // maxTicksLimit: getMaxValuewithPadding(cameradata?.nonConfigured.dataSet[1]),

                                                                                    font: {
                                                                                        size: 15,
                                                                                        family: 'Arial'
                                                                                    },
                                                                                    stepSize: 1,
                                                                                    // min: 3,
                                                                                    // max: 10,
                                                                                    callback(n) {
                                                                                        if (n < 1e3)
                                                                                            return n;
                                                                                        if (n >= 1e3)
                                                                                            return (
                                                                                                +(
                                                                                                    n / 1e3
                                                                                                ).toFixed(
                                                                                                    1
                                                                                                ) + "K"
                                                                                            );
                                                                                    },
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-4 d-flex flex-column justify-content-end service_list_graph">
                                                                <div>
                                                                    <i
                                                                        className="fas fa-square"
                                                                        style={{
                                                                            color: "rgba(74, 194, 55, 0.5)",
                                                                        }}
                                                                    ></i>{" "}
                                                                    Configured
                                                                </div>
                                                                <div>
                                                                    <i
                                                                        className="fas fa-square"
                                                                        style={{
                                                                            color: "rgba(255, 48, 48, 0.5)",
                                                                        }}
                                                                    ></i>{" "}
                                                                    Non-Configured
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Fragment>)}
                </Fragment>
            )}
        </Fragment>
    );
}

export default HomeScreen;
