import React, { useState, useRef, useEffect } from "react";

const Crop = (props) => {
    const [context, setContext] = useState(null);
    const [points, setPoints] = useState([]);
    const [multiplePoints, setMultiplePoints] = useState([]);
    const [redoPoints, setRedoPoints] = useState([]);
    const [lineColors, setLineColors] = useState(["green", "red"]);

    useEffect(() => {
        props.redoPoints(redoPoints)
    }, [redoPoints])

    const myCanvas = useRef();

    const area_selection_style = props.area_selection_style

    const rectanglePointSize = 4

    const linePointSize = props.linePointSize

    useEffect(() => {
        if (props.serviceName.toLowerCase().includes("vehicle overspeed")) {
            setLineColors(['green', 'green'])
        }
    }, [props.serviceName])

    useEffect(() => {
        if (props?.area_values) {
            if (area_selection_style === 'multipleRectangles') {
                if (
                    Array.isArray(multiplePoints) &&
                    Array.isArray(props?.area_values[0]) &&
                    multiplePoints.length === 0
                ) {
                    setMultiplePoints([].concat(...props.area_values[0]));
                }
            } else {
                points?.length === 0 && setPoints(props?.area_values[0]);
            }
        }
    }, []);

    useEffect(() => {
        const canvasEle = myCanvas.current
        canvasEle.width = props.width;
        canvasEle.height = props.height;
        props.setImageWidthHeight({
            image_width: canvasEle.width,
            image_height: canvasEle.height,
        })
        canvasEle.style.backgroundSize = '100% 100%';
        canvasEle.style.backgroundRepeat = 'no-repeat';
        canvasEle.style.borderRadius = '8px';
        canvasEle.style.backgroundImage = `url(${props?.send_image})`;
        setContext(canvasEle.getContext("2d"));
    }, []);

    const compareArrays = (a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
    };

    const clickHandler = (e) => {
        setRedoPoints([])
        if (points?.length <= rectanglePointSize - 1 && area_selection_style === 'rectangle') {
            setPoints([...points, { "x": e.nativeEvent.offsetX, "y": e.nativeEvent.offsetY }]);
        }
        if (area_selection_style === 'multipleRectangles') {
            setMultiplePoints([...multiplePoints, { "x": e.nativeEvent.offsetX, "y": e.nativeEvent.offsetY }])

            context.fillStyle = "orange";
            context.beginPath();
            context.arc(e.nativeEvent.offsetX, e.nativeEvent.offsetY, 4, 0, 2 * Math.PI);
            context.fill();
        }
        if (points?.length <= linePointSize - 1 && area_selection_style === 'lines') {
            setPoints([...points, { "x": e.nativeEvent.offsetX, "y": e.nativeEvent.offsetY }]);
        }

    }

    useEffect(() => {
        if (props.undo_area_selection && (area_selection_style === 'rectangle' || area_selection_style === 'lines')) {
            const pointHistory = points.pop()
            setRedoPoints([...redoPoints, pointHistory])

            context?.clearRect(0, 0, myCanvas.current.width, myCanvas.current.height);
        }
        if (props.undo_area_selection && area_selection_style === 'multipleRectangles') {
            for (const point of points) {
                if (compareArrays(points[points.length - 1], point)) {
                    point.pop()
                    const multiplePointsHistory = multiplePoints.pop()
                    setRedoPoints([...redoPoints, multiplePointsHistory])

                    context?.clearRect(0, 0, myCanvas.current.width, myCanvas.current.height);
                }
                if (point.length === 0) {
                    points.pop()
                }
            }
        }
    }, [context, props.undo_area_selection])

    useEffect(() => {
        if (props.redo_area_selection && (area_selection_style === 'rectangle' || area_selection_style === 'lines')) {
            const redoPointHistory = redoPoints.pop()
            setPoints([...points, redoPointHistory])
        }
        if (props.redo_area_selection && area_selection_style === 'multipleRectangles') {
            const redoMultiplePointHistory = redoPoints.pop()
            setMultiplePoints([...multiplePoints, redoMultiplePointHistory])
        }
    }, [props.redo_area_selection])

    useEffect(() => {
        if (multiplePoints.length !== 0) {
            const groupSize = 4;
            const groups = [];
            for (let i = 0; i < multiplePoints.length; i += groupSize) {
                groups.push(multiplePoints.slice(i, i + groupSize));
            }
            setPoints(groups)
        }
    }, [multiplePoints])


    const clearCanvas = (context) => {
        context?.clearRect(0, 0, myCanvas.current.width, myCanvas.current.height);
    };

    const drawRectangle = (rectangle) => {
        for (let i = 1; i < (rectangle.length + 1); i++) {
            context.fillStyle = "orange";
            context.beginPath();
            context.arc(rectangle[i - 1]?.x, rectangle[i - 1]?.y, 4, 0, 2 * Math.PI);
            context.fill();
            drawLine(context, rectangle[i - 1]?.x, rectangle[i - 1]?.y, rectangle[i]?.x, rectangle[i]?.y);
        }
        if (rectangle.length === rectanglePointSize) {
            drawLine(
                context,
                rectangle[rectangle.length - 1]?.x,
                rectangle[rectangle.length - 1]?.y,
                rectangle[0]?.x,
                rectangle[0]?.y
            );
        }
    };

    useEffect(() => {
        if (!context) return;
        const drawLineSegments = () => {
            let lineNumber = 0;
            points.forEach((element, i) => {
                context.beginPath();
                context.fillStyle = "orange";
                context.arc(element?.x, element?.y, 4, 0, 2 * Math.PI);
                context.fill();

                if (i % 2 !== 0) {
                    drawLine(
                        context,
                        points[i - 1]?.x,
                        points[i - 1]?.y,
                        element?.x,
                        element?.y,
                        lineColors[lineNumber]
                    );
                    ++lineNumber;
                }
            });
        };

        if (area_selection_style === 'lines' && points.length) {
            clearCanvas(context);
            drawLineSegments();
            props.get_areas(points);
        }
    }, [context, points, area_selection_style, props.undo_area_selection]);


    useEffect(() => {
        if (!context || area_selection_style !== 'rectangle') return;
        clearCanvas(context);
        drawRectangle(points);
        props.get_areas(points);
    }, [context, points, area_selection_style, props.undo_area_selection]);

    useEffect(() => {
        if (!context || area_selection_style !== 'multipleRectangles') return;
        clearCanvas(context);
        points.forEach((point) => {
            drawRectangle(point);
        });
        props.get_areas(points);
    }, [context, points, area_selection_style, props.undo_area_selection]);


    const drawLine = (context, x1, y1, x2, y2, lineColor = "orange") => {
        context.beginPath();
        context.strokeStyle = lineColor;
        context.strokeWidth = 1;
        context.moveTo(x1, y1);
        context.lineTo(x2, y2);
        context.stroke();
        context.closePath();
    }

    useEffect(() => {
        if (props.clear_data === true) {
            if (myCanvas) {
                clearCanvas(context);
                setPoints([])
                setMultiplePoints([])
                props.clearFalse(false);
            }
        }
    }, [props.clear_data])

    return (
        <canvas ref={myCanvas} onClick={clickHandler} style={{ backgroundColor: '#3333330D' }} />
    );
};

export default Crop;