import {
    GET_SOFTWARE_UPDATE_DATA_REQUEST,
    GET_SOFTWARE_UPDATE_DATA_SUCCESS,
    GET_SOFTWARE_UPDATE_DATA_FAIL,

    PUT_SOFTWARE_UPDATE_DATA_REQUEST,
    PUT_SOFTWARE_UPDATE_DATA_SUCCESS,
    PUT_SOFTWARE_UPDATE_DATA_FAIL,
    PUT_SOFTWARE_UPDATE_DATA_RESET,

    INSTANT_SOFTWARE_UPDATE_REQUEST,
    INSTANT_SOFTWARE_UPDATE_FAIL,
    INSTANT_SOFTWARE_UPDATE_RESET,
    INSTANT_SOFTWARE_UPDATE_SUCCESS,

} from '../../constants/organizationConstant/softwareUpdateConstant'


export const getSoftwareUpdateReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_SOFTWARE_UPDATE_DATA_REQUEST:
            return { loading: true, success: [...state.success] };

        case GET_SOFTWARE_UPDATE_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_SOFTWARE_UPDATE_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};


export const putSoftwareUpdateDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case PUT_SOFTWARE_UPDATE_DATA_REQUEST:
            return { loading: true };

        case PUT_SOFTWARE_UPDATE_DATA_SUCCESS:
            return { success: true, loading: false };

        case PUT_SOFTWARE_UPDATE_DATA_FAIL:
            return { loading: false, error: action.error };

        case PUT_SOFTWARE_UPDATE_DATA_RESET:
            return { loading: false };

        default:
            return state;
    }
};


export const instantSoftwareUpdateReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case INSTANT_SOFTWARE_UPDATE_REQUEST:
            return { loading: true };

        case INSTANT_SOFTWARE_UPDATE_SUCCESS:
            return { success: true, loading: false };

        case INSTANT_SOFTWARE_UPDATE_FAIL:
            return { loading: false, error: action.error };

        case INSTANT_SOFTWARE_UPDATE_RESET:
            return { loading: false, };

        default:
            return state;
    }
};