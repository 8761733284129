import React, { useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';

function BackupTimePeriod(props) {

    const {
        onBackupPeriodChange,
        setBackupPeriodTime,
        BackupPeriodTime,
        setBackupPeriod,
        BackupPeriod,
        className,
        setWeek,
        Week,
        value,
        timeOption,
        disabled = false,
    } = props

    useEffect(() => {
        if (value && typeof value === 'object') {
            let period = Object.keys(value)
            setBackupPeriod(period?.map(item => item.charAt(0).toUpperCase() + item.slice(1)))
            if (period[0].includes("week")) {
                const [week, time] = value[period[0]].split(",")
                setBackupPeriodTime(time.trim())
                setWeek([week])
            } else {
                setBackupPeriodTime(value[period[0]].trim())
            }
        }
    }, [value])

    useEffect(() => {
        let periodObject = {}
        let BackupFrequency = BackupPeriod[0].toLowerCase()
        if (!BackupFrequency.includes("week")) {
            periodObject[BackupFrequency] = BackupPeriodTime
        } else {
            periodObject[BackupFrequency.toLowerCase()] = `${Week[0]}, ${BackupPeriodTime}`
        }
        onBackupPeriodChange(periodObject)
    }, [BackupPeriod, BackupPeriodTime, Week])

    const weekOption = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
    ]

    const BackupFrequencyOnChange = (e) => {
        setBackupPeriod(e)
        setBackupPeriodTime('')
        setWeek(["Select Weekday"])
    }

    return (
        <div className={`d-flex time-period-dropdown ${className}`}>
            <Multiselect
                onSelect={BackupFrequencyOnChange}
                onRemove={BackupFrequencyOnChange}
                selectedValues={BackupPeriod}
                className='filterBox bg-light-grey'
                singleSelect={true}
                options={timeOption}
                placeholder='Hourly'
                id='frequency-input'
                emptyRecordMsg="No Status available."
                isObject={false}
                avoidHighlightFirstOption={true}
                customArrow={<><i className="fs-5 ri-arrow-down-s-fill"></i></>}
                disable={disabled}
            />
            {BackupPeriod[0].includes("Week") && (
                <Multiselect
                    onSelect={(e) => setWeek(e)}
                    onRemove={(e) => setWeek(e)}
                    selectedValues={Week}
                    className='filterBox bg-light-grey week-select'
                    singleSelect={true}
                    options={weekOption}
                    id='week-period-input'
                    placeholder='Select Weekday'
                    emptyRecordMsg="No Status available."
                    isObject={false}
                    avoidHighlightFirstOption={true}
                    customArrow={<><i className="fs-5 ri-arrow-down-s-fill"></i></>}
                    disable={disabled}
                />
            )}
            <input
                type={BackupPeriod[0].includes("Month") ? 'datetime-local' : 'time'}
                className={`bg-light-grey border rounded-right px-2 ${disabled && 'is-disable'}`}
                style={{ width: "140px" }}
                value={BackupPeriodTime || ''}
                onChange={(e) => setBackupPeriodTime(e.target.value)}
                disabled={disabled}
            />
        </div>
    )
}

export default BackupTimePeriod