import axios from 'axios'
import {
    GET_BACKUP_AND_RESTORE_DATA_REQUEST,
    GET_BACKUP_AND_RESTORE_DATA_SUCCESS,
    GET_BACKUP_AND_RESTORE_DATA_FAIL,

    PUT_BACKUP_AND_RESTORE_DATA_REQUEST,
    PUT_BACKUP_AND_RESTORE_DATA_SUCCESS,
    PUT_BACKUP_AND_RESTORE_DATA_FAIL,
} from '../../constants/organizationConstant/backupAndRestoreConstant'

import { API_ENDPOINT } from '../../constants/apiEndpoint'
import { getAPI, putAPI } from '../../global/ApiManager';


export const getBackupAndRestoreData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_BACKUP_AND_RESTORE_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/backup_manager/backup_api/`, dispatch)

        dispatch({
            type: GET_BACKUP_AND_RESTORE_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_BACKUP_AND_RESTORE_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const putBackupAndRestoreData = (formData, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PUT_BACKUP_AND_RESTORE_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await putAPI(
            `/backup_manager/backup_api/${id}/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: PUT_BACKUP_AND_RESTORE_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PUT_BACKUP_AND_RESTORE_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const exportBackup = async (token, postData = {}) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/backup_manager/export_backup/`,
            postData,
            config
        )

        return { 'status': 200, "success": data }
    } catch (error) {
        return { 'status': error.data }
    }
}