import { Alert } from 'react-bootstrap'
import alert from './alert.png'
import { capitalizeFirstLater } from '../global/Helper';

export const CreateServiceAlert = ({ closeToast, service_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{service_name}</b> Has Been Applied to Camera.
    </div>
);
export const AddServiceAlert = ({ closeToast, service_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{service_name}</b> Service Has Been Added Successful !
    </div>
);

export const FailServiceAlert = ({ closeToast, error }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-error-warning-line me-2" style={{ color: "var(--danger)" }}></i>
            Service Failed to Install
        </h5>
        <b style={{ textTransform: "capitalize", color: "var(--danger)" }}>{error.trim()}</b>
    </div>
);



export const EditServiceAlert = ({ closeToast }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b></b> Service Has Been Edited Successful !
    </div>
);


export const DeleteServiceAlert = ({ closeToast, service_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-delete-bin-line me-1" style={{ color: "var(--danger)" }}></i>Deleted
        </h5>
        <b>{capitalizeFirstLater(service_name)}</b> Has Been Deleted Successful !
    </div>
);
export const AddCmeraAlert = ({ closeToast, camera_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(camera_name)}</b> Has Been Added Successful !
    </div>
);
export const UpdateCmeraAlert = ({ closeToast, camera_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(camera_name)}</b> Has Been Updated Successful !
    </div>
);
export const CmeraAlert = ({ closeToast, message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-delete-bin-line me-1" style={{ color: "var(--danger)" }}></i>
            Unsuccessful
        </h5>
        <b></b>{message}
    </div>
);
export const CameraDeleteAlert = ({ closeToast, camera_name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-delete-bin-line me-1" style={{ color: "var(--danger)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(camera_name)}</b> Has Been Deleted Successful !
    </div>
);


export const SwitchOn_Off_Alert = ({ closeToast, massage, type }) => {
    return (
        <div style={{ width: "100%" }}>

            <h5 className="fw-bold d-flex align-items-center mb-1">
                <i className={type ? "ri-checkbox-circle-line green" : "ri-error-warning-line me-1 red"} ></i>
                {type ? "Success" : "Error"}
            </h5>
            <p>{massage}</p>
        </div>
    );
}


export const AddFaceAlert = ({ closeToast, message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(message)}</b> Has Been Added Successful !
    </div>
);
export const FaceDetectionAlert = ({ message }) => (
    <div style={{ width: "100%" }}>
        {message.includes("Unknown") ?
            <h5 className="fw-bold d-flex align-items-center mb-1">
                <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--danger)" }}></i>
                Unsuccessful
            </h5> :
            <h5 className="fw-bold d-flex align-items-center mb-1">
                <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
                Successful
            </h5>
        }
        {capitalizeFirstLater(message)}
    </div>
);
export const FaceDetectionWarning = ({ message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-delete-bin-line me-1" style={{ color: "var(--danger)" }}></i>
            Unsuccessful
        </h5>
        <b>{capitalizeFirstLater(message)}</b> Already Exists !
    </div>
);
export const DefaultUpdateAlerts = ({ closeToast, name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(name)}</b> Details Updated Successful !
    </div>
);

export const SubscriptionAlert = ({ children }) => {
    return (
        <>
            <Alert className="subscription-alert">
                <img src={alert} className="me-3" alt="" />
                <b>{children}</b>
            </Alert>
        </>
    )
}

export const DeleteUserAlert = ({ closeToast, username }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-delete-bin-line me-1" style={{ color: "var(--danger)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(username)}</b> Has Been Deleted Successful !
    </div>
);
export const DefaultAddAlert = ({ closeToast, name }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(name)}</b> Has Been Added Successful!
    </div>
);

export const RestoreUserAlert = ({ closeToast, username }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <b>{capitalizeFirstLater(username)}</b> Has Been Restored Successful !
    </div>
);
export const ResetPasswordAlert = ({ closeToast }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        Password Has Been changed Successful!
    </div>
);

export const ImgFormatAlert = ({ photoFormat, closeToast }) => (
    < div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--danger)" }}></i>
            Failed
        </h5>
        <span><b>{photoFormat}</b> file is not supported, You can only upload <b>jpg, jpeg, png</b> files.</span>
    </div >
);

export const VideoFormatAlert = ({ videoFormat, closeToast }) => (
    < div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--danger)" }}></i>
            Failed
        </h5>
        <span><b>{videoFormat}</b> file is not supported, You can only upload <b>mp4, avi, mkv</b> video files.</span>
    </div >
);

export const AllAngleFaceAlert = ({ closeToast }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--danger)" }}></i>
            Failed
        </h5>
        <span>Please add photos of all sides</span>
    </div>
);

export const ServiceALert = ({ status, message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i
                className="ri-checkbox-circle-line me-1"
                style={status === 'success'
                    ?
                    { color: "var(--danger)" }
                    :
                    { color: "var(--success" }} />
            {status}
        </h5>
        <span>{message}</span>
    </div>
)

export const UserDetailsAlert = ({ closeToast }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        User Detail Has Been Updated Successful!
    </div>
);

export const CommonErrorAlert = ({ status, message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-error-warning-line me-1 fs-5" style={{ color: "var(--danger)" }}></i>
            Unsuccessful
        </h5>
        <span>{message}</span>
    </div>
)

export const CommonSuccessAlert = ({ status, message }) => (
    <div style={{ width: "100%" }}>
        <h5 className="fw-bold d-flex align-items-center mb-1">
            <i className="ri-checkbox-circle-line me-1" style={{ color: "var(--success)" }}></i>
            Successful
        </h5>
        <span>{message}</span>
    </div>
)
