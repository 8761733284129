export const emailValidator = /^([_\-.0-9a-zA-Z]+)@([_\-.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/;

export const passwordValidator = /^(?=.*[a-z])(?=.*\d)(?=.{8,})/;

export const imgValidation = (/\.(jpg|jpeg|png)$/);

export const videoValidation = (/[^\s]+(.*?).(MP4|mp4|AVI|avi|MKV|mkv)$/);

export const removeBase64String = /^data:image\/(png|jpeg);base64,/;

export const capitalizeFirstLater = (string) => {
    const arr = string.split(/[ -]/);
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2
}

export const switchWebCamera = async () => {
    let deviceArray = []
    try {
        // Prompt for permission to access the camera
        const tracks = await navigator.mediaDevices.getUserMedia({ video: true });
        tracks?.getTracks()?.forEach(track => track.stop());

        let stream = await navigator.mediaDevices.enumerateDevices();
        deviceArray = stream?.map(mediaDevice => {
            if (mediaDevice.kind === 'videoinput') {
                let device_id = mediaDevice.deviceId;
                return device_id
            }
        })
    } catch (err) {
        console.log('Unable to access camera: ' + err.message);
    }
    return deviceArray
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


export const getFormattedDateTime = (dayjs, frequency, type) => {
    if (type === "start") {
        return `${dayjs.startOf(frequency).$y}-${dayjs.startOf(frequency).$M + 1}-${dayjs.startOf(frequency).$D}-${dayjs.startOf(frequency).$H}-${dayjs.startOf(frequency).$m}-00`
    } else if (type === "end") {
        return `${dayjs.endOf(frequency).$y}-${dayjs.endOf(frequency).$M + 1}-${dayjs.endOf(frequency).$D}-${dayjs.endOf(frequency).$H}-${dayjs.endOf(frequency).$m}-00`
    }
}

export const getFormattedDate = (dayjs, frequency, type) => {
    if (type === "start") {
        return `${dayjs.startOf(frequency).$D} ${monthNames[dayjs.startOf(frequency).$M]}, ${dayjs.startOf(frequency).$y}`
    } else if (type === "end") {
        return `${dayjs.endOf(frequency).$D} ${monthNames[dayjs.endOf(frequency).$M]}, ${dayjs.endOf(frequency).$y}`
    }
}


export function sortObjectByKey(obj) {
    if (obj) {
        const sortedKeys = Object.keys(obj).sort();
        const sortedObject = {};
        for (const key of sortedKeys) {
            sortedObject[key] = obj[key];
        }
        return sortedObject;
    }
}

export function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(sortObjectByKey(obj1)) === JSON.stringify(sortObjectByKey(obj2));
}

export const areaImageWidth = 865
export const areaImageHeight = 452


export const dummyNotificationData = {
    to_user_name: "",
    pdf_file: false,
    excel_file: false,
    alert_email: "",
    alert_api: "",
    alert_whatsapp: "",
    type_of_alert: ["email"],
    on_time_alerts: false,
    report_frequency: {}
}

export const getProgressBarColor = (FreeStorage) => {
    if (FreeStorage > 85) {
        return 'bg-danger'
    } else if (FreeStorage > 50) {
        return 'bg-warning'
    } else {
        return 'bg-success'
    }
}

export const getFaceDirection = (cases) => {
    switch (cases) {
        case 0:
            return "front";

        case 1:
            return "left";

        case 2:
            return "right";
    }
}

export function isObjectLike(value) {
    return Object.prototype.toString.call(value) === "[object Object]";
}

export const imageToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});