import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loader({ margin = "180", variant }) {
    return (
        <Spinner
            variant={variant}
            animation='border'
            role='status'
            style={{
                height: '30px',
                width: '30px',
                margin: `${margin}px auto`,
                // margin: `120px auto`,
                display: 'block'
            }}
        >
            <span className='sr-only'>Loading...</span>
        </Spinner>
    )
}

export default Loader
