export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_FORGET_PASSWORD_REQUEST = 'USER_FORGET_PASSWORD_REQUEST'
export const USER_FORGET_PASSWORD_SUCCESS = 'USER_FORGET_PASSWORD_SUCCESS'
export const USER_FORGET_PASSWORD_FAIL = 'USER_FORGET_PASSWORD_FAIL'
export const USER_FORGET_PASSWORD_RESET = 'USER_FORGET_PASSWORD_RESET'

export const USER_NEW_PASSWORD_REQUEST = 'USER_NEW_PASSWORD_REQUEST'
export const USER_NEW_PASSWORD_SUCCESS = 'USER_NEW_PASSWORD_SUCCESS'
export const USER_NEW_PASSWORD_FAIL = 'USER_NEW_PASSWORD_FAIL'
export const USER_NEW_PASSWORD_RESET = 'USER_NEW_PASSWORD_RESET'

export const USER_LIVE_CAM_ACCESS_REQUEST = 'USER_LIVE_CAM_ACCESS_REQUEST'
export const USER_LIVE_CAM_ACCESS_SUCCESS = 'USER_LIVE_CAM_ACCESS_SUCCESS'
export const USER_LIVE_CAM_ACCESS_FAIL = 'USER_LIVE_CAM_ACCESS_FAIL'

export const REMOVE_LIVE_CAM_ACCESS_REQUEST = 'REMOVE_LIVE_CAM_ACCESS_REQUEST'
export const REMOVE_LIVE_CAM_ACCESS_SUCCESS = 'REMOVE_LIVE_CAM_ACCESS_SUCCESS'
export const REMOVE_LIVE_CAM_ACCESS_FAIL = 'REMOVE_LIVE_CAM_ACCESS_FAIL'
export const REMOVE_LIVE_CAM_ACCESS_RESET = 'REMOVE_LIVE_CAM_ACCESS_FAIL'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const CREATE_USER_RESET = 'CREATE_USER_RESET'

export const CREATE_USER_EMAIL_VERIFICATION_REQUEST = 'CREATE_USER_EMAIL_VERIFICATION_REQUEST'
export const CREATE_USER_EMAIL_VERIFICATION_SUCCESS = 'CREATE_USER_EMAIL_VERIFICATION_SUCCESS'
export const CREATE_USER_EMAIL_VERIFICATION_FAIL = 'CREATE_USER_EMAIL_VERIFICATION_FAIL'
export const CREATE_USER_EMAIL_VERIFICATION_RESET = 'CREATE_USER_EMAIL_VERIFICATION_RESET'
