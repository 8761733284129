import React, { Fragment, useEffect, useState } from 'react'
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/joy/Tooltip';
import CustomArrow from './CustomArrow';
import { Checkbox } from 'antd';
import TooltipDropdown from './TooltipDropdown';
import Spinner from 'react-bootstrap/Spinner';
import { useSearchParams } from 'react-router-dom';

function MultiselectDropdown(props) {
    const {
        disableTooltipDropdown,
        SelectedItemsCount,
        showOptionArrow,
        onTooltipChange,
        setCheckBoxValue,
        setFilterClasses,
        FilterClasses,
        CheckBoxValue,
        displayValue,
        placeholder,
        className,
        onChange,
        loading,
        options,
    } = props;

    let [searchParams, setSearchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [RefreshComponents, setRefreshComponents] = useState(0);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const serviceDropdownOpen = Boolean(anchorEl);
    const serviceSelectDropdownId = serviceDropdownOpen ? 'simple-services-popover' : undefined;

    useEffect(() => {
        if (serviceDropdownOpen && disableTooltipDropdown === undefined
        ) {
            onTooltipChange(Object.values(FilterClasses))
        }
    }, [RefreshComponents])

    useEffect(() => {
        if (serviceDropdownOpen) {
            onChange(CheckBoxValue)
        }
    }, [CheckBoxValue])

    const onCheckBoxChange = (e) => {
        if (CheckBoxValue.filter(c => c.title === e.title)[0]?.title === e.title) {
            setCheckBoxValue(CheckBoxValue.filter(v => v.title !== e.title))
            delete FilterClasses?.[e['title']]
            searchParams.delete('doubtful')
            setSearchParams(searchParams)
        } else {
            setCheckBoxValue([...new Set([...CheckBoxValue, e])])
        }
        setRefreshComponents((prev) => prev + 1)
    }

    return (
        <div className={className}>
            <button aria-describedby={serviceSelectDropdownId} onClick={handleClick} className={`d-flex align-items-center multiselect-popover ${serviceDropdownOpen ? 'border-dark' : 'border-grey'}`}>
                {placeholder}
                <CustomArrow count={SelectedItemsCount} />
            </button>
            <Popover
                id={serviceSelectDropdownId}
                open={serviceDropdownOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {(!options && loading) ? (
                    <div className='text-center pt-3 pb-2 w-100'>
                        <Spinner animation="border" variant="dark" size="sm" />
                    </div>
                ) : (
                    <Fragment>
                        {options?.length === 0 ? (
                            <div className="text-center p-2 text-grey" style={{
                                fontSize: "13px",
                                fontWeight: "500",
                            }}>
                                No options found
                            </div>
                        ) :
                            options?.map((e, index) => (
                                <Tooltip
                                    title={
                                        <TooltipDropdown
                                            CheckBoxValue={CheckBoxValue}
                                            onCheckBoxChange={onCheckBoxChange}
                                            RefreshComponents={RefreshComponents}
                                            setRefreshComponents={setRefreshComponents}
                                            FilterClasses={FilterClasses}
                                            setFilterClasses={setFilterClasses}
                                            dropdownOption={e}
                                            displayValue={displayValue}
                                        />
                                    }
                                    placement="right"
                                    key={`${index + 1}`}
                                    open={disableTooltipDropdown}
                                    disableFocusListener
                                >
                                    <div
                                        className={`p-2 ps-3 d-flex align-items-center gap-2 cursor-pointer ${e.title === CheckBoxValue.filter(c => c.title === e.title)[0]?.title ? 'text-black' : 'text-grey'}`}
                                        onClick={() => onCheckBoxChange(e)}
                                    >
                                        <Checkbox
                                            checked={e.title === CheckBoxValue.filter(c => c.title === e.title)[0]?.title}
                                        >
                                        </Checkbox>
                                        <div
                                            className="d-flex align-items-center justify-content-between gap-2"
                                            style={{ width: "calc(100% - 25px)" }}
                                            title={e[displayValue]}
                                        >
                                            <div className="text-truncate pe-2 text-capitalize" style={{
                                                fontSize: "13px",
                                                fontWeight: "500",
                                            }}>
                                                {e[displayValue]}
                                            </div>
                                            {showOptionArrow && (
                                                <i className="fs-5 ri-arrow-right-s-fill"></i>
                                            )}
                                        </div>
                                    </div>
                                </Tooltip>
                            ))}
                    </Fragment>
                )}
            </Popover>
        </div >
    )
}

export default MultiselectDropdown