import { React, useState, useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown';
import SearchInput from '../../../components/SearchInput'
import user_image from '../../../images/react-logo-1.png'
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch, useSelector } from "react-redux";
import { addAddOnToShortcut, deleteInstallAddOnData, getAddOnData, getInstalledAddOnData, postInstallAddOnData } from '../../../actions/organizationActions/addOnsActions';
import noRequestFound from '../../../images/no-camera-request-found.gif'

function AddOns() {

    const [searchFilter, setSearchFilter] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [addOns, setAddOns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const dispatch = useDispatch();

    const addOnDataGet = useSelector((state) => state.AddOnData);
    const { loading: getAddOnDataLoad, success: getAddOnDataSuccess } = addOnDataGet;

    const installAddOnPost = useSelector((state) => state.InstallAddOnPost);
    const { loading: postInstalledAddOnLoad, success: postInstallAddOnSuccess } = installAddOnPost;

    const installAddOnDelete = useSelector((state) => state.InstallAddOnDelete);
    const { loading: deleteInstalledAddOnLoad, success: deleteInstallAddOnSuccess } = installAddOnDelete;

    const installedAddOnDataGet = useSelector((state) => state.InstalledAddOnData);
    const { loading: getInstalledAddOnDataLoad, success: getInstalledAddOnDataSuccess } = installedAddOnDataGet;

    const addToShortCut = useSelector((state) => state.AddToShortCut);
    const { loading: addToShortCutLoad, success: addToShortCutSuccess } = addToShortCut;

    const itemsPerPage = 4;

    const addOnParams = {
        page: currentPage,
        itemsPerPage: itemsPerPage
    }

    useEffect(() => {
        dispatch(getAddOnData(addOnParams, searchValue))
    }, [])

    useEffect(() => {
        dispatch(getInstalledAddOnData())
    }, [addToShortCutSuccess, deleteInstallAddOnSuccess, postInstallAddOnSuccess])

    useEffect(() => {
        const pageCount = getAddOnDataSuccess?.count

        if (pageCount) {

            setTotalPages(Math.ceil(pageCount / itemsPerPage));
        }

    }, [getAddOnDataSuccess])


    useEffect(() => {
        dispatch(getAddOnData(addOnParams, searchValue))
    }, [currentPage, searchValue])


    useEffect(() => {
        if (getAddOnDataSuccess && getAddOnDataSuccess.results && getAddOnDataSuccess.results.length > 0) {
            const mappedAddOns = getAddOnDataSuccess?.results?.map(item => {
                const installedAddOn = getInstalledAddOnDataSuccess?.find(installedItem => installedItem.add_on_id === item.id);

                return {
                    ...item,
                    installed: !!installedAddOn,
                    isPinned: installedAddOn?.is_pinned || false,
                    isLoading: false,
                    update: installedAddOn?.add_on_id === item.id && installedAddOn?.version < item.version,
                };
            });

            setAddOns(mappedAddOns);
        } else {
            setAddOns([])
        }
    }, [getAddOnDataSuccess, getInstalledAddOnDataSuccess]);



    const searchFilterOptions = [
        'Installed',
        'Uninstalled'
    ]

    const searchFilterOnChange = (e) => {
        setSearchFilter(e)
    }


    const setAddOnLoadingState = (prevAddOns, itemId) =>
        prevAddOns?.map((addOn) =>
            addOn.id === itemId ? { ...addOn, isLoading: true } : addOn
        );

    const handleInstall = (item) => {
        setAddOns((prevAddOns) => setAddOnLoadingState(prevAddOns, item.id));
        dispatch(postInstallAddOnData({ add_on_id: item.id }));
    };

    const handleUninstall = (item) => {
        setAddOns((prevAddOns) => setAddOnLoadingState(prevAddOns, item.id));
        dispatch(deleteInstallAddOnData({ add_on_id: item.id }));
    };

    const handleAddToShortcuts = (item, status) => {
        setAddOns((prevAddOns) => setAddOnLoadingState(prevAddOns, item.id));
        dispatch(addAddOnToShortcut({ add_on_id: item.id, is_pinned: status }));
    };

    const handlePaginationClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className='add-on-container d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between mt-2 mb-4'>
                <div className='search-input' >
                    <SearchInput
                        width='20rem'
                        onChange={(e) => setSearchValue(e)}
                    />
                </div>
                {/* <div className='filter-option'>
                    <Multiselect
                        onSelect={searchFilterOnChange}
                        onRemove={searchFilterOnChange}
                        selectedValues={searchFilter}
                        className='filterBox bg-light-grey'
                        singleSelect={true}
                        options={searchFilterOptions}
                        isObject={false}
                        customArrow={<i className="fs-5 ri-arrow-down-s-fill"></i>}
                    />
                </div> */}
            </div>
            <div className='add-on-card mb-3'>
                <ListGroup >
                    {addOns?.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "calc(100% - 190px)" }}>
                            <div className="text-center background-none">
                                <img className='background-none opacity ' src={noRequestFound} height={"220px"} width={"220px"} alt="" />
                                <p className='opacity'>No addons available!</p>
                            </div>
                        </div>)}
                    {
                        addOns?.map((item, index) => (
                            <ListGroup.Item key={index} style={{ 'background': 'rgba(217, 217, 217, 0.17)' }} className='mt-1 mb-1 d-flex flex-row p-0 justify-content-between align-items-center'>
                                <div className='p-2 d-flex align-item-center'>
                                    <img
                                        style={{ 'width': '65px', 'height': '65px', 'objectFit': 'cover' }}
                                        src={user_image}
                                        alt=""
                                    />
                                </div>
                                <div className='add-on-body w-100'>
                                    <div className='p-1'><strong className='label'>{item.label}</strong>{item.installed ? <span className='install-tag ms-4'>Installed</span> : <></>}{item.update ? <span className='update-tag ms-2'>Update</span> : <></>}</div>
                                    <span className='p-1 detail'>updated_time not specified</span>
                                    <p className='p-1 limited-width-paragraph'>{item.description}</p>
                                </div>
                                <div className='me-3'>
                                    <div>
                                        <Dropdown >
                                            <div className='d-flex flex-row align-items-center manage-dropdown'>
                                                {
                                                    postInstalledAddOnLoad && item.isLoading && (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Installing...
                                                        </>
                                                    )
                                                }

                                                {
                                                    deleteInstalledAddOnLoad && item.isLoading && (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Uninstalling...
                                                        </>
                                                    )
                                                }

                                                {
                                                    addToShortCutLoad && item.isLoading && (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Adding to shortcut...
                                                        </>
                                                    )
                                                }


                                                {
                                                    !item.isLoading && (
                                                        <div className='dropdown-heading p-2 h-100' style={{ 'height': '100%' }}>Manage</div>
                                                    )
                                                }

                                                <Dropdown.Toggle split variant="info" id="dropdown-custom-2" disabled={postInstalledAddOnLoad ? true : false} />

                                            </div>
                                            <Dropdown.Menu>
                                                {
                                                    item.installed ? <>
                                                        {
                                                            item.update ?
                                                                <Dropdown.Item onClick={() => handleInstall(item)}>Update</Dropdown.Item>
                                                                :
                                                                <></>
                                                        }
                                                        <Dropdown.Item onClick={() => handleUninstall(item)}>UnInstall</Dropdown.Item>
                                                        {
                                                            item.isPinned ?
                                                                <Dropdown.Item onClick={() => handleAddToShortcuts(item, false)}>Remove from Shortcut</Dropdown.Item>
                                                                :
                                                                <Dropdown.Item onClick={() => handleAddToShortcuts(item, true)}>Add to Shortcut</Dropdown.Item>
                                                        }
                                                    </>
                                                        :
                                                        <>
                                                            <Dropdown.Item onClick={() => handleInstall(item)}>Install</Dropdown.Item>
                                                            <Dropdown.Item >Details</Dropdown.Item>
                                                        </>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </div>
            {addOns?.length !== 0 && (
                <div className="pagination d-flex justify-content-end" >
                    <Pagination>
                        <Pagination.Prev onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                        {[...Array(totalPages).keys()]?.map((page) => (
                            <Pagination.Item
                                key={page + 1}
                                active={page + 1 === currentPage}
                                onClick={() => handlePaginationClick(page + 1)}
                            >
                                {page + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            )}
        </div>
    )
}

export default AddOns