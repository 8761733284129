import React from "react";
import "./RegisterLogin.css";

function RegisterScreenLeftSideBar() {
    return (
        <div className="left-side-bar">
            <div className="side-bar-container d-flex flex-column align-items-center justify-content-center h-100 py-5">
                <div className="text-center">
                    <div className="nwarch-logo" style={{ fontSize: "2.35rem" }}>
                        NWarch
                        <span className="text-white"> AI</span>
                    </div>
                </div>
                <div className="position-absolute bottom-0 left-0 text-center mb-3">
                    <h6 style={{ fontSize: "10px", marginBottom: "10px", color: "var(--powered_by)" }}>
                        {"Powered by D&W"}
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default RegisterScreenLeftSideBar;
