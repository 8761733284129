import React, { useState, useEffect } from 'react'
import SearchInput from "../../../components/SearchInput";
import { Table } from "antd";
import { Image, Modal } from 'react-bootstrap'
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import noUserFound from '../../../images/no-users-found.gif'
import { removeUsersInFaceRecognitionCamera, getFaceRecognitionCamera, getFaceRecognitionUsers } from '../../../actions/faceRecognitionActions';
import { useDispatch, useSelector } from 'react-redux'
import { REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET } from '../../../constants/faceRecognitionConstant'

export default function UsersAddedOnCameraModal(props) {

    const addedUser = props.addedUser
    const cameraId = props.cameraId
    const serviceId = props.serviceId
    const cameraTablePageNumber = props.cameraTablePageNumber

    const dispatch = useDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [search, setSearch] = useState('');

    const usersRemoveInFaceRecognitionCamera = useSelector((state) => state.usersRemoveInFaceRecognitionCamera);
    const { loading, removeUsersSuccess } = usersRemoveInFaceRecognitionCamera;

    useEffect(() => {
        if (removeUsersSuccess) {
            dispatch({ type: REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET })
            dispatch(getFaceRecognitionCamera(serviceId, cameraTablePageNumber))
            dispatch(getFaceRecognitionUsers())
            props.closeAddedUserCameraModal(true)
        }
    }, [dispatch, removeUsersSuccess])

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: 180,
            ellipsis: true,
        },
        {
            title: "User ID",
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: selectedRowKeys.length ? (
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => { setDeleteModalShow(true) }}
                >
                    <span className='delete-icon'></span>
                    Delete
                </div>) : "Action",
            dataIndex: 'action',
            key: 'action',
        },
    ];

    const onSingleRowSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys([...selectedRowKeys, record.key])
        } else {
            const selected = selectedRowKeys.filter(e => e !== record.key)
            setSelectedRowKeys(selected)
        }
    }

    const added_camera_data = addedUser?.filter((user) => user.username.toLowerCase().includes(search?.toLowerCase()) || String(user.user_id)?.includes(String(search)))?.map((user) => ({
        key: user.id,
        user_name: <div>
            <Image
                height={30}
                width={40}
                className="me-1 border_radius_sm"
                src={user.user_profile}
            />{" "}
            {user.username}
        </div>,
        user_id: user.user_id,
        action:
            <div
                className={selectedRowKeys.includes(user.id) ? "d-none" : 'd-flex justify-content-end'}
                onClick={() => setSelectedRowKeys([...selectedRowKeys, user.id])}
            >
                <span
                    className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                >
                    <i className="ri-delete-bin-5-line "></i>
                </span>
            </div>
    }))

    return (
        <div className={added_camera_data?.length === 0 ? 'height-0' : 'user-camera-access-container px-3'}>
            <div className="d-flex justify-content-between align-items-end">
                <SearchInput onChange={(e) => setSearch(e)} />
                <div>
                    <button
                        type='button'
                        className='add-user-btn'
                        style={{ padding: "7px 7px" }}
                        onClick={() => { props.showAddedUserCameraModal(true) }}
                    >
                        <span className='add-user-icon'></span>
                    </button>
                </div>
            </div>

            <Table
                rowSelection={{
                    selectedRowKeys,
                    onSelect: onSingleRowSelect,
                    onSelectAll: (items) => items ? setSelectedRowKeys(addedUser?.map(e => e.id)) : setSelectedRowKeys([])
                }}
                columns={columns}
                dataSource={added_camera_data}
                pagination={{
                    pageSize: 10,
                }}
                scroll={{ x: 700, y: "300px" }}
            />
            {added_camera_data?.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "300px" }}>
                    <div className="text-center background-none">
                        <img className='background-none opacity no-user-found' src={noUserFound} alt="" />
                        <p className='opacity'>No Users Found!<br />
                            Add Users to start building the User List.</p>
                    </div>
                </div>) : null}
            <div
                className="text-end mt-4"
            >
                <button className="btn px-3 py-2 border close" type='button' onClick={() => {
                    props.closeAddedUserCameraModal(true)
                }}>
                    Cancel
                </button>
            </div>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={deleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        loading={loading}
                        item_name={"Selected Users"}
                        dispatch_item={removeUsersInFaceRecognitionCamera({ "camera_id": cameraId, "user_id": selectedRowKeys, "service_id": serviceId })}
                        onHide={() => { setDeleteModalShow(false) }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}
