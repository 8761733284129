import axios from 'axios';
import { logout } from '../actions/userActions';
import { API_ENDPOINT } from '../constants/apiEndpoint';

export const getAPI = async (config, url, dispatch) => {
    try {
        const { data } = await axios.get(
            `${API_ENDPOINT}${url}`,
            config
        )
        return { data: data }

    } catch (error) {
        if (error.status === 401) {
            // Token expired, handle the situation (e.g., refresh token or redirect to login)
            dispatch(logout());
            window.location.replace("/");
        }
        throw error
    }
}

export const postAPI = async (url, formData, config, dispatch) => {
    try {
        const { data } = await axios.post(
            `${API_ENDPOINT}${url}`,
            formData,
            config
        )
        return { data: data }

    } catch (error) {
        if (error.status === 401) {
            dispatch(logout());
            window.location.replace("/");
        }
        throw error
    }
}

export const putAPI = async (url, formData, config, dispatch) => {
    try {
        const { data } = await axios.put(
            `${API_ENDPOINT}${url}`,
            formData,
            config
        )
        return { data: data }

    } catch (error) {
        if (error.status === 401) {
            dispatch(logout());
            window.location.replace("/");
        }
        throw error
    }
}

export const deleteAPI = async (url, config, dispatch) => {
    try {
        const { data } = await axios.delete(
            `${API_ENDPOINT}${url}`,
            config
        )
        return { data: data }

    } catch (error) {
        if (error.status === 401) {
            dispatch(logout());
            window.location.replace("/");
        }
        throw error
    }
}