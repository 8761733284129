import React from 'react'

function PieChartLabels({ aiServiceName, color, count }) {
  return (
    <>
      <div>
        {" "}
        <i
          className="fas fa-square"
          style={{ color: [color] }}
        ></i>{" "}
        {aiServiceName}{" "}
        [{count}]
      </div>
    </>
  )
}

export default PieChartLabels