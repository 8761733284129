import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterScreenLeftSideBar from "../../components/AuthenticationComponents/RegisterScreenLeftSideBar";
import RegisterScreenRightSideBar from "../../components/AuthenticationComponents/RegisterScreenRightSideBar";
import { login, createUserEmailVerification, emailCheck } from "../../actions/userActions";
import { useSelector, useDispatch } from "react-redux";
import AlertMesaage from "../../components/AlertMesaage";
import { homePageData } from "../../actions/dataActions";
import { USER_LOGIN_RESET, CREATE_USER_EMAIL_VERIFICATION_RESET } from "../../constants/userConstants";
import Loading from "../../components/Loading";
import { emailValidator } from '../../global/Helper'
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { getInstalledAddOnData } from "../../actions/organizationActions/addOnsActions";

function AdminLogInScreen() {
  const { register, setError, handleSubmit, formState: { errors } } = useForm();

  const navigate = useNavigate();
  const Ref = useRef(null);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [firstTimeLoginVerified, setFirstTimeLoginVerified] = useState(true);
  const [activationKey, setActivationKey] = useState('');
  const [verificationCodeSendSuccess, setVerificationCodeSendSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [timer, setTimer] = useState('00:00');

  const [invoiceRole, setInvoiceRole] = useState([
    "annotator user",
    "po user",
    "security guard",
    "ocr checker",
    "grn user",
    "finance user"
  ]);

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const userCreateEmailVerification = useSelector((state) => state.userCreateEmailVerification);
  const { loading: emailVerificationLoad, success: verificationSendSuccess } = userCreateEmailVerification;

  const installedAddOnDataGet = useSelector((state) => state.InstalledAddOnData);
  const { success: getInstalledAddOnDataSuccess } = installedAddOnDataGet;

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total, minutes, seconds
    };
  }

  const startTimer = (e) => {
    let { total, minutes, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        ((minutes > 9 ? minutes : '0' + minutes) + ':'
          + (seconds > 9 ? seconds : '0' + seconds)).toString()
      )
    }
  }

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  }

  const onClickReset = () => {
    clearTimer(getDeadTime());
  }

  useEffect(() => {
    if (userInfo) {
      if (invoiceRole.includes(userInfo?.user_role)) {
        dispatch(getInstalledAddOnData())
        navigate("./invoice_automation", { replace: true });
      } else {
        dispatch(homePageData());
        navigate("./home/", { replace: true });
      }
    }
    if (error) {
      error.detail?.includes("email") && setError('email', { type: "manual", message: "Email Does Not Exist!" }, { shouldFocus: true });
      error.detail?.includes("password") && setError('password', { type: "manual", message: "Wrong Password!" }, { shouldFocus: true });
      error?.non_field_errors?.[0]?.includes("otp") && setError('verificationCode', { type: "manual", message: "Verification Code is Wrong!" }, { shouldFocus: true });
      dispatch({ type: USER_LOGIN_RESET })
    }
  }, [userInfo, navigate, dispatch, error, getInstalledAddOnDataSuccess]);

  useEffect(() => {
    if (verificationSendSuccess) {
      setActivationKey(verificationSendSuccess?.activation_key)
      dispatch({ type: CREATE_USER_EMAIL_VERIFICATION_RESET })
      startTimer()
      onClickReset()
      setVerificationCodeSendSuccess(true)
    }
  }, [dispatch, verificationSendSuccess])

  const onEmailChange = async (e) => {
    setEmail(e.target.value)
    setFirstTimeLoginVerified(true)
    if (emailValidator.test(e.target.value)) {
      let { status, first_time_login_verified } = await emailCheck(e.target.value)
      if (status) {
        setFirstTimeLoginVerified(first_time_login_verified)
        !first_time_login_verified && dispatch(createUserEmailVerification(e.target.value));
      }
    }
  }

  const sendVerificationCode = async () => {
    let { status } = await emailCheck(email)
    if (status) {
      dispatch(createUserEmailVerification(email));
    } else {
      setError('email', { type: "manual", message: "Email is not registered!" }, { shouldFocus: true })
    }
  };

  const loginHandler = (e) => {
    dispatch(login(e.email, e.password, e.verificationCode, activationKey));
  };

  return (
    <div>
      <div className="row g-0 auth-container">
        <div className="col-md-5">
          <RegisterScreenLeftSideBar />
        </div>
        <div className="col-md-7">
          <RegisterScreenRightSideBar>
            <h5 className='mb-4 text-center register_headline'>Sign In</h5>
            {error?.non_field_errors?.[0]?.toLowerCase().includes("user") && (
              <AlertMesaage variant="danger">{error?.non_field_errors?.[0]}</AlertMesaage>
            )}
            <Form noValidate autoComplete="off" onSubmit={handleSubmit(loginHandler)}>
              <Form.Group className="mb-2">
                <Form.Label className="form-label">Email</Form.Label>
                <Form.Control
                  id="email"
                  className="form-control border-input"
                  type="email"
                  placeholder="Enter Your Email Address"
                  title="Enter your Email Address"
                  isInvalid={errors.email}
                  {...register("email", {
                    required: "Please Enter Email.",
                    pattern: {
                      value: /^([_\-.0-9a-zA-Z]+)@([_\-.0-9a-zA-Z]+)\.([a-zA-Z]){2,7}$/,
                      message: "Please Enter Valid Email."
                    },
                    onChange: async (e) => onEmailChange(e)
                  })}
                />
                <Form.Control.Feedback className="invalid-feedback">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              {!firstTimeLoginVerified && (
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <button
                      type='button'
                      onClick={sendVerificationCode}
                      className={`text-primary verify-btn ${(emailVerificationLoad || timer !== '00:00') && 'is-disable'}`}
                      disabled={emailVerificationLoad || timer !== '00:00'}
                    >
                      {verificationCodeSendSuccess ? "Resend" : "Send"} Verification Code
                    </button>
                    {timer !== '00:00' && (
                      <div className="stop-watch d-flex align-items-center">
                        <span className="stop-watch-icon"></span>
                        {timer}
                      </div>
                    )}
                  </div>
                  <Form.Group className='mb-4' >
                    <Form.Label className='m-0' htmlFor=""><b>Verification Code</b></Form.Label>
                    <Form.Control
                      type="number"
                      name="verificationCode"
                      className='border-input'
                      id="verificationCode"
                      placeholder='Enter Verification Code'
                      isInvalid={errors.verificationCode}
                      {...register("verificationCode", {
                        required: "Please Enter Verification Code.",
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.verificationCode?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}
              <Form.Group className="password-field mb-4">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  className={"form-control border-input"}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  title="Enter your Password"
                  isInvalid={errors.password}
                  {...register("password", {
                    required: "Please Enter Password.",
                  })}
                />
                <i
                  onClick={() => setShowPassword(!showPassword)}
                  className={`${showPassword ? 'ri-eye-line' : 'ri-eye-close-line'} text-dark`}
                ></i>
                <Form.Control.Feedback className="invalid-feedback">
                  {errors.password?.message}
                </Form.Control.Feedback>
                <div className="mt-3 text-end">
                  <Link to="/forgot-password" className="text-primary">Forgot Password?</Link>
                </div>
              </Form.Group>
              <div className="text-center mb-4">
                <button
                  type="submit"
                  className={`border-radius btn btn_primary`}
                  disabled={loading || emailVerificationLoad}
                >
                  <b>Sign In</b>
                  {(loading || emailVerificationLoad) && <Loading />}
                </button>
              </div>
            </Form>
            <p className="text-center m-0">
              Not a member?
              <Link to="register/"> Create Account</Link>
            </p>
          </RegisterScreenRightSideBar>
        </div>
      </div>
    </div >
  );
}

export default AdminLogInScreen;
