import React, { Fragment, useEffect, useState } from 'react'
import noVideo from "../../../components/inActive.png";
import 'video.js/dist/video-js.css';
import VideoJS from './VideoJS';
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import AccessTooltip from "../../../components/AccessTooltip";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import AddCamera from "../../configurepage/AddCamera";
import { CameraDeleteAlert, CommonErrorAlert } from "../../../components/ToastAlert";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal";
import { DELETE_CAMERA_RESET } from "../../../constants/dataConstants";
import { cameraDelete } from "../../../actions/dataActions";
import { getLivecamData, getVideoRef, startStreaming } from '../../../actions/livecamAction'
import noServiceIcon from '../../../images/no-service-icon.jpeg'
import noCameraLarge from '../../../images/noCameraLarge.png'
import { API_ENDPOINT } from "../../../constants/apiEndpoint";

function LiveStreamingTab(props) {
    const {
        camera,
        PlayVideo,
        setPlayVideo,
        setLoading,
        loading,
    } = props;

    const [cameraDeleteModalShow, setCameraDeleteModalShow] = useState(false);
    const [cameraDetail, setCameraDetail] = useState(false);
    const [cameraId, setCameraId] = useState(false);
    const [addLiveCamModalShow, setAddLiveCamModalShow] = useState(false);
    const [updateCameraDetails, setUpdateCameraDetails] = useState(undefined)

    const dispatch = useDispatch();

    const handleClose = () => setAddLiveCamModalShow(false);
    const handleShow = () => setAddLiveCamModalShow(true);

    const playerRef = React.useRef(null);

    const deleteCamera = useSelector((state) => state.deleteCamera);
    const { loading: deleteCameraLoader, cameraDeleteSuccus } = deleteCamera;

    const LivecamData = useSelector((state) => state.LivecamData);
    const { livecamData: all_camera_data } = LivecamData;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    useEffect(() => {
        if (cameraDeleteSuccus) {
            toast(<CameraDeleteAlert camera_name={cameraDetail} />);
            setCameraDeleteModalShow(false);
            dispatch(getLivecamData());
            dispatch({ type: DELETE_CAMERA_RESET });
        }
    }, [cameraDeleteSuccus]);

    const videoType = {
        "ip camera": { url: PlayVideo, type: 'application/x-mpegURL' },
        "video": { url: `${API_ENDPOINT}${camera?.rtsp_url}`, type: 'video/mp4' }
    }

    const videoJsOptions = {
        poster: camera?.image,
        autoplay: true,
        loop: false,
        muted: true,
        liveui: true,
        controls: true,
        responsive: true,
        fluid: true,
        enableDocumentPictureInPicture: true,
        sources: [{
            src: videoType[camera?.camera_type?.type_label]?.url,
            type: videoType[camera?.camera_type?.type_label]?.type,
        }]
    };

    const handlePlayerReady = (player) => {
        // You can handle player events here, for example:
        playerRef.current = player;
        dispatch(getVideoRef(player));
    };

    const getStreamObject = async () => {
        setLoading(true)
        if (camera?.camera_type?.type_label === "ip camera") {
            const form = {
                "camera_id": camera?.camera_id,
                "rtsp_url": camera?.rtsp_url
            }
            const { data, status } = await startStreaming(form);
            if (data.error) {
                toast(<CommonErrorAlert message={data.error} />)
            } else if (status === 200) {
                setPlayVideo(data.m3u8_url)
            } else {
                toast(<CommonErrorAlert message={data} />)
            }
        } else {
            setPlayVideo(true)
        }
        setLoading(false)
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <h4 className="m-0 text-capitalize">{camera?.camera_name}</h4>
                    <div className="d-flex align-items-center ms-4" style={{ color: "var(--light-grey)", fontSize: "10px" }}>
                        {camera?.camera_type?.type_label !== 'video' && (
                            camera?.active_status === true ? (
                                <>
                                    <i
                                        className="ri-checkbox-blank-circle-fill me-1"
                                        style={{ color: "var(--success)" }}
                                    ></i>
                                    Active{" "}
                                </>
                            ) : (
                                <>
                                    <i
                                        className="ri-checkbox-blank-circle-fill me-1"
                                        style={{ color: "var(--danger)" }}
                                    ></i>
                                    Inactive{" "}
                                </>
                            ))
                        }
                        -
                        <i className="ri-camera-line ms-1"></i>
                        <span className='ms-1'>{camera?.camera_type?.type_label}</span>
                    </div>
                </div>
                <div className="d-flex">
                    <OverlayTrigger
                        trigger={['hover', 'click']}
                        overlay={
                            <Tooltip>
                                <AccessTooltip
                                    access={pageAccess?.['delete'].includes(userInfo?.user_role)}
                                    text={`Delete ${camera?.camera_name}`}
                                />
                            </Tooltip>
                        }
                    >
                        <span className="">
                            <button
                                onClick={() => {
                                    setCameraDeleteModalShow(true)
                                    setCameraId(camera?.camera_id)
                                    setCameraDetail(camera?.camera_name)
                                }}
                                disabled={!pageAccess?.['delete'].includes(userInfo?.user_role)}
                                style={
                                    {
                                        pointerEvents: !pageAccess?.['delete'].includes(userInfo?.user_role) ? 'none' : '',
                                    }
                                }
                                className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center ms-2"
                            >
                                <i className="ri-delete-bin-5-line "></i>
                            </button>
                        </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                        trigger={['hover', 'click']}
                        overlay={
                            <Tooltip>
                                <AccessTooltip
                                    access={pageAccess?.['update'].includes(userInfo?.user_role)}
                                    text={`Update ${camera?.camera_name}`}
                                />
                            </Tooltip>
                        }
                    >
                        <span className="">
                            <button
                                className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2"
                                onClick={() => {
                                    handleShow();
                                    setUpdateCameraDetails(camera);
                                }}
                                disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                                style={
                                    {
                                        pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '',
                                    }
                                }
                            >
                                <i className="ri-edit-line"></i>
                            </button>
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
            <div style={{ color: "var(--light-grey)", fontSize: "17px" }} className="mb-2">{camera?.group_name}</div>
            {

                <div className="position-relative camera-play-container">
                    {PlayVideo ? (
                        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                    ) : (
                        <Fragment>
                            <img
                                src={camera?.image || noCameraLarge}
                                alt="NWarch camera added image"
                                style={{ objectFit: camera?.image ? "fill" : "cover" }}
                                onError={e => e.target.src = noCameraLarge}
                            />
                            <div className="position-absolute top-50 start-50 translate-middle" onClick={getStreamObject}>
                                {loading ? (
                                    <div className="dot-pulse"></div>
                                ) : (
                                    <i className="ri-play-circle-fill fs-2 text-white cursor-pointer"></i>
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>

            }
            <div className="mt-3">
                {Array.isArray(camera?.services) && camera?.services?.map((service) => {
                    return (
                        <OverlayTrigger
                            trigger={['hover', 'click']}
                            key={service.id}
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-${service.id}`} className="camera-services-tooltip text-capitalize">
                                    {service.service_name}
                                </Tooltip>
                            }
                        >
                            <img
                                src={service.icon}
                                width={35}
                                height={35}
                                onError={(e) => e.target.src = noServiceIcon}
                                className="me-2 rounded-circle object-cover cursor-pointer"
                                alt={service.service_name}
                                key={service.id}
                            />
                        </OverlayTrigger>
                    )
                })}
            </div>
            <Modal size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={cameraDeleteModalShow}
            >
                <Modal.Body>
                    <DeleteConfirmModal
                        onHide={() => setCameraDeleteModalShow(false)}
                        item_name={cameraDetail}
                        dispatch_item={cameraDelete(cameraId)}
                        loading={deleteCameraLoader}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                scrollable={true}
                show={addLiveCamModalShow}
                size="lg"
                onHide={handleClose}
                centered
            >
                <Modal.Body>
                    <AddCamera
                        handleClose={(e) => e === true && handleClose()}
                        camera_data={updateCameraDetails}
                        token={userInfo?.token}
                        cameraGroupData={all_camera_data?.group_data}
                    />
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default LiveStreamingTab