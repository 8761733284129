import React from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { areObjectsEqual } from '../../../global/Helper';

function SetupTimeWiseSchedule(props) {
    const {
        data,
        index,
        CameraOption,
        CameraSchedule,
        DisabledDropdown,
        setCameraSchedule,
        setRefreshComponents,
    } = props

    const weeksOption = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
    ]

    function setCameraScheduleValues(key, value, index) {
        CameraSchedule[index][key] = value
        setRefreshComponents(prev => prev + 1)
    }

    const optionValueDecorator = (value) => {
        return (
            <div className='text-truncate' title={value}>{value}</div>
        )
    }

    return (
        <>
            <div className="d-flex align-items-end gap-3 mb-2">
                <div className='w-100'>
                    <label className={`${index > 0 && 'd-none'} fw-bolder d-block text-dark w-100`}>Set Days</label>
                    <div className="position-relative">
                        <Multiselect
                            onSelect={(e) => {
                                setCameraScheduleValues("days", e.includes("All Days") ? weeksOption : e, index)
                            }}
                            onRemove={(e) => {
                                setCameraScheduleValues("days", e, index)
                            }}
                            // selectedValues={data.days === true ? ["All Days"] : data.days}
                            selectedValues={data.days}
                            // selectionLimit={data.days === true ? 1 : -1}
                            isObject={false}
                            showCheckbox={true}
                            className={`filterBox bg-light-grey border border_radius_sm ${data.days.length !== 0 && 'remove-placeholder'}`}
                            // options={['All Days', ...weeksOption]}
                            options={weeksOption}
                            placeholder={data.days.length === 0 ? 'Select Days' : ''}
                            emptyRecordMsg="No Days Available."
                            avoidHighlightFirstOption={true}
                            showArrow
                            customArrow={<i className="fs-5 ri-arrow-down-s-fill"></i>}
                            customCloseIcon={<i className="ri-close-line ms-2"></i>}
                            optionValueDecorator={optionValueDecorator}
                            hideSelectedList
                        // hideSelectedList={data.days !== true}
                        />
                        {Array.isArray(data?.days) && (
                            <div className="search-wrapper searchWrapper position-absolute top-0 start-0 text-nowrap">
                                {data.days?.slice(0, 2)?.map((e, index2) => (
                                    <span key={`${index2 + 1}`} className="chip" title={e} style={{ width: "85px" }}>
                                        <span className='text-truncate'>{e}</span>
                                        <i className="custom-close ms-auto" onClick={() => {
                                            const value = data.days.filter((e, i) => i !== index2)
                                            setCameraScheduleValues("days", value, index)
                                        }}>
                                            <i className="ri-close-line ms-2"></i>
                                        </i>
                                    </span>
                                ))}
                                {data.days?.length > 2 && (
                                    <span key={index} className="chip">{data.days?.length - 2}+</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-100'>
                    <label className={`${index > 0 && 'd-none'} fw-bolder d-block text-dark w-100`}>Set Time</label>
                    <div className='d-flex'>
                        <input
                            value={data.time.split("-")[0]?.trim() || ''}
                            type='time'
                            className='bg-light-grey border rounded-left p-2 d-flex justify-content-between w-100'
                            id='camera-from-time'
                            onChange={(e) => {
                                const time = CameraSchedule[index].time
                                const value = `${e.target.value} - ${time ? time.split("-")[1].trim() : ''}`
                                CameraSchedule[index].time = value
                                setRefreshComponents(prev => prev + 1)
                            }}
                        />
                        <input
                            value={data.time.split("-")[1]?.trim() || ''}
                            type='time'
                            className='bg-light-grey border rounded-right p-2 d-flex justify-content-between w-100'
                            id='camera-to-time'
                            onChange={(e) => {
                                const time = CameraSchedule[index].time
                                const value = `${time ? time.split("-")[0].trim() : ''} - ${e.target.value}`
                                CameraSchedule[index].time = value
                                setRefreshComponents(prev => prev + 1)
                            }}
                        />
                    </div>
                </div>
                <div className='w-100'>
                    <label className={`${index > 0 && 'd-none'} fw-bolder d-block text-dark w-100`}>Set Cameras</label>
                    <div className="position-relative">
                        <Multiselect
                            onSelect={(e) => {
                                setCameraScheduleValues("camera_service_config_ids", e.includes("All Cameras") || e, index)
                            }}
                            onRemove={(e) => {
                                setCameraScheduleValues("camera_service_config_ids", e, index)
                            }}
                            selectedValues={data.camera_service_config_ids === true ? ["All Cameras"] : data.camera_service_config_ids}
                            selectionLimit={data.camera_service_config_ids === true ? 1 : -1}
                            isObject={false}
                            showCheckbox={true}
                            className={`filterBox bg-light-grey border border_radius_sm ${data.camera_service_config_ids?.length !== 0 && 'remove-placeholder'}`}
                            options={['All Cameras', ...CameraOption]}
                            placeholder={data.camera_service_config_ids?.length === 0 ? 'Select Cameras' : ''}
                            emptyRecordMsg="No Cameras Available."
                            avoidHighlightFirstOption={true}
                            showArrow
                            customArrow={<i className="fs-5 ri-arrow-down-s-fill"></i>}
                            customCloseIcon={<i className="ri-close-line ms-2"></i>}
                            optionValueDecorator={optionValueDecorator}
                            disable={DisabledDropdown}
                            hideSelectedList={data.camera_service_config_ids !== true}
                        />
                        {Array.isArray(data?.camera_service_config_ids) && (
                            <div className={`search-wrapper searchWrapper position-absolute top-0 start-0 text-nowrap ${DisabledDropdown && 'is-disable'}`}>
                                {data?.camera_service_config_ids?.slice(0, 2)?.map((e, index2) => (
                                    <span key={`${index2 + 1}`} className="chip text-truncate" title={e} style={{ width: "85px" }}>
                                        <span className='text-truncate'>{e}</span>
                                        <i className="custom-close ms-auto" onClick={() => {
                                            const value = data?.camera_service_config_ids?.filter((e, i) => i !== index2)
                                            setCameraScheduleValues("camera_service_config_ids", value, index)
                                        }}>
                                            <i className="ri-close-line ms-2"></i>
                                        </i>
                                    </span>
                                ))}
                                {data?.camera_service_config_ids?.length > 2 && (
                                    <span key={index} className="chip">{data?.camera_service_config_ids?.length - 2}+</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <button
                        // className={`bg-danger text-white fs-5 border_radius_xsm px-2 ${CameraSchedule.length === 1 && 'is-disable'}`}
                        className={`bg-danger text-white fs-5 border_radius_xsm px-2 border-0`}
                        // disabled={CameraSchedule.length === 1}
                        onClick={() => {
                            setCameraSchedule(CameraSchedule.filter((e, i) => index !== i))
                        }}
                    >
                        <i className="ri-delete-bin-5-line"></i>
                    </button>
                </div>
            </div>
            {CameraSchedule.slice(index + 1)?.map((obj2, i) => {
                if (areObjectsEqual(data, obj2)) {
                    return (
                        <div className={`text-danger mb-2 ${i > 0 && 'd-none'}`} key={`${i + 1}`}>
                            <i className="fal fa-exclamation-circle me-2"></i>
                            Above Schedule already exists. Please choose a different time slot or edit the above schedule.
                        </div>
                    );
                }
            })}
        </>
    )
}

export default SetupTimeWiseSchedule