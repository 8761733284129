
import { Modal } from "react-bootstrap";
import noInternet from '../images/no-internet.gif'

function NoInternetModal(props) {
    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body style={{ backgroundColor: "#fdfdfd", borderRadius: "4px" }}>
                <button className="position-absolute top-0 end-0 px-2 bg-white" onClick={props.onHide}>
                    <i className="ri-close-line fs-4"></i>
                </button>
                <div className="d-flex flex-column align-itmes-center justify-content-center mt-2 text-center">
                    <div className="mb-1">
                        <img src={noInternet} alt="calender icon" width='180' />
                    </div>
                    <h3 className="mb-0"><b>No Internet Connection</b></h3>
                    <p style={{ color: "rgba(51, 51, 51, 0.7)" }}>Could not connect to the internet. <br></br>Please check your network.</p>
                </div>
                <div className="d-flex align-itmes-center justify-content-center mt-4">
                    <button
                        className="btn px-3 py-2 bg-warning text-dark"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        TRY AGAIN
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NoInternetModal;
