import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import AddSelectDropdown from './AddSelectDropdown'
import { toast } from "react-toastify";
import {
    getUserDepartment,
    getUserLocation,
    createUserDepartment,
    createUserLocation,
    postMasterListUserData,
    updateMasterListUserData,
    getMasterListUserData,
} from '../../../actions/organizationActions/masterListActions';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'antd';
import { POST_MASTER_LIST_USER_DATA_RESET, UPDATE_MASTER_LIST_USER_DATA_RESET } from '../../../constants/organizationConstant/masterListConstant';
import { DefaultAddAlert, CommonErrorAlert, DefaultUpdateAlerts } from '../../../components/ToastAlert'

function AddUser({ closeAddUserModal, UserDetails }) {

    const [validated, setValidated] = useState(false);
    const [DepartmentValues, setDepartmentValues] = useState("");
    const [LocationValues, setLocationValues] = useState("");
    const [UserName, setUserName] = useState("");
    const [Email, setEmail] = useState("");
    const [Contact, setContact] = useState("");
    const [Password, setPassword] = useState('');
    const [DepartmentError, setDepartmentError] = useState(false);
    const [LocationError, setLocationError] = useState(false);
    const [SelectedDepartmentValue, setSelectedDepartmentValue] = useState("");
    const [SelectedLocationValue, setSelectedLocationValue] = useState("");
    const [DepartmentOptions, setDepartmentOptions] = useState([]);
    const [DepartmentRefresh, setDepartmentRefresh] = useState(0);
    const [LocationOptions, setLocationOptions] = useState([]);
    const [LocationRefresh, setLocationRefresh] = useState(0);
    const [PasswordShow, setPasswordShow] = useState(false);
    const [ConfirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [ConfirmPasswordValidator, setConfirmPasswordValidator] = useState(false);

    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const masterListUserDataPost = useSelector((state) => state.masterListUserDataPost);
    const { error: postMasterListUserError, loading: postMasterListUserLoad, success: postMasterListUserSuccess } = masterListUserDataPost;

    const masterListUserDataUpdate = useSelector((state) => state.masterListUserDataUpdate);
    const { error: updateMasterListUserError, loading: updateMasterListUserLoad, success: updateMasterListUserSuccess } = masterListUserDataUpdate;

    const userId = UserDetails?.id;

    useEffect(() => {
        if (UserDetails) {
            setDepartmentValues(UserDetails.department)
            setLocationValues(UserDetails.location)
            setSelectedDepartmentValue(UserDetails.department)
            setSelectedLocationValue(UserDetails.location)
            setUserName(UserDetails.username)
            setContact(UserDetails.phone)
            setEmail(UserDetails.email)
        }
    }, [UserDetails])

    useEffect(() => {
        if (postMasterListUserSuccess) {
            dispatch(getMasterListUserData())
            closeAddUserModal()
            toast(<DefaultAddAlert name={UserName} />);
            dispatch({ type: POST_MASTER_LIST_USER_DATA_RESET })
        }
        if (postMasterListUserError) {
            toast(<CommonErrorAlert message={postMasterListUserError} />);
            dispatch({ type: POST_MASTER_LIST_USER_DATA_RESET })
        }
        if (updateMasterListUserError) {
            toast(<CommonErrorAlert message={updateMasterListUserError} />);
            dispatch({ type: UPDATE_MASTER_LIST_USER_DATA_RESET })
        }
        if (updateMasterListUserSuccess) {
            dispatch(getMasterListUserData())
            closeAddUserModal()
            dispatch({ type: UPDATE_MASTER_LIST_USER_DATA_RESET })
            toast(<DefaultUpdateAlerts name={UserName} />);
        }

    }, [dispatch, toast, postMasterListUserSuccess, postMasterListUserError, updateMasterListUserError, updateMasterListUserSuccess])

    const contactOnChange = (e) => {
        if (Number.isInteger(Number(e.target.value))) {
            if ((e.target.value).trim().length > 0) {
                setContact(Number(e.target.value))
            } else {
                setContact('')
            }
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false || DepartmentValues.length === 0 || LocationValues.length === 0) {
            event.stopPropagation();
            DepartmentValues.length === 0 ? setDepartmentError(true) : setDepartmentError(false);
            LocationValues.length === 0 ? setLocationError(true) : setLocationError(false);
        } else {

            let user_details = {
                username: UserName,
                email: Email,
                phone: Contact,
                department: DepartmentValues,
                location: LocationValues,
                password: Password,
            }

            if (!userId) {
                dispatch(postMasterListUserData(user_details))
            } else {
                user_details.id = userId
                dispatch(updateMasterListUserData(user_details, userId))
            }
        }
        setValidated(true);
    };

    useEffect(() => {
        async function startFetching() {
            setDepartmentOptions([])
            const { data } = await getUserDepartment(userInfo?.token)
            if (!ignore) {
                setDepartmentOptions(data)
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [DepartmentRefresh])

    useEffect(() => {
        async function startFetching() {
            setLocationOptions([])
            const { data } = await getUserLocation(userInfo?.token)
            if (!ignore) {
                setLocationOptions(data)
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [LocationRefresh])

    return (
        <div>
            <Modal.Header className="position-relative justify-content-center insight-header" closeButton>
                <Modal.Title className="fs-4">
                    {userId ? "Update User" : "Add User"}
                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='px-3 add-user-form'>
                <Modal.Body>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" className='mb-4'>
                            <Form.Label className='m-0'>User Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter User Name"
                                className='input_box'
                                name='user_name'
                                value={UserName}
                                onChange={e => setUserName(e.target.value)}
                                maxLength={60}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the user name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4'>
                            <Form.Label className='m-0'>Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter Email"
                                className='input_box'
                                name='email'
                                value={Email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4 position-relative'>
                            <Form.Label className='m-0 fw-bold text-dark'>Password</Form.Label>
                            <Form.Control
                                required
                                type={PasswordShow ? 'text' : 'password'}
                                placeholder="Enter Password"
                                className='input_box'
                                onChange={e => setPassword(e.target.value)}
                                maxLength={60}
                            />
                            <i
                                onClick={() => setPasswordShow(!PasswordShow)}
                                style={{ top: "30px" }}
                                className={`${PasswordShow ? 'ri-eye-line' : 'ri-eye-close-line'} fs-5 position-absolute text-dark end-0 pe-4`}
                            ></i>
                            <Form.Control.Feedback type="invalid">
                                Please enter the password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4 position-relative'>
                            <Form.Label className='m-0 fw-bold text-dark'>Confirm Password</Form.Label>
                            <Form.Control
                                required
                                type={ConfirmPasswordShow ? 'text' : 'password'}
                                placeholder="Enter Confirm Password"
                                className='input_box'
                                onChange={e => {
                                    setConfirmPasswordValidator(e.target.value !== Password)
                                }}
                                isInvalid={ConfirmPasswordValidator}
                                maxLength={60}
                            />
                            <i
                                onClick={() => setConfirmPasswordShow(!ConfirmPasswordShow)}
                                style={{ top: "30px" }}
                                className={`${ConfirmPasswordShow ? 'ri-eye-line' : 'ri-eye-close-line'} fs-5 position-absolute text-dark end-0 pe-4`}
                            ></i>
                            <Form.Control.Feedback type="invalid">
                                {ConfirmPasswordValidator ? "Password does not match" : "Please enter the confirm password."}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4'>
                            <Form.Label className='m-0'>Contact</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Contact"
                                className='input_box'
                                name='phone'
                                value={Contact}
                                onChange={contactOnChange}
                                maxLength={12}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter the contact number.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4'>
                            <Form.Label className='m-0'>Department</Form.Label>
                            <AddSelectDropdown
                                placeholder="Select or Enter Department"
                                required={true}
                                inValidMessage="Please select the department."
                                addBtnText="Add Department"
                                option={DepartmentOptions}
                                onSelectValue={DepartmentValues}
                                onSelect={e => setDepartmentValues(e.name)}
                                onChange={(e) => {
                                    setSelectedDepartmentValue(e)
                                    setDepartmentError(false)
                                    setDepartmentValues('')
                                }}
                                selectedValue={SelectedDepartmentValue}
                                isInvalid={DepartmentError}
                                handleAddBtn={async () => {
                                    const { status } = await createUserDepartment(userInfo?.token, SelectedDepartmentValue)
                                    if (status == 200) {
                                        setSelectedDepartmentValue("")
                                        setDepartmentRefresh((prev) => prev + 1)
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className='mb-4'>
                            <Form.Label className='m-0'>Location</Form.Label><br />
                            <AddSelectDropdown
                                placeholder="Select or Enter Location"
                                required={true}
                                inValidMessage="Please select the location."
                                addBtnText="Add Location"
                                onSelectValue={LocationValues}
                                option={LocationOptions}
                                onChange={(e) => {
                                    setLocationValues('')
                                    setSelectedLocationValue(e)
                                    setLocationError(false)
                                }}
                                selectedValue={SelectedLocationValue}
                                onSelect={e => setLocationValues(e.name)}
                                isInvalid={LocationError}
                                handleAddBtn={async () => {
                                    const { status } = await createUserLocation(userInfo?.token, SelectedLocationValue)
                                    if (status == 200) {
                                        setSelectedLocationValue("")
                                        setLocationRefresh((prev) => prev + 1)
                                    }
                                }}
                            />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='insight-btn-container'>
                    <button className="btn me-3 px-3 py-2 border close" type='button' onClick={() => closeAddUserModal()}>
                        Cancel
                    </button>
                    {(postMasterListUserLoad || updateMasterListUserLoad) ? <Button type="primary" className='bg-warning border text-dark' size="large" loading>
                        {userId ? "Update" : "Add"}
                    </Button> :
                        <button
                            className="btn px-4 py-2 bg-warning border text-dark"
                            type='submit'
                            disabled={ConfirmPasswordValidator}
                        >
                            {userId ? "Update" : "Add"}
                        </button>
                    }
                </Modal.Footer>
            </Form>
        </div>
    )
}

export default AddUser