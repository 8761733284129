import React from 'react'
import { Form } from 'react-bootstrap';

function ConfigureName({ onChange, value }) {
    return (
        <div className='mb-4'>
            <Form.Label className='m-0'>Configuration Name</Form.Label>
            <Form.Control
                style={{ fontSize: "0.70rem" }}
                type="text"
                className="alert-input"
                maxLength={60}
                // isInvalid={}
                onChange={onChange}
                // min="0"
                placeholder="Enter Configuration Name"
                value={value}
            />
        </div>
    )
}

export default ConfigureName