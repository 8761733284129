import React, { useState, useRef } from 'react'
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar';
import { Form } from "react-bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import YearSliderBar from './YearSliderBar';
import { monthNames } from '../../../global/Helper';

function MonthComparison(props) {
    const {
        StartDate,
        setStartDate,
        EndDate,
        setEndDate,
        StartYear,
        setStartYear,
        EndYear,
        setEndYear,
    } = props

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const [CalendarType, setCalendarType] = useState('start');
    const startMonthName = monthNames[StartDate.set('year', StartYear).month()]
    const endMonthName = monthNames[EndDate.set('year', EndYear).month()]

    const DisableStartMonth = (date) => {
        return date.startOf('month').$d.toLocaleDateString() === EndDate.startOf('month').$d.toLocaleDateString();
    }

    const DisableEndMonth = (date) => {
        return date.startOf('month').$d.toLocaleDateString() === StartDate.startOf('month').$d.toLocaleDateString();
    }

    return (
        <div>
            <h6>Select Months for Comparison</h6>
            <div className="d-flex align-items-center justify-content-center gap-2" style={{ width: "320px" }}>
                <Form.Control
                    ref={startDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={startMonthName || ""}
                    placeholder='Select Month'
                    onFocus={() => setCalendarType('start')}
                />
                -
                <Form.Control
                    ref={endDateRef}
                    type="text"
                    readOnly
                    className='compare-input'
                    value={endMonthName || ""}
                    placeholder='Select Month'
                    onFocus={() => setCalendarType('end')}
                    autoFocus
                />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='month-comparison-container my-4 pb-2'>

                    {CalendarType === 'start' ? (
                        <>
                            <YearSliderBar
                                setCurrentYear={setStartYear}
                                CurrentYear={StartYear}
                            />
                            <MonthCalendar
                                shouldDisableMonth={DisableStartMonth}
                                value={StartDate}
                                onChange={(e) => {
                                    setStartDate(e)
                                    startDateRef.current.focus()
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <YearSliderBar
                                setCurrentYear={setEndYear}
                                CurrentYear={EndYear}
                            />
                            <MonthCalendar
                                shouldDisableMonth={DisableEndMonth}
                                value={EndDate}
                                onChange={(e) => {
                                    setEndDate(e)
                                    endDateRef.current.focus()
                                }}
                            />
                        </>
                    )}
                </div>
            </LocalizationProvider>
        </div>
    )
}

export default MonthComparison