import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function LabelTooltip({ content, placement }) {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip id="tooltip-info-label-top" className='label-tooltip'>
                    <div className='tooltip-container'>
                        <p className='m-0'>
                            {content}
                        </p>
                    </div>
                </Tooltip>
            }
        >
            <i className="px-2 ri-information-line fs-6 cursor-pointer accuracy-info-icon"></i>
        </OverlayTrigger>
    )
}

export default LabelTooltip