import {
    GET_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    GET_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    GET_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_RESET,

    POST_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    POST_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    POST_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
    POST_ROLES_AND_RESPONSIBILITY_DATA_RESET,

    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_RESET,

    DELETE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    DELETE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    DELETE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
    DELETE_ROLES_AND_RESPONSIBILITY_DATA_RESET,

} from '../../constants/organizationConstant/rolesAndResponsibilityConstants'

export const getRolesAndResponsibilityDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_ROLES_AND_RESPONSIBILITY_DATA_REQUEST:
            return { loading: true };

        case GET_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_ROLES_AND_RESPONSIBILITY_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};

export const getSingleRolesAndResponsibilityDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST:
            return { loading: true };

        case GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_FAIL:
            return { loading: false, error: action.error };

        case GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const postRolesAndResponsibilityDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case POST_ROLES_AND_RESPONSIBILITY_DATA_REQUEST:
            return { loading: true };

        case POST_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS:
            return { success: true, loading: false };

        case POST_ROLES_AND_RESPONSIBILITY_DATA_FAIL:
            return { loading: false, error: action.error };

        case POST_ROLES_AND_RESPONSIBILITY_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const updateRolesAndResponsibilityDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case UPDATE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST:
            return { loading: true };

        case UPDATE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS:
            return { success: true, loading: false };

        case UPDATE_ROLES_AND_RESPONSIBILITY_DATA_FAIL:
            return { loading: false, error: action.error };

        case UPDATE_ROLES_AND_RESPONSIBILITY_DATA_RESET:
            return {};

        default:
            return state;
    }
};

export const deleteRolesAndResponsibilityDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case DELETE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST:
            return { loading: true };

        case DELETE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS:
            return { success: true, loading: false };

        case DELETE_ROLES_AND_RESPONSIBILITY_DATA_FAIL:
            return { loading: false, error: action.error };

        case DELETE_ROLES_AND_RESPONSIBILITY_DATA_RESET:
            return {};

        default:
            return state;
    }
};