import React from 'react'
import Popover from '@mui/material/Popover';

function BackupHistory(props) {
    const {
        data,
    } = props

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <button
                aria-owns={open ? 'backup-history-popover' : undefined}
                type="button"
                onClick={handlePopoverOpen}
                className="d-flex align-items-center btn-light-grey btn text-nowrap">
                <i className="ri-history-line me-2"></i>
                Backup History
            </button>
            <Popover
                onClose={handlePopoverClose}
                id="backup-history-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {(data?.length === 0 || !data) ? (
                    <div className={`text-truncate py-3 text-center backup-history-heading`}>
                        No Backup History Found.
                    </div>
                ) : (
                    data?.map((e, index) => (
                        <div
                            key={`${index + 1}`}
                            className={`text-truncate py-3 backup-history-heading ${index + 1 !== data?.length && 'border-bottom'}`}
                            title={e.split("_").join(" ")}
                        >
                            {e.split("_").join(" ")}
                        </div>
                    ))
                )}
            </Popover>
        </div>
    )
}

export default BackupHistory