import React from 'react'
import reportSummaryLoader from '../../../images/report-summay/report-summary-loader.gif'

function AnalyticsLoading() {
    return (
        <div style={{ height: "calc(100vh - 200px)" }} className='d-flex justify-content-center align-items-center'>
            <div className='text-center'>
                <img src={reportSummaryLoader} alt="loading" height={210} width={280} />
                <h5><b>Showing Report Summary</b></h5>
                <p>Please wait for a moment while the data is being<br />fetched</p>
            </div>
        </div>
    )
}

export default AnalyticsLoading