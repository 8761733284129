export const GET_MASTER_LIST_USER_DATA_REQUEST = 'GET_MASTER_LIST_USER_DATA_REQUEST'
export const GET_MASTER_LIST_USER_DATA_SUCCESS = 'GET_MASTER_LIST_USER_DATA_SUCCESS'
export const GET_MASTER_LIST_USER_DATA_FAIL = 'GET_MASTER_LIST_USER_DATA_FAIL'


export const POST_MASTER_LIST_USER_DATA_REQUEST = 'POST_MASTER_LIST_USER_DATA_REQUEST'
export const POST_MASTER_LIST_USER_DATA_SUCCESS = 'POST_MASTER_LIST_USER_DATA_SUCCESS'
export const POST_MASTER_LIST_USER_DATA_FAIL = 'POST_MASTER_LIST_USER_DATA_FAIL'
export const POST_MASTER_LIST_USER_DATA_RESET = 'POST_MASTER_LIST_USER_DATA_RESET'


export const UPDATE_MASTER_LIST_USER_DATA_REQUEST = 'UPDATE_MASTER_LIST_USER_DATA_REQUEST'
export const UPDATE_MASTER_LIST_USER_DATA_SUCCESS = 'UPDATE_MASTER_LIST_USER_DATA_SUCCESS'
export const UPDATE_MASTER_LIST_USER_DATA_FAIL = 'UPDATE_MASTER_LIST_USER_DATA_FAIL'
export const UPDATE_MASTER_LIST_USER_DATA_RESET = 'UPDATE_MASTER_LIST_USER_DATA_RESET'


export const DELETE_MASTER_LIST_USER_DATA_REQUEST = 'DELETE_MASTER_LIST_USER_DATA_REQUEST'
export const DELETE_MASTER_LIST_USER_DATA_SUCCESS = 'DELETE_MASTER_LIST_USER_DATA_SUCCESS'
export const DELETE_MASTER_LIST_USER_DATA_FAIL = 'DELETE_MASTER_LIST_USER_DATA_FAIL'
export const DELETE_MASTER_LIST_USER_DATA_RESET = 'DELETE_MASTER_LIST_USER_DATA_RESET'