import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { capitalizeFirstLater, monthNames } from '../../../global/Helper';
import noDataFound from '../../../images/report-summay/no-data-found.gif'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function BarChart(props) {
    const {
        data,
        options,
        compareScreen,
        xAxisName,
        MonthsName,
        setChartMonths,
        ChartMonths,
    } = props

    const [GraphData, setGraphData] = useState(data)
    const [selectedLabel, setSelectedLabel] = useState([])

    useEffect(() => {
        setGraphData({
            labels: data.labels,
            datasets: data?.datasets?.filter(e => !selectedLabel.includes(e.label))
        })
    }, [selectedLabel, data])

    const datasetHandler = (label) => {
        if (selectedLabel.includes(label)) {
            setSelectedLabel(selectedLabel.filter(e => e !== label))
        } else if (compareScreen) {
            if (data?.datasets?.length - 2 !== selectedLabel.length * 2) {
                setSelectedLabel([...selectedLabel, label])
            }
        } else if (data?.datasets?.length - 1 !== selectedLabel.length) {
            setSelectedLabel([...selectedLabel, label])
        }
    }

    const countDataset = data?.datasets?.length / 2

    let xAxis = xAxisName === "day" ? "Time" : xAxisName

    return (
        <div className={!compareScreen ? 'chart-container-border' : ''}>
            {!compareScreen && (
                <h6 className='fw-bold mt-3 ms-3 mb-4'>Events Generated</h6>
            )}
            <div className="d-flex">
                <div className='chart-y-axis-tag text-center position-relative'>
                    <span className='position-absolute'>No. of Events</span>
                </div>
                <div className='position-relative' style={{
                    padding: "45px 15px 10px 20px",
                    width: "calc(100% - 30px)"
                }}>
                    {!compareScreen ? (
                        <div className="bar-chart-legends d-flex gap-4 position-absolute top-0 end-0 me-4">
                            {data?.datasets?.length > 1 && data.datasets?.map((e, i) => (
                                <div className='d-flex align-items-center cursor-pointer' onClick={() => datasetHandler(e.label)} key={`${i + 1}`}>
                                    <div className='me-2 legend-box' style={{ backgroundColor: e.backgroundColor }}></div>
                                    {selectedLabel.includes(e.label) ? (
                                        <strike className="opacity">{e.label} ({e.data.reduce((a, b) => a + b)})</strike>
                                    ) : (
                                        <span>{e.label} ({e.data.reduce((a, b) => a + b)})</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="bar-chart-legends d-flex flex-column gap-2 position-absolute top-0 end-0 me-4">
                            <div className="d-flex gap-4">
                                {data.datasets.length > 1 && data.datasets.slice(0, countDataset)?.map((e, i) => (
                                    <div className='d-flex align-items-center cursor-pointer' onClick={() => datasetHandler(e.label)} key={`${i + 1}`}>
                                        <div className='me-2 legend-box' style={{ backgroundColor: e.backgroundColor }}></div>
                                        {selectedLabel.includes(e.label) ? (
                                            <strike className="opacity">{e.label} ({e.data.reduce((a, b) => a + b)})</strike>
                                        ) : (
                                            <span>{e.label} ({e.data.reduce((a, b) => a + b)})</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex gap-4">
                                {data.datasets.length > 1 && data.datasets.slice(countDataset)?.map((e, i) => (
                                    <div className='d-flex align-items-center cursor-pointer' onClick={() => datasetHandler(e.label)} key={`${i + 1}`}>
                                        <div className='me-2 legend-box' style={{ backgroundColor: e.backgroundColor }}></div>
                                        {selectedLabel.includes(e.label) ? (
                                            <strike className="opacity">{e.label} ({e.data.reduce((a, b) => a + b)})</strike>
                                        ) : (
                                            <span>{e.label} ({e.data.reduce((a, b) => a + b)})</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {GraphData?.datasets ? (
                        <Bar options={options} data={GraphData} />
                    ) : (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <img src={noDataFound} className="opacity" alt="" height={"192px"} width={"192px"} />
                                <h4 className="opacity fw-bold fs-6">No Results For this Month</h4>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='chart-x-axis-tag text-center'>
                {xAxisName === "custom" ? (
                    <div className='d-flex align-items-center gap-3 justify-content-center'>
                        <i
                            className={`fas fa-caret-left cursor-pointer text-center p-2 ${ChartMonths === 0 && 'is-disable'}`}
                            onClick={() => {
                                if (ChartMonths !== 0) {
                                    setChartMonths(prev => prev - 1)
                                }
                            }}
                        >
                        </i>
                        <span className="text-nowrap">
                            Dates of {monthNames[MonthsName[ChartMonths].$M]}
                        </span>
                        <i
                            className={`fas fa-caret-right cursor-pointer p-2 ${MonthsName.length === (ChartMonths + 1) && 'is-disable'}`}
                            onClick={() => {
                                if (MonthsName.length !== (ChartMonths + 1)) {
                                    setChartMonths(prev => prev + 1)
                                }
                            }}
                        >
                        </i>
                    </div>
                ) : (
                    capitalizeFirstLater(xAxis)
                )}
            </div>
        </div>
    );
}

export default BarChart