import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTimeWiseCameraScheduleData, updateTimeWiseCameraScheduleData } from '../../../actions/servicesActions';
import { UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET } from '../../../constants/servicesConstant';
import { CommonErrorAlert, CommonSuccessAlert } from '../../../components/ToastAlert';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import SetupTimeWiseSchedule from './SetupTimeWiseSchedule';
import { areObjectsEqual } from '../../../global/Helper';



function TimeWiseServiceModal(props) {

    const dispatch = useDispatch();

    const {
        onHide,
        serviceName,
        DisabledDropdown,
        CameraServiceConfigId,
        CameraOption,
    } = props

    const TimeWiseCameraScheduleGet = useSelector((state) => state.TimeWiseCameraScheduleGet);
    const { success: getCameraScheduleSuccess/*, loading: getCameraScheduleLoad, error: getCameraScheduleError*/ } = TimeWiseCameraScheduleGet;

    const TimeWiseCameraScheduleUpdate = useSelector((state) => state.TimeWiseCameraScheduleUpdate);
    const { loading: updateCameraScheduleLoad, success: updateCameraScheduleSuccess, error: updateCameraScheduleError } = TimeWiseCameraScheduleUpdate;

    const cameraScheduleData = {
        "days": [],
        "time": "",
        "camera_service_config_ids": DisabledDropdown ? [...CameraOption] : []
    }

    const [CameraSchedule, setCameraSchedule] = useState([{ ...cameraScheduleData }]);
    const [RefreshComponents, setRefreshComponents] = useState(0);
    const [DisabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        if (getCameraScheduleSuccess?.service_activation_schedule?.length > 0) {
            setCameraSchedule(getCameraScheduleSuccess?.service_activation_schedule)
        }
    }, [getCameraScheduleSuccess])

    useEffect(() => {
        if (updateCameraScheduleSuccess) {
            dispatch(getTimeWiseCameraScheduleData(CameraServiceConfigId));
            toast(<CommonSuccessAlert message={<div>Successfully schedule added for <b>{serviceName}</b></div>} />);
            dispatch({ type: UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET })
            onHide()
        }
        if (updateCameraScheduleError) {
            toast(<CommonErrorAlert message={updateCameraScheduleError} />);
            dispatch({ type: UPDATE_TIME_WISE_CAMERA_SCHEDULE_RESET })
        }
    }, [dispatch, updateCameraScheduleSuccess, updateCameraScheduleError])

    useEffect(() => {
        outerLoop: for (let i = 0; i < CameraSchedule.length; i++) {
            const scheduleData = CameraSchedule[i]
            const timeValues = scheduleData.time.split("-")

            if (scheduleData.camera_service_config_ids?.length !== 0 && scheduleData.days?.length !== 0 && timeValues[0]?.trim().length !== 0 && timeValues[1]?.trim().length !== 0) {
                setDisabledButton(false)

                for (let j = i + 1; j < CameraSchedule.length; j++) {
                    if (areObjectsEqual(CameraSchedule[i], CameraSchedule[j])) {
                        setDisabledButton(true)
                        break outerLoop;
                    }
                }
            } else {
                setDisabledButton(true)
                break;
            }
        }
    }, [CameraSchedule, RefreshComponents])

    const saveTimeWiseData = () => {
        const formData = {
            "flag_of_global": !DisabledDropdown,
            "service_config_id": CameraServiceConfigId,
            "service_activation_schedule": CameraSchedule,
        }
        dispatch(updateTimeWiseCameraScheduleData(formData));
    }

    return (
        <div>
            <Modal.Header className='border-none px-4' closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='fw-bold'>
                    Setup Time for {serviceName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4'>
                <div className='d-flex align-items-center fs-6'>
                    {/* <div className='opacity me-2'>Total Intrusion Detection Hours : </div><span className='bold'>00</span> */}
                    <div className='ms-auto'>
                        <button
                            disabled={DisabledButton}
                            className='border_radius_xsm add-schedule-btn btn bg-dark px-2 text-white'
                            onClick={() => setCameraSchedule([...CameraSchedule, cameraScheduleData])}
                        >
                            <i className="fas fa-plus me-2"></i>
                            Add Schedule
                        </button>
                    </div>
                </div>
                <div className='mt-4 pb-4'>
                    <div className='schedule-container'>
                        <div className="border-bottom pb-4">
                            {CameraSchedule?.map((e, index) => (
                                <SetupTimeWiseSchedule
                                    key={`${index + 1}`}
                                    index={index}
                                    data={e}
                                    CameraSchedule={CameraSchedule}
                                    DisabledDropdown={DisabledDropdown}
                                    setCameraSchedule={setCameraSchedule}
                                    RefreshComponents={RefreshComponents}
                                    setRefreshComponents={setRefreshComponents}
                                    CameraOption={CameraOption}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-none px-4'>
                {updateCameraScheduleLoad ? (
                    <Button
                        type="primary"
                        className='bg-warning border text-dark border_radius_sm'
                        size="large"
                        loading>
                        Save
                    </Button>
                ) : (
                    <button
                        className='btn bg-warning px-3'
                        onClick={saveTimeWiseData}
                        disabled={DisabledButton}
                    >
                        Save
                    </button>
                )}
            </Modal.Footer>
        </div>
    )
}

export default TimeWiseServiceModal