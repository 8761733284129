import axios from 'axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,

    USER_FORGET_PASSWORD_REQUEST,
    USER_FORGET_PASSWORD_SUCCESS,
    USER_FORGET_PASSWORD_FAIL,

    USER_NEW_PASSWORD_REQUEST,
    USER_NEW_PASSWORD_SUCCESS,
    USER_NEW_PASSWORD_FAIL,

    USER_LIVE_CAM_ACCESS_REQUEST,
    USER_LIVE_CAM_ACCESS_SUCCESS,
    USER_LIVE_CAM_ACCESS_FAIL,

    REMOVE_LIVE_CAM_ACCESS_REQUEST,
    REMOVE_LIVE_CAM_ACCESS_SUCCESS,
    REMOVE_LIVE_CAM_ACCESS_FAIL,

    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,

    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAIL,

    CREATE_USER_EMAIL_VERIFICATION_REQUEST,
    CREATE_USER_EMAIL_VERIFICATION_SUCCESS,
    CREATE_USER_EMAIL_VERIFICATION_FAIL,

} from '../constants/userConstants'
import { API_ENDPOINT } from '../constants/apiEndpoint'
import { getAPI, postAPI } from '../global/ApiManager'
import { RESET_LIVECAM_VIDEO_REF } from '../constants/livecamConstant'

export const login = (email, password, otp, activation_key) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const infoToSend = {
            'email': email.toLowerCase(),
            'password': password,
            'otp': otp || null,
            'activation_key': activation_key || null,
        }

        const { data } = await axios.post(`${API_ENDPOINT}/user/login/`, infoToSend, config);

        localStorage.setItem('userInfo', JSON.stringify(data))

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error?.data
        })
    }
}

export const logout = () => async (dispatch, getState) => {

    const {
        userLogin: { userInfo },
    } = getState()

    const {
        videoRefGet: { videoRefObj },
    } = getState()

    // For handling the live streaming request
    if (videoRefObj && !videoRefObj.isDisposed()) {
        videoRefObj.dispose();
        dispatch({ type: RESET_LIVECAM_VIDEO_REF })
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
        }
    }

    localStorage.removeItem('userInfo')
    dispatch({ type: USER_LOGOUT })

    const userDetails = {
        'id': userInfo?.id,
        'email': userInfo?.username?.toLowerCase()
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    if (userInfo?.id) {
        await axios.post(
            `${API_ENDPOINT}/user/logout/`, userDetails,
            config
        )
    }
}

export const register = (isAdmin, email, password, activation_key, otp) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/user/register/`,
            { 'isAdmin': isAdmin, 'email': email, 'password': password, 'activation_key': activation_key, 'otp': otp },
            config
        )

        localStorage.setItem('userInfo', JSON.stringify(data))

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: JSON.stringify(error?.data)
        })
    }
}

export const listUsers = (key) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get_all_users_list?search=${key}`, dispatch)

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload: error
                ? error.data.detail
                : error.message,
        })
    }
}

export const forgetPassword = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGET_PASSWORD_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/password_reset/`,
            { 'email': email.toLowerCase() },
            config
        )

        dispatch({
            type: USER_FORGET_PASSWORD_SUCCESS,
            payload: data
        })



    } catch (error) {
        dispatch({
            type: USER_FORGET_PASSWORD_FAIL,

            payload: error
                ? error.data.email
                : error.message,
        })
    }
}


export const newPassword = (token, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_NEW_PASSWORD_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/password_reset/confirm/`,
            { 'token': token, 'password': password },
            config
        )

        dispatch({
            type: USER_NEW_PASSWORD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_NEW_PASSWORD_FAIL,

            payload: error
                ? error.data.password
                : "Token is expire go and generate new token"
        })
    }
}


export const userLiveCamAccess = (id, page, key) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIVE_CAM_ACCESS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            params: {
                page: page,
                search: key,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get_user_access_camera/${id}`, dispatch)

        dispatch({
            type: USER_LIVE_CAM_ACCESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_LIVE_CAM_ACCESS_FAIL,

            payload: error
                ? error.data.email
                : error.message,
        })
    }
}


export const DeleteUser = (user_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/delete_normal_user/${user_id}`, dispatch)

        dispatch({
            type: USER_DELETE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,

            payload: error
                ? error.data.email
                : error.message,
        })
    }
}


export const userRemoveLiveCamAccess = (id, profileId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REMOVE_LIVE_CAM_ACCESS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/remove_camera_access/${id}/${profileId}`, dispatch)

        dispatch({
            type: REMOVE_LIVE_CAM_ACCESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: REMOVE_LIVE_CAM_ACCESS_FAIL,

            payload: error
                ? error.data.email
                : error.message,
        })
    }
}


export const createNormalUser = (userData) => async (dispatch, getState) => {
    try {
        const formData = new FormData()

        formData.append('user_id', userData?.user_id)
        formData.append('username', userData.userName)
        formData.append('email_id', userData.emailId)
        formData.append('password', userData.password)
        formData.append('conf_password', userData.confirmPassword)
        formData.append('emp_code', userData.employeeCode)
        formData.append('profile_pic', userData.profile_pic)
        formData.append('cam_access', JSON.stringify(userData.cam_access))
        formData.append('remove_camera', JSON.stringify(userData.remove_camera))

        dispatch({
            type: CREATE_USER_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/create_normal_user`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: CREATE_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_USER_FAIL,

            payload: error
                ? error.data.message
                : error.message,
        })
    }
}


export const createUserEmailVerification = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_USER_EMAIL_VERIFICATION_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/user/send_verification_code/`,
            { 'email': email },
            config
        )

        dispatch({
            type: CREATE_USER_EMAIL_VERIFICATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_USER_EMAIL_VERIFICATION_FAIL,

            payload: error
                ? error.data.email
                : error.message,
        })
    }
}


export const emailCheck = async (email) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(
            `${API_ENDPOINT}/user/check_email/`,
            { 'email': email },
            config
        )

        return { 'status': true, 'data': data.message, 'first_time_login_verified': data.first_time_login_verified }
    } catch (error) {
        let errors = error
            ? error?.data?.message
            : error?.message
        return { 'status': false, 'data': errors }

    }
}


export const checkOnlineStatus = async () => {
    try {
        await fetch('https://www.google.com', { mode: 'no-cors' });
        return true;
    } catch (error) {
        return false;
    }
};

export const invoiceRoles = async () => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.get(
            `${API_ENDPOINT}/invoice_automation/invoice_roles/`,
            config,
        );
        return { 'data': data, status: 200 }
    } catch (error) {
        return { 'data': error, status: 400 }
    }
};