import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap';
import { createCameraGroup, getCameraGroups } from '../../actions/livecamAction'
import { useSelector } from "react-redux";

function CameraGroupSelect({ optionId, placeholder, selectOptionId, cameraGroupValidation, setCameraGroupValidation, defaultValue }) {

    const optionsRef = useRef(null);
    const [optionValue, setOptionValue] = useState("");
    const [options, setOptions] = useState([]);
    const [invalidGroupInput, setInvalidGroupInput] = useState(false);
    const [groupAlreadyExists, setGroupAlreadyExists] = useState(false);
    const [duplicateGroup, setDuplicateGroup] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        defaultValue && setOptionValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        async function startFetching() {
            setOptions([])
            const { data } = await getCameraGroups(userInfo?.token)
            if (!ignore) {
                setOptions(data)
            }
        }
        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        }
    }, [optionValue])

    useEffect(() => {
        setDuplicateGroup(options?.filter((e) => e.group_name?.toLowerCase() === optionValue?.trim().toLowerCase())?.length >= 1)
    }, [options])

    const onFocusHandler = () => {
        optionsRef.current.style.visibility = "visible";
    }

    const onBlurHandler = () => {
        setTimeout(() => {
            optionsRef.current.style.visibility = "hidden";
        }, 400);
        setInvalidGroupInput(false)
        setGroupAlreadyExists(false)
        setCameraGroupValidation(false)
    }

    const handleClick = (e) => {
        setOptionValue(e.target.innerHTML)
        optionId(e.target.value)
    }

    const handleOnchange = (e) => {
        setOptionValue(e.target.value)
        setInvalidGroupInput(false)
        setGroupAlreadyExists(false)
        setCameraGroupValidation(false)
        optionId(null)
    }

    const handleAddGroupBtn = async () => {
        if (optionValue.trim().length === 0) {
            setInvalidGroupInput(true)
        } else if (duplicateGroup) {
            setGroupAlreadyExists(true)
        } else {
            await createCameraGroup(userInfo?.token, optionValue.trim())
            setOptionValue('')
        }
    }

    const filterOptions = selectOptionId ? options?.filter((e) => e.id != selectOptionId) : options?.filter((e) => e.group_name?.toLowerCase().includes(optionValue?.trim().toLowerCase()))

    return (
        <Form.Group className='position-relative'>
            <Form.Label className="m-0">Group Name</Form.Label>
            <Form.Control
                type="text"
                autoComplete="off"
                className="input_box group_name_arrow"
                name="camera_group_name"
                placeholder="Select or Add Group Name"
                title="Select or Add Group Name"
                value={optionValue}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                onChange={handleOnchange}
                isInvalid={invalidGroupInput || groupAlreadyExists || cameraGroupValidation}
                required
            />
            {invalidGroupInput ? (
                <Form.Control.Feedback type="invalid">
                    Please Enter Group Name
                </Form.Control.Feedback>
            ) : (
                <Form.Control.Feedback type="invalid">
                    {groupAlreadyExists ? "Group Name already exists." : "Please Select or Add Group Name."}
                </Form.Control.Feedback>
            )}

            <div ref={optionsRef} className="group-options position-absolute">
                <ul >
                    {filterOptions?.map((e) => {
                        return (
                            <li key={e.id} value={e.id} onClick={handleClick}>
                                {e.group_name}
                            </li>
                        )
                    })}
                    {!duplicateGroup && (
                        <li className='text-center add-grp-btn' onClick={handleAddGroupBtn}>
                            <i className="fas fa-plus me-1"></i>
                            Add New Group
                        </li>
                    )}
                </ul >
            </div>
        </Form.Group>
    )
}

export default CameraGroupSelect