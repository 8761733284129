import axios from 'axios'
import {
    GET_ADD_ON_DATA_REQUEST,
    GET_ADD_ON_DATA_SUCCESS,
    GET_ADD_ON_DATA_FAIL,
    POST_ADD_ON_INSTALL_DATA_REQUEST,
    POST_ADD_ON_INSTALL_DATA_SUCCESS,
    POST_ADD_ON_INSTALL_DATA_FAIL,
    GET_ADD_ON_INSTALL_DATA_REQUEST,
    GET_ADD_ON_INSTALL_DATA_SUCCESS,
    GET_ADD_ON_INSTALL_DATA_FAIL,
    DELETE_ADD_ON_INSTALL_DATA_REQUEST,
    DELETE_ADD_ON_INSTALL_DATA_SUCCESS,
    DELETE_ADD_ON_INSTALL_DATA_FAIL,
    ADD_ADD_ON_TO_SHORTCUT_REQUEST,
    ADD_ADD_ON_TO_SHORTCUT_SUCCESS,
    ADD_ADD_ON_TO_SHORTCUT_FAIL
} from '../../constants/organizationConstant/addOnsConstant'
import { API_ENDPOINT_AWS, API_ENDPOINT } from '../../constants/apiEndpoint'
import { postAPI } from '../../global/ApiManager';


export const getAddOnData = (selectedData, keyword) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ADD_ON_DATA_REQUEST
        })

        const params = {
            search: keyword,
            page: selectedData?.page,
            items_per_page: selectedData?.itemsPerPage,
        }

        const config = {
            params: params,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT_AWS}/add_on/get_addons/`,
            config
        )

        dispatch({
            type: GET_ADD_ON_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_ADD_ON_DATA_FAIL,
            error: error.response && Object.values(error.response.data)[0]
                ? Object.values(error.response.data)[0]
                : error.message,
        })
    }
};


export const getInstalledAddOnData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ADD_ON_INSTALL_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/addons/_get_installed_addons/`,
            config
        )

        dispatch({
            type: GET_ADD_ON_INSTALL_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_ADD_ON_INSTALL_DATA_FAIL,
            error: error.response && Object.values(error.response.data)[0]
                ? Object.values(error.response.data)[0]
                : error.message,
        })
    }
};


export const postInstallAddOnData = (postData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ADD_ON_INSTALL_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/addons/_install_update_addon/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: POST_ADD_ON_INSTALL_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_ADD_ON_INSTALL_DATA_FAIL,
            error: error.response && Object.values(error.response.data)[0]
                ? Object.values(error.response.data)[0]
                : error.message,
        })
    }
};


export const deleteInstallAddOnData = (deleteData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_ADD_ON_INSTALL_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/addons/_uninstall_addon/`,
            deleteData,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_ADD_ON_INSTALL_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_ADD_ON_INSTALL_DATA_FAIL,
            error: error.response && Object.values(error.response.data)[0]
                ? Object.values(error.response.data)[0]
                : error.message,
        })
    }
};


export const addAddOnToShortcut = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_ADD_ON_TO_SHORTCUT_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/addons/_set_config_addon/`,
            formData,
            config,
            dispatch
        )

        dispatch({
            type: ADD_ADD_ON_TO_SHORTCUT_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ADD_ADD_ON_TO_SHORTCUT_FAIL,
            error: error.response && Object.values(error.response.data)[0]
                ? Object.values(error.response.data)[0]
                : error.message,
        })
    }
}