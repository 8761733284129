import React, { useState, useRef, useCallback, useEffect, Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from "react-bootstrap";
import Webcam from "react-webcam";
import setProfile from '../../../images/capture_tutorial.gif'
import FaceValidationStepper from './FaceValidationStepper';
import { MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS, MULTIPLE_ANGLE_FACE_VALIDATION_RESET } from '../../../constants/faceRecognitionConstant';
import { faceAngleValidation, multipleAngleFaceValidation } from '../../../actions/faceRecognitionActions';
import { Button } from 'antd';
import { getFaceDirection, imageToBase64, imgValidation, removeBase64String } from '../../../global/Helper'
import { ImgFormatAlert } from '../../../components/ToastAlert';
import { toast } from 'react-toastify';

const CaptureFace = ({
    videoStream,
    choseImage,
    currentStep,
    setCurrentStep,
    serviceId,
    videoConstraints,
    onSwitchCamera,
    ...props }) => {

    const dispatch = useDispatch();
    const [showImage, setShowImage] = useState();
    const [showStepper, setShowStepper] = useState(false);
    const [errors, setErrors] = useState(undefined);
    const [faceValidate, setFaceValidate] = useState(undefined);

    const faceValidation = useSelector((state) => state.faceValidation);
    const { error, faceVerified, loading } = faceValidation

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (error) {
            setErrors(JSON.parse(error)?.data)
        }
    }, [error])

    useEffect(() => {
        setShowStepper(false)
        setCurrentStep(0)
        setShowImage(false)
    }, [props.refresh])

    useEffect(() => {
        if (faceVerified) {
            setCurrentStep(currentStep + 1)
            setFaceValidate({ success: true });
        }
    }, [faceVerified]);

    useEffect(() => {
        if (error || faceVerified) {
            setTimeout(() => {
                dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_RESET })
            }, 5000);
        }
    }, [faceVerified, error]);

    const webcamRef = useRef(null);

    useEffect(() => {
        if (videoStream) {
            const faceCaptureInterval = setInterval(async () => {

                const imageSrc = webcamRef?.current?.getScreenshot();
                if (imageSrc) {
                    let image_base64 = imageSrc.replace(removeBase64String, "")
                    let face_data = {
                        faceAngle: getFaceDirection(currentStep),
                        capturedFace: image_base64,
                        service_id: serviceId
                    }

                    const { data, status } = await faceAngleValidation(userInfo?.token, face_data)
                    status === 200 && setFaceValidate({ success: true });
                    status === 400 && setFaceValidate({ error: JSON.parse(data)?.data });
                }
            }, 2000);

            return () => {
                clearInterval(faceCaptureInterval)
            }
        }
    }, [videoStream, webcamRef, currentStep])

    const captureFace = useCallback((uploadImage) => {

        const imageSrc = uploadImage || webcamRef.current.getScreenshot();

        let image_base64 = imageSrc.replace(removeBase64String, "")

        const faceValidator = (faceParam, uploadImage) => {
            if (uploadImage) {
                dispatch({ type: MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS, payload: getFaceDirection(currentStep) })
            } else {
                dispatch(multipleAngleFaceValidation(faceParam));
            }
        }

        if (currentStep === 0) {
            let front_face_data = { faceAngle: "front", capturedFace: image_base64, "service_id": serviceId }
            props.setFrontFace(imageSrc);
            faceValidator(front_face_data, uploadImage)
        }
        else if (currentStep === 1) {
            let left_face_data = { faceAngle: "left", capturedFace: image_base64, "service_id": serviceId }
            props.setLeftFace(imageSrc);
            faceValidator(left_face_data, uploadImage)
        }
        else if (currentStep === 2) {
            let right_face_data = { faceAngle: "right", capturedFace: image_base64, "service_id": serviceId }
            props.setRightFace(imageSrc);
            faceValidator(right_face_data, uploadImage)
        }
        else return currentStep;


    }, [webcamRef, currentStep]);

    return (
        <>
            {showStepper && <FaceValidationStepper currentStep={currentStep} status={error && 'error'} />}
            <div className='position-relative d-flex align-items-center flex-column'>
                {videoStream && currentStep !== 3 ? (
                    <Fragment>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={videoConstraints}
                            forceScreenshotSourceSize={true}
                            screenshotQuality={1}
                            className={`object-fill ${(errors || faceValidate?.error) && 'border-danger border border-3'} ${(faceVerified || faceValidate?.success) && 'border-success border border-3'}`}
                            width={370}
                            height={320}
                        />
                        <div style={{ width: "370px" }}>
                            {(errors || faceValidate?.error) && <div className={`text-truncate text-start text-danger fw-semibold ${(faceVerified || faceValidate?.success) && 'd-none'}`} title={errors || faceValidate?.error}>{errors || faceValidate?.error}</div>}
                            {(faceVerified || faceValidate?.success) && <div className='text-truncate text-start text-success fw-semibold'>Perfect Positioned!</div>}
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        {showImage ? (
                            <img src={props.frontFace} alt='Profile Photo' className='mb-2 object-fill' width={370} height={320} />)
                            : (
                                <Image
                                    className={`object-fill ${choseImage ? 'upload-image' : 'default-image'}`}
                                    src={choseImage || setProfile}
                                />
                            )}
                    </Fragment>)}
            </div>
            <div className='user-profile-upload d-flex justify-content-center gap-3 my-2'>
                {!videoStream || currentStep === 3 ? (
                    <button
                        disabled={currentStep === 3}
                        className='d-flex align-items-center capture-btn border_radius_sm btn'
                        onClick={() => {
                            props.startStreaming();
                            setShowImage(false);
                            setShowStepper(true);
                        }}
                    >
                        <span className={'capture-image-icon me-2'}></span>
                        <span>Add Image</span>
                    </button>
                ) : (
                    <Fragment>
                        {loading ?
                            <Button
                                type="text"
                                className='d-flex align-items-center capture-btn border_radius_sm btn'
                                size="large"
                                loading
                            >
                                <span className={'capture-image-icon me-2'}></span>
                                <label htmlFor="">Capture</label>
                            </Button> :
                            <div className='d-flex justify-content-center gap-3 w-100' style={{ background: "none", padding: "0" }}>
                                <input
                                    type="file"
                                    accept='.jpg,.jpeg,.png'
                                    className=" d-none"
                                    id='upload-face-obj'
                                    onChange={async (e) => {
                                        const [file] = e.target.files;

                                        if (imgValidation.test(file?.name)) {
                                            const imgObj = await imageToBase64(file)
                                            currentStep === 3 && props.stopLiveCamera();
                                            captureFace(imgObj);
                                            setShowImage(true)
                                        } else {
                                            const photoFormat = file.name?.split('.').pop();
                                            toast(<ImgFormatAlert photoFormat={photoFormat} />)
                                        }
                                        e.target.value = '';
                                    }}
                                />
                                <label
                                    htmlFor='upload-face-obj'
                                    className='capture-btn btn d-flex align-items-center' style={{ border: "none" }}>
                                    <i className="ri-upload-2-line fs-5 me-2"></i>
                                    Upload
                                </label>
                                <button
                                    className='d-flex align-items-center capture-btn border_radius_sm btn'
                                    onClick={() => {
                                        currentStep === 3 && props.stopLiveCamera();
                                        captureFace();
                                        setShowImage(true)
                                    }}
                                >
                                    <span className={'capture-image-icon me-2'}></span>
                                    Capture
                                </button>
                                <button
                                    onClick={onSwitchCamera}
                                    className='capture-btn btn d-flex align-items-center' style={{ border: "none" }}>
                                    <i className="ri-refresh-line fs-5 me-2"></i>
                                    Switch
                                </button>
                            </div>}
                    </Fragment>
                )}
            </div>
        </ >
    )
}

export default CaptureFace;