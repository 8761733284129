import React from 'react'
import { Rect, Transformer, Image } from 'react-konva';
import useImage from 'use-image';
import deleteIcon from './icons/deleteIcon.png';
import editIcon from './icons/editIcon.png';
import { Html } from 'react-konva-utils';

function Rectangle(props) {
    const {
        onTransform,
        shapeProps,
        onDragMove,
        isSelected,
        onClick,
        onDelete,
        onEdit,
        onEditInput,
    } = props

    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const inputRef = React.useRef(null);
    const [deleteBtn] = useImage(deleteIcon);
    const [editBtn] = useImage(editIcon);

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer()?.batchDraw();
        }
    }, [isSelected]);
    return (
        <React.Fragment>
            <Rect
                ref={shapeRef}
                {...shapeProps}
                onClick={onClick}
                onTransform={onTransform}
                onDragMove={onDragMove}
            />
            <Rect
                x={shapeProps.x}
                y={shapeProps.y}
                width={shapeProps.width}
                height={25}
                fill={shapeProps.textBackground}
            />
            <Html
                divProps={{
                    style: {
                        position: 'absolute',
                        top: `${shapeProps.y + 1}px`,
                        left: `${shapeProps.x}px`,
                        zIndex: "10000",
                        width: `${shapeProps.width / 2}px`,
                    },
                }}
            >
                <input
                    type="text"
                    style={{ width: `${shapeProps.width / 2}px` }}
                    className='fs-10 border-0 bg-transparent text-truncate text-white edit-konva-input'
                    value={shapeProps.text}
                    onChange={onEditInput}
                    ref={inputRef}
                />
            </Html>
            <Image
                x={shapeProps.x + shapeProps.width - 36}
                y={shapeProps.y + 5}
                width={15}
                height={15}
                image={editBtn}
                onClick={(e) => {
                    onEdit(e);
                    inputRef.current.focus()
                }}
                onMouseEnter={e => {
                    // style stage container:
                    const container = e.target.getStage().container();
                    container.style.cursor = "pointer";
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                }}
            />
            <Image
                x={shapeProps.x + shapeProps.width - 18}
                y={shapeProps.y + 5}
                width={15}
                height={15}
                onClick={onDelete}
                image={deleteBtn}
                onMouseEnter={e => {
                    // style stage container:
                    const container = e.target.getStage().container();
                    container.style.cursor = "pointer";
                }}
                onMouseLeave={e => {
                    const container = e.target.getStage().container();
                    container.style.cursor = "default";
                }}
            />
            {isSelected && (
                <Transformer
                    rotateEnabled={false}
                    keepRatio={false}
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 70 || newBox.height < 60) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    )
}

export default Rectangle