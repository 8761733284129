
import { useDispatch } from 'react-redux'
import { Button } from 'antd';


function RecoverConfirmModal(props) {

    const dispatch = useDispatch();
    return (
        <>
            <div className="d-flex align-itmes-center justify-content-center mt-2 text-center">
                <div className="text-center">
                    <div className="d-flex justify-content-center mb-2">
                        <i className="fs-1 recover-user-icon text-danger"></i>
                    </div>
                    <h3>Are you sure?</h3>
                    <h5 className="mb-0">Do you want to recover this user <b>{props.item_name}</b> ?</h5>
                </div>
            </div>
            <div className="d-flex align-itmes-center justify-content-end mt-4">
                <button className="btn me-3 px-3 py-2 border close" onClick={props.onHide}>
                    Cancel
                </button>

                {props?.loading ? (<Button type="" size="large" loading style={{
                    background: "rgb(74, 194, 55)",
                }}>
                    Restore
                </Button>) :
                    (
                        <button
                            style={{
                                background: "rgb(74, 194, 55)",
                            }}
                            className="btn px-3 py-2 border text-light"
                            onClick={() => {
                                dispatch(props.dispatch_item);
                            }}
                        >
                            Restore
                        </button>
                    )
                }

            </div>
        </>
    );
}

export default RecoverConfirmModal;
