export const GET_FACE_RECOGNITION_CAMERAS_REQUEST = 'GET_FACE_RECOGNITION_CAMERAS_REQUEST'
export const GET_FACE_RECOGNITION_CAMERAS_SUCCESS = 'GET_FACE_RECOGNITION_CAMERAS_SUCCESS'
export const GET_FACE_RECOGNITION_CAMERAS_FAIL = 'GET_FACE_RECOGNITION_CAMERAS_FAIL'


export const GET_FACE_RECOGNITION_USERS_REQUEST = 'GET_FACE_RECOGNITION_USERS_REQUEST'
export const GET_FACE_RECOGNITION_USERS_SUCCESS = 'GET_FACE_RECOGNITION_USERS_SUCCESS'
export const GET_FACE_RECOGNITION_USERS_FAIL = 'GET_FACE_RECOGNITION_USERS_FAIL'


export const DELETE_FACE_RECOGNITION_CAMERA_REQUEST = 'DELETE_FACE_RECOGNITION_CAMERAS_REQUEST'
export const DELETE_FACE_RECOGNITION_CAMERA_SUCCESS = 'DELETE_FACE_RECOGNITION_CAMERAS_SUCCESS'
export const DELETE_FACE_RECOGNITION_CAMERA_FAIL = 'DELETE_FACE_RECOGNITION_CAMERAS_FAIL'
export const DELETE_FACE_RECOGNITION_CAMERA_RESET = 'DELETE_FACE_RECOGNITION_CAMERAS_FAIL'


export const DELETE_FACE_RECOGNITION_USER_REQUEST = 'DELETE_FACE_RECOGNITION_USERS_REQUEST'
export const DELETE_FACE_RECOGNITION_USER_SUCCESS = 'DELETE_FACE_RECOGNITION_USERS_SUCCESS'
export const DELETE_FACE_RECOGNITION_USER_FAIL = 'DELETE_FACE_RECOGNITION_USERS_FAIL'
export const DELETE_FACE_RECOGNITION_USER_RESET = 'DELETE_FACE_RECOGNITION_USERS_FAIL'


export const ADD_FACE_RECOGNITION_USER_REQUEST = 'ADD_FACE_RECOGNITION_USERS_REQUEST'
export const ADD_FACE_RECOGNITION_USER_SUCCESS = 'ADD_FACE_RECOGNITION_USERS_SUCCESS'
export const ADD_FACE_RECOGNITION_USER_FAIL = 'ADD_FACE_RECOGNITION_USERS_FAIL'
export const ADD_FACE_RECOGNITION_USER_RESET = 'ADD_FACE_RECOGNITION_USERS_RESET'


export const GET_USER_CONFIGURATION_REQUEST = 'GET_USER_CONFIGURATION_REQUEST'
export const GET_USER_CONFIGURATION_SUCCESS = 'GET_USER_CONFIGURATION_SUCCESS'
export const GET_USER_CONFIGURATION_FAIL = 'GET_USER_CONFIGURATION_FAIL'


export const APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST = 'APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST'
export const APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS = 'APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS'
export const APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL = 'APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL'
export const APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET = 'APPLY_USERS_IN_FACE_RECOGNITION_CAMERA_RESET'


export const REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST = 'REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_REQUEST'
export const REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS = 'REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_SUCCESS'
export const REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL = 'REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_FAIL'
export const REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET = 'REMOVE_USERS_IN_FACE_RECOGNITION_CAMERA_RESET'


export const GET_CAMERAS_CONFIGURATION_REQUEST = 'GET_CAMERAS_CONFIGURATION_REQUEST'
export const GET_CAMERAS_CONFIGURATION_SUCCESS = 'GET_CAMERAS_CONFIGURATION_SUCCESS'
export const GET_CAMERAS_CONFIGURATION_FAIL = 'GET_CAMERAS_CONFIGURATION_FAIL'


export const APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_REQUEST = 'APPLY_CAMERA_IN_FACE_RECOGNITION_CAMERA_REQUEST'
export const APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_SUCCESS = 'APPLY_CAMERA_IN_FACE_RECOGNITION_CAMERA_SUCCESS'
export const APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_FAIL = 'APPLY_CAMERA_IN_FACE_RECOGNITION_CAMERA_FAIL'
export const APPLY_CAMERAS_IN_FACE_RECOGNITION_USER_RESET = 'APPLY_CAMERA_IN_FACE_RECOGNITION_CAMERA_RESET'


export const DELETE_FACE_PERMANENT_REQUEST = "DELETE_FACE_PERMANENT_REQUEST";
export const DELETE_FACE_PERMANENT_SUCCESS = "DELETE_FACE_PERMANENT_SUCCESS";
export const DELETE_FACE_PERMANENT_FAIL = "DELETE_FACE_PERMANENT_FAIL";
export const DELETE_FACE_PERMANENT_RESET = "DELETE_FACE_PERMANENT_RESET";

export const RESTORE_FACE_REQUEST = "RESTORE_FACE_REQUEST";
export const RESTORE_FACE_SUCCESS = "RESTORE_FACE_SUCCESS";
export const RESTORE_FACE_FAIL = "RESTORE_FACE_FAIL";
export const RESTORE_FACE_RESET = "RESTORE_FACE_RESET";

export const RECYCLED_FACE_REQUEST = "RECYCLED_FACE_REQUEST";
export const RECYCLED_FACE_SUCCESS = "RECYCLED_FACE_SUCCESS";
export const RECYCLED_FACE_FAIL = "RECYCLED_FACE_FAIL";
export const RECYCLED_FACE_RESET = "RECYCLED_FACE_RESET";

export const MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST = "MULTIPLE_ANGLE_FACE_VALIDATION_REQUEST";
export const MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS = "MULTIPLE_ANGLE_FACE_VALIDATION_SUCCESS";
export const MULTIPLE_ANGLE_FACE_VALIDATION_FAIL = "MULTIPLE_ANGLE_FACE_VALIDATION_FAIL";
export const MULTIPLE_ANGLE_FACE_VALIDATION_RESET = "MULTIPLE_ANGLE_FACE_VALIDATION_RESET";