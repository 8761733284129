import React, { useState, useRef, useEffect } from "react";
import { Overlay, Popover, Badge, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getEventDetails } from "../actions/dataActions";
import moment from "moment";
import { Link } from "react-router-dom";
import EventDetailPage from '../screens/EventsPage/EventDetailPage'
import noServiceIcon from '../images/no-service-icon.jpeg';

function NotificatonIcon() {
  let [totalScrollNotification, setTotalScrollNotification] = useState(10);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [showEventDetail, setShowEventDetail] = useState(false);
  const [modalEvent, setModalEvent] = useState();

  const ref = useRef(null);

  const dispatch = useDispatch();

  const GetNotification = useSelector((state) => state.GetNotification);
  const { loading, notifications } = GetNotification;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(getNotification(totalScrollNotification));
  }, [dispatch, totalScrollNotification]);

  const handleNotification = async (id) => {
    setShowEventDetail(true)
    const { data } = await getEventDetails(id, userInfo?.token)

    setModalEvent(data)
    setShow(false);
    dispatch(getNotification(totalScrollNotification));
  };

  const handleScroll = (e) => {
    const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === Math.floor(e.target.clientHeight);
    if (bottom && !notifications?.showed_last_event) {
      setTotalScrollNotification(totalScrollNotification + 10)
    }
  }

  return (
    <div ref={ref}>
      <div role="button" onClick={(e) => {
        setTarget(e.target);
        setShow(!show);
      }}>

        <i
          className="ri-notification-2-line bell_icon"
        ></i>
        {
          notifications?.alert_is_not_seen !== 0 ? (

            <Badge pill bg="danger alert_text">
              {
                notifications?.alert_is_not_seen > 99 ? '99+' : notifications?.alert_is_not_seen
              }
            </Badge>
          ) : null
        }
      </div>


      <Overlay
        show={show}
        target={target}
        placement="bottom-start"
        container={ref}
        containerPadding={20}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained">
          <Popover.Body onScroll={handleScroll}>
            <div className="d-flex flex-column">
              {notifications?.get_alert?.length === 0 ? (
                <ul>
                  <li className="notification_list unread border-0 text-center">
                    <div className="d-flex align-items-center justify-content-center text-center w-100">
                      <em>No Notifications</em>
                    </div>
                  </li>
                </ul>
              ) : (
                <ul>
                  {notifications?.get_alert?.map((notification) => {
                    return (
                      <Link
                        to="#"
                        key={notification.id}
                        onClick={(e) => {
                          setShow(false);
                          handleNotification(notification.id);
                        }}
                      >
                        <li
                          className={
                            !notification.detail_seen
                              ? "notification_list unread"
                              : "notification_list"
                          }

                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className=" d-flex align-items-center">
                              <div className="img_box user_box me-2">
                                <img
                                  src={notification?.service?.icon || noServiceIcon}
                                  alt="..."
                                  onError={(e) => e.target.src = noServiceIcon}
                                />
                              </div>
                              <div className="notification_text">
                                <h5 className="user_name m-0">
                                  {notification.message}
                                </h5>
                                <h6 className="user_status m-0">
                                  {
                                    notification?.camera?.camera_name
                                  }
                                </h6>
                              </div>
                            </div>
                            <div className="notification_time">
                              <h6 className="user_status m-0">
                                {moment
                                  .tz(notification.alert_date, "Asia/Kolkata")
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}{" "}
                              </h6>
                            </div>
                          </div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              )}
            </div>
            {loading && (
              <div className="text-center">
                <Spinner animation="border" size="sm" />
              </div>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
      <Modal
        scrollable={true}
        show={showEventDetail}
        size="lg"
        onHide={() => setShowEventDetail(false)}
        centered
      >
        <Modal.Body>
          <EventDetailPage
            modalEvent={modalEvent}
            onHide={() => setShowEventDetail(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NotificatonIcon;
