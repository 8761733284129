import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import DateComparison from './DateComparison';
import WeekComparison from './WeekComparison';
import MonthComparison from './MonthComparison';
import { Link } from 'react-router-dom';
import { getFormattedDate } from '../../../global/Helper';
import dayjs from 'dayjs';

function CompareFilterButton(props) {

    const {
        calendarType,
        compareScreen,
        ServiceName,
        StartDateCompare,
        EndDateCompare,
        StartYearCompare,
        EndYearCompare,
        StartDateObject,
    } = props

    const [StartDate, setStartDate] = useState(dayjs().add(-1, 'day'));
    const [EndDate, setEndDate] = useState(dayjs());

    const [StartWeek, setStartWeek] = useState(dayjs().add(-1, 'week'));
    const [EndWeek, setEndWeek] = useState(dayjs());

    const [StartMonth, setStartMonth] = useState(dayjs().add(-1, 'month'));
    const [EndMonth, setEndMonth] = useState(dayjs());

    const [StartYear, setStartYear] = useState(parseInt(new Date().getFullYear()));
    const [EndYear, setEndYear] = useState(parseInt(new Date().getFullYear()));

    const [StartDateFilter, setStartDateFilter] = useState([]);
    const [EndDateFilter, setEndDateFilter] = useState([]);

    useEffect(() => {
        if (StartDateObject) {
            if (calendarType === "day") {
                setStartDate(StartDateObject)
                setEndDate(StartDateObject.add(-1, calendarType))

            } else if (calendarType === "week") {
                setStartWeek(StartDateObject)
                setEndWeek(StartDateObject.add(-1, calendarType))

            } else if (calendarType === "month") {
                setStartMonth(StartDateObject.set('year', StartYear))
                setEndMonth(StartDateObject.add(-1, calendarType).set('year', EndYear))
                setStartYear(StartYearCompare)
                setEndYear(EndYearCompare)
            }
        }
    }, [StartDateObject, calendarType, StartYearCompare, EndYearCompare])

    useEffect(() => {
        if (compareScreen) {
            if (calendarType === "day") {
                setStartDate(StartDateCompare)
                setEndDate(EndDateCompare)
            } else if (calendarType === "week") {
                setStartWeek(StartDateCompare)
                setEndWeek(EndDateCompare)
            } else if (calendarType === "month") {
                setStartMonth(StartDateCompare)
                setEndMonth(EndDateCompare)
                setStartYear(StartYearCompare)
                setEndYear(EndYearCompare)
            }
        }

    }, [StartDateCompare, EndDateCompare, compareScreen, StartYearCompare, EndYearCompare])


    useEffect(() => {
        if (calendarType === "day") {
            setStartDateFilter(StartDate)
            setEndDateFilter(EndDate)

        } else if (calendarType === "week") {
            setStartDateFilter(StartWeek)
            setEndDateFilter(EndWeek)

        } else if (calendarType === "month") {
            setStartDateFilter(StartMonth.set('year', StartYear))
            setEndDateFilter(EndMonth.set('year', EndYear))
        }

    }, [calendarType, StartDate, EndDate, StartWeek, EndWeek, StartMonth, EndMonth, StartYear, EndYear])

    const ComparisonComponents = {
        day: DateComparison,
        week: WeekComparison,
        default: MonthComparison,
    };

    const CalendarComponents = ComparisonComponents[calendarType] || ComparisonComponents.default;

    return (
        <OverlayTrigger
            trigger="click"
            placement={'bottom'}
            rootClose
            overlay={
                <Popover className="date-comparison-container">
                    <Popover.Body className='p-3'>
                        <CalendarComponents
                            StartDate={calendarType === 'day' ? StartDate : StartWeek}
                            setStartDate={calendarType === 'day' ? setStartDate : setStartWeek}
                            EndDate={calendarType === 'day' ? EndDate : EndWeek}
                            setEndDate={calendarType === 'day' ? setEndDate : setEndWeek}
                            setStartYear={setStartYear}
                            StartYear={StartYear}
                            setEndYear={setEndYear}
                            EndYear={EndYear}
                        />
                        <div className='text-center'>
                            <Link
                                to="/analytics/compare/"
                                state={{
                                    StartDateFilter: StartDateFilter,
                                    EndDateFilter: EndDateFilter,
                                    calendarType: calendarType,
                                    ServiceName: ServiceName,
                                    StartYear: StartYear,
                                    EndYear: EndYear,
                                    // dateTimeObject: StartDateObject,
                                }}
                                className='btn px-3 py-1 bg-warning border text-dark'
                            >Compare
                            </Link>
                        </div>
                    </Popover.Body>
                </Popover>
            }
        >
            {compareScreen ? (
                <div className='d-flex align-items-center fs-6 compare-calendar-heading px-3 py-1 cursor-pointer text-nowrap'>
                    <div
                        className='me-1'
                    >{ServiceName}
                    </div>
                    -
                    <span className='me-1 opacity ms-1'>Comparing</span>
                    {calendarType === "day" ? (
                        <>
                            {getFormattedDate(StartDateCompare, calendarType, "start")}
                            <span className='opacity mx-1'>
                                and
                            </span>
                            {getFormattedDate(EndDateCompare, calendarType, "end")}
                            <i className="ri-calendar-line ms-4 fs-5"></i>
                        </>
                    ) : (
                        <>
                            {getFormattedDate(StartDateCompare, calendarType, "start")}
                            {" "}-{" "}
                            {getFormattedDate(StartDateCompare, calendarType, "end")}
                            <span className='opacity mx-1'>
                                and
                            </span>
                            {getFormattedDate(EndDateCompare, calendarType, "start")}
                            {" "}-{" "}
                            {getFormattedDate(EndDateCompare, calendarType, "end")}
                            <i className="ri-calendar-line ms-4 fs-5"></i>
                        </>
                    )}

                </div>
            ) : (
                <button
                    className={`compare-btn d-flex align-items-center ${calendarType === 'custom' && 'is-disable'}`}
                    disabled={calendarType === 'custom'}
                >
                    <i className="ri-bar-chart-2-line me-2" style={{ fontSize: "1rem" }}></i>
                    Compare
                </button>

            )}
        </OverlayTrigger>
    )
}

export default CompareFilterButton