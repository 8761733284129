export const HOMEPAGE_DATA_GET_REQUEST = 'HOMEPAGE_DATA_GET_REQUEST'
export const HOMEPAGE_DATA_GET_SUCCESS = 'HOMEPAGE_DATA_GET_SUCCESS'
export const HOMEPAGE_DATA_GET_FAIL = 'HOMEPAGE_DATA_GET_FAIL'
export const HOMEPAGE_DATA_GET_RESET = 'HOMEPAGE_DATA_GET_RESET'

export const WEBCAM_DETAIL_REQUEST = "WEBCAM_DETAIL_REQUEST";
export const WEBCAM_DETAIL_SUCCESS = "WEBCAM_DETAIL_SUCCESS";
export const WEBCAM_DETAIL_FAIL = "WEBCAM_DETAIL_FAIL";
export const WEBCAM_DETAIL_RESET = "WEBCAM_DETAIL_RESET";


export const ADD_CAMERA_REQUEST = 'ADD_CAMERA_REQUEST'
export const ADD_CAMERA_SUCCESS = 'ADD_CAMERA_SUCCESS'
export const ADD_CAMERA_FAIL = 'ADD_CAMERA_FAIL'
export const ADD_CAMERA_RESET = 'ADD_CAMERA_RESET'


export const UPDATE_CAMERA_REQUEST = 'UPDATE_CAMERA_REQUEST'
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS'
export const UPDATE_CAMERA_FAIL = 'UPDATE_CAMERA_FAIL'
export const UPDATE_CAMERA_RESET = 'UPDATE_CAMERA_RESET'


export const DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST'
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS'
export const DELETE_CAMERA_FAIL = 'DELETE_CAMERA_FAIL'
export const DELETE_CAMERA_RESET = 'DELETE_CAMERA_RESET'


export const GET_CAMERA_USER_REQUEST = 'GET_CAMERA_USER_REQUEST'
export const GET_CAMERA_USER_SUCCESS = 'GET_CAMERA_USER_SUCCESS'
export const GET_CAMERA_USER_FAIL = 'GET_CAMERA_USER_FAIL'
export const GET_CAMERA_USER_RESET = 'GET_CAMERA_USER_RESET'


export const CREATE_CAMERA_USER_REQUEST = 'CREATE_CAMERA_USER_REQUEST'
export const CREATE_CAMERA_USER_SUCCESS = 'CREATE_CAMERA_USER_SUCCESS'
export const CREATE_CAMERA_USER_FAIL = 'CREATE_CAMERA_USER_FAIL'


export const GET_NOTIFIACTION_REQUEST = 'GET_NOTIFIACTION_REQUEST'
export const GET_NOTIFIACTION_SUCCESS = 'GET_NOTIFIACTION_SUCCESS'
export const GET_NOTIFIACTION_FAIL = 'GET_NOTIFIACTION_FAIL'


export const CAMERA_FRAME_REFRESH_REQUEST = "CAMERA_FRAME_REFRESH_REQUEST";
export const CAMERA_FRAME_REFRESH_SUCCESS = "CAMERA_FRAME_REFRESH_SUCCESS";
export const CAMERA_FRAME_REFRESH_FAIL = "CAMERA_FRAME_REFRESH_FAIL";


export const GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST = "GET_NAVIGATION_BAR_PAGE_ACCESS_REQUEST";
export const GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS = "GET_NAVIGATION_BAR_PAGE_ACCESS_SUCCESS";
export const GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL = "GET_NAVIGATION_BAR_PAGE_ACCESS_FAIL";


export const GET_PAGE_ACCESS_REQUEST = "GET_PAGE_ACCESS_REQUEST";
export const GET_PAGE_ACCESS_SUCCESS = "GET_PAGE_ACCESS_SUCCESS";
export const GET_PAGE_ACCESS_FAIL = "GET_PAGE_ACCESS_FAIL";