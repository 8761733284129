import { Switch } from 'antd';
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Stage, Layer, Image, Shape } from 'react-konva';
import useImage from 'use-image';
import VideoJS from '../livecamPage/components/VideoJS';
import Magnifier from "react-magnifier";
import { Html } from 'react-konva-utils';
import { useDispatch, useSelector } from 'react-redux';
import { getFaceRecognitionUser, postDoubtfulEvent } from '../../actions/EventActions';
import { POST_DOUBTFUL_EVENT_DATA_RESET } from '../../constants/EventConstants';
import moment from 'moment';
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@mui/material';
import { API_ENDPOINT } from '../../constants/apiEndpoint';
import noFace from "../../images/profile_image.png";
import Rectangle from './components/Rectangle';
import { Dropdown, Spinner } from 'react-bootstrap';
import { getEventDetails } from '../../actions/dataActions';
import { toast } from 'react-toastify';
import { SwitchOn_Off_Alert } from '../../components/ToastAlert';
import axios from 'axios';

function EventDetailPage(props) {
    const modalEvent = props.modalEvent

    const divRef = useRef(null);
    const playBtnRef = useRef(null);
    const playerRef = useRef(null);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [plotShow, setPlotShow] = useState(true);
    const [image] = useImage(modalEvent?.image);
    const [playVideo, setPlayVideo] = useState(false);
    const [classesName, setClassesName] = useState(null);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null)
    const [eventData, setEventData] = useState({})
    const [annotations, setAnnotations] = useState([]);
    const [newAnnotation, setNewAnnotation] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const doubtfulEventPost = useSelector((state) => state.doubtfulEventPost);
    const { succuss: doubtfulEventPostSuccuss } = doubtfulEventPost;
    const AnnotationDataPost = useSelector((state) => state.retrain_save_data);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const [isRetraingSupport, setIsRetrainingSupport] = useState(false)

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    const dispatch = useDispatch();

    useEffect(() => {
        setWidth(divRef.current.offsetWidth);
        setHeight(divRef.current.offsetHeight);
    }, [divRef?.current?.offsetHeight, divRef?.current?.offsetWidth]);

    useEffect(() => {
        setAnnotations([])
        setEventData(modalEvent)
        setIsRetrainingSupport(modalEvent?.service?.extra_details?.retraining_support)
    }, [modalEvent])

    useEffect(() => {
        eventData?.class_name && setClassesName({ class_name: eventData?.class_name });
        eventData?.class_name && setSelectedClass(eventData?.class_name);

        const bBoxData = eventData?.additional_fields?.new_bbox && eventData?.additional_fields?.new_bbox.length > 0 ? eventData?.additional_fields.new_bbox?.[0]?.bbox : eventData?.additional_fields?.bbox?.[0]?.bbox

        const bbox = bBoxData?.map((item, i) => {
            const formateDated = extractRectangleData(item)
            return {
                x: Math.round(formateDated.x),
                y: Math.round(formateDated.y),
                width: Math.round(formateDated.width),
                height: Math.round(formateDated.height),
                fill: '',
                id: 'rect' + (i + 1),
                class: formateDated.className
            }
        })

        if (bbox) {
            setAnnotations(bbox)
        }
    }, [eventData])

    useEffect(() => {
        if (doubtfulEventPostSuccuss) {
            dispatch({ type: POST_DOUBTFUL_EVENT_DATA_RESET })
            props.onDoubtfulDropdownChange(eventData?.id)
        }
    }, [doubtfulEventPostSuccuss])


    const videoJsOptions = useMemo(() => ({
        poster: eventData?.image,
        autoplay: true,
        loop: true,
        muted: true,
        liveui: true,
        controls: true,
        responsive: false,
        fluid: false,
        sources: [{
            src: `${API_ENDPOINT}${eventData?.additional_fields?.event_video_path}`,
            type: "video/mp4",
        }]


    }), [eventData])

    const x_scale = width / eventData?.additional_fields?.bbox?.[0]?.image_size?.[1]
    const y_scale = height / eventData?.additional_fields?.bbox?.[0]?.image_size?.[0]

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('ended', function () {
            setPlayVideo(!playVideo)
            playBtnRef.current.classList.remove('d-none')
        });

        player.on('play', function () {
            playBtnRef.current.classList.add('d-none')
        });
    };

    useEffect(() => {
        async function startFetching() {
            setLoading(true)
            const { data } = await getFaceRecognitionUser(userInfo?.token, { camera_id: eventData.camera_id })
            setOptions(data)
            setLoading(false)
        }
        if (open) {
            startFetching();
        } else {
            setOptions([])
        }
    }, [userInfo, open]);


    const onChangeDoubtfulDropdown = (selected_data) => {
        setClassesName(selected_data)
        const data = {
            alert_object_id: eventData?.id,
            corrected_data: selected_data
        }
        dispatch(postDoubtfulEvent(data));
    }

    const getTextColor = (cases) => {
        switch (cases) {
            case 'Authorized':
                return 'text-success';

            case 'Blacklisted':
                return 'text-danger';

            default:
                return 'text-warning';
        }
    }

    const extractRectangleData = (data) => {
        const { top_left, top_right, bottom_left, _ } = data;

        const x = top_left[0] * x_scale
        const y = top_left[1] * y_scale;
        const width = (top_right[0] * x_scale) - (top_left[0] * x_scale);
        const height = (bottom_left[1] * y_scale) - (top_left[1] * y_scale);
        return {
            x,
            y,
            width,
            height,
            className: data.class
        };
    };

    function convertRectFormat(rect) {
        // Destructure the input object for ease of use
        const { x, y, width, height, class: className } = rect;

        let X = x / x_scale
        let Y = y / y_scale
        let Width = width / x_scale
        let Height = height / y_scale

        // Calculate the coordinates
        const topLeft = [X, Y];
        const topRight = [X + Width, Y];
        const bottomLeft = [X, Y + Height];
        const bottomRight = [X + Width, Y + Height];


        return {
            top_left: topLeft,
            top_right: topRight,
            bottom_left: bottomLeft,
            bottom_right: bottomRight,
            class: className
        };

        // Return the new format
    }

    const submitEventDetails = async () => {
        const convertedRect = annotations.map(e => convertRectFormat(e))
        setIsLoading(true)
        const Data = {
            alert_id: eventData.id,
            service_id: eventData.service_id,
            bbox: [{
                "bbox": convertedRect,
                "image_size": eventData?.additional_fields?.bbox?.[0]?.image_size,
                "image_selection_bbox": eventData?.additional_fields?.bbox?.[0]?.image_selection_bbox
            }]
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        try {
            const res = await axios.post(
                `${API_ENDPOINT}${'/service_app/correct_annotation_of_events/'}`,
                Data,
                config
            )
            if (res?.status === 200) {
                async function getData() {
                    if (!AnnotationDataPost.loading) {
                        const { data } = await getEventDetails(eventData.id, userInfo?.token)
                        setEventData(data)
                        setIsLoading(false)
                        toast(<SwitchOn_Off_Alert type={true} massage={'Correction Updated.'} />);

                    }
                }
                getData()
            } else {
                toast(<SwitchOn_Off_Alert type={false} massage={'Correction Not Updated.'} />);
                setIsLoading(false)
            }
        } catch (error) {
            toast(<SwitchOn_Off_Alert type={false} massage={'Correction Not Updated.'} />);
            setIsLoading(false)
        }
    }

    const annotationsToDraw = useMemo(() => [...annotations, ...newAnnotation], [newAnnotation, annotations]);

    const handleMouseEnter = event => {

        event.target.getStage().container().style.cursor = isRetraingSupport & !props.autoPlay ? "crosshair" : 'default';
    };

    const handleMouseDown = event => {
        if (selectedId === null && newAnnotation.length === 0 && isRetraingSupport && !props.autoPlay) {
            const { x, y } = event.target.getStage().getPointerPosition();
            setNewAnnotation([{ x, y, width: 0, height: 0 }]);
        }
    };

    const handleMouseMove = event => {
        if (selectedId === null && newAnnotation.length === 1 && isRetraingSupport && !props.autoPlay) {
            const sx = Math.round(newAnnotation[0].x);
            const sy = Math.round(newAnnotation[0].y);
            const { x, y } = event.target.getStage().getPointerPosition();
            setNewAnnotation([
                {
                    x: sx,
                    y: sy,
                    width: Math.round(x) - sx,
                    height: Math.round(y) - sy,
                    class: selectedClass
                }
            ]);
        }
    };

    const handleMouseUp = () => {
        if (selectedId === null && newAnnotation.length === 1 && isRetraingSupport && !props.autoPlay) {
            if (newAnnotation[0].width !== 0 && newAnnotation[0].height !== 0) {
                annotations.push(...newAnnotation);
                setAnnotations(annotations);
            }
            setNewAnnotation([]);
        }
    };

    const handleKeyDown = event => {
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (selectedId !== null) {
                const newAnnotations = annotations.filter(
                    annotation => annotation.id !== selectedId
                );
                setAnnotations(newAnnotations);
            }
        }
    };

    const customRenderOption = (props, option) => {
        return (
            <Tooltip
                key={option.value}
                title={
                    <div className='text-center bg-white p-3 pb-2'>
                        <img
                            src={option.image ? `${API_ENDPOINT}${option.image}` : noFace}
                            className='rounded-circle object-cover mb-2'
                            alt="..."
                            width={150}
                            height={150}
                            onError={(e) => e.target.src = noFace}
                        />
                        <div className='fw-bold fs-5 text-black text-truncate text-capitalize'>{option.class_name}</div>
                        <div className={`fw-semibold fs-6 text-danger text-truncate text-capitalize ${getTextColor(option.value)}`}>{option.value} Person</div>
                    </div>
                }
                placement="right-end"
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -50],
                                },
                            },
                        ],
                    },
                }}
            >
                <button
                    className='p-2 px-3 w-100 d-flex align-items-center border-0 bg-transparent'
                    onClick={() => onChangeDoubtfulDropdown(option)}
                >
                    <img
                        src={option.image ? `${API_ENDPOINT}${option.image}` : noFace}
                        onError={(e) => e.target.src = noFace}
                        className='object-cover rounded-circle me-2'
                        alt="..."
                        width={30}
                        height={30}
                    />
                    <span className='text-black-50 text-truncate text-capitalize'>{option.class_name}</span>
                </button>
            </Tooltip>
        )
    }

    return (
        <Fragment>
            <div
                className="border-radius position-relative event-video-container"
                ref={divRef}
                style={{ width: "100%", height: "480px" }}
                onKeyDown={handleKeyDown}
                tabIndex={1}
            >
                {playVideo ? (
                    <div className='border-radius'>
                        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                    </div>
                ) : (
                    <Stage
                        width={width}
                        height={height}
                        style={{ backgroundColor: image ? '#fff' : '#3333330D' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    >
                        <Layer>
                            <Image
                                width={width}
                                height={height}
                                image={image}
                                cornerRadius={8}
                                onMouseDown={() => setSelectedId(null)}
                            />
                            {plotShow && (
                                <Fragment>
                                    {eventData?.additional_fields?.bbox?.[0]?.image_selection_bbox?.map((imageSelection, index) => (
                                        <Shape
                                            onMouseDown={() => setSelectedId(null)}
                                            key={`${index + 1}`}
                                            sceneFunc={(context, shape) => {
                                                context.beginPath();
                                                imageSelection?.map((e) => {
                                                    context.lineTo(e[0] * x_scale, e[1] * y_scale);
                                                })
                                                context.closePath();
                                                context.fillStrokeShape(shape);
                                            }}
                                            stroke="#febe00"
                                            strokeWidth={1}
                                        />
                                    ))}
                                    {annotationsToDraw.map((rect, i) => {
                                        const shapeProps = { ...rect }
                                        if (shapeProps.width && shapeProps.width < 22) {
                                            shapeProps.width = 22
                                        }
                                        if (shapeProps.height && shapeProps.height < 22) {
                                            shapeProps.height = 22
                                        }

                                        return (
                                            <Rectangle
                                                key={i}
                                                reactId={i}
                                                imageWidth={width}
                                                imageHeight={height}
                                                deleteRect={setAnnotations}
                                                shapeProps={shapeProps}
                                                isSelected={i === selectedId}
                                                onSelect={() => setSelectedId(i)}
                                                IsautoPlay={props.autoPlay}
                                                isRetraingSupport={isRetraingSupport}
                                                onChange={newAttrs => {
                                                    const rects = annotations.slice();
                                                    rects[i] = newAttrs;
                                                    setAnnotations(rects);
                                                }}
                                            />
                                        );
                                    })}
                                </Fragment>
                            )}
                            {!plotShow && (
                                <Html
                                    divProps={{
                                        style: {
                                            position: 'absolute',
                                            borderRadius: "8px",
                                            top: "0px",
                                            left: "0px",
                                            zIndex: "0",
                                        },
                                    }}
                                >
                                    <Magnifier
                                        src={eventData?.image}
                                        mgShape='circle'
                                        mgBorderWidth={1}
                                        width={width}
                                        height={height}
                                    />
                                </Html>
                            )}
                        </Layer>
                    </Stage>
                )}
                {eventData?.additional_fields?.event_video_path && (
                    <div ref={playBtnRef}>
                        <button
                            className={`cursor-pointer position-absolute top-50 start-50 translate-middle d-none event-play-button rounded-circle py-2`}
                            onClick={() => eventData?.additional_fields?.event_video_path && setPlayVideo(!playVideo)}
                        >
                            <i className={`${playVideo ? 'ri-pause-fill' : 'ri-play-fill'} bg-light-grey fs-4 p-2 rounded-pill`}></i>
                        </button>
                    </div>
                )}
            </div>
            <div className="text-capitalize my-2">
                <div className='h4 fw-bold m-0'>{eventData?.additional_fields?.classes?.map(e => e.count && `${e.count} ${e.class_name}`).join(', ')}</div>
                <div className="d-flex align-items-center gap-2">
                    <div className='text-black-50 bold text-truncate' style={{ fontSize: "16px" }} title={eventData?.message} >{eventData?.message}</div>
                    <i className="fas fa-circle" style={{ fontSize: "4px" }}></i>
                    <div className='h5 text-black m-0 text-nowrap text-truncate' title={eventData?.service?.label || eventData?.service_name}>{eventData?.service?.label || eventData?.service_name}{" "}</div>
                    <div className='ms-auto text-nowrap'>
                        <label className='m-0 text-dark text-capitalize cursor-pointer bg-light-grey rounded-1 p-2 me-2' style={{ fontSize: "13px" }}>
                            Auto Play:
                            <Switch defaultChecked size='small' className='play-switch ms-2' checked={props.autoPlay} onChange={(e) => props.setAutoPlay(e)} />
                        </label>
                        <label className='m-0 text-dark text-capitalize cursor-pointer bg-light-grey rounded-1 p-2' style={{ fontSize: "13px" }}>
                            {plotShow ? 'Show' : 'Hide'} plots:
                            <Switch defaultChecked size='small' className='plot-switch ms-2' checked={plotShow} onChange={(e) => setPlotShow(e)} />
                        </label>
                    </div>
                </div>
            </div>
            <div className="h6 m-0 text-capitalize d-flex gap-3">
                <div className='bg-light-grey rounded-1 py-1 px-2 d-flex align-items-center gap-2 fw-normal'>
                    <i className="ri-vidicon-line bold fs-5"></i>
                    {eventData?.camera?.camera_name || eventData?.camera_name}
                </div>
                <div className='bg-light-grey rounded-1 py-1 px-2 d-flex align-items-center gap-2 fw-normal'>
                    <i className="ri-time-line bold fs-5"></i>
                    {moment(new Date(eventData?.alert_date)).format("DD-MM-YYYY h:mm:ss A")}
                </div>
                <div className='text-end d-flex align-items-end gap-2 ms-auto'>
                    {(pageAccess?.['update'].includes(userInfo?.user_role) && eventData?.service_name?.toLowerCase().includes("face")) && (
                        <Autocomplete
                            value={classesName}
                            open={open}
                            onChange={(e, v) => v !== null && onChangeDoubtfulDropdown(v)}
                            getOptionLabel={(option) => option.class_name}
                            renderOption={customRenderOption}
                            disableClearable
                            disablePortal
                            id="combo-box-demo"
                            options={options || []}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            sx={{ width: 250 }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Detection For"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading && <CircularProgress color="inherit" size={15} />}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />}
                            isOptionEqualToValue={(option, value) => value !== option}
                            loading={loading}
                        />
                    )}
                    {
                        isRetraingSupport && <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='btn py-2'>
                                {selectedClass || eventData?.class_name}
                            </Dropdown.Toggle>
                            {/* eventData?.additional_fields?.classes */}
                            <Dropdown.Menu className='p-0 px-2'>
                                {
                                    eventData?.additional_fields?.classes.map((item, i) => {
                                        return <Dropdown.Item
                                            key={i}
                                            onClick={() => setSelectedClass(item.class_name)}
                                            className='border-bottom px-0 py-2'
                                        >
                                            {item.class_name}
                                        </Dropdown.Item>
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    }


                    <button
                        className="btn px-4 py-2 border bg-white"
                        onClick={props.onHide}
                        type='button'
                    >
                        Cancel
                    </button>
                    {
                        isRetraingSupport && <button
                            className="btn px-4 py-2 border bg-warning"
                            onClick={submitEventDetails}
                            type='button'
                            disabled={isLoading}
                        >
                            {
                                isLoading ? <div className='d-flex align-items-center gap-2'><Spinner animation="border" size='sm' variant="dark" />
                                    Save
                                </div> : 'Save'
                            }

                        </button>
                    }

                </div>
            </div>
            <div className="event-carousel-container position-absolute w-100 start-0 end-0 top-0 bottom-0 m-auto h-fit-content" style={{ zIndex: "-1" }}>
                <div className="d-flex align-items-center justify-content-lg-between">
                    <div
                        className={`left-arrow rounded-circle text-center`}
                        onClick={() => props.prevBtn(eventData?.id)}
                    >
                        <i className="fas fa-chevron-left fs-5 me-1"></i>
                    </div>
                    <div
                        className={`right-arrow rounded-circle text-center`}
                        onClick={() => props.nextBtn(eventData?.id)}
                    >
                        <i className="fas fa-chevron-right fs-5 ms-1"></i>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EventDetailPage