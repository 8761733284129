import React, { useEffect, useMemo, useState } from 'react'
import Header from "../../components/Header";
import { Nav, Tab } from 'react-bootstrap';
import "./organization.css"
import MasterList from './components/MasterList'
import RolesAndResponsibility from './components/RolesAndResponsibility';
import SubscriptionManagementTable from './components/SubscriptionManagementTable'
import AnalyticsSetup from '../analyticsSetupPage/AnalyticsSetup';
import { useSelector } from "react-redux";
import { capitalizeFirstLater } from '../../global/Helper'
import { useSearchParams } from "react-router-dom";
import PageNotAccess from '../404PageNotFound/PageNotAccess';
import BackupAndRestore from './components/BackupAndRestore';
import StorageManagement from './components/StorageManagement';
import SoftwareUpdate from './components/SoftwareUpdate';
import AddOns from './components/AddOns'
import { API_ENDPOINT } from '../../constants/apiEndpoint';
import Loader from "../../components/Loader";

function Organization() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [components, setComponents] = useState({});

    let ActiveTab = searchParams.get("tab")

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { loading: accessPageLoading, success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]

    const organizationComponents = {
        "report-configuration": <AnalyticsSetup />,
        "subscription": <SubscriptionManagementTable />,
        "users": <MasterList />,
        "roles-&-responsibilities": <RolesAndResponsibility />,
        "backup-and-restore": <BackupAndRestore />,
        "storage-management": <StorageManagement />,
        "software-update": <SoftwareUpdate />,
    }

    useEffect(() => {
        setComponents(organizationComponents)
    }, [])

    const installedAddOnDataGet = useSelector((state) => state.InstalledAddOnData);
    const { success: getInstalledAddOnDataSuccess } = installedAddOnDataGet;

    useEffect(() => {
        // Reference to the iframe
        const iframe = document.getElementById('yourIframeId');

        // Your user token
        const userToken = 'your_user_token';

        // Send the user token to the iframe
        iframe?.contentWindow?.postMessage(userToken, '*');
    }, []);

    useEffect(() => {
        if (getInstalledAddOnDataSuccess) {
            setComponents(prevComponents => {
                const installedData = getInstalledAddOnDataSuccess.reduce((acc, item) => {
                    acc[item.label] = <iframe
                        title="Your Iframe Title"
                        src={`${API_ENDPOINT}/${item.name}?token=${userInfo?.token}`}
                        className='h-100 w-100'
                        frameBorder="0"
                        allowFullScreen
                        id="yourIframeId"
                    />;
                    return acc;
                }, {});

                return { ...organizationComponents, ...installedData };
            });
        }
    }, [getInstalledAddOnDataSuccess]);

    const currentTab = useMemo(() => ActiveTab || Object.keys(organizationComponents)[0], [ActiveTab])

    return (
        <div className='organization-container'>
            <Header props={{ page_name: "Organization" }} />
            <Tab.Container id="left-tabs-example" activeKey={currentTab} onSelect={e => setSearchParams({ "tab": e })}>
                <div className="d-flex gap-3">
                    <div className="pills-side-bar">
                        <Nav variant="pills" className="flex-column top-pill">
                            {components && Object.keys(components)?.map(e => (
                                <Nav.Item className='w-100' key={e}>
                                    <Nav.Link eventKey={e} className='text-truncate'>{e.split('-')?.map(e => capitalizeFirstLater(e) + " ")}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Nav variant="pills" className="flex-column bottom-pill">
                            <Nav.Item className='w-100' key={'kkk'}>
                                <Nav.Link eventKey={'add-ons'} className='text-truncate'>Add Ons</Nav.Link>
                            </Nav.Item>
                        </Nav>

                    </div>
                    <div className="tab-side-bar">
                        <Tab.Content>
                            {accessPageLoading ? (
                                <Loader />
                            ) : (
                                currentTab === "add-ons" ? (
                                    pageAccess?.[currentTab]?.includes(userInfo?.user_role) ? <AddOns /> : <PageNotAccess />
                                ) : (
                                    Object.keys(organizationComponents).includes(currentTab) ? (pageAccess?.[currentTab]?.includes(userInfo?.user_role) ? components[currentTab] : <PageNotAccess />) : components[currentTab]
                                )
                            )}
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </div>
    )
}

export default Organization