import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

function DateTimeRangeModal(props) {
    const [FromDateTimeValue, setFromDateTimeValue] = useState(null);
    const [ToDateTimeValue, setToDateTimeValue] = useState(null);

    return (
        <Modal
            show={props.show}
            onHide={() => props.closeDateTimeRangeModalShow()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className='date-time-range-modal'
            fullscreen='lg-down'
            centered
        >
            <Modal.Header closeButton className='px-4'>
                <Modal.Title id="contained-modal-title-vcenter">
                    Customize Filter
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center pt-0'>
                <div className='d-flex align-items-center justify-content-center range-hr-line mb-2'>
                    <div className='me-3 bold'>From</div>
                    <hr className='w-50 position-relative' />
                    <div className='ms-3 bold'>To</div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='d-flex justify-content-center gap-5 mb-4'>
                        <div className='calendar-box pb-3'>
                            <DateCalendar value={FromDateTimeValue} onChange={(newValue) => setFromDateTimeValue(newValue)} />
                            <DesktopTimePicker
                                value={FromDateTimeValue}
                                onChange={(newValue) => setFromDateTimeValue(newValue)}
                            />
                        </div>
                        <div className='calendar-box pb-3'>
                            <DateCalendar value={ToDateTimeValue} onChange={(newValue) => setToDateTimeValue(newValue)} />
                            <DesktopTimePicker
                                value={ToDateTimeValue}
                                onChange={(newValue) => setToDateTimeValue(newValue)}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            className='btn calendar-clear-btn bold me-2'
                            onClick={() => {
                                setFromDateTimeValue(null)
                                setToDateTimeValue(null)
                                if (props.onReset) { props.onReset() };
                            }}
                        >
                            Clear
                        </button>
                        <button
                            className='btn calendar-show-result-btn bold'
                            onClick={() => props.CustomDateHandlerChange(FromDateTimeValue, ToDateTimeValue)}
                        >
                            Show Result
                        </button>
                    </div>
                </LocalizationProvider>
            </Modal.Body>
        </Modal>
    )
}

export default DateTimeRangeModal