import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Tab, Nav, Table, Row, Col, Modal, OverlayTrigger, Tooltip, Dropdown, Spinner } from "react-bootstrap";
import "./ServicePage.css";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { CameraDeleteAlert, SwitchOn_Off_Alert } from "../../components/ToastAlert";
import "react-toastify/dist/ReactToastify.css";
import noEventsFound from "../../components/noData.gif";
import { serviceConfigDelete, getCamera, Services_On_Off, listServices, getServicesData, getTimeWiseCameraScheduleData, getSingleServicesData, refreshServiceActiveInactive, Update_Service_version, Retrain_process_service, Check_retrain_status } from "../../actions/servicesActions";
import Loader from "../../components/Loader";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { SERVICE_CONFIG_DELETE_RESET, SERVICE_ON_OFF_RESET } from "../../constants/servicesConstant";
import AllServicesModal from './AllServicesModal'
import ServiceConfigModal from './ServiceConfigModal'
import { API_ENDPOINT } from '../../constants/apiEndpoint'
import FaceRecognitionTab from './FaceRecognitionService/FaceRecognitionTab'
import { Switch } from 'antd';
import ServiceConfigurationScreen from './ServiceConfigurationScreen'
import PageNotAccess from '../404PageNotFound/PageNotAccess';
import SystemUtilization from './SystemUtilization'
import TimeWiseServiceModal from "./TimeWiseCameraService/TimeWiseServiceModal";
import SeatingArrangement from "./OccupancyMonitoring/SeatingArrangement";
import OccupancyMonitoringModal from "./OccupancyMonitoring/OccupancyMonitoringModal";
import { capitalizeFirstLater } from "../../global/Helper";
import { useSearchParams } from "react-router-dom";
import noServiceIcon from '../../images/no-service-icon.jpeg'


export default function ServiceAppliedScreen() {
    let [searchParams, setSearchParams] = useSearchParams();
    let ActiveTab = searchParams.get("tab")
    const [modalShow, setModalShow] = useState(false);
    const [cameraId, setCameraId] = useState(0);
    const [showService, setShowService] = useState(false);
    const [showServiceConfig, setShowServiceConfig] = useState(false);
    const [TimeWiseServiceModalShow, setTimeWiseServiceModalShow] = useState(false);
    const [TimeWiseServiceCameras, setTimeWiseServiceCameras] = useState([]);
    const [DisabledDropdown, setDisabledDropdown] = useState(false);
    const [CameraServiceConfigId, setCameraServiceConfigId] = useState(0);

    const [show, setShow] = useState(false);
    const [SeatingArrangementModalShow, setSeatingArrangementModalShow] = useState(false);
    const [OccupancyMonitoringModalShow, setOccupancyMonitoringModalShow] = useState(false);
    const [OccupancyMonitoringData, setOccupancyMonitoringData] = useState({});

    const [serviceName, setServiceName] = useState('');
    const [serviceId, setServiceId] = useState(0);

    const handleClose = () => setShowService(false)
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const servicesList = useSelector((state) => state.servicesList);
    const { serviceData: listServiceData } = servicesList;

    const ServicesData = useSelector((state) => state.ServicesData);
    const { error, loading, serviceData } = ServicesData;

    const deleteConfigService = useSelector((state) => state.deleteConfigService);
    const { loading: cameraDeleteLoad, deleteSuccess } = deleteConfigService;

    const On_Off_Services = useSelector((state) => state.On_Off_Services);
    const { loading: onOfLoad, success: onOfSuccess, error: onOfError } = On_Off_Services;

    const ServicesDataGetSingle = useSelector((state) => state.ServicesDataGetSingle);
    const { loading: getSingleCameraServiceLoading } = ServicesDataGetSingle;

    const check_retrain_status_state = useSelector((state) => state.retrain_status);
    const { loading: retrainStatusLoading, success: retrainStatusSuccess, error: retrainStatusError } = check_retrain_status_state;

    const [servicesSelectedName, setServicesSelectedName] = useState();
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [serviesId, setServiesId] = useState(null);
    const [ServiceSwitch, setServiceSwitch] = useState(false);
    const [InstructionShow, setInstructionShow] = useState(true);

    const [isRetraingSupport, setIsRetrainingSupport] = useState(false)

    const [versionData, setVersionData] = useState([
    ])
    const [retrainingStatus, setRetrainingStatus] = useState(false)

    const [intervalId, setIntervalId] = useState(null);

    const pageAccessGet = useSelector((state) => state.pageAccessGet);
    const { success: accessPage } = pageAccessGet;

    const windowPath = window.location.pathname?.replaceAll("/", "")
    const pageAccess = accessPage?.[`${windowPath}`]


    const [selectedVersion, setSelectedVersion] = useState(null)
    useEffect(() => {
        dispatch(getServicesData());
        dispatch(listServices());
        dispatch(refreshServiceActiveInactive());
    }, [dispatch])

    useEffect(() => {
        if (deleteSuccess) {
            setModalShow(false);
            toast(<CameraDeleteAlert camera_name={serviceName} />);
            dispatch(getServicesData());
            dispatch({ type: SERVICE_CONFIG_DELETE_RESET });
        }
    }, [deleteSuccess]);


    useEffect(() => {
        if (listServiceData?.service_installing_status) {
            const getAlertDataInterval = setInterval(() => {
                dispatch(getServicesData());
                dispatch(listServices());

            }, 10000);

            return () => {
                clearInterval(getAlertDataInterval)
            }
        }
    }, [listServiceData])


    useEffect(() => {
        if (onOfError) {
            toast(<SwitchOn_Off_Alert type={false} massage={onOfError} />);
            dispatch({ type: SERVICE_ON_OFF_RESET })
            setIsSwitchOn(!ServiceSwitch)
        }

        if (onOfSuccess) {
            toast(<SwitchOn_Off_Alert type={true} massage={onOfSuccess} />);
            dispatch({ type: SERVICE_ON_OFF_RESET })
            setIsSwitchOn(!ServiceSwitch)
            dispatch(getServicesData());
        }

    }, [onOfError, onOfSuccess])

    const selectedCameraService = useMemo(() => serviceData?.configured_cameras?.filter((rows) => {
        if (ActiveTab) {
            if (Number(rows.service.id) === Number(ActiveTab)) {
                return rows;
            }
        } else if (
            Number(rows.service.id) === Number(serviceData?.service_data[0]?.id)
        ) {
            return rows;
        }
    }), [ActiveTab, serviceData]);

    useEffect(() => {
        if (selectedCameraService) {
            const getCameraService = selectedCameraService.filter(e => !e.camera_id)[0]
            setIsRetrainingSupport(getCameraService?.service?.extra_details?.retraining_support)

            if (getCameraService?.service?.extra_details?.version) {
                setVersionData(getCameraService?.service?.extra_details?.version)
            } else {
                setVersionData([])
            }
            if (getCameraService?.service?.extra_details?.active_version) {
                setSelectedVersion(getCameraService?.service?.extra_details?.active_version)
            }

            setServicesSelectedName(getCameraService?.service.service_name)
            setIsSwitchOn(getCameraService?.extra_fields.is_active)
            setServiesId(getCameraService?.service_id)
        }
    }, [selectedCameraService])

    const TableRows = () => {
        if (loading === false) {
            const CameraData = selectedCameraService?.map((rows) =>
            (rows.camera?.camera_name && (
                <tr className="scroll" key={rows.id}>
                    <td>{capitalizeFirstLater(rows.camera.camera_name)}</td>
                    <td>
                        {moment(new Date(rows?.date_time)).format("DD-MM-YYYY  h:mm A")}
                    </td>
                    {rows.camera.active_status === true ? (
                        <td>
                            <i
                                className="ri-checkbox-blank-circle-fill me-1"
                                style={{ color: "var(--success)" }}
                            ></i>
                            Active
                        </td>
                    ) : (
                        <td>
                            <i
                                className="ri-checkbox-blank-circle-fill me-1"
                                style={{ color: "var(--danger)" }}
                            ></i>
                            Inactive
                        </td>
                    )}
                    <td
                        className="text-center d-flex justify-content-center"
                    >
                        <OverlayTrigger
                            trigger={['hover', 'click']}
                            overlay={
                                <Tooltip>
                                    {!pageAccess?.['delete'].includes(userInfo?.user_role) ? 'Access Denied!' : `Delete ${rows.camera.camera_name}`}
                                </Tooltip>
                            }
                        >
                            <span className="">
                                <button
                                    onClick={() => {
                                        setModalShow(true);
                                        setServiceName(rows.camera.camera_name)
                                        setServiceId(rows.service.id)
                                        setCameraId(rows.camera.camera_id);
                                    }}
                                    className="btn secondary_small_btn bg_danger d-flex align-items-center justify-content-center"
                                    disabled={!pageAccess?.['delete'].includes(userInfo?.user_role)}
                                    style={{
                                        pointerEvents: !pageAccess?.['delete'].includes(userInfo?.user_role) ? 'none' : '',
                                    }}
                                >
                                    <i className="ri-delete-bin-5-line "></i>
                                </button>
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            trigger={['hover', 'click']}
                            overlay={
                                <Tooltip>
                                    {!pageAccess?.['update'].includes(userInfo?.user_role) ? 'Access Denied!' : `Edit ${rows.camera.camera_name}`}
                                </Tooltip>
                            }
                        >
                            <span
                                onClick={() => {
                                    setServiceId(rows.service.id)
                                    setCameraId(rows.camera.camera_id);
                                    setServiceName(rows.service.service_name)
                                    dispatch(getSingleServicesData(rows.id))
                                }}
                            >
                                {rows.service.extra_details?.area_selection_type && (
                                    <button
                                        className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2"
                                        onClick={() => setShow(true)}
                                        disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                                        style={{
                                            pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '',
                                        }}
                                    >
                                        <i className="ri-edit-line"></i>
                                    </button>
                                )}
                                {rows.service.service_name.toLowerCase().includes("occupancy") && (
                                    <button
                                        className="btn secondary_small_btn d-flex align-items-center justify-content-center ms-2"
                                        onClick={() => {
                                            setSeatingArrangementModalShow(true);
                                        }}
                                        disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                                        style={{
                                            pointerEvents: !pageAccess?.['update'].includes(userInfo?.user_role) ? 'none' : '',
                                        }}
                                    >
                                        <i className="ri-edit-line"></i>
                                    </button>
                                )}
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                            trigger={['hover', 'click']}
                            overlay={
                                <Tooltip>
                                    {!pageAccess?.['update'].includes(userInfo?.user_role) ? 'Access Denied!' : `Edit schedule for ${rows.camera.camera_name}`}
                                </Tooltip>
                            }
                        >
                            <span className="ms-2">
                                <button
                                    className="btn secondary_small_btn d-flex align-items-center justify-content-center"
                                    onClick={() => {
                                        setServiceName(rows.service.service_name)
                                        setTimeWiseServiceModalShow(true)
                                        setTimeWiseServiceCameras([rows])
                                        dispatch(getTimeWiseCameraScheduleData(rows.id));
                                        setCameraServiceConfigId(rows.id)
                                        setDisabledDropdown(true)
                                    }}
                                    disabled={!pageAccess?.['update'].includes(userInfo?.user_role)}
                                >
                                    <i className="ri-time-line"></i>
                                </button>
                            </span>
                        </OverlayTrigger>
                    </td>
                </tr>
            ))
            );
            return CameraData;
        }
    };

    const SwitchHandler = async (checked) => {
        setServiceSwitch(checked)
        setIsSwitchOn(checked)
        const obj = {
            service_id: serviesId,
            is_active: checked,
            service_name: servicesSelectedName
        }
        dispatch(Services_On_Off(obj))
    }

    const retrain_process = async () => {
        setRetrainingStatus(true);
        const obj = {
            service_id: serviesId,
        };
        dispatch(Retrain_process_service(obj));

        // Clear any existing interval
        if (intervalId) {
            clearInterval(intervalId);
        }

        const newIntervalId = setInterval(() => {

            check_retrain_status_func();

        }, 5000);


        // Save the interval ID
        setIntervalId(newIntervalId);
    };
    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    const stopRetrainStatusCheck = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };
    const check_retrain_status_func = async () => {
        const obj = {
            service_id: serviesId,
        }
        dispatch(Check_retrain_status(obj))
    }

    useEffect(() => {
        const getCameraService = selectedCameraService?.filter(e => !e.camera_id)[0]

        if (getCameraService?.service?.extra_details?.is_retraining && isRetraingSupport) {
            const getAlertDataInterval = setInterval(() => {
                setRetrainingStatus(true)
                check_retrain_status_func()
            }, 5000);
            return () => {
                clearInterval(getAlertDataInterval)
                stopRetrainStatusCheck()
            }
        }
        if (isRetraingSupport) {

            check_retrain_status_func()
        }
    }, [serviesId])
    useEffect(() => {
        setRetrainingStatus(retrainStatusSuccess?.is_retraining)
        // if (!retrainStatusSuccess?.is_retraining) {
        //     stopRetrainStatusCheck()
        // }
    }, [retrainStatusSuccess])

    return (
        <div>
            {error?.status === 403 ? (
                <PageNotAccess />
            ) : (
                <Fragment>
                    <div className="service_box">
                        <SystemUtilization />
                        {
                            loading === false ? (
                                <Tab.Container
                                    id="left-tabs-example"
                                    activeKey={ActiveTab || serviceData?.service_data?.[0]?.id}
                                    defaultActiveKey={serviceData?.service_data?.[0]?.id}
                                    onSelect={e => {
                                        searchParams.set("tab", e)
                                        setSearchParams(searchParams)
                                    }}
                                >
                                    <div className="d-flex gap-3">
                                        <div className='services-options pills-side-bar'>
                                            <Nav variant="pills" className="flex-column border-top-right border-top-left">
                                                <OverlayTrigger
                                                    trigger={['hover', 'click']}
                                                    overlay={
                                                        <Tooltip>
                                                            {!pageAccess?.['install_service'].includes(userInfo?.user_role) ? 'Access Denied!' : 'Install New Service'}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="">

                                                        <Nav.Item style={{
                                                            cursor: !pageAccess?.['install_service'].includes(userInfo?.user_role) ? 'not-allowed' : 'pointer'
                                                        }}
                                                        >
                                                            <button
                                                                className="add-service-btn bg-dark text-white border-top-right border-top-left"
                                                                onClick={() => setShowService(true)}
                                                                disabled={!pageAccess?.['install_service'].includes(userInfo?.user_role)}
                                                                style={
                                                                    {
                                                                        pointerEvents: !pageAccess?.['install_service'].includes(userInfo?.user_role) ? 'none' : '',
                                                                    }}
                                                            >
                                                                <i className="fas fa-plus me-2"></i>
                                                                Add Service
                                                            </button>
                                                        </Nav.Item>
                                                    </span>
                                                </OverlayTrigger>
                                                <div className="service-options-container">
                                                    {serviceData?.service_data?.map((data, index) => {
                                                        return (
                                                            <Nav.Item id={data.id} key={data.id} className="service_tab position-relative">
                                                                <Nav.Link
                                                                    id={data.id}
                                                                    eventKey={data.id}
                                                                    className="d-flex align-items-center gap-2 justify-content-start "
                                                                >
                                                                    {serviceData?.configured_cameras?.map((camera) => {
                                                                        if (camera.camera_id === null && camera.service_id === data.id) {
                                                                            return (
                                                                                <div key={camera.id} className="dots_active"
                                                                                    style={{ background: camera.extra_fields.is_active ? "green" : "red" }}>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                    <img
                                                                        src={data.icon}
                                                                        width={30}
                                                                        height={30}
                                                                        className="rounded-circle object-cover"
                                                                        alt={data.service_name}
                                                                        onError={(e) => e.target.src = noServiceIcon}
                                                                    />
                                                                    <p style={{ fontSize: "13px" }} className="m-0 text-capitalize text-truncate" title={data.service_name}>{data.service_name}</p>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        );
                                                    })}
                                                </div>
                                            </Nav>
                                        </div>
                                        <div className="services-tab tab-side-bar">
                                            {servicesSelectedName && (
                                                <div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
                                                    <div className="switch_servies my-2">
                                                        <label className="services_name text-truncate" title={capitalizeFirstLater(servicesSelectedName)}>{capitalizeFirstLater(servicesSelectedName)}</label>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'click']}
                                                            overlay={!pageAccess?.['install_service'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}
                                                        >
                                                            <span className="">
                                                                <Switch
                                                                    checked={isSwitchOn}
                                                                    disabled={!pageAccess?.['install_service'].includes(userInfo?.user_role)}
                                                                    onChange={(e) => { SwitchHandler(e) }}
                                                                    size="small"
                                                                    loading={onOfLoad}
                                                                    style={
                                                                        {
                                                                            pointerEvents: !pageAccess?.['install_service'].includes(userInfo?.user_role) ? 'none' : '',
                                                                        }}
                                                                />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="d-flex gap-2 align-items-center ms-auto mb-3 mb-md-0">
                                                        {isRetraingSupport &&
                                                            <OverlayTrigger
                                                                trigger={['hover', 'click']}
                                                                overlay={
                                                                    !isSwitchOn ?
                                                                        <Tooltip>
                                                                            Enable the Service
                                                                        </Tooltip> : <Tooltip>Retrain</Tooltip>
                                                                }
                                                            >
                                                                <button type="" className=" btn-warning text-black py-1 px-2 fs-7 " style={{ borderRadius: '3px', height: '30px', opacity: isSwitchOn ? 1 : 0.7 }}
                                                                    onClick={() => retrain_process()}
                                                                    disabled={retrainingStatus ? retrainingStatus : !isSwitchOn}
                                                                >
                                                                    {
                                                                        retrainingStatus ? <div className="d-flex align-items-center gap-2">
                                                                            <Spinner animation="border" size="sm" variant="dark" />  Retraining
                                                                        </div> : 'Retrain'
                                                                    }


                                                                </button>
                                                            </OverlayTrigger>
                                                        }
                                                        {versionData.length > 0 &&
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='btn py-2 d-flex align-items-center justify-content-center fs-7' style={{ borderRadius: '3px', height: '30px' }}>
                                                                    {selectedVersion === 0 ? versionData[selectedVersion]?.version_name : selectedVersion ? versionData[selectedVersion]?.version_name : 'Versions'}
                                                                </Dropdown.Toggle>
                                                                {/* modalEvent?.additional_fields?.classes */}
                                                                <Dropdown.Menu className='p-0 px-2'>
                                                                    {
                                                                        versionData.map((item, index) => {
                                                                            return <Dropdown.Item
                                                                                key={index}
                                                                                eventKey={ActiveTab || serviceData?.service_data?.[0]?.id}
                                                                                className='border-bottom px-0 py-1  fs-7 '
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    setSelectedVersion(index)
                                                                                    const data = {
                                                                                        service_id: serviesId,
                                                                                        selected_index: index
                                                                                    }

                                                                                    dispatch(Update_Service_version(data))
                                                                                }}
                                                                            >
                                                                                {item.version_name}
                                                                            </Dropdown.Item>
                                                                        })
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }
                                                        {selectedCameraService?.[0]?.service?.service_name.toLowerCase().includes("occupancy") && (
                                                            <OverlayTrigger
                                                                trigger={['hover', 'click']}
                                                                overlay={
                                                                    <Tooltip>
                                                                        Occupancy Monitoring Setup
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button
                                                                    className="btn d-flex align-items-center occupancy-setup-btn"
                                                                    onClick={() => {
                                                                        setOccupancyMonitoringModalShow(true)
                                                                        setOccupancyMonitoringData(selectedCameraService.filter(e => !e.camera_id)[0])
                                                                    }}
                                                                >
                                                                    <i className="ri-settings-3-line me-2"></i>
                                                                    Setup
                                                                </button>
                                                            </OverlayTrigger>
                                                        )}
                                                        <OverlayTrigger
                                                            trigger={['hover', 'click']}
                                                            overlay={
                                                                <Tooltip>
                                                                    Add camera schedule
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button
                                                                className="btn secondary_small_btn d-flex align-items-center justify-content-center"
                                                                onClick={() => {
                                                                    const config_id = selectedCameraService.filter(e => e.camera === null)[0].id
                                                                    dispatch(getTimeWiseCameraScheduleData(config_id));
                                                                    setCameraServiceConfigId(config_id);
                                                                    setTimeWiseServiceModalShow(true);
                                                                    setServiceName(selectedCameraService?.[0]?.service?.service_name);
                                                                    setTimeWiseServiceCameras(selectedCameraService);
                                                                    setDisabledDropdown(false)
                                                                }}
                                                            >
                                                                <i className="ri-time-line fs-5"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                        <div className="d-flex align-items-center ">
                                                            <OverlayTrigger
                                                                trigger={['hover', 'click']}
                                                                overlay={
                                                                    <Tooltip>
                                                                        {!pageAccess?.['create'].includes(userInfo?.user_role) ? 'Access Denied!' : 'Add Camera'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className="">
                                                                    <button className="btn secondary_small_btn  d-flex align-items-center justify-content-center"
                                                                        onClick={() => {
                                                                            dispatch(getCamera(selectedCameraService?.[0]?.service.id))
                                                                            setShowServiceConfig(true)
                                                                            setServiceName(selectedCameraService?.[0]?.service?.service_name)
                                                                            setServiceId(selectedCameraService?.[0]?.service.id);
                                                                        }}
                                                                        disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                                                                        style={
                                                                            {
                                                                                pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                                                                            }}
                                                                    >
                                                                        <i className="ri-video-add-line fs-5"></i>
                                                                    </button>
                                                                </span>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div >
                                                </div >
                                            )}
                                            {serviceData?.configured_cameras?.length !== 0 ?
                                                <Tab.Content className="p-3 all-services-container">
                                                    {selectedCameraService?.[0]?.service?.service_name?.includes('Face Recognition') ? (
                                                        <Tab.Pane eventKey={selectedCameraService?.[0]?.service.id} className="face-recognition-tab-panel" style={{ height: "100%" }}>
                                                            <FaceRecognitionTab service_name={selectedCameraService?.[0]?.service?.service_name} service_id={selectedCameraService?.[0].service.id} pageAccess={pageAccess} />
                                                        </Tab.Pane>
                                                    ) : (
                                                        <Tab.Pane eventKey={selectedCameraService?.[0]?.service?.id} className="position-relative">
                                                            <div className="service-selected-table">
                                                                <Table responsive="sm">
                                                                    <thead className="eventstable  p-relative">
                                                                        <tr>
                                                                            <th className="s_cmaera_name">
                                                                                Camera Name
                                                                            </th>
                                                                            <th className="s_date_time">
                                                                                Date & Time
                                                                            </th>
                                                                            <th className="s_status">
                                                                                Status
                                                                            </th>
                                                                            <th className="s_actions text-center">
                                                                                Actions
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {selectedCameraService?.length == 1 ?
                                                                            <tr className="bg-white">
                                                                                <td colSpan={4} className="bg-white">
                                                                                    <div className="d-flex justify-content-center align-items-center w-100">
                                                                                        <div className="text-center">
                                                                                            <img src={noEventsFound} className="" alt="" height={"200px"} width={"200px"} />
                                                                                            <h5 style={{ opacity: "0.5" }}>No details were found. Once you add a camera,<br></br> all the information will appear here.</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr> :
                                                                            TableRows()
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Tab.Pane>
                                                    )}
                                                </Tab.Content>
                                                : <div className=" text-center mt-5">
                                                    <img src={noEventsFound} alt="" height={"200px"} width={"200px"} />
                                                    <h4>No details were found. Once you add a service,<br /> all the information will appear here.</h4>
                                                </div>}
                                        </div >

                                    </div >
                                </Tab.Container >

                            ) : (
                                <Loader />
                            )

                        }
                    </div >
                    <ServiceConfigModal
                        show={showServiceConfig}
                        closeServiceConfigModal={() => setShowServiceConfig(false)}
                        service_name={serviceName}
                        service_id={serviceId}
                    >
                    </ServiceConfigModal>
                    <Modal
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        show={showService}
                        dialogClassName="modal-90w"
                        fullscreen='lg-down'
                        onHide={() => {
                            setShowService(false)
                        }}
                    >
                        <Modal.Header className='custom-modal-styling' closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                Add Services
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">
                            <AllServicesModal
                                size="xl"
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                handle_close={(e) => e === true && handleClose()}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={modalShow}
                    >
                        <Modal.Body>
                            <DeleteConfirmModal
                                item_name={serviceName}
                                onHide={() => setModalShow(false)}
                                dispatch_item={serviceConfigDelete(cameraId, serviceId)}
                                loading={cameraDeleteLoad}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal
                        className="seating-arrangement-modal"
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        scrollable={true}
                        show={SeatingArrangementModalShow}
                        onHide={() => setSeatingArrangementModalShow(false)}
                    >
                        <SeatingArrangement
                            setShow={() => setSeatingArrangementModalShow(false)}

                        />
                    </Modal>
                    <Modal
                        scrollable={true}
                        show={show}
                        size="xl"
                        onHide={() => setShow(false)}
                        centered
                    >
                        {!getSingleCameraServiceLoading && (
                            <ServiceConfigurationScreen
                                setShow={() => setShow(false)}
                            />
                        )}
                    </Modal>
                    <Modal
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        className="time-wise-service-modal"
                        centered
                        show={TimeWiseServiceModalShow}
                        onHide={() => setTimeWiseServiceModalShow(false)}
                    >
                        <TimeWiseServiceModal
                            serviceName={serviceName}
                            CameraOption={TimeWiseServiceCameras.filter(e => e.camera !== null)?.map(e => (e.camera.camera_name))}
                            onHide={() => setTimeWiseServiceModalShow(false)}
                            DisabledDropdown={DisabledDropdown}
                            CameraServiceConfigId={CameraServiceConfigId}
                        />
                    </Modal>
                    <Modal
                        scrollable={true}
                        show={OccupancyMonitoringModalShow}
                        size="lg"
                        onHide={() => setOccupancyMonitoringModalShow(false)}
                        centered
                    >
                        <OccupancyMonitoringModal
                            occupancyMonitoringModalHide={() => setOccupancyMonitoringModalShow(false)}
                            data={OccupancyMonitoringData}
                        />
                    </Modal>
                </Fragment>
            )}

        </div >

    );
}