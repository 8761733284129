import React, { useState, useEffect, Fragment, useMemo } from "react";
import Header from "../../components/Header";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getLivecamData } from '../../actions/livecamAction'
import { Alert, Nav, Tab, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import Loader from "../../components/Loader";
import './livecam.css'
import LivecamFilter from "./LivecamFilter";
import noCameraFound from '../../images/no-camera-found.gif'
import AllCameraTabItems from "./components/AllCameraTabItems";
import PageNotAccess from '../404PageNotFound/PageNotAccess';
import LiveStreamingTab from "./components/LiveStreamingTab";
import AddCamera from "../configurepage/AddCamera";
import { RESET_LIVECAM_VIDEO_REF } from "../../constants/livecamConstant";

const LiveCam = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(null)
  const [groupNameFilterValue, setGroupNameFilterValue] = useState([])
  const [serviceNameFilterValue, setServiceNameFilterValue] = useState([])
  const [statusFilterValue, setStatusFilterValue] = useState([])
  const [addLiveCamModalShow, setAddLiveCamModalShow] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPipModeStart, setIsPipModeStart] = useState(false);

  const dispatch = useDispatch();

  const handleClose = () => setAddLiveCamModalShow(false);
  const handleShow = () => setAddLiveCamModalShow(true);

  const LivecamData = useSelector((state) => state.LivecamData);
  const { error: cameraError, loading: cameraLoading, livecamData: all_camera_data } = LivecamData;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const videoRefGet = useSelector((state) => state.videoRefGet);
  const { videoRefObj } = videoRefGet;

  const pageAccessGet = useSelector((state) => state.pageAccessGet);
  const { success: accessPage } = pageAccessGet;

  const windowPath = window.location.pathname?.replaceAll("/", "")
  const pageAccess = accessPage?.[`${windowPath}`]

  function searchValueHandler(params) {
    if (params !== '') {
      searchParams.set("query", params);
    } else {
      searchParams.delete("query");
    }
    setSearchParams(searchParams)
  }

  function groupNameFilterHandler(params) {
    setGroupNameFilterValue(params)
    if (params?.length !== 0) {
      searchParams.set("group_ids", JSON.stringify([params[0]?.id]));
    }
    if (params?.length === 0 || params[0]?.camera_group === "All Group") {
      searchParams.delete("group_ids");
    }
    setSearchParams(searchParams)
  }

  function serviceNameFilterHandler(params) {
    setServiceNameFilterValue(params)
    if (params?.length !== 0) {
      searchParams.set("service_ids", JSON.stringify([params[0]?.id]));
    }
    if (params?.length === 0 || params[0]?.camera_group === "All Services") {
      searchParams.delete("service_ids");
    }
    setSearchParams(searchParams)
  }

  function statusFilterHandler(params) {
    setStatusFilterValue(params)
    if (params[0]?.camera_group !== "Both") {
      searchParams.set("status", params[0]?.camera_group === "Active");
    }
    if (params?.length === 0 || params[0]?.camera_group === "Both") {
      searchParams.delete("status");
    }
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setSearchParams({})
  }, [])


  useEffect(() => {
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    dispatch(getLivecamData(params));
  }, [searchParams, dispatch]);

  const getSingleCamera = useMemo(() => all_camera_data?.cameras_data?.filter(e => e.camera_id === Number(activeTab))[0], [activeTab, all_camera_data])

  useEffect(() => {
    setActiveTab(all_camera_data?.cameras_data?.[0]?.camera_id)
  }, [all_camera_data?.cameras_data?.[0]?.camera_id])

  useEffect(() => {
    videoRefObj?.on('enterpictureinpicture', function () {
      setIsPipModeStart(true)
    });
    videoRefObj?.on('leavepictureinpicture', function () {
      setIsPipModeStart(false)
      setPlayVideo(false)
    });
  }, [videoRefObj])

  const disposeVideoObject = () => {
    if (videoRefObj) {
      if (!isPipModeStart && !videoRefObj.isDisposed()) {
        videoRefObj.dispose();
        dispatch({ type: RESET_LIVECAM_VIDEO_REF })
      }
    }
  }

  return (
    <div className="live-cam-container">
      {cameraError?.status === 403 ? (
        <PageNotAccess />) : (
        <Fragment>
          {cameraError?.data?.detail ? (
            <Alert variant="danger">{cameraError?.data?.detail}</Alert>
          ) : (
            <Fragment>
              <Header
                props={{
                  page_name: " Livecam",
                }}
              />
              <Tab.Container
                id="left-tabs-example"
                activeKey={activeTab}
                onSelect={e => {
                  setActiveTab(e)
                  setPlayVideo(false)
                  setLoading(false)
                  disposeVideoObject()
                }}
              >
                <div className="d-flex gap-3">
                  <div className="pills-side-bar">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item style={{
                        cursor: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'not-allowed' : 'pointer'
                      }}
                      >
                        <OverlayTrigger trigger={['hover', 'click']} overlay={!pageAccess?.['create'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                          <span className="">
                            <Nav.Link className="p-0 mb-0 text-center"
                              onClick={() => handleShow()}
                              disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                              style={{
                                pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                              }}
                            >
                              <div
                                className="add-live-cam-heading"
                              >
                                <h5 className="text-white m-0">
                                  <i className="fas fa-plus me-2"></i>
                                  Add LiveCam
                                </h5>
                              </div>
                            </Nav.Link>
                          </span>
                        </OverlayTrigger>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="live-cam-filter-container" style={{
                          cursor: "auto",
                        }} >
                          <LivecamFilter
                            groupNameFilterValue={groupNameFilterValue}
                            serviceNameFilterValue={serviceNameFilterValue}
                            statusFilterValue={statusFilterValue}
                            groupData={all_camera_data?.group_data}
                            serviceData={all_camera_data?.service_data}
                            searchValueHandler={searchValueHandler}
                            groupNameFilterHandler={groupNameFilterHandler}
                            serviceNameFilterHandler={serviceNameFilterHandler}
                            statusFilterHandler={statusFilterHandler}
                            advanceFilter={true}
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <div className="live-cam-scroll-container">
                        {cameraLoading ? (
                          <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <Loader margin="0" />
                          </div>
                        ) : (
                          <>
                            {all_camera_data?.cameras_data?.length === 0 ? (
                              <div
                                style={{
                                  color: "var(--light-grey)",
                                  fontSize: "12px",
                                }}
                                className="d-flex flex-column align-items-center justify-content-center h-100"
                              >
                                No Camera’s Added
                              </div>
                            ) : (
                              all_camera_data?.cameras_data?.map((camera) => (
                                <Nav.Item
                                  key={camera.camera_id}
                                  title={camera.camera_name}
                                >
                                  <Nav.Link eventKey={camera.camera_id}>
                                    <AllCameraTabItems camera={camera} />
                                  </Nav.Link>
                                </Nav.Item>
                              )))}
                          </>
                        )}
                      </div>
                    </Nav>
                  </div>
                  <div className="tab-side-bar">
                    <Tab.Content>
                      {cameraLoading ? (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                          <Loader margin="0" />
                        </div>
                      ) :
                        <>
                          {all_camera_data?.cameras_data?.length === 0 ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ width: "-webkit-fill-available", height: "100%" }}>
                              <div className="text-center background-none">
                                <img className='background-none opacity no-camera-found' src={noCameraFound} alt="" />
                                <p className='opacity'>No details were found. Once you add a camera,<br /> all the information will appear here.</p>
                                <OverlayTrigger trigger={['hover', 'click']} overlay={!pageAccess?.['create'].includes(userInfo?.user_role) ? <Tooltip>Access Denied!</Tooltip> : <></>}>
                                  <span className="">
                                    <button
                                      className='no-user-btn btn'
                                      onClick={() => handleShow()}
                                      disabled={!pageAccess?.['create'].includes(userInfo?.user_role)}
                                      style={{
                                        pointerEvents: !pageAccess?.['create'].includes(userInfo?.user_role) ? 'none' : '',
                                      }}
                                    >
                                      <i className="fas fa-plus me-2"></i>
                                      Add LiveCam
                                    </button>
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </div>
                          ) : (
                            <LiveStreamingTab
                              camera={getSingleCamera}
                              PlayVideo={playVideo}
                              setPlayVideo={setPlayVideo}
                              loading={loading}
                              setLoading={setLoading}
                            />
                          )}
                        </>
                      }
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </Fragment>)}
        </Fragment>
      )}
      <Modal
        scrollable={true}
        show={addLiveCamModalShow}
        size="lg"
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <AddCamera
            handleClose={(e) => e === true && handleClose()}
            token={userInfo?.token}
            cameraGroupData={all_camera_data?.group_data}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LiveCam;
