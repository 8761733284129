import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import "./EventPage.css";
import Header from "../../components/Header";
import { Image, Modal, Alert } from "react-bootstrap";
import noEventsFound from "../../components/noData.gif";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { getAlertData } from "../../actions/servicesActions";
import Loader from "../../components/Loader";
import { Pagination, Table, } from 'antd';
import { getNotification, getEventDetails } from "../../actions/dataActions";
import { GetEventFilterOption } from "../../actions/EventActions";
import EventDetailPage from './EventDetailPage'
import PageNotAccess from '../404PageNotFound/PageNotAccess';
import MultiselectDropdown from "./components/MultiselectDropdown";
import DateTimeRangeModal from "../analyticsPage/analyticsComponents/DateTimeRangeModal";
import noPhoto from '../../images/no-service-icon.jpeg'

const Event = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let page_params = searchParams.get("page") ? searchParams.get("page") : 1;
  let search_params = searchParams.get("search") ? searchParams.get("search") : '';
  let camera_name_params = searchParams.get("camera_name");
  let service_name_params = searchParams.get("service_name");
  let date_range_params = searchParams.get("dateRange");
  let getDoubtful = searchParams.get("doubtful");

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const [show, setShow] = useState(false);
  const [modalEvent, setModalEvent] = useState({});

  const [selectedClasses, setSelectedClasses] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [previousEvent, setPreviousEvent] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);
  const [autoPlay, setAutoPlay] = useState(true);
  const [dateTimeRangeModalShow, setDateTimeRangeModalShow] = useState(false);
  const [serviceCheckbox, setServiceCheckbox] = useState([])
  const [cameraCheckbox, setCameraCheckbox] = useState([])
  const [FilterClasses, setFilterClasses] = useState({});
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [latestEventId, setLatestEventId] = useState(null);
  const prevAlertRef = useRef();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const AlertData = useSelector((state) => state.AlertData);
  const { loading: alertLoad, error: alertError, getAlert } = AlertData;

  const EventFiltrationData = useSelector((state) => state.GetEventFilterOption);
  const { loading: filterLoad, EventfilteOptionsData } = EventFiltrationData;

  const eventFilterObject = useMemo(() => ({
    page: page_params,
    search: search_params,
    camera_name: camera_name_params,
    service_name: service_name_params,
    dateRange: date_range_params,
    class_values: selectedClasses,
    doubtful: getDoubtful,
  }), [page_params, search_params, camera_name_params, service_name_params, date_range_params, selectedClasses, getDoubtful])


  useEffect(() => {
    setSearchParams({});
    if (divRef.current) {
      setDivHeight(divRef.current.clientHeight + 178);
    }
  }, []);

  useEffect(() => {
    const paramsData = { service_name: eventFilterObject.service_name };
    dispatch(GetEventFilterOption(paramsData));
  }, [eventFilterObject.service_name]);

  useEffect(() => {
    dispatch(getAlertData(eventFilterObject));
    setShowLoading(true)
  }, [dispatch, eventFilterObject]);

  useEffect(() => {
    const getAlertDataInterval = setInterval(() => {
      if (!alertLoad) {
        dispatch(getAlertData(eventFilterObject));
        setShowLoading(false);
      }
    }, 2000);
    return () => {
      clearInterval(getAlertDataInterval);
    };
  }, [eventFilterObject, dispatch, alertLoad]);

  useEffect(() => {
    if (getAlert?.results?.length > 0) {
      const latestEvent = getAlert.results[0];
      if (latestEvent.id !== latestEventId) {
        setLatestEventId(latestEvent.id);
        if (prevAlertRef.current && latestEvent.id !== prevAlertRef.current.results[0]?.id) {
          if (autoPlay) {
            handleShow(latestEvent.id);
          }
        }
      }
      prevAlertRef.current = getAlert;
    }
  }, [getAlert, autoPlay]);

  const searchFilter = (e) => {
    searchParams.set("search", e)
    searchParams.delete("page")
    setSearchParams(searchParams)
  };

  const handlePagination = (e) => {
    searchParams.set("page", e)
    setSearchParams(searchParams)
    setShowLoading(true)
  };

  const handleClose = () => {
    setShow(false);
    setPreviousEvent(null);
    setNextEvent(null);
  };

  const handlePageChange = useCallback(async (newPage) => {
    searchParams.set("page", newPage);
    setSearchParams(searchParams);
    setShowLoading(true);
    await dispatch(getAlertData({ ...eventFilterObject, page: newPage }));
    setShowLoading(false);
  }, [dispatch, eventFilterObject, searchParams, setSearchParams]);

  const handlePrevEvent = useCallback(async () => {
    if (selectedEventIndex === 0) {
      const prevPage = getPageValue(getAlert?.previous);
      if (prevPage) {
        await handlePageChange(prevPage);
        const lastEventOnPrevPage = getAlert?.results[getAlert?.results.length - 1];
        if (lastEventOnPrevPage) {
          setModalEvent(lastEventOnPrevPage);
          getEventDetails(lastEventOnPrevPage.id, userInfo?.token);
          setSelectedEventIndex(getAlert?.results.length - 1);
        }
      }
    } else {
      const prevIndex = (selectedEventIndex - 1 + getAlert?.results?.length) % getAlert?.results?.length;
      setModalEvent(getAlert?.results[prevIndex]);
      getEventDetails(getAlert?.results[prevIndex].id, userInfo?.token);
      setSelectedEventIndex(prevIndex);
    }
  }, [selectedEventIndex, getAlert?.results, getAlert?.previous, handlePageChange, userInfo?.token]);

  const handleNextEvent = useCallback(async () => {
    if (selectedEventIndex === getAlert?.results?.length - 1) {
      const nextPage = getPageValue(getAlert?.next);
      if (nextPage) {
        await handlePageChange(nextPage);
        const firstEventOnNextPage = getAlert?.results[0];
        if (firstEventOnNextPage) {
          setModalEvent(firstEventOnNextPage);
          getEventDetails(firstEventOnNextPage.id, userInfo?.token);
          setSelectedEventIndex(0);
        }
      }
    } else {
      const nextIndex = (selectedEventIndex + 1) % getAlert?.results?.length;
      setModalEvent(getAlert?.results[nextIndex]);
      getEventDetails(getAlert?.results[nextIndex].id, userInfo?.token);
      setSelectedEventIndex(nextIndex);
    }
  }, [selectedEventIndex, getAlert?.results, getAlert?.next, handlePageChange, userInfo?.token]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (show) {
        if (event.key === 'ArrowLeft') {
          handlePrevEvent();
        } else if (event.key === 'ArrowRight') {
          handleNextEvent();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, handlePrevEvent, handleNextEvent]);

  const handleShow = async (id) => {
    setShow(true);
    const { data } = await getEventDetails(id, userInfo?.token);
    setModalEvent(data);
    setSelectedEventIndex(getAlert?.results?.findIndex(event => event.id === id));
    setShowLoading(false);
    dispatch(getAlertData(eventFilterObject));
    dispatch(getNotification());
  };

  const crumbHandler = () => {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active" aria-current="page">
            Events
          </li>
        </ol>
      </nav>
    );
  };

  const onChangeMultiselectDropdown = (key, newValue) => {
    if (newValue.length !== 0) {
      searchParams.set(key, JSON.stringify(newValue?.map((e) => ((e.title)))))
      searchParams.delete("page")
    } else {
      searchParams.delete(key)
    }
    setSearchParams(searchParams)
  };

  function padZero(number) {
    return number < 10 ? "0" + number : number;
  }

  const DateHandlerChange = (startCustomDate, endCustomDate) => {
    if (startCustomDate && endCustomDate) {
      function formatDate(currentDate) {
        let year = currentDate.getFullYear();
        let month = padZero(currentDate.getMonth() + 1);
        let day = padZero(currentDate.getDate());
        let hours = padZero(currentDate.getHours());
        let minutes = padZero(currentDate.getMinutes());
        let seconds = padZero(currentDate.getSeconds());

        return [year, month, day, hours, minutes, seconds].join('-');
      }

      searchParams.delete("page")
      searchParams.set("dateRange", JSON.stringify([formatDate(startCustomDate.$d), formatDate(endCustomDate.$d)]))
      setSearchParams(searchParams)
      setDateTimeRangeModalShow(false)
    }
  }

  const resetDate = () => {
    searchParams.delete("dateRange")
    setSearchParams(searchParams)
  }

  const eventTableColumns = [
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      key: 'event_name',
      ellipsis: true,
      // width: "35%",
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      ellipsis: true,
    },
    {
      title: 'Service Name',
      dataIndex: 'service_name',
      key: 'service_name',
      ellipsis: true,
    },
    {
      title: 'Camera name',
      dataIndex: 'camera_name',
      key: 'camera_name',
      ellipsis: true,
    },
    {
      title: 'Date & Time',
      dataIndex: 'date_and_time',
      key: 'date_and_time',
      width: "18%",
    },
  ];

  const getPageValue = (link) => {
    if (link) {
      const url = new URL(link);

      const urlParams = new URLSearchParams(url.search);

      const pageValue = urlParams.get('page');

      return pageValue
    }
  }

  const eventTableData = getAlert?.results?.map((rows) => {
    return {
      key: rows.id,
      event_name: (
        <div id={`${rows.id}`} className="text-capitalize text-truncate" title={rows.message}>
          {" "}
          <Image
            height={30}
            width={40}
            className="me-1 border_radius_sm"
            src={rows.image || noPhoto}
            onError={(e) => e.target.src = noPhoto}
          />{" "}
          <span>{rows.message}</span>
        </div>
      ),
      details: (
        <div className="text-capitalize" detail_seen={`${rows.detail_seen}`} title={rows?.message_details?.join(", ")}>
          <div className="text-truncate" style={{ width: "210px" }}>
            {rows?.message_details.length > 0 ? rows?.message_details?.join(", ") : '-'}
          </div>
        </div>
      ),
      service_name: (
        <div className="text-capitalize text-truncate" title={rows.service?.label || rows.service_name}>
          {rows.service?.label || rows.service_name}
        </div>
      ),
      camera_name: (
        <div className="text-capitalize text-truncate" title={rows.camera?.camera_name || rows.camera_name}>
          {rows.camera?.camera_name || rows.camera_name}
        </div>
      ),
      date_and_time: (
        <div title={rows.alert_date} >
          {moment(new Date(rows?.alert_date)).format("DD-MM-YYYY   h:mm A")}
          {rows.additional_fields?.doubtful?.length > 0 && (
            <div className="ribbon ribbon-top-right"><span></span></div>
          )}
        </div>
      )
    }
  })

  useEffect(() => {
    if (autoPlay) {
      const alertData = getAlert?.results?.[0]

      if (alertData?.detail_seen === false) {
        setShow(true)
        setModalEvent(alertData)
        getEventDetails(alertData.id, userInfo?.token)
      }
    }
  }, [getAlert?.results?.length, autoPlay])

  return (
    <div className="event-page-container position-relative">
      {alertError?.status === 403 ? (
        <PageNotAccess />
      ) : (
        <>
          <div ref={divRef}>
            <div className="d-flex justify-content-between align-items-center">
              <Header props={{ page_name: " Events", crumb: crumbHandler() }} />
            </div>
            <div className="d-flex gap-2 flex-wrap flex-lg-nowrap mb-2 mb-lg-0">
              <input
                type="text"
                className="search_bar form-control text-truncate fw-semibold border-grey"
                placeholder="Search By Event Name, Service Name and Camera Name"
                title="Search by event name, service name and camera name"
                onChange={(e) => searchFilter(e.target.value)}
              />
              <MultiselectDropdown
                SelectedItemsCount={JSON.parse(service_name_params)?.length}
                placeholder="Service Name"
                onChange={(e) => onChangeMultiselectDropdown("service_name", e)}
                options={EventfilteOptionsData?.services}
                displayValue="title"
                showOptionArrow={true}
                setCheckBoxValue={setServiceCheckbox}
                CheckBoxValue={serviceCheckbox}
                onTooltipChange={(e) => setSelectedClasses(e)}
                FilterClasses={FilterClasses}
                setFilterClasses={setFilterClasses}
                loading={filterLoad}
                className='ms-lg-auto'
              />

              <MultiselectDropdown
                SelectedItemsCount={JSON.parse(camera_name_params)?.length}
                placeholder="Camera Name"
                onChange={(e) => onChangeMultiselectDropdown("camera_name", e)}
                options={EventfilteOptionsData?.cameras}
                displayValue="title"
                showOptionArrow={false}
                setCheckBoxValue={setCameraCheckbox}
                CheckBoxValue={cameraCheckbox}
                disableTooltipDropdown={false}
              />
              <button
                className="bg-transparent text-dark border-grey rounded-1 px-2 py-1 event-date-range-filter"
                onClick={() => setDateTimeRangeModalShow(true)}
              >
                <i className="ri-calendar-line fs-6"></i>
              </button>
            </div>
          </div >
          {
            alertError ? (
              <Alert className="mt-3" variant="danger">{alertError?.data?.detail}</Alert>
            ) : (
              <>
                <Table
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        handleShow(record.key)
                      },
                    };
                  }}
                  rowClassName={(record) => `${!eval(record.details.props.detail_seen) && 'event_unseen'} cursor-pointer position-relative`}
                  columns={eventTableColumns}
                  dataSource={!(showLoading && alertLoad) && eventTableData} pagination={false}
                  scroll={{ x: 800, y: `calc(100vh - ${divHeight}px)` }}
                />
                {(showLoading && alertLoad) && (
                  <Loader />
                )}
                {(!(showLoading && alertLoad) && getAlert?.results?.length) === 0 && (
                  <div className="d-flex justify-content-center mt-5">
                    <div className="text-center">
                      <img
                        src={noEventsFound}
                        alt=""
                        height={"200px"}
                        width={"200px"}
                      />
                      <h4>No Events Found.</h4>
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-end pagination_bottom position-absolute end-0 bottom-0 mt-2">
                  <Pagination
                    defaultCurrent={1}
                    current={Number(page_params) || 1}
                    pageSize={20}
                    total={getAlert?.count}
                    onChange={handlePagination}
                    showLessItems={true}
                  />
                </div>
              </>
            )}
        </>)}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        dialogClassName="event-details-modal"
        centered
      >
        <Modal.Body>
          <EventDetailPage
            modalEvent={modalEvent}
            onHide={handleClose}
            prevBtn={handlePrevEvent}
            nextBtn={handleNextEvent}
            onDoubtfulDropdownChange={handleShow}
            autoPlay={autoPlay}
            setAutoPlay={setAutoPlay}
          />
        </Modal.Body>
      </Modal>
      <DateTimeRangeModal
        show={dateTimeRangeModalShow}
        closeDateTimeRangeModalShow={() => setDateTimeRangeModalShow(false)}
        CustomDateHandlerChange={DateHandlerChange}
        onReset={resetDate}
      />
    </div>
  );
};

export default Event;
