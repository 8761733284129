import {
    GET_BACKUP_AND_RESTORE_DATA_REQUEST,
    GET_BACKUP_AND_RESTORE_DATA_SUCCESS,
    GET_BACKUP_AND_RESTORE_DATA_FAIL,

    PUT_BACKUP_AND_RESTORE_DATA_REQUEST,
    PUT_BACKUP_AND_RESTORE_DATA_SUCCESS,
    PUT_BACKUP_AND_RESTORE_DATA_FAIL,
    PUT_BACKUP_AND_RESTORE_DATA_RESET,

} from '../../constants/organizationConstant/backupAndRestoreConstant'


export const getBackupAndRestoreDataReducer = (state = { success: [] }, action) => {
    switch (action.type) {
        case GET_BACKUP_AND_RESTORE_DATA_REQUEST:
            return { loading: true };

        case GET_BACKUP_AND_RESTORE_DATA_SUCCESS:
            return { success: action.payload, loading: false };

        case GET_BACKUP_AND_RESTORE_DATA_FAIL:
            return { loading: false, error: action.error };

        default:
            return state;
    }
};


export const putBackupAndRestoreDataReducer = (state = { success: false }, action) => {
    switch (action.type) {
        case PUT_BACKUP_AND_RESTORE_DATA_REQUEST:
            return { loading: true };

        case PUT_BACKUP_AND_RESTORE_DATA_SUCCESS:
            return { success: true, loading: false };

        case PUT_BACKUP_AND_RESTORE_DATA_FAIL:
            return { loading: false, error: action.error };

        case PUT_BACKUP_AND_RESTORE_DATA_RESET:
            return {};

        default:
            return state;
    }
};