import React, { useEffect, useRef, useState } from 'react';
import { Image, Rect, Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import deleteIcon from '../../../components/jCrop/icons/remove.png';
import useImage from 'use-image';

function Rectangle({ reactId, shapeProps, isSelected, onSelect, onChange, deleteRect, isRetraingSupport, IsautoPlay, imageWidth, imageHeight }) {
    const shapeRef = useRef();
    const trRef = useRef();
    const headingShapeRef = useRef();

    const [deleteBtn] = useImage(deleteIcon);
    const [textWidth, setTextWidth] = useState(0);

    const colorObj = {
        red: '#E73F49',
        orange: '#ff9966',
        yellow: '#ffcc00',
        lightGreen: '#99cc33',
        green: '#339900',
        trBgColor_red: '#e73f4a20',
    };
    const shapeColor = colorObj.red;

    useEffect(() => {
        if (isSelected && trRef.current && shapeRef.current && headingShapeRef.current) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const onMouseEnter = event => {
        event.target.getStage().container().style.cursor = isRetraingSupport && !IsautoPlay ? "move" : 'default';
    };

    const onMouseLeave = event => {
        event.target.getStage().container().style.cursor = isRetraingSupport && !IsautoPlay ? "crosshair" : 'default';
    };

    const handleDragMove = (e) => {
        const node = shapeRef.current;
        const newX = Math.max(0, Math.min(e.target.x(), imageWidth - node.width()));
        const newY = Math.max(0, Math.min(e.target.y(), imageHeight - node.height()));

        node.x(newX);
        node.y(newY);

        onChange({
            ...shapeProps,
            x: newX,
            y: newY,
        });
    };

    const handleTransformEnd = () => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);

        // Ensure the width and height do not fall below 50
        const newWidth = Math.max(50, node.width() * scaleX);
        const newHeight = Math.max(50, node.height() * scaleY);
        const newX = Math.max(0, Math.min(node.x(), imageWidth - newWidth));
        const newY = Math.max(0, Math.min(node.y(), imageHeight - newHeight));

        node.width(newWidth);
        node.height(newHeight);
        node.x(newX);
        node.y(newY);

        onChange({
            ...shapeProps,
            x: newX,
            y: newY,
            width: newWidth,
            height: newHeight,
        });
    };

    return (
        <React.Fragment>
            <Rect
                onMouseDown={onSelect}
                ref={shapeRef}
                stroke={shapeColor}
                strokeWidth={1.5}
                lineJoin="round"
                strokeScaleEnabled={false}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...shapeProps}
                fill={'#e73f4a2d'}
                draggable={isRetraingSupport && !IsautoPlay}
                onDragMove={isRetraingSupport && !IsautoPlay ? handleDragMove : undefined}
                onDragEnd={isRetraingSupport && !IsautoPlay ? handleDragMove : undefined}
                onTransform={isRetraingSupport && !IsautoPlay ? handleTransformEnd : undefined}
            />
            {shapeProps.height === 0 && shapeProps.width === 0 ? null : (
                <React.Fragment>
                    <Rect
                        x={shapeProps.x - 0.8}
                        y={shapeProps.y - 18}
                        ref={headingShapeRef}
                        width={textWidth > shapeProps.width ? textWidth : shapeProps.width + 1.8}
                        height={20}
                        fill={shapeColor}
                        onMouseEnter={e => {
                            const container = e.target.getStage().container();
                            container.style.cursor = "default";
                        }}
                        onMouseLeave={e => {
                            const container = e.target.getStage().container();
                            container.style.cursor = "default";
                        }}
                    />
                    <Text
                        x={shapeProps.x + 3}
                        y={shapeProps.y - 13}
                        text={shapeProps.class}
                        wrap="none"
                        fill='white'
                        ref={(node) => {
                            setTextWidth(node?.getTextWidth() + (isRetraingSupport && !IsautoPlay ? 25 : 8))
                        }}
                    />
                    {isRetraingSupport && !IsautoPlay && (
                        <Image
                            x={shapeProps.x + (textWidth > shapeProps.width ? textWidth - 18 : shapeProps.width - 18)}
                            y={shapeProps.y - 15}
                            width={14}
                            height={14}
                            onClick={() => {
                                deleteRect((prevData) => {
                                    const filteredData = prevData.filter(item => item.class);
                                    return filteredData.filter((_, i) => i !== reactId);
                                });
                            }}
                            image={deleteBtn}
                            onMouseEnter={e => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "pointer";
                            }}
                            onMouseLeave={e => {
                                const container = e.target.getStage().container();
                                container.style.cursor = "default";
                            }}
                        />
                    )}
                </React.Fragment>
            )}
            {isSelected && isRetraingSupport && !IsautoPlay && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    ignoreStroke={true}
                    anchorStrokeWidth={1.5}
                    keepRatio={false}
                    rotateEnabled={false}
                />
            )}
        </React.Fragment>
    );
}

export default Rectangle;
