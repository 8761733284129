import { API_ENDPOINT } from '../../constants/apiEndpoint';
import axios from 'axios';
import {
    GET_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    GET_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    GET_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

    POST_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    POST_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    POST_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    UPDATE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

    DELETE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST,
    DELETE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
    DELETE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,

} from '../../constants/organizationConstant/rolesAndResponsibilityConstants';
import { deleteAPI, getAPI, postAPI, putAPI } from '../../global/ApiManager';

export const getRolesAndResponsibilityData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ROLES_AND_RESPONSIBILITY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/get_roles_table_data/`, dispatch)

        dispatch({
            type: GET_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
            error: error
                ? error.data.detail
                : error.message,
        })
    }
};

export const getSingleRolesAndResponsibilityData = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await getAPI(config, `/app/user_access_table_api/${id}`, dispatch)

        dispatch({
            type: GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_SINGLE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
            error: error
                ? error.data
                : error.message,
        })
    }
};


export const postRolesAndResponsibilityData = (postData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POST_ROLES_AND_RESPONSIBILITY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await postAPI(
            `/app/user_access_table_api/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: POST_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};


export const updateRolesAndResponsibilityData = (postData, id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await putAPI(
            `/app/user_access_table_api/${id}/`,
            postData,
            config,
            dispatch
        )

        dispatch({
            type: UPDATE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: UPDATE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};

export const deleteRolesAndResponsibilityData = (userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_ROLES_AND_RESPONSIBILITY_DATA_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await deleteAPI(
            `/app/user_access_table_api/${userId}`,
            config,
            dispatch
        )

        dispatch({
            type: DELETE_ROLES_AND_RESPONSIBILITY_DATA_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: DELETE_ROLES_AND_RESPONSIBILITY_DATA_FAIL,
            error: error && Object.values(error.data)[0]
                ? Object.values(error.data)[0]
                : error.message,
        })
    }
};

export const getUserRoles = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/roles_api/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}


export const getConfigServices = async (token) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        const { data } = await axios.get(
            `${API_ENDPOINT}/app/get_config_services_api/`,
            config
        )

        return { 'data': data }
    } catch (error) {
        return { 'data': [] }
    }
}